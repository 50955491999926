import store from '../store/index';

export function required(value) {
  if (value instanceof File) {
    return !!value.size;
  }
  if (typeof value === 'string') {
    return (value && !!value.length) || store.state.translations.keys.form_field_required;
  }
  if (typeof value === 'number') {
    return !!value || store.state.translations.keys.form_field_required;
  }

  return store.state.translations.keys.form_field_required;
}
