<template>
  <v-container fluid>
    <v-dialog
      v-model="login"
      persistent
      scrollable
      max-width="480">
      <v-card>
        <v-toolbar
          color="secondary"
          >
          <v-toolbar-title>
            {{translate('login')}}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text
          class="mt-3">
          <v-container>
            <v-text-field
              v-model="username"
              :label="translate('Username')"
              @keypress.enter="loginUser"
              prepend-icon="mdi-account"
            ></v-text-field>
            <v-text-field
              type="password"
              v-model="password"
              :label="translate('login_password')"
              @keypress.enter="loginUser"
              prepend-icon="mdi-lock-check"
            ></v-text-field>
            <v-select
              class="dropdown-value"
              dense
              prepend-icon="mdi-rocket-launch"
              v-model="api_link"
              :items="environments"
              item-text="text"
              item-value="link"
            ></v-select>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            large
            color="accent"
            @click.stop="loginUser();">
            {{translate('login')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Snackbar
        :text="notificationText"
        :type="notificationType"/>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import Snackbar from '../components/Snackbar';

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      api_link: process.env.VUE_APP_API_BASE_URL,
      login: true,
      notificationType: 'error',
      notificationText: '',
      environments: [
        { text: 'BiH', link: 'https://api.zame.dev', entityCode: 'bh' },
        { text: 'Croatia', link: 'https://hr1.zame.dev', entityCode: 'hr' },
        { text: 'Macedonia', link: 'https://mk.zame.dev', entityCode: 'mk' },
        { text: 'Montenegro', link: 'https://cg.zame.dev', entityCode: 'cg' },
        { text: 'Serbia', link: 'https://sr.zame.dev', entityCode: 'sr' },
        { text: 'Slovenia', link: 'https://sl.zame.dev', entityCode: 'sl' },
        { text: 'Serbia Local', link: 'http://10.200.114.75:5555', entityCode: 'sr' },
        { text: 'Local environment', link: 'http://127.0.0.1:5555', entityCode: 'le' },
      ],
    };
  },
  components: {
    Snackbar,
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
  },
  created() {
    localStorage.setItem('entity_code', 'none');
  },
  methods: {
    async loginUser() {
      const success = await this.$store.dispatch('auth/login', {
      username: this.username,
      password: this.password,
      apiLink: this.api_link,
      });
      if (success) {
        localStorage.setItem('api_link', this.api_link);
        for (let i = 0; i < this.environments.length; i += 1) {
          if (this.environments[i].link === this.api_link) {
            localStorage.setItem('entity_code', this.environments[i].entityCode);
            break;
          }
        }
        this.$router.go();
      } else {
        this.notificationText = this.translate('login_wrong_password');
      }
    },
    loginUserOld() {
      if (window.btoa(this.password) === 'emFtZQ==') {
        localStorage.setItem('authenticatedAt', Date.now());
        localStorage.setItem('token', 'emFtZQ==');
        localStorage.setItem('api_link', this.api_link);
        for (let i = 0; i < this.environments.length; i += 1) {
          if (this.environments[i].link === this.api_link) {
            localStorage.setItem('entity_code', this.environments[i].entityCode);
            break;
          }
        }
        this.$router.go();
      } else {
        this.notificationText = this.translate('login_wrong_password');
      }
    },
  },
};
</script>

<style>
  .v-input__slot {
    padding: 6px;
  }
  .v-input__prepend-outer {
    padding-top: 6px;
  }
</style>
