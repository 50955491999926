import axios from 'axios';

const apiToken = process.env.VUE_APP_API_TOKEN;

const state = {
  token: localStorage.getItem('token') || '',
};


const actions = {
  async login({ commit }, { username, password, apiLink }) {
    console.log(apiLink, 'api_ling', apiToken, '::', process.env.VUE_APP_API_TOKEN);
    try {
      const result = await axios({
        method: 'POST',
        url: `${apiLink}/auth/`,
        data: { username, password },
        headers: {
          'x-api-key': apiToken,
        },
      });
      commit('setUserToken', result.data.token);
      localStorage.setItem('authenticatedAt', Date.now());
      localStorage.setItem('token', 'emFtZQ==');
      localStorage.setItem('tokenUser', result.data.token);
      return true;
    } catch (error) {
      return false;
    }
  },
};

const mutations = {
  setUserToken: (currentState, token) => ({ ...currentState, token }),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
