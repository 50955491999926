<template>
  <tr :class="[invalidData ? 'invalid' : '', 'planning-row']">

    <td :class="[errorBucket.option ? 'has-error' : '', 'input-value']">
      <v-edit-dialog
        large
        eager
        :return-value.sync="item.option">
        {{ item.option }}
        <template v-slot:input>
          <v-text-field v-model="item.option"
            ref="option"
            single-line
            counter />
        </template>
      </v-edit-dialog>
    </td>
    <td :class="[errorBucket.value ?'has-error' : '', 'input-value']">
      <v-edit-dialog
        eager
        large
        :return-value.sync="item.value">
        {{ item.value }}
        <template v-slot:input>
          <v-text-field v-model="item.value"
            ref="value"
            single-line
            counter />
        </template>
      </v-edit-dialog>
    </td>


    <td ref="delete">
      <v-icon small
              @click.stop="deleteItem()">
        mdi-delete
      </v-icon>
    </td>

  </tr>
</template>

<script>
import { each } from 'lodash';
import { mapGetters } from 'vuex';
import validators from '@/validators';

export default {
  name: 'OptionCategoryRow',
  props: {
    item: {
      type: Object,
    },
    rowIndex: Number,
  },
  data() {
    return {
      validators,
      invalidData: false,
      errorBucket: {},
      OptionsOption: [],
    };
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
  },
  created() {
    // this.OptionCategory = this.getOptionCategory;
  },
  methods: {

    deleteItem() {
      this.$emit('delete', { item: this.item, rowIndex: this.rowIndex });
    },
    validate() {
      this.errorBucket = {};
      each(this.$refs, (val, key) => {
        if (val.errorBucket && val.errorBucket.length) this.errorBucket[key] = val.errorBucket;
      });

      if (Object.keys(this.errorBucket).length) {
        this.invalidData = true;
      } else {
        this.invalidData = false;
      }

      return this.errorBucket;
    },
  },
};
</script>

<style scoped lang="scss" >
.planning-row {
  $input-size: 10px !important;

  &.invalid > td:first-of-type{
    border-left: solid 4px #ff0000;
  }

  &.invalid > td {
    background: #fcecec;

    &.has-error {
      background: rgba(240, 52, 52, 1);
    }
  }

  .has-error {
    background: #fcecec;
  }

  .input-value {
    font-size: $input-size;
  }

  .dropdown-value {
    font-size: $input-size;

    &.fixed-size {
      width: 100px;

      &.medium {
        width: 150px;
      }

      &.large {
        width: 200px;
      }

      &.extra-large {
        width: 250px;
      }
    }
  }

  .depreciation {
    background: #eeeeee;
  }
}
</style>
