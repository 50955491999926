import Vue from 'vue';
import Vuetify, { VListItem } from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify, {
  components: {
    VListItem,
  },
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#607D8B',
        secondary: '#FFC107',
        accent: '#FFA000',
      },
    },
  },
});
