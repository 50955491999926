export function validatePlanningRows(grid, rows) {
  let isValid = true;
  for (let i = 0; i < rows.length; i += 1) {
    const idx = grid.getRowIndexByPrimaryKey(rows[i].id);
    const row = grid.getRowByIndex(idx);
    const cols = grid.getColumns();
    for (let j = 0; j < cols.length; j += 1) {
      if (cols[j].validationRules
          && cols[j].validationRules.required === true
          && row.children[j].textContent === '') {
        row.children[j].classList.add('error');
        isValid = false;
      }
    }
  }
  return isValid;
}
