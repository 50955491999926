<template>
  <ul class="breadcrumbs">
    <li v-for="(item, idx) in items"
      @click="navigate(item.route)"
      :class="{ disabled: item.disabled }"
      :key="idx">
      <span v-if="item.translationKey">
        {{translate(item.translationKey)}}
      </span>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Breadcrumbs',
  props: {
    items: Array,
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
  },
  methods: {
    navigate(route) {
      this.$router.push(route);
    },
    lastItemInList(items, idx) {
      return idx === items.length - 1;
    },
  },
};
</script>

<style lang="scss" scoped>
  .breadcrumbs {
      list-style: none;
      display: inline-flex;
      margin-bottom: 0.75em;
      padding: 0;

    & li {
      cursor: pointer;
      user-select: none;

      &.disabled {
        pointer-events: none;
        cursor: not-allowed;

        span {
          color: #FFC107;
        }
      }

      &:first-child span {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 0 0.5em 0 1em;

        &:before {
          display: none;
        }
      }

      span {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 36px;
        background: #607D8B;
        text-align: center;
        position: relative;
        margin: 0 8px 0 0;
        padding: 0 1em 0 2em;
        font-size: 14px;
        font-weight: 500;
        font-stretch: 100%;
        text-decoration: none;
        color: #ffffff;
        box-sizing: border-box;
        text-transform: uppercase;
        letter-spacing: .0892857143em;

        &:after {
          content: "";
          border-top: 18px solid transparent;
          border-bottom: 18px solid transparent;
          border-left: 20px solid #607D8B;
          position: absolute;
          right: -20px;
          top: 0;
          z-index: 1;
        }

        &:before {
          content: "";
          border-top: 18px solid transparent;
          border-bottom: 18px solid transparent;
          border-left: 20px solid #f3f3f3;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 0;
        }
      }
    }
  };
</style>
