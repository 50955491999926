<template>
  <v-container fluid>
    <Breadcrumbs
      :items="breadcrumbs"/>
    <v-data-table fixed-header
      dense
      class="elevation-1"
      :page.sync="pagination.page"
      :server-items-length="pagination.total || items.length"
      :headers="headers"
      :items="items"
      :footer-props="{
        'items-per-page-options': pagination.itemsPerPage
      }"
      :hide-default-footer="true"
      :items-per-page="pagination.size">
      <template v-slot:top>
        <v-toolbar flat>
          <v-col cols="12" sm="3"
            class="px-0">
            <v-text-field
              clearable
              v-model="search"
              append-icon="mdi-magnify"
              :label="translate('plannings_sales_search_items')"
              single-line
              hide-details
            ></v-text-field>
           </v-col>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:header="props">
        <thead>
          <tr>
            <th :colspan="headers.length - capexDepreciationPeriods.length
                                         - capexBookValuePeriods.length
                                         - gainLossPeriods.length"
              class="text-center">
              {{translate('plannings_capex_ol_items')}}
            </th>
            <th :colspan="capexDepreciationPeriods.length"
              class="text-center depreciation">
              {{translate('plannings_capex_ol_depreciation_calc')}}
            </th>
            <th :colspan="capexBookValuePeriods.length"
              class="text-center">
              {{translate('plannings_capex_ol_book_value_calc')}}
            </th>
            <th :colspan="gainLossPeriods.length"
              class="text-center">
              {{translate('plannings_capex_ol_gain_loss')}}
            </th>
          </tr>
          <tr>
            <th v-for="(header, idx) in props.headers"
              :key="idx">
              {{header.text}}
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:body="props">
        <tbody>
          <tr v-for="(item, itemIdx) in props.items"
            v-show="!search || (item.product_name.indexOf(search) !== -1)"
            :ref="`row-${item.id}-${itemIdx}`"
            :key="`row-${item.id}-${itemIdx}`"
            :item="item">
            <td>
              {{item.planning_period}}
            </td>
            <td>
              {{item.leasing_type}}
            </td>
            <td>
              {{item.segment}}
            </td>
            <td>
              {{item.product_group}}
            </td>
            <td>
              {{item.product_name}}
            </td>
            <td>
              {{item.channel}}
            </td>
            <td>
              {{item.currency}}
            </td>
            <td>
              {{item.maturity}}
            </td>
            <td>
              {{item.guarantee | toPercentage('string')}}
            </td>
            <td>
              {{item.residual_value | toPercentage('string')}}
            </td>
            <td>
              {{item.ppmv | toPercentage('string')}}
            </td>
            <td>
              {{item.capex}}
            </td>
            <td>
              <v-edit-dialog
                large
                eager>
                {{ item.depreciation_period }}
                <template v-slot:input>
                  <v-text-field v-model="item.depreciation_period"
                    ref="depreciation_period"
                    single-line
                    counter />
                </template>
              </v-edit-dialog>
            </td>
            <td>
              {{item.purchase_value_to_be_depreciated | toPercentage('string')}}
            </td>
            <td>
              {{item.depreciation_rate | toPercentage('string')}}
            </td>
            <td>
              {{item.rv_deposit}}
            </td>
            <td>
              {{item.book_value}}
            </td>
            <td>
              {{item.total}}
            </td>
            <td v-for="(depreciation, depreciationIdx) in item.depreciation_calc"
              class="depreciation"
              :key="`depreciation-${itemIdx}-${depreciationIdx}`">
              {{depreciation.value}}
            </td>
            <td v-for="(bookValue, bookValueIdx) in item.book_value_calc"
              :key="`book-value-${itemIdx}-${bookValueIdx}`">
              {{bookValue.value}}
            </td>
            <td v-for="(gainLossValue, gainLossIdx) in item.gain_loss_calc"
              class="gain-loss"
              :key="`gain-loss-${itemIdx}-${gainLossIdx}`">
              {{gainLossValue.value}}
            </td>
          </tr>
          <tr v-if="!props.items.length"
            class="v-data-table__empty-wrapper">
            <td :colspan="headers.length">{{translate('plannings_capex_no_items')}}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <Snackbar :text="notificationText"
      :type="notificationType" />
    <v-overlay :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate
        size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Breadcrumbs from '../components/Breadcrumbs';
import Snackbar from '../components/Snackbar';
import api from '../api/lbt-api';
import { generateCapexDepreciationPeriods } from '../utilities/capexDepreciationPeriods';

const {
  getCapexOLForPlanning,
  updateCapexOlForPlanning,
  getPlanning,
} = api;

export default {
  name: 'CapexOLPlanning',
  components: {
    Breadcrumbs,
    Snackbar,
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
  },
  data() {
    return {
      notificationText: '',
      notificationType: '',
      loader: false,
      items: [],
      headers: [],
      search: '',
      pagination: {
        page: 1,
        size: 200,
        total: 0,
        itemsPerPage: [25, 50, 100],
      },
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'breadcrumbs_plannings_capex_ol',
          disabled: true,
        },
      ],
      capexDepreciationPeriods: [],
      capexBookValuePeriods: [],
      gainLossPeriods: [],
    };
  },
  methods: {
    async fetchCapexOlItems() {
      const capexOl = await getCapexOLForPlanning(this.$route.params.planningId, this.pagination);
      this.items = capexOl.capex_ol_entries;
      this.pagination.total = capexOl.pagination.total;
    },
    async savePlanning() {
      const capexOlRequests = [];

      // TODO: Send all capex items in bulk request
      this.loader = true;
      try {
        for (let iterator = 0; iterator < this.items.length; iterator += 1) {
          capexOlRequests.push(updateCapexOlForPlanning(this.$route.params.planningId, this.items[iterator]));
        }
        await Promise.all(capexOlRequests);
        this.notificationText = this.translate('notifications_planning_updated');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchCapexOlItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
  },
  async created() {
    this.loader = true;
    this.headers = [
      {
        text: this.translate('plannings_capex_ol_placement_period'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_leasing_type'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_segment'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_product_group'),
        align: 'left',
        sortable: false,
        width: 150,
      },
      {
        text: this.translate('plannings_capex_ol_product_name'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_channel'),
        align: 'left',
        sortable: false,
        width: 150,
      },
      {
        text: this.translate('plannings_capex_ol_currency'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_maturity'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_guarantee'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_residual_value'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_ppmv'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_capex'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_depreciation_period'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_purchuase_value_depreciated'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_depreciation_rate'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_rv_deposit'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_book_value'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_total'),
        align: 'left',
        sortable: false,
      },
    ];
    try {
      // const start = new Date().getTime();
      await this.fetchCapexOlItems();
      const planning = await getPlanning(this.$route.params.planningId);
      this.capexDepreciationPeriods = generateCapexDepreciationPeriods(planning.year, planning.month - 1);
      this.capexBookValuePeriods = generateCapexDepreciationPeriods(planning.year, planning.month - 1);
      this.gainLossPeriods = generateCapexDepreciationPeriods(planning.year, planning.month - 1);
      // const end = new Date().getTime();
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
    this.capexDepreciationPeriods.forEach((el) => {
      this.headers.push({
        text: el,
        align: 'left',
        sortable: false,
        class: 'depreciation',
        value: el,
      });
    });
    this.capexBookValuePeriods.forEach((el) => {
      this.headers.push({
        text: el,
        align: 'left',
        sortable: false,
        value: el,
      });
    });
    this.gainLossPeriods.forEach((el) => {
      this.headers.push({
        text: el,
        align: 'left',
        sortable: false,
        class: 'gain-loss',
        value: el,
      });
    });
  },
};
</script>

<style lang="scss" scoped>
table {

  tr {
    $input-size: 10px !important;

    td {
      font-size: $input-size;
      height: 37px;

      &.depreciation, &.gain-loss {
        background: #eeeeee;
      }
    }
  }
}
</style>
