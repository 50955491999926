<template>
  <div>
    <v-container fluid style="padding-bottom: 0 !important;;">
      <v-row>
        <v-col sm="12" xs="12">
          <Breadcrumbs
            :items="breadcrumbs"/>
        </v-col>
        <v-col sm="2" xs="12">
          <v-text-field v-if="editMode"
            v-model="modifier"
            :label="'Exchange rate modifier'"
            :hint="'Enter to confirm'"
            prepend-icon="mdi-compare-vertical"
            @keypress.enter="saveModifier"
            style="margin: 0 !important; padding: 0 !important;"
          ></v-text-field>
        </v-col>
        <v-col sm="2" xs="12">
          <v-text-field
            v-model="fx1a"
            v-if="editMode"
            :label="'Year -1 avg'"
            :hint="'Enter to confirm'"
            prepend-icon="mdi-compare-vertical"
            @keypress.enter="saveMinusOne"
            style="margin: 0 !important; padding: 0 !important;"
          ></v-text-field>
        </v-col>
        <v-col sm="2" xs="12">
          <v-text-field
            v-model="fx2p"
            v-if="editMode"
            :label="'Year -2 eoy'"
            :hint="'Enter to confirm'"
            prepend-icon="mdi-compare-vertical"
            @keypress.enter="saveMinusTwop"
            style="margin: 0 !important; padding: 0 !important;"
          ></v-text-field>
        </v-col>
        <v-col sm="2" xs="12">
          <v-text-field
            v-model="fx2a"
            v-if="editMode"
            :label="'Year -2 avg'"
            :hint="'Enter to confirm'"
            prepend-icon="mdi-compare-vertical"
            @keypress.enter="saveMinusTwo"
            style="margin: 0 !important; padding: 0 !important;"
          ></v-text-field>
        </v-col>
        <v-col sm="2" xs="12">
          <v-text-field
            v-model="fxbs"
            v-if="editMode"
            :label="'BS BDG'"
            :hint="'Enter to confirm'"
            prepend-icon="mdi-compare-vertical"
            @keypress.enter="saveBsBDG"
            style="margin: 0 !important; padding: 0 !important;"
          ></v-text-field>
        </v-col>
        <v-col sm="2" xs="12">
          <v-text-field
            v-model="fxpl"
            v-if="editMode"
            :label="'P&L BDG'"
            :hint="'Enter to confirm'"
            prepend-icon="mdi-compare-vertical"
            @keypress.enter="savePlBDG"
            style="margin: 0 !important; padding: 0 !important;"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <v-toolbar flat style="border: 1px solid rgb(214 208 208)">
            <v-col cols="12" sm="3"
                   class="px-0">
              <ejs-dropdownlist id='dropdownlist' ref='dropdown'
                                v-model="currencyModel"
                                :dataSource='currenciesDataSource'
                                :change='onCurrencyChange'
                                placeholder='Currency..'
                                width='100%'>
              </ejs-dropdownlist>
            </v-col>
          </v-toolbar>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <ejs-grid
        :columns='gridColumns' :dataSource='gridItems' v-if="gridItems.length"  :allowSorting="true"  :load='load' :cellSave='cellSave'
        :editSettings='editSettings' :selectionSettings='selectionOptions' :allowResizing='true'
        :pageSettings='pageSettings' :sortSettings='sortOptions' :searchSettings='searchOptions'
        :allowPaging='true' :commandClick='commandClick' gridLines='Horizontal' :allowExcelExport='true'
        :excelHeaderQueryCellInfo='excelHeader' :actionComplete='customiseCell'>
      </ejs-grid>
      <v-btn color="accent"
             fab
             fixed
             right
             bottom
             @click.stop="saveFx()">
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
      <Snackbar :text="notificationText"
                :type="notificationType" />
      <v-overlay :value="loader"
                 :z-index="1000">
        <v-progress-circular indeterminate
                             size="64"></v-progress-circular>
      </v-overlay>
    </v-container>
  </div>
</template>

<script>
  import Vue from 'vue';
  import {
    GridPlugin, Page, Sort, Edit, Resize, Search, ExcelExport, CommandColumn,
  } from '@syncfusion/ej2-vue-grids';
  import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';
  import { Query } from '@syncfusion/ej2-data';
  import { mapGetters, mapState } from 'vuex';
  import Snackbar from '../components/Snackbar';
  import Breadcrumbs from '../components/Breadcrumbs';
  import { ejsSingleClickToEditCell } from '../utilities/ejsSingleClickToEditCell';

  import api from '../api/lbt-api';
  import store from '../store';

  const {
    getFx,
    updateFx,
    updateFxModifier,
    updateFxArchive,
    getPlanning,
  } = api;

  Vue.use(GridPlugin);
  Vue.use(DropDownListPlugin);

  export default {
    name: 'OtherInput',
    components: {
      Snackbar,
      Breadcrumbs,
    },
    data() {
      return {
        dataLoaded: false,
        search: '',
        modifier: '',
        fx1a: '',
        fx2p: '',
        fx2a: '',
        fxbs: '',
        fxpl: '',
        gridColumns: [],
        gridItems: [],
        notificationText: '',
        notificationType: '',
        deleteDialog: false,
        deleteDialogDescription: '',
        itemForDeletion: {},
        loader: false,
        editMode: true,
        items: [],
        otherFinreps: [],
        planningInfo: {},
        breadcrumbs: [
          {
            translationKey: 'breadcrumbs_plannings',
            route: '/plannings',
            disabled: false,
          },
          {
            translationKey: 'exchange rates',
            disabled: true,
          },
        ],
        placementPeriodValues: [],
        otherPeriods: [],
        editSettings: {
          allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch',
        },
        sortOptions: { columns: [{ field: 'tempId', direction: 'Ascending' }, { field: 'id', direction: 'Descending' }] },
        selectionOptions: { mode: 'Cell', type: 'Multiple', cellSelectionMode: 'Box' },
        searchOptions: { fields: ['note'], operator: 'contains', ignoreCase: true },
        dropdownValidationRule: { required: true },
        pageSettings: { pageSizes: true, pageSize: 20 },
        highlightCellAttributes: { class: ['dont-highlight-cell', 'no-arrows'] },
        deleteActionCommand: [{ buttonOption: { cssClass: 'v-icon notranslate v-icon--link mdi mdi-delete theme--light delete-action' } }],
        finrepCodeTypeParams: this.initDropdownParams(),
        otherTypeParams: this.initDropdownParams(),
        otherPeriodColumns: [],
        pagination: {
          page: 1,
          size: 25000,
          total: 0,
          itemsPerPage: [2500, 5000, 10000],
        },
        currencyModel: null,
        currenciesDataSource: [],
      };
    },
    async created() {
      this.loader = true;
        await this.fetchFxItems(this.pagination);
      try {
        this.modifier = this.activePlanning.exchange_rate_modifier;
        // const option = await getOption(this.$route.params.optionId);
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    computed: {
      ...mapGetters('translations', [
        'translate',
      ]),
      ...mapGetters('options', [
        'getOptionCategory',
      ]),
      ...mapState('plannings', [
        'activePlanning',
      ]),
    },
    watch: {
      search(searchText) {
        const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
        if (searchText !== null && searchText.length > 1) {
          grid.search(searchText);
        } else {
          grid.search();
        }
      },
    },
    provide: {
      grid: [Page, Sort, Edit, Resize, Search, ExcelExport, CommandColumn],
    },
    methods: {
      async fetchFxItems(pagination) {
        const planning = await getPlanning(this.$route.params.planningId);
        this.planningInfo = planning;
        const optionVals = await getFx(this.$route.params.planningId, null, pagination);
        this.items = optionVals.fx_entries;
        this.pagination.total = optionVals.pagination.total;

        if (this.currenciesDataSource.length === 0) {
          const currencies = [];
          for (let i = 0; i < this.getOptionCategory('CURRENCY').options.length; i += 1) {
            currencies.push({
              text: this.getOptionCategory('CURRENCY').options[i].option,
              value: this.getOptionCategory('CURRENCY').options[i].id,
            });
          }
          this.currenciesDataSource = currencies;
          const self = this;
          const interval = setInterval(() => {
            if (self.activePlanning) {
              self.currencyModel = self.activePlanning.lcy_option.option;
              clearInterval(interval);
            }
          }, 100);
        }
        if (this.planningInfo.locked === 1) {
          this.editSettings.allowEditing = false;
          this.editSettings.allowAdding = false;
          this.editSettings.allowDeleting = false;
          this.editMode = false;
        }
      },
      async onCurrencyChange() {
        this.loader = true;
        const optionVals = await getFx(this.$route.params.planningId, this.currencyModel, this.pagination);
        this.items = optionVals.fx_entries;
        this.pagination.total = optionVals.pagination.total;
        const gridItem = {};
        const gridColumns = [];
        for (let i = this.items.length - 1; i >= 0; i -= 1) {
          gridColumns.push({
            field: `${this.items[i].period}`,
            headerText: `${this.items[i].period}`,
            customAttributes: this.highlightCellAttributes,
            width: 75,
            allowEditing: true,
            type: 'numeric',
            editType: 'numericedit',
            // allowSorting: false,
          });
          gridItem[this.items[i].period] = this.items[i].value;
        }
        this.gridItems = [gridItem];
        this.gridColumns = gridColumns;
        this.loader = false;
      },
      load() {
        const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
        ejsSingleClickToEditCell(grid);
        if (this.planningInfo.locked === 1) {
          this.editSettings.allowEditing = false;
          this.editSettings.allowAdding = false;
          this.editSettings.allowDeleting = false;
        }
      },
      customiseCell(queryCellInfo) {
        const { cell } = queryCellInfo;
        if (cell && cell.textContent === 'NaN') {
          cell.classList.add('error');
        }
      },
      lookupOtherPeriodHeader(idx) {
        return this.otherPeriods[Number(idx)];
      },
      initDropdownParams() {
        return {
          params: {
            dataSource: null,
            fields: { text: 'title', value: 'id' },
            query: new Query(),
          },
        };
      },
      dropdownValueAccess(field, data) {
        return this.getOtherFinrepValue(field, data[field]);
      },
      commandClick(args) {
        this.deleteItem(args.rowData);
      },
      cellSave(args) {
        if (args.value !== '') {
          const { cell } = args;
          cell.classList.add('e-updatedtd');
          cell.classList.remove('error');
        }
      },
      excelDownloadClick() {
        const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
        const excelExportProperties = {
          // exportType: 'CurrentPage',
          fileName: `Other Input - Planning - ${this.activePlanning.year}-${this.activePlanning.month}.xlsx`,
        };
        grid.excelExport(excelExportProperties);
      },
      saveModifier() {
        updateFxModifier(this.$route.params.planningId, this.modifier);

        this.notificationText = 'Exchange rate modifier updated!';
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
      },
      saveMinusOne() {
        updateFxArchive(this.$route.params.planningId, this.fx1a, `${(this.activePlanning.year - 1)}AVG`);

        this.notificationText = 'Minus One period updated!';
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
      },
      saveMinusTwo() {
        updateFxArchive(this.$route.params.planningId, this.fx2a, `${(this.activePlanning.year - 2)}AVG`);

        this.notificationText = 'Minus One period  updated!';
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
      },
      saveMinusTwop() {
        updateFxArchive(this.$route.params.planningId, this.fx2p, `${(this.activePlanning.year - 2)}YE`);

        this.notificationText = 'Minus One period eoy updated!';
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
      },
      saveBsBDG() {
        updateFxArchive(this.$route.params.planningId, this.fxbs, `${(this.activePlanning.year)}BDG`);

        this.notificationText = 'BS BDG updated!';
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
      },
      savePlBDG() {
        updateFxArchive(this.$route.params.planningId, this.fxpl, `${(this.activePlanning.year)}ADG`);

        this.notificationText = 'P&L BDG updated!';
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
      },
      excelHeader(args) {
        const { field } = args.gridCell.column;
        if (field !== undefined && field.includes('.')) {
          const { cell } = args;
          if (field.startsWith('other_period')) {
            cell.value = this.otherPeriods[Number(cell.value)];
          }
        }
      },
      setDropdownDatasources() {
        const parentFinreps = [];
        const childrenFinreps = [];

        this.otherFinreps.forEach((item) => {
          if (!item.parent_id || item.parent_id === 0 || item.parent_id === '') {
            parentFinreps.push(item);
          } else {
            childrenFinreps.push(item);
          }
        });

        this.finrepCodeTypeParams.params.dataSource = childrenFinreps;
        this.otherTypeParams.params.dataSource = parentFinreps;
      },
      getOtherFinrepValue(key, value) {
        let fetchedValue = '';
        this.otherFinreps.forEach((item) => {
          if (item.id === value) {
            fetchedValue = item.title;
          }
        });
        return fetchedValue;
      },
      validate() {
        return true;
      },
      setDialogState(value) {
        this.deleteDialog = value;
      },
      async saveFx() {
        const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
        // let [...addedRecords] = grid.getBatchChanges().addedRecords;
        const [...changedRecords] = grid.getBatchChanges().changedRecords;
        const diffa = Object.keys(changedRecords[0]).reduce((diff, key) => {
          if (this.gridItems[0][key] === changedRecords[0][key]) return diff;
          return {
            ...diff,
            [key]: changedRecords[0][key],
          };
        }, {});

        const fxUpdateRequests = [];

        try {
          Object.keys(diffa).forEach((key) => {
            for (let i = 0; i < this.items.length; i += 1) {
              if (this.items[i].period === key) {
                this.items[i].value = diffa[key];
                fxUpdateRequests.push(updateFx(this.$route.params.planningId, this.items[i], this.items[i].id));
              }
            }
          });

          await Promise.all(fxUpdateRequests);
          this.notificationText = this.translate('notifications_planning_updated');
          this.notificationType = 'success';
          setTimeout(() => { this.notificationText = null; }, 100);
          await this.onCurrencyChange();
        } catch (message) {
          this.notificationText = message;
          this.notificationType = 'error';
          setTimeout(() => { this.notificationText = null; }, 100);
        } finally {
          this.loader = false;
        }
        /*
        this.loader = true;

        try {
          for (let iterator = 0; iterator < rowNum; iterator += 1) {
            fxUpdateRequests.push(updateFx(this.$route.params.planningId, this.items[iterator], this.items[iterator].id));
          }
          await Promise.all(fxUpdateRequests);
          this.notificationText = this.translate('notifications_planning_updated');
          this.notificationType = 'success';
          await this.fetchFxItems();
        } catch (message) {
          this.notificationText = message;
          this.notificationType = 'error';
        } finally {
          this.loader = false;
        }
         */
      },
    },
    async beforeRouteEnter(from, to, next) {
      await store.dispatch('finrepBsPl/getOptions');
      next();
    },
  };
</script>

<style>
  @import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-icons/styles/material.css";

  .container {
    padding-bottom: 0 !important;
  }

  .hig2hlight-cell {
    height: 70px !important;
  }

</style>
