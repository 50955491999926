/* eslint-disable */
export function isAuthenticated() {
  const authTime = localStorage.getItem('authenticatedAt');
  const token = localStorage.getItem('token');
  if (authTime  && token === 'emFtZQ==' && (Date.now() - authTime < 14400000)) {
    return true;
  } else {
    localStorage.removeItem('authenticatedAt');
    return false;
  }
};
