export function generateInterestRatePeriods(startYear, startMonth) {
  const numOfMonths = 12;
  const numOfQuarters = 4;
  const interestRatePeriods = [];
  let nextYear = startYear + 1;
  let month = startMonth;

  while (month <= numOfMonths) {
    interestRatePeriods.push({
      period: `${startYear}M${month}`,
      value: 0,
    });
    month += 1;
  }

  for (let iterator = 1; iterator <= numOfQuarters; iterator += 1) {
    interestRatePeriods.push({
      period: `${nextYear}Q${iterator}`,
      value: 0,
    });
  }

  for (let yearIterator = 1; yearIterator <= 4; yearIterator += 1) {
    nextYear += 1;
    interestRatePeriods.push({
      period: `${nextYear}`,
      value: 0,
    });
  }

  return interestRatePeriods;
}
