<template>
  <v-container fluid class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>
    <v-toolbar flat>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click='excelDownloadClick'>
        Export to Excel
      </v-btn>
    </v-toolbar>
    <ejs-treegrid ref="grid" :dataSource='items' v-if='dataLoaded' childMapping='sub' :treeColumnIndex='0'
                  :height='560' :allowExcelExport='true' :excelHeaderQueryCellInfo='excelHeader'
                  :allowFiltering='true' :filterSettings="{type: 'Menu'}" :allowResizing="true" :resizeStop="resizeStop" >
      <e-columns>
        <e-column field='id' headerText='ID' :visible=false isPrimaryKey='true'>
        </e-column>
        <e-column field='tempId' headerText='tempID' :visible=false>
        </e-column>
        <e-column field='summary_pos' :headerText="translate('plannings_plbs_summary_pos')" :width='columnWidths[2]'>
        </e-column>
        <e-column field='position' :headerText="translate('plannings_plbs_position')" :width='columnWidths[3]'>
        </e-column>
        <e-column textAlign='center' :headerText="translate('Periods')" :columns='periodsColumns'>
        </e-column>
      </e-columns>
    </ejs-treegrid>
    <!--
    <v-btn color="accent"
      fab
      fixed
      right
      bottom
      @click.stop="toolbarClick()">
      <v-icon dark>mdi-content-save</v-icon>
    </v-btn>
    -->
    <Snackbar :text="notificationText"
              :type="notificationType" />
    <v-overlay :value="loader"
               :z-index="1000">
      <v-progress-circular indeterminate
                           size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import Vue from 'vue';
import Breadcrumbs from '@/components/Breadcrumbs';
import Snackbar from '@/components/Snackbar';
import {
  TreeGridPlugin, Page, Sort, Resize, Filter, ExcelExport,
} from '@syncfusion/ej2-vue-treegrid';
import api from '../api/lbt-api';

const {
  getSummaryCAPEXForPlanning,
} = api;

Vue.use(TreeGridPlugin);

export default {
  name: 'SummaryPEREX',
  components: {
    Breadcrumbs,
    Snackbar,
  },
  data() {
    return {
      notificationText: '',
      loader: false,
      dataLoaded: false,
      notificationType: null,
      items: [],
      expanded: [],
      periods: [],
      toolbar: ['ExcelExport', 'PdfExport', 'CsvExport'],
      periodsColumns: [],
      sortOptions: { columns: [{ field: 'tempId', direction: 'Ascending' }, { field: 'id', direction: 'Descending' }] },
      selectionOptions: { mode: 'Cell', type: 'Multiple', cellSelectionMode: 'Box' },
      searchOptions: { fields: ['leasing_type_id'], operator: 'contains', ignoreCase: true },
      pageSettings: { pageSizes: true, pageSize: 100 },
      columnWidths: [0, 0, 220, 220],
      storagePrefix: 'summary_capex',
      storageSeparator: '.-.',
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'CAPEX',
          disabled: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
    ...mapState('plannings', [
      'activePlanning',
    ]),
  },
  provide: {
    treegrid: [Page, Sort, Resize, Filter, ExcelExport],
  },
  async created() {
    this.headers = [

      {
        text: this.translate('plannings_plbs_summary_pos'),
        align: 'left',
        sortable: false,
        colspan: 10,
      },

      {
        text: this.translate('plannings_plbs_position'),
        align: 'left',
        sortable: false,
        width: 350,
      },
    ];
    this.loader = true;
    try {
      this.retrieveColumnWidths();
      const planning = await getSummaryCAPEXForPlanning(this.$route.params.planningId);
      this.periods = planning.periods;
      // eslint-disable-next-line arrow-body-style
      this.items = planning.rows;
      this.prepareHeaders();
      this.dataLoaded = true;
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
  },
  methods: {
    toggleExpandedItem(item) {
      // eslint-disable-next-line
      item.isExpanded = !item.isExpanded;
      this.expanded.push(item);
    },
    customiseCell(queryCellInfo) {
      const { cell } = queryCellInfo;
      if (cell.textContent === 'NaN') {
        cell.classList.add('error');
      }
    },
    excelDownloadClick() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const excelExportProperties = {
        fileName: `CAPEX - Planning - ${this.activePlanning.year}-${this.activePlanning.month}.xlsx`,
      };
      grid.excelExport(excelExportProperties);
    },
    excelHeader(args) {
      const { cell } = args;
      if (cell.index > 2) {
        cell.value = this.lookupHeader(cell.value);
      }
    },
    lookupHeader(idx) {
      return this.periods[Number(idx)];
    },
    retrieveColumnWidths() {
      for (let i = 0; i < this.columnWidths.length; i += 1) {
        const width = localStorage.getItem(this.storagePrefix + this.storageSeparator + i);
        if (width) {
          this.columnWidths[i] = width;
        }
      }
    },
    resizeStop(item) {
      let width = item.column.width.split('px');
      width = parseInt(width[0], 10);
      localStorage.setItem(this.storagePrefix + this.storageSeparator + item.column.index, width);
    },
    toolbarClick() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      grid.excelExport();
    },
    load() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      grid.copy(true);
    },
    prepareHeaders() {
      const offset = 5;
      const item = this.items[0];
      for (let j = 0; j < item.values.length; j += 1) {
        const width = localStorage.getItem(this.storagePrefix + this.storageSeparator + (offset + j));
        this.periodsColumns.push({
          field: `values.${j}`,
          headerText: j,
          headerValueAccessor: this.lookupHeader,
          width: width != null ? width : 90,
          format: 'n2',
          textAlign: 'right',
          allowFiltering: false,
        });
      }
    },
  },
};
</script>


<style>
@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-icons/styles/material.css";

.container {
  padding-bottom: 5rem !important;
}

.e-grid.e-default .e-gridheader th.e-firstcell {
  border-left: none;
}

.e-dlg-container.e-dlg-center-center,
.e-dlg-container.e-dlg-center-center {
  display: none;
}

.e-grid .e-headercell.highlight-cell, .highlight-cell {
  background: #eeeeee;
}

.v-icon.v-icon.delete-action {
  font-size: 16px;
}
</style>
