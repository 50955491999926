<template>
  <v-dialog
    persistent
    v-model="dialogVisible"
    max-width="290"
  >
    <v-card>
      <v-card-title class="headline">{{translate('dialog_confirm_action')}}</v-card-title>

      <v-card-text>
        {{description}}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="green darken-1"
          text
          @click.stop="disagree();"
        >
          {{translate('dialog_disagree')}}
        </v-btn>

        <v-btn
          color="green darken-1"
          text
          @click.stop="agree();"
        >
           {{translate('dialog_agree')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ConfirmDialog',
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
  },
  props: {
    dialog: Boolean,
    description: String,
  },
  data() {
    return {
      dialogVisible: this.dialog,
    };
  },
  methods: {
    agree() {
      this.$emit('confirmDelete');
    },
    disagree() {
      this.dialogVisible = false;
    },
  },
  watch: {
    dialogVisible() {
      this.$emit('change', this.dialogVisible);
    },
    dialog() {
      this.dialogVisible = this.dialog;
    },
  },
};
</script>
