import {
  map,
} from 'lodash';

export function parseVolume(volume, volumeIndex) {
  const volumeItem = volume;
  volumeItem.fyEditable = volumeIndex > 1;
  volumeItem.periods = map(volumeItem.periods, (itemPeriod, i) => {
    const period = itemPeriod;
    period.volumeIndex = volumeIndex;
    period.periodIndex = i;
    period.isLast = volumeItem.periods.length - 1 === i;
    period.showInPercentage = volumeIndex > 1;
    return period;
  });
  return volumeItem;
}
