<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="activities"
      :server-items-length="pagination.totalItems"
      :options.sync="options"
      @update:options="fetchActivityLogs"
      class="elevation-1"
    ></v-data-table>
  </v-container>
</template>

<script>
import api from '../api/lbt-api';

const { getUserActivityLogs } = api;

export default {
  name: 'UserActivityLogs',
  data() {
    return {
      activities: [],
      options: {
        page: 1,
        itemsPerPage: 10,
      }, // This will keep track of the table's pagination and sorting options
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Username', value: 'username' },
        { text: 'Activity', value: 'activity' },
        { text: 'Model', value: 'model' },
        { text: 'Timestamp', value: 'timestamp' },
      ],
    };
  },
  methods: {
    async fetchActivityLogs() {
      // Using the options object to get current page and items per page
      const { page, itemsPerPage } = this.options;
      const response = await getUserActivityLogs({ page, perPage: itemsPerPage });
      this.activities = response.items;
      this.pagination.totalItems = response.meta.total;
      this.options.page = response.meta.page;
      this.options.itemsPerPage = response.meta.per_page;
    },
  },
  created() {
    this.fetchActivityLogs(); // Initial fetch when component is created
  },
};
</script>
