<template>
  <v-container fluid class="container">
    <ejs-chart v-if='chartData' :title='config.equity.title' :subTitle='config.equity.subTitle' :primaryXAxis='config.equity.primaryXAxis' :primaryYAxis='config.equity.primaryYAxis' :legendSettings='legendSettings'  :palettes='palettes'>
      <e-series-collection>
        <e-series :dataSource='chartData.equity' type='StackingColumn' xName='period' yName='y1' name='Equity' :marker='marker'> </e-series>
        <e-series :dataSource='chartData.equity'  type='Line' xName='period' yName='y2' name='RoE' :marker='lineMarker'> </e-series>
      </e-series-collection>
    </ejs-chart>
    <ejs-chart v-if='chartData' :title='config.funding.title' :subTitle='config.funding.subTitle' :primaryXAxis='config.funding.primaryXAxis' :primaryYAxis='config.funding.primaryYAxis' :legendSettings='legendSettings'  :palettes='palettes'>
      <e-series-collection>
        <e-series :dataSource='chartData.funding' type='StackingColumn' xName='period' yName='y4' name='Shareholder' :marker='marker'> </e-series>
        <e-series :dataSource='chartData.funding' type='StackingColumn' xName='period' yName='y2' name='Bank' :marker='marker'> </e-series>
        <e-series :dataSource='chartData.funding'  type='StackingColumn' xName='period' yName='y3' name='Other' :marker='marker'> </e-series>
        <e-series :dataSource='chartData.funding'  type='Line' xName='period' yName='y1' name='Total' :marker='lineMarker'> </e-series>
      </e-series-collection>
    </ejs-chart>
    <ejs-chart v-if='chartData' :title='config.funding_placement.title' :subTitle='config.funding_placement.subTitle' :primaryXAxis='config.funding_placement.primaryXAxis' :primaryYAxis='config.funding_placement.primaryYAxis' :legendSettings='legendSettings'  :palettes='palettes'>
      <e-series-collection>
        <e-series :dataSource='chartData.funding_placement' type='StackingColumn' xName='period' yName='y2' name='Shareholder' :marker='marker'> </e-series>
        <e-series :dataSource='chartData.funding_placement' type='StackingColumn' xName='period' yName='y1' name='Bank' :marker='marker'> </e-series>
        <e-series :dataSource='chartData.funding_placement'  type='StackingColumn' xName='period' yName='y3' name='Other' :marker='marker'> </e-series>
      </e-series-collection>
    </ejs-chart>
    <ejs-chart v-if='chartData' :title='config.gae.title' :subTitle='config.gae.subTitle' :primaryXAxis='config.gae.primaryXAxis' :primaryYAxis='config.gae.primaryYAxis'  :secondaryYAxis='config.gae.secondaryYAxis' :legendSettings='legendSettings'  :palettes='palettes'>
      <e-series-collection>
        <e-series :dataSource='chartData.gae' type='StackingColumn' xName='period' yName='y2' name='PEREX' :marker='marker'> </e-series>
        <e-series :dataSource='chartData.gae' type='StackingColumn' xName='period' yName='y3' name='OPEX' :marker='marker'> </e-series>
        <e-series :dataSource='chartData.gae'  type='StackingColumn' xName='period' yName='y4' name='DEPRECIATION' :marker='marker'> </e-series>
      </e-series-collection>
    </ejs-chart>
    <ejs-chart v-if='chartData' :title='config.loans.title' :subTitle='config.loans.subTitle' :primaryXAxis='config.loans.primaryXAxis' :primaryYAxis='config.gae.primaryYAxis' :legendSettings='legendSettings'  :palettes='palettes'>
      <e-series-collection>
        <e-series :dataSource='chartData.loans' type='StackingColumn' xName='period' yName='y2' name='Existing' :marker='marker'> </e-series>
        <e-series :dataSource='chartData.loans' type='StackingColumn' xName='period' yName='y3' name='New' :marker='marker'> </e-series>
        <e-series :dataSource='chartData.loans'  type='StackingColumn' xName='period' yName='y4' name='Provisions' :marker='lineMarker'> </e-series>
        <e-series :dataSource='chartData.loans'  type='Line' xName='period' yName='y1' name='Total' :marker='lineMarker'> </e-series>
      </e-series-collection>
    </ejs-chart>
    <ejs-chart v-if='chartData' :title='config.moveableProperty.title' :subTitle='config.moveableProperty.subTitle' :primaryXAxis='config.moveableProperty.primaryXAxis' :primaryYAxis='config.gae.primaryYAxis' :legendSettings='legendSettings'  :palettes='palettes'>
      <e-series-collection>
        <e-series :dataSource='chartData.moveableProperty' type='StackingColumn' xName='period' yName='y2' name='Existing' :marker='marker'> </e-series>
        <e-series :dataSource='chartData.moveableProperty' type='StackingColumn' xName='period' yName='y3' name='New' :marker='marker'> </e-series>
        <e-series :dataSource='chartData.moveableProperty'  type='Line' xName='period' yName='y1' name='Total' :marker='lineMarker'> </e-series>
      </e-series-collection>
    </ejs-chart>
    <ejs-chart v-if='chartData' :title='config.netFee.title' :subTitle='config.netFee.subTitle' :primaryXAxis='config.netFee.primaryXAxis' :primaryYAxis='config.netFee.primaryYAxis' :legendSettings='legendSettings'  :palettes='palettes'>
      <e-series-collection>
        <e-series :dataSource='chartData.netFee' type='StackingColumn' xName='period' yName='y2' name='Fee Income' :marker='marker'> </e-series>
        <e-series :dataSource='chartData.netFee' type='StackingColumn' xName='period' yName='y3' name='Fee Expense' :marker='marker'> </e-series>
        <e-series :dataSource='chartData.netFee'  type='Line' xName='period' yName='y1' name='NFI' :marker='lineMarker'> </e-series>
      </e-series-collection>
    </ejs-chart>
    <ejs-chart v-if='chartData' :title='config.nii.title' :subTitle='config.nii.subTitle' :primaryXAxis='config.nii.primaryXAxis' :primaryYAxis='config.nii.primaryYAxis' :legendSettings='legendSettings'  :palettes='palettes'>
      <e-series-collection>
        <e-series :dataSource='chartData.nii' type='StackingColumn' xName='period' yName='y2' name='Interest Income' :marker='marker'> </e-series>
        <e-series :dataSource='chartData.nii' type='StackingColumn' xName='period' yName='y3' name='Interest Expense' :marker='marker'> </e-series>
        <e-series :dataSource='chartData.nii'  type='Line' xName='period' yName='y1' name='NII' :marker='lineMarker'> </e-series>
      </e-series-collection>
    </ejs-chart>
    <ejs-chart v-if='chartData' :title='config.rental.title' :subTitle='config.rental.subTitle' :primaryXAxis='config.rental.primaryXAxis' :primaryYAxis='config.rental.primaryYAxis' :legendSettings='legendSettings'  :palettes='palettes'>
      <e-series-collection>
        <e-series :dataSource='chartData.rental' type='StackingColumn' xName='period' yName='y2' name='Moveable Property Depreciation' :marker='marker'> </e-series>
        <e-series :dataSource='chartData.rental'  type='Line' xName='period' yName='y1' name='Rental Income' :marker='lineMarker'> </e-series>
      </e-series-collection>
    </ejs-chart>
    <v-overlay :value="loader" :z-index="1000">
      <v-progress-circular indeterminate
      size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import Vue from 'vue';
import {
  ChartPlugin, StackingColumnSeries, LineSeries, Category, DataLabel, Legend,
} from '@syncfusion/ej2-vue-charts';
import api from '../api/lbt-api';

const {
  getChart,
} = api;

const chartConfig = {
  equity: {
    title: 'Equity RoE',
    subTitle: '(in 000 EUR)',
    primaryXAxis: { valueType: 'Category' },
    primaryYAxis: { title: 'EUR' },
  },
  funding: {
    title: 'Funding',
    subTitle: '(in 000 EUR)',
    primaryXAxis: { valueType: 'Category' },
    primaryYAxis: { title: 'EUR' },
  },
  funding_placement: {
    title: 'Funding Placements',
    subTitle: '(in 000 EUR)',
    primaryXAxis: { valueType: 'Category' },
    primaryYAxis: { title: 'EUR' },
  },
  gae: {
    title: 'General Administrative Expenses',
    subTitle: '(in 000 EUR)',
    primaryXAxis: { valueType: 'Category' },
    primaryYAxis: { title: 'EUR' },
    secondaryYAxis: { title: 'EUR' },
  },
  loans: {
    title: 'Loans',
    subTitle: '(in 000 EUR)',
    primaryXAxis: { valueType: 'Category' },
    primaryYAxis: { title: 'EUR' },
  },
  moveableProperty: {
    title: 'Moveable Property - Operating Lease',
    subTitle: '(in 000 EUR)',
    primaryXAxis: { valueType: 'Category' },
    primaryYAxis: { title: 'EUR' },
  },
  netFee: {
    title: 'Net Fee Income',
    subTitle: '(in 000 EUR)',
    primaryXAxis: { valueType: 'Category' },
    primaryYAxis: { title: 'EUR' },
  },
  nii: {
    title: 'Net Interest Income',
    subTitle: '(in 000 EUR)',
    primaryXAxis: { valueType: 'Category' },
    primaryYAxis: { title: 'EUR' },
  },
  rental: {
    title: 'Rental Income (Operating Lease)',
    subTitle: '(in 000 EUR)',
    primaryXAxis: { valueType: 'Category' },
    primaryYAxis: { title: 'EUR' },
  },
};

Vue.use(ChartPlugin);

export default {
  name: 'Charts',
  async created() {
    try {
      this.loader = true;
      const data = await getChart(this.$route.params.planningId);
      this.setEquityChartData(data.equity);
      this.setFundingChartData(data.funding);
      this.setFundingPlacementChartData(data.funding_placement);
      this.setGAEChartData(data.gae);
      this.setLoansChartData(data.loans);
      this.setMoveablePropertyChartData(data.movable_property);
      this.setNetFeeIncome(data.netfee);
      this.setNiiChartData(data.nii);
      this.setRentalChartData(data.rental);
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
  },
  data() {
    return {
      loader: false,
      notificationText: '',
      notificationType: '',
      config: chartConfig,
      chartData: {
        equity: null,
        funding: null,
        funding_placement: null,
        gae: null,
        loans: null,
        moveableProperty: null,
        netFee: null,
        nii: null,
        rental: null,
      },
      legendSettings: {
        visible: true,
        position: 'Bottom',
      },
      marker: {
        dataLabel: {
          visible: true,
          position: 'Middle',
        },
      },
      lineMarker: {
        visible: true,
      },
      palettes: ['#8282F4', '#E94649', '#F6B53F'],
    };
  },
  provide: {
    chart: [StackingColumnSeries, LineSeries, Category, DataLabel, Legend],
  },
  methods: {
    setEquityChartData(data) {
      const cdata = [];
      for (let i = 0; i < data.periods.length; i += 1) {
        cdata.push({
          period: data.periods[i],
          y1: data.rows[0].values[i],
          y2: data.rows[1].values[i],
        });
      }
      this.chartData.equity = cdata;
    },
    setFundingChartData(data) {
      const cdata = [];
      for (let i = 0; i < data.periods.length; i += 1) {
        cdata.push({
          period: data.periods[i],
          y1: data.rows[0].values[i],
          y2: data.rows[1].values[i],
          y3: data.rows[2].values[i],
          y4: data.rows[3].values[i],
        });
      }
      this.chartData.funding = cdata;
    },
    setFundingPlacementChartData(data) {
      const cdata = [];
      for (let i = 0; i < data.periods.length; i += 1) {
        cdata.push({
          period: data.periods[i],
          y1: data.rows[0].values[i],
          y2: data.rows[1].values[i],
          y3: data.rows[2].values[i],
        });
      }
      this.chartData.funding_placement = cdata;
    },
    setGAEChartData(data) {
      const cdata = [];
      for (let i = 0; i < data.periods.length; i += 1) {
        cdata.push({
          period: data.periods[i],
          y2: data.rows[0].values[i],
          y3: data.rows[1].values[i],
          y4: data.rows[2].values[i],
        });
      }
      this.chartData.gae = cdata;
    },
    setLoansChartData(data) {
      const cdata = [];
      for (let i = 0; i < data.periods.length; i += 1) {
        cdata.push({
          period: data.periods[i],
          y1: data.rows[0].values[i],
          y2: data.rows[1].values[i],
          y3: data.rows[2].values[i],
          y4: data.rows[3].values[i],
        });
      }
      this.chartData.loans = cdata;
    },
    setMoveablePropertyChartData(data) {
      const cdata = [];
      for (let i = 0; i < data.periods.length; i += 1) {
        cdata.push({
          period: data.periods[i],
          y1: data.rows[0].values[i],
          y2: data.rows[1].values[i],
          y3: data.rows[2].values[i],
        });
      }
      this.chartData.moveableProperty = cdata;
    },
    setNetFeeIncome(data) {
      const cdata = [];
      for (let i = 0; i < data.periods.length; i += 1) {
        cdata.push({
          period: data.periods[i],
          y1: data.rows[0].values[i],
          y2: data.rows[1].values[i],
          y3: data.rows[2].values[i],
        });
      }
      this.chartData.netFee = cdata;
    },
    setNiiChartData(data) {
      const cdata = [];
      for (let i = 0; i < data.periods.length; i += 1) {
        cdata.push({
          period: data.periods[i],
          y1: data.rows[0].values[i],
          y2: data.rows[1].values[i],
          y3: data.rows[2].values[i],
        });
      }
      this.chartData.nii = cdata;
    },
    setRentalChartData(data) {
      const cdata = [];
      for (let i = 0; i < data.periods.length; i += 1) {
        cdata.push({
          period: data.periods[i],
          y1: data.rows[0].values[i],
          y2: data.rows[1].values[i],
        });
      }
      this.chartData.rental = cdata;
    },
  },
};

</script>
<style lang='scss' scoped>
  #container{
    height: 350px;
  }
</style>
