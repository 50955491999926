<template>
  <v-container fluid
    class="container-main">
    <Breadcrumbs
      :items="breadcrumbs"/>
    <v-data-table fixed-header
      dense
      class="elevation-1"
      item-key="id"
      :page.sync="pagination.page"
      :server-items-length="pagination.total || options.length"
      :headers="headers"
      :items="options"
      :footer-props="{
        'items-per-page-options': pagination.itemsPerPage
      }"
      :items-per-page="pagination.size">
      <template v-slot:body="props">

        <template v-for="(item) in props.items">
          <tbody :key="`row-${item.id}`">
            <tr :item="item">
            <td>
              {{item.id}}
            </td>
            <td>
              {{item.category.replace(/_/g, ' ')}}
            </td>
             <td>
              <v-icon
               @click="editItem(item.category)"
               >
               mdi-pencil
              </v-icon>
            </td>
          </tr>
          </tbody>
        </template>

      </template>

    </v-data-table>
    <Snackbar
      :text="notificationText"
      :type="notificationType"/>
    <v-overlay
      :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';
import validators from '@/validators';
import Snackbar from '../components/Snackbar';
import api from '../api/lbt-api';


const {
  getOptions,
} = api;

export default {
  name: 'options',
  components: {
    Snackbar,
    Breadcrumbs,
  },
  data() {
    return {
      validators,
      loader: false,
      dialog: false,
      notificationText: '',
      notificationType: '',
      headers: [],
      options: [],
      pagination: {
        page: 1,
        size: 25,
        total: 0,
        itemsPerPage: [25, 50, 100],
      },
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_options',
          route: '/options',
          disabled: false,
        },
        {
          translationKey: 'breadcrumbs_options_list',
          disabled: true,
        },
      ],

    };
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
  },
  async created() {
    this.headers = [
      {
        text: this.translate('option_id'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('option_name'),
        align: 'left',
        sortable: false,

      },
      {
        text: 'Action',
        align: 'left',
        sortable: false,

      },


    ];
    this.loader = true;
    try {
      const options = await getOptions();
      // eslint-disable-next-line arrow-body-style

      this.options = options;
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
  },
  methods: {
    editItem(id) {
      this.$router.push({ path: `/options/${id}` });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-main{
  padding-bottom: 5rem !important;
}
</style>
