import Vue from 'vue';
import Vuex from 'vuex';
import translations from './translations/index';
import options from './options/index';
import plannings from './plannings/index';
import finrepBsPl from './finrepBsPl/index';
import loading from './loading/index';
import auth from './auth/auth';


Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    translations,
    options,
    plannings,
    finrepBsPl,
    loading,
    auth,
  },
});
