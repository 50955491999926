<template>
  <v-container fluid class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>
    <v-toolbar flat>
       <v-col cols="12" sm="3"
        class="px-0">
        <v-text-field
          clearable
          v-model="search"
          append-icon="mdi-magnify"
          :label="translate('plannings_sales_search_items')"
          single-line
          hide-details
        ></v-text-field>
       </v-col>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click='excelDownloadClick'>
        Export to Excel
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary"
          @click.stop="addNewFundingItem()">
        <v-icon class="mr-2">mdi-table-large-plus</v-icon>
        {{translate('new funding item')}}
      </v-btn>
    </v-toolbar>
    <ejs-grid :dataSource='items' :allowSorting="true" :actionComplete='customiseCell' v-if="dataLoaded"
              :load='load' :cellSave='cellSave' :editSettings='editSettings'
              :selectionSettings='selectionOptions' :allowResizing='true'  :pageSettings='pageSettings'
              :sortSettings='sortOptions' :searchSettings='searchOptions' :allowPaging='true'
              :commandClick='commandClick' gridLines='Horizontal' :allowExcelExport='true'
              :excelQueryCellInfo="excelExportPreprocessing" :excelHeaderQueryCellInfo='excelHeader'
              :allowFiltering='true' :filterSettings="{type: 'Menu'}">
        <e-columns>
        <e-column field='id' headerText='ID' :visible=false isPrimaryKey='true'>
        </e-column>
        <e-column field='tempId' headerText='tempID' :visible=false>
        </e-column>
        <e-column field='line' :headerText="translate('Line')" width=150>
        </e-column>
        <e-column field='currency_id' :headerText="translate('plannings_sales_currency')" :filter="filterDropdown" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='currencyTypeParams' :validationRules='dropdownValidationRule' width=72>
        </e-column>
        <e-column field='maturity' :headerText="translate('plannings_sales_maturity')" editType='numericedit' width=68>
        </e-column>
        <e-column field='repayment_id' :headerText="translate('plannings_sales_repayment')" :filter="filterDropdown" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='repaymentTypeParams' :validationRules='dropdownValidationRule' width=86>
        </e-column>
        <e-column field='interest_rate_type_id' :headerText="translate('plannings_sales_interest_rate_type')" :filter="filterDropdown" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='interestRateTypeParams' :validationRules='dropdownValidationRule' width=110>
        </e-column>
        <e-column field='reference_rate_id' :headerText="translate('plannings_sales_reference_rate')" :filter="filterDropdown" foreignKeyValue="name" :validationRules='referenceRateValidationRule' :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='referenceRateTypeParams' width=100>
        </e-column>
        <e-column field='fee' :headerText="translate('Approval fee')" :valueAccessor="approvalFeeValueAccessor" editType='numericedit' :edit='percentageTypeParams' width=90>
        </e-column>
        <e-column field='fee_accrual_id' :headerText="translate('Approval fee accrual')" :valueAccessor='dropdownValueAccess' :filter="filterDropdown" foreignKeyValue="name" editType='dropdownedit' :edit='feeAccrualTypeParams' :validationRules='dropdownValidationRule' width=130>
        </e-column>
        <e-column field='placement_period' :headerText="translate('Repayment starts')" editType='dropdownedit' :edit='placementPeriodTypeParams' :validationRules='dropdownValidationRule' width=118>
        </e-column>
        <e-column textAlign='center' :headerText="translate('table_actions')" :commands='deleteActionCommand' :allowSorting="false" :allowEditing="false" width=60>
        </e-column>
        <e-column textAlign='center' :headerText="translate('plannings_sales_financing_volumes')" :allowSorting="false" :columns='financingVolumeColumns'>
        </e-column>
        <e-column textAlign='center' :headerText="translate('plannings_sales_interest_rate')" :allowSorting="false" :columns='interestRatesColumns'>
        </e-column>
      </e-columns>
    </ejs-grid>


<template>
  <v-container class="grey lighten-5">
    <v-row no-gutters>
      <v-col
        cols="12"
      >
        <v-card
          class="pa-2"
          outlined
          tile
        >
         Asset - Liabilities difference
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
    <ejs-grid :dataSource='items_funding' v-if="dataLoaded" :load='load' :cellSave='cellSave'
              :selectionSettings='selectionOptions'  :sortSettings='sortOptions' :searchSettings='searchOptions'
              :commandClick='commandClick' gridLines='Horizontal' :allowExcelExport='true'
              :excelHeaderQueryCellInfo='excelHeader'>
      <e-columns>
        <e-column field='id' headerText='ID' :visible=false isPrimaryKey='true'>
        </e-column>
        <e-column field='tempId' headerText='tempID' :visible=false>
        </e-column>
        <e-column field='summary_pos' :headerText="translate('Position')" width=220>
        </e-column>
        <e-column textAlign='center' :headerText="translate('Periods')" :columns='valuesColumns'>
        </e-column>
      </e-columns>
    </ejs-grid>
    <v-btn color="accent"
      fab
      fixed
      right
      bottom
      @click.stop="savePlanning()">
      <v-icon dark>mdi-content-save</v-icon>
    </v-btn>
    <v-btn
      color="accent"
      fixed
      middle
      bottom
      @click.stop="rmerge = true"
      >
      Import funding
    </v-btn>
    <Snackbar :text="notificationText"
      :type="notificationType" />
    <v-overlay :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate
        size="64"></v-progress-circular>
    </v-overlay>
    <ConfirmDialog
      :dialog="deleteDialog"
      :description="deleteDialogDescription"
      @change="setDialogState"
      @confirmDelete="confirmDeleteAction"
    />
    <v-dialog
      v-model="rmerge"
      persistent
      scrollable
      max-width="768">
      <v-card>
        <v-form ref="fundingCopyPlanning">
          <v-toolbar
            color="secondary"
            >
            <v-toolbar-title>
              Copy funding data
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text
            class="mt-3">
            <v-container>
                  <v-row>
                    <v-col
                      cols="3"
                      md="4"
                    >
                    <v-select
                      prepend-icon="mdi-file-settings-variant"
                      v-model="prvi"
                      :items="plannings"
                      :item-text="'id'"
                      :item-value="'id'"
                      :label="translate('ID of planning to import from')"
                    ></v-select>
                    </v-col>
                  </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              @click.stop="resetImport();">
              {{translate('button_cancel')}}
            </v-btn>
            <v-btn
              large
              color="accent"
              @click.stop="confirmImportAction();">
              {{translate('button_save')}}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue';
import {
  GridPlugin, Page, Sort, Edit, Resize, Filter, ForeignKey, Search, ExcelExport, CommandColumn,
} from '@syncfusion/ej2-vue-grids';
import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { mapGetters, mapState } from 'vuex';
import { createElement } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import {
  map,
  flatMap,
  sumBy,
  toNumber,
} from 'lodash';
import Snackbar from '../components/Snackbar';
import Breadcrumbs from '../components/Breadcrumbs';
import api from '../api/lbt-api';
import store from '../store/index';
import ConfirmDialog from '../components/ConfirmDialog';
import { generateFinancingVolumesPeriods } from '../utilities/fundingFinancingVolumes';
import { generateInterestRatePeriods } from '../utilities/interestRatePeriods';
import { generatePlacementPeriodsCapex, generatePlacementPeriodsFunding } from '../utilities/placementPeriod';
import { parseVolume } from '../utilities/parseVolume';
import { validatePlanningRows } from '../utilities/validatePlanningRows';

// TODO: Refactor destructing
const {
  createFundingForPlanning,
  getFundingForPlanning,
  updateFundingForPlanning,
  deleteFundingForPlanning,
  getPlanning,
  getLoanFunding,
  getFundingDiff,
  fundingCopyPlanning,
} = api;

Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);

export default {
  name: 'FundingInput',
  components: {
    Snackbar,
    Breadcrumbs,
    ConfirmDialog,
  },
  async created() {
    this.headersForExcel = [
      {
        text: this.translate('Line'),
        align: 'left',
        sortable: false,
        value: 'line',
      },
      {
        text: this.translate('plannings_sales_currency'),
        align: 'left',
        sortable: false,
        value: 'currency_id',
      },
      {
        text: this.translate('plannings_sales_maturity'),
        align: 'left',
        sortable: false,
        value: 'maturity',
      },
      {
        text: this.translate('plannings_sales_repayment'),
        align: 'left',
        sortable: false,
        value: 'repayment_id',
      },
      {
        text: this.translate('plannings_sales_interest_rate_type'),
        align: 'left',
        sortable: false,
        value: 'interest_rate_type_id',
      },
      {
        text: this.translate('plannings_sales_reference_rate'),
        align: 'left',
        sortable: false,
        value: 'reference_rate_id',
      },
      {
        text: this.translate('Approval fee'),
        align: 'left',
        sortable: false,
        value: 'fee',
      },
      {
        text: this.translate('Approval fee accrual'),
        align: 'left',
        sortable: false,
        value: 'fee_accrual_id',
      },
      {
        text: this.translate('Repayment starts'),
        align: 'left',
        sortable: false,
        value: 'placement_period',
      },
      {
        text: this.translate('table_actions'),
        align: 'left',
        sortable: false,
        value: 'actions',
      },
    ];
    this.loader = true;
    try {
      await this.fetchPlanningFundingItems();
      const planning = await getPlanning(this.$route.params.planningId);
      this.planningInfo = planning;
      await this.fetchPlannings();
      this.financingVolumesPeriods = generateFinancingVolumesPeriods(planning.year, planning.month - 1, this.periodVals);
      this.interestRatePeriods = generateInterestRatePeriods(planning.year, planning.month - 1);
      this.placementPeriodValues = generatePlacementPeriodsFunding(planning.year, planning.month);
      this.differencePeriods = generatePlacementPeriodsCapex(planning.year, planning.month);
      this.setDropdownDatasources();
      this.prepareFinancingVolumesGridHeaders();
      this.prepareInterestRatesGridHeaders();
      if (this.planningInfo.locked === 1) {
        this.editSettings.allowEditing = false;
        this.editSettings.allowAdding = false;
        this.editSettings.allowDeleting = false;
      }
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.dataLoaded = true;
      this.loader = false;
    }
    this.financingVolumesPeriods.forEach((el) => {
      el.periods.forEach((data) => {
        this.financingVolumeHeaders.push(`${el.year}${data.period}`);
      });
      this.financingVolumeHeaders.push('FY');
    });
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
    ...mapGetters('options', [
      'getOptionCategory',
    ]),
    ...mapState('plannings', [
      'activePlanning',
    ]),
  },
  watch: {
    search(searchText) {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      if (searchText !== null && searchText.length > 1) {
        grid.search(searchText);
      } else {
        grid.search();
      }
    },
  },
  data() {
    return {
      dataLoaded: false,
      search: '',
      notificationText: '',
      notificationType: '',
      loader: false,
      itemForDeletion: null,
      rmerge: false,
      deleteDialog: false,
      deleteDialogDescription: '',
      financingVolumesPeriods: [],
      interestRatePeriods: [],
      headers: [],
      headersForExcel: [],
      valuesColumns: [],
      items: [],
      items_funding: [],
      plid: 0,
      pagination: {
        page: 1,
        size: 50,
        total: 0,
        itemsPerPage: [20, 50, 100],
      },
      planningInfo: {},
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'plannings_funding',
          disabled: true,
        },
      ],
      currencyValues: [],
      repaymentValues: [],
      interestRateTypeValue: [],
      referenceRateValues: [],
      accrualValues: [],
      financingVolumesValues: {},
      interestRateValues: {},
      placementPeriodValues: [],
      periodVals: [],
      prvi: 0,
      plannings: [],
      editSettings: {
        allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch',
      },
      sortOptions: { columns: [{ field: 'tempId', direction: 'Ascending' }, { field: 'id', direction: 'Descending' }] },
      selectionOptions: { mode: 'Cell', type: 'Multiple', cellSelectionMode: 'Box' },
      searchOptions: { fields: ['line'], operator: 'contains', ignoreCase: true },
      dropdownValidationRule: { required: true },
      referenceRateValidationRule: { required: false },
      pageSettings: { pageSizes: false },
      currencyTypeParams: this.initDropdownParams(),
      repaymentTypeParams: this.initDropdownParams(),
      interestRateTypeParams: this.initDropdownParams(),
      referenceRateTypeParams: this.initDropdownParams(),
      feeAccrualTypeParams: this.initDropdownParams(),
      placementPeriodTypeParams: this.initDropdownParams(),
      financingVolumeHeaders: [],
      percentageTypeParams: {
        params: {
          min: 0,
          max: 100,
          step: 0.01,
        },
      },
      highlightCellAttributes: { class: 'highlight-cell' },
      highlightCellNoArrowsAttributes: { class: ['highlight-cell', 'no-arrows'] },
      noArrowsAttributes: { class: ['no-arrows'] },
      deleteActionCommand: [{ buttonOption: { cssClass: 'v-icon notranslate v-icon--link mdi mdi-delete theme--light delete-action' } }],
      financingVolumeColumns: [],
      interestRatesColumns: [],
      differenceColumns: [],
      filterDropdown: {
        ui: {
          create: (args) => {
            const flValInput = createElement('input', { className: 'flm-input' });
            args.target.appendChild(flValInput);
            this.dropInstances[args.column.field] = new DropDownList({
              dataSource: this.dropInstances[args.column.field].dataSource,
              fields: this.dropInstances[args.column.field].fields,
              placeholder: 'Select a value',
              popupHeight: '240px',
            });
            this.dropInstances[args.column.field].appendTo(flValInput);
          },
          write: (args) => {
            this.dropInstances[args.column.field].text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, this.dropInstances[args.column.field].value);
          },
        },
      },
      dropInstances: {
        currency_id: {
          dataSource: [],
          dataAccessor: 'currencyValues',
          fields: { text: 'option', value: 'id' },
        },
        repayment_id: {
          dataSource: [],
          dataAccessor: 'repaymentValues',
          fields: { text: 'option', value: 'id' },
        },
        interest_rate_type_id: {
          dataSource: [],
          dataAccessor: 'interestRateTypeValue',
          fields: { text: 'option', value: 'id' },
        },
        reference_rate_id: {
          dataSource: [],
          dataAccessor: 'referenceRateValues',
          fields: { text: 'option', value: 'id' },
        },
        fee_accrual_id: {
          dataSource: [],
          dataAccessor: 'accrualValues',
          fields: { text: 'option', value: 'id' },
        },
      },
    };
  },
  provide: {
    grid: [Page, Sort, Edit, Resize, Filter, ForeignKey, Search, ExcelExport, CommandColumn],
  },
  methods: {
    load() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      grid.element.addEventListener('mousedown', (e) => {
        if (e.target.classList.contains('e-rowcell')) {
          const index = Number(e.target.getAttribute('Index'));
          const colindex = Number(e.target.getAttribute('aria-colindex'));
          const { field } = grid.getColumns()[colindex];
          grid.editModule.editCell(index, field);
        }
      });
    },
    lookupFinancingVolumesPeriodHeader(idx) {
      return this.financingVolumeHeaders[Number(idx)];
    },
    lookupInterestRateHeader(idx) {
      return this.interestRatePeriods[Number(idx)].period;
    },
    async fetchPlanningFundingItems() {
      const funding = await getFundingForPlanning(this.$route.params.planningId, this.pagination);
      const fundingdif = await getFundingDiff(this.$route.params.planningId);
      this.periodVals = await getLoanFunding(this.$route.params.planningId);
      this.items_funding = fundingdif.rows;
      this.headers = fundingdif.periods;
      this.pagination.total = funding.pagination.total;
      this.prepareValuesGridHeaders();
      this.currencyValues = this.getOptionCategory('CURRENCY').options;
      this.repaymentValues = this.getOptionCategory('REPAYMENT_TYPE').options;
      this.interestRateTypeValue = this.getOptionCategory('INTEREST_RATE_TYPE').options;
      this.referenceRateValues = this.getOptionCategory('REFERENCE_RATE').options;
      this.accrualValues = this.getOptionCategory('ACCRUAL_METHOD').options;
      this.items = this.prepareData(funding.funding_entries);
      this.setFilterDropdowns();
    },
    setFilterDropdowns() {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.dropInstances) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.dropInstances.hasOwnProperty(key)) {
          // console.log(this.dropInstances[key].dataAccessor);
          // console.log(this[this.dropInstances[key].dataAccessor]);
          this.dropInstances[key].dataSource = this[this.dropInstances[key].dataAccessor];
        }
      }
    },
    prepareData(data) {
      const items = data;
      for (let i = 0; i < items.length; i += 1) {
        for (let j = 2; j < items[i].financing_volumes.length; j += 1) {
          for (let k = 0; k < items[i].financing_volumes[j].periods.length; k += 1) {
            items[i].financing_volumes[j].periods[k].value = (items[i].financing_volumes[j].periods[k].value * 100).toFixed(2);
          }
        }
        for (let j = 0; j < items[i].interest_rates.length; j += 1) {
          items[i].interest_rates[j].value = (items[i].interest_rates[j].value * 100).toFixed(2);
        }
        items[i].fee = (items[i].fee * 100).toFixed(2);
      }
      return items;
    },
    setDropdownDatasources() {
      this.currencyTypeParams.params.dataSource = this.getOptionCategory('CURRENCY').options;
      this.repaymentTypeParams.params.dataSource = this.getOptionCategory('REPAYMENT_TYPE').options;
      this.interestRateTypeParams.params.dataSource = this.getOptionCategory('INTEREST_RATE_TYPE').options;
      this.referenceRateTypeParams.params.dataSource = this.getOptionCategory('REFERENCE_RATE').options;
      this.feeAccrualTypeParams.params.dataSource = this.getOptionCategory('ACCRUAL_METHOD').options;
      this.placementPeriodTypeParams.params.dataSource = this.placementPeriodValues;
    },
    prepareFinancingVolumesGridHeaders() {
      const periods = flatMap(map(this.financingVolumesPeriods, parseVolume), volume => volume.periods);
      let frOffset = 0;
      periods.forEach((period, idx) => {
        if (period.showInPercentage === true) {
          this.financingVolumeColumns.push({
            field: `financing_volumes.${period.volumeIndex}.periods.${period.periodIndex}.value`,
            headerText: idx + frOffset,
            headerValueAccessor: this.lookupFinancingVolumesPeriodHeader,
            customAttributes: this.highlightCellNoArrowsAttributes,
            valueAccessor: this.financingVolumeValueAccessor,
            width: 70,
            editType: 'numericedit',
            edit: this.percentageTypeParams,
            allowSorting: false,
            allowFiltering: false,
            textAlign: 'right',
          });
        } else {
          this.financingVolumeColumns.push({
            field: `financing_volumes.${period.volumeIndex}.periods.${period.periodIndex}.value`,
            headerText: idx + frOffset,
            headerValueAccessor: this.lookupFinancingVolumesPeriodHeader,
            customAttributes: this.highlightCellNoArrowsAttributes,
            width: 70,
            editType: 'numericedit',
            allowSorting: false,
            allowFiltering: false,
            textAlign: 'right',
            format: 'n2',
          });
        }
        if (period.isLast) {
          frOffset += 1;
          this.financingVolumeColumns.push({
            field: `financing_volumes.${period.volumeIndex}.fy`,
            headerText: idx + frOffset,
            headerValueAccessor: this.lookupFinancingVolumesPeriodHeader,
            customAttributes: this.highlightCellNoArrowsAttributes,
            allowEditing: period.volumeIndex >= 2,
            width: 70,
            editType: 'numericedit',
            allowSorting: false,
            allowFiltering: false,
            textAlign: 'right',
            format: 'n2',
          });
        }
      });
    },
    prepareInterestRatesGridHeaders() {
      for (let i = 0; i < this.interestRatePeriods.length; i += 1) {
        this.interestRatesColumns.push({
          field: `interest_rates.${i}.value`,
          headerText: i,
          headerValueAccessor: this.lookupInterestRateHeader,
          valueAccessor: this.interestRateValueAccessor,
          customAttributes: this.noArrowsAttributes,
          width: 70,
          editType: 'numericedit',
          edit: this.percentageTypeParams,
          allowSorting: false,
          allowFiltering: false,
          textAlign: 'right',
          format: 'n2',
        });
      }
    },
    prepareValuesGridHeaders() {
      const itemd = this.items_funding[0];
      for (let i = 0; i < itemd.values.length; i += 1) {
        this.valuesColumns.push({
          field: `values.${i}`,
          headerText: i,
          headerValueAccessor: this.lookupValuesHeader,
          customAttributes: this.highlightCellAttributes,
          width: 60,
          allowSorting: false,
          allowFiltering: false,
          textAlign: 'right',
          format: 'n2',
        });
      }
    },
    lookupValuesHeader(idx) {
      return this.headers[Number(idx)];
    },
    initDropdownParams() {
      return {
        params: {
          dataSource: null,
          fields: { text: 'option', value: 'id' },
          query: new Query(),
        },
      };
    },
    dropdownValueAccess(field, data) {
      return this.fetchPlaningsSalesItemValues(field, data[field]);
    },
    commandClick(args) {
      this.deleteItem(args.rowData);
    },
    financingVolumeValueAccessor(column, item) {
      const colName = column.split('.');
      return `${item.financing_volumes[colName[1]].periods[colName[3]].value}%`;
    },
    interestRateValueAccessor(column, item) {
      const colName = column.split('.');

      return `${item.interest_rates[colName[1]].value}%`;
    },
    approvalFeeValueAccessor(column, item) {
      return `${item.fee}%`;
    },
    cellSave(args) {
      let volume;
      const colName = args.columnName.split('.');
      const { rowData } = args;
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const parentRow = args.cell.closest('tr');
      let fy = ''; // default message - if needed
      switch (colName[0]) {
        case 'financing_volumes':
          if (colName[2] === 'fy') break;

          volume = rowData.financing_volumes[colName[1]];
          for (let i = 0; i < grid.dataSource.length; i += 1) {
            if (grid.dataSource[i].id === rowData.id) {
              fy = sumBy(volume.periods, volumePeriod => toNumber(volumePeriod.value));
              if (colName[1] < 2) {
                grid.dataSource[i].financing_volumes[colName[1]].periods[colName[3]].value = args.value;
                grid.dataSource[i].financing_volumes[colName[1]].fy = fy;
                break;
              }
            }
          }

          for (let i = args.cell.cellIndex; i < parentRow.children.length; i += 1) {
            const colNameIn = grid.columnModel[i].field.split('.');
            if (colNameIn[0] === 'financing_volumes' && colNameIn[2] === 'fy') {
              if (colName[1] < 2) {
                parentRow.children[i].innerHTML = fy;
                break;
              } else {
                if (parseFloat(fy) !== 100.0) {
                  parentRow.children[i].classList.add('warning');
                } else {
                  parentRow.children[i].classList.remove('warning');
                }
                break;
              }
            }
          }

          break;
        case 'interest_rate_type_id':
          for (let i = args.cell.cellIndex; i < parentRow.children.length; i += 1) {
            const colNameIn = grid.columnModel[i].field ? grid.columnModel[i].field.split('.') : '';
            if (colNameIn[0] === 'reference_rate_id') {
              const parts = args.cell.outerHTML.split('<input');
              parts[1] = parts[1].replace('name="interest_rate_type_id"', 'name="interest_rate_type_id" /');
              const htmlToParse = parts.join('<input');
              const row = new DOMParser().parseFromString(htmlToParse, 'text/xml');
              const rowIndex = row.firstChild.getAttribute('index');
              switch (this.getInterestTypeValue(args.value)) {
                case 'Variable':
                  grid.contentModule.rows[rowIndex].cells[i].column.validationRules.required = true;
                  break;
                case 'Fixed':
                  grid.contentModule.rows[rowIndex].cells[i].column.validationRules.required = false;
                  break;
                default:
                  break;
              }
              break;
            }
          }
          break;
        default:
          break;
      }

      if (args.value !== '') {
        const { cell } = args;
        cell.classList.add('e-updatedtd');
        cell.classList.remove('error');
      }
    },
    excelDownloadClick() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const excelExportProperties = {
        // exportType: 'CurrentPage',
        fileName: `Funding - Planning - ${this.activePlanning.year}-${this.activePlanning.month}.xlsx`,
      };
      grid.excelExport(excelExportProperties);
    },
    excelHeader(args) {
      const col = args.gridCell.column;
      if (col.field !== undefined && col.field.includes('.')) {
        const { cell } = args;
        if (col.field.startsWith('financing')) {
          cell.value = this.lookupFinancingVolumesPeriodHeader(cell.value);
          const parts = col.field.split('.');
          if (parseFloat(parts[1]) >= 2 && parts[2] !== 'fy') {
            col.format = 'p2';
          }
        } else if (col.field.startsWith('interest')) {
          cell.value = this.lookupInterestRateHeader(cell.value);
          col.format = 'p2';
        }
      }
      if (col.field === 'fee') {
        col.format = 'p2';
      }
    },
    excelExportPreprocessing(item) {
      const tempItem = item;
      if (tempItem.value.toString().slice(-1) === '%') {
        const val = tempItem.value.toString();
        tempItem.value = parseFloat(val.substr(0, val.length - 1)) / 100;
      }
      return tempItem;
    },
    customiseCell(queryCellInfo) {
      const { cell } = queryCellInfo;
      if (cell && cell.textContent === 'NaN') {
        cell.classList.add('error');
      }
    },
    fetchPlaningsSalesItemValues(key, value) {
      let fetchedValue = '';
      switch (key) {
        case 'currency_id':
          fetchedValue = this.getCurrencyValue(value);
          break;
        case 'maturity':
          fetchedValue = value;
          break;
        case 'repayment_id':
          fetchedValue = this.getRepaymentValue(value);
          break;
        case 'interest_rate_type_id':
          fetchedValue = this.getInterestTypeValue(value);
          break;
        case 'reference_rate_id':
          fetchedValue = this.getReferenceRateValue(value);
          break;
        case 'fee_accrual_id':
          fetchedValue = this.getFeeAccrualValue(value);
          break;
        case 'financing_volumes':
          this.getFinancingVolumesValue(value);
          break;
        case 'interest_rates':
          this.getInterestRatesValue(value);
          break;
        case 'total_investment_costs':
          this.getTotalInvestmentCostValue(value);
          break;
        case 'total_interest_rates':
          this.getTotalInterestRatesValue(value);
          break;
        case 'ponder_for_averages':
          this.getPonderForAveragesValue(value);
          break;
        default:
          break;
      }
      return fetchedValue;
    },
    getPonderForAveragesValue(value) {
      this.ponderForAverageValues = {};
      const singleObj = {};
      value.forEach((item) => {
        const jsonTitle = item.period;
        this.json_fields[`PFA-${jsonTitle}`] = `PFA-${jsonTitle}`;
        singleObj[`PFA-${jsonTitle}`] = item.value;
      });
      this.ponderForAverageValues = singleObj;
    },
    getTotalInterestRatesValue(value) {
      this.totalInterestRatesValues = {};
      const singleObj = {};
      value.forEach((item) => {
        const jsonTitle = item.period;
        this.json_fields[`TIR-${jsonTitle}`] = `TIR-${jsonTitle}`;
        singleObj[`TIR-${jsonTitle}`] = (item.value).toFixed(3);
      });
      this.totalInterestRatesValues = singleObj;
    },
    getTotalInvestmentCostValue(value) {
      this.ticVolumes = {};
      const singleObj = {};
      value.forEach((item) => {
        item.periods.forEach((innerItem) => {
          const jsonTitle = item.year + innerItem.period;
          this.json_fields[`TIC-${jsonTitle}`] = `TIC-${jsonTitle}`;
          singleObj[`TIC-${jsonTitle}`] = innerItem.value;
        });
      });
      this.ticVolumes = singleObj;
    },
    getInterestRatesValue(value) {
      this.interestRateValues = {};
      const singleObj = {};
      value.forEach((item) => {
        const jsonTitle = item.period;
        this.json_fields[`IR-${jsonTitle}`] = `IR-${jsonTitle}`;
        singleObj[`IR-${jsonTitle}`] = `${(item.value * 100).toFixed(2)} %`;
      });
      this.interestRateValues = singleObj;
    },
    getFinancingVolumesValue(value) {
      this.financingVolumesValues = {};
      const singleObj = {};
      let fyValue = '';
      value.forEach((item) => {
        fyValue = item.fy;
        item.periods.forEach((innerItem) => {
          const jsonTitle = item.year + innerItem.period;
          this.json_fields[`FV-${jsonTitle}`] = `FV-${jsonTitle}`;
          singleObj[`FV-${jsonTitle}`] = innerItem.value;
        });
        singleObj[`FV-${item.year}-FY`] = fyValue;
        this.json_fields[`FV-${item.year}-FY`] = `FV-${item.year}-FY`;
      });
      this.financingVolumesValues = singleObj;
    },
    getCurrencyValue(value) {
      let fetchedValue = '';
      this.currencyValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getRepaymentValue(value) {
      let fetchedValue = '';
      this.repaymentValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getInterestTypeValue(value) {
      let fetchedValue = '';
      this.interestRateTypeValue.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getReferenceRateValue(value) {
      let fetchedValue = '';
      this.referenceRateValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getFeeAccrualValue(value) {
      let fetchedValue = '';
      this.accrualValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    updatePagination(page) {
      this.pagination.page = page;
      this.fetchPlanningFundingItems(this.pagination);
    },
    addNewFundingItem() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const [...addedRecords] = grid.getBatchChanges().addedRecords;
      const addedRecordsSize = addedRecords ? addedRecords.length : 0;
      grid.addRecord({
        tempId: Math.random(),
        id: this.items.length + addedRecordsSize + 1,
        line: '',
        currency_id: 0,
        maturity: 48,
        repayment_id: 0,
        interest_rate_type_id: 0,
        reference_rate_id: 0,
        fee: 0,
        fee_accrual_id: 0,
        placement_period: null,
        financing_volumes: generateFinancingVolumesPeriods(
          this.planningInfo.year,
          this.planningInfo.month - 1,
          this.periodVals,
        ),
        interest_rates: generateInterestRatePeriods(this.planningInfo.year, this.planningInfo.month - 1),
      });
    },
    validate() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const [...addedRecords] = grid.getBatchChanges().addedRecords;
      const [...changedRecords] = grid.getBatchChanges().changedRecords;
      if (validatePlanningRows(grid, addedRecords)
        && validatePlanningRows(grid, changedRecords)) {
        return true;
      }
      return false;
    },
    async savePlanning() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      let [...addedRecords] = grid.getBatchChanges().addedRecords;
      let [...changedRecords] = grid.getBatchChanges().changedRecords;
      if (addedRecords === undefined && changedRecords === undefined) {
        return;
      }
      if (addedRecords) addedRecords = this.divideBy100(addedRecords);
      if (changedRecords) changedRecords = this.divideBy100(changedRecords);

      if (!this.validate()) {
        return;
      }

      // TODO: Send all sales items
      this.loader = true;
      this.plid = this.$route.params.planningId;
      const fundingCreateRequests = [];
      const fundingUpdateRequests = [];
      try {
        if (addedRecords !== undefined) {
          for (let i = 0; i < addedRecords.length; i += 1) {
            fundingCreateRequests.push(createFundingForPlanning(this.plid, addedRecords[i]));
          }
        }
        if (changedRecords !== undefined) {
          for (let j = 0; j < changedRecords.length; j += 1) {
            fundingUpdateRequests.push(updateFundingForPlanning(this.plid, changedRecords[j], changedRecords[j].id));
          }
        }
        await Promise.all(fundingCreateRequests);
        await Promise.all(fundingUpdateRequests);
        this.notificationText = this.translate('notifications_planning_updated');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlanningFundingItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    divideBy100(records) {
      const data = records;
      for (let i = 0; i < data.length; i += 1) {
        for (let j = 0; j < data[i].financing_volumes.length; j += 1) {
          let fyAgain = 0; // Jbg nešto zeza, moram ovako.
          for (let k = 0; k < data[i].financing_volumes[j].periods.length; k += 1) {
            if (j < 2) {
              fyAgain += parseFloat(data[i].financing_volumes[j].periods[k].value);
            } else {
              data[i].financing_volumes[j].periods[k].value /= 100;
            }
          }
          if (j < 2) {
            data[i].financing_volumes[j].fy = fyAgain;
          }

          data[i].financing_volumes[j].fy = parseFloat(data[i].financing_volumes[j].fy);
        }
        for (let j = 0; j < data[i].interest_rates.length; j += 1) {
          data[i].interest_rates[j].value /= 100;
        }
        data[i].fee /= 100;
      }
      return data;
    },
    deleteItem(item) {
      this.itemForDeletion = item;
      this.deleteDialog = true;
      this.deleteDialogDescription = this.translate('dialog_delete_action_description');
    },
    async confirmDeleteAction() {
      if (this.itemForDeletion.tempId) {
        let index = -1;
        for (let i = 0; i < this.items.length; i += 1) {
          if (this.items[i].id === this.itemForDeletion.id) {
            index = i;
            break;
          }
        }

        if (index !== -1) {
          this.$delete(this.items, index);
          this.itemForDeletion = null;
        } else {
          const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
          const [...addedRecords] = grid.getBatchChanges().addedRecords;
          for (let i = 0; i < addedRecords.length; i += 1) {
            if (addedRecords[i].tempId === this.itemForDeletion.tempId) {
              grid.deleteRecord('id', addedRecords[i]);
              const rowIdx = grid.getRowIndexByPrimaryKey(addedRecords[i].id);
              const row = grid.getRowByIndex(rowIdx);
              grid.deleteRow(row);
              break;
            }
          }
        }
        this.deleteDialog = false;
        return;
      }
      this.loader = true;
      try {
        await deleteFundingForPlanning(this.$route.params.planningId, this.itemForDeletion.id);
        this.deleteDialog = false;
        this.itemForDeletion = null;
        this.notificationText = this.translate('notifications_item_deleted_successfully');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlanningFundingItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    setDialogState(value) {
      this.deleteDialog = value;
    },
    resetImport() {
      this.rmerge = false;
    },
    async confirmImportAction() {
      this.loader = true;
      try {
        await fundingCopyPlanning(this.prvi, this.$route.params.planningId);
        this.notificationText = this.translate('notifications_planning_updated');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlanningFundingItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
        this.rmerge = false;
      }
    },
    async fetchPlannings() {
      const plannings = await store.dispatch('plannings/getPlannings', this.pagination);
      this.plannings = plannings.plannings;
    },
  },
  async beforeRouteEnter(from, to, next) {
    await store.dispatch('options/getOptions');
    next();
  },
};
</script>

<style>
  @import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-icons/styles/material.css";

  .container {
    padding-bottom: 5rem !important;
  }
  .ev3-center {
    text-align: center !important;
    padding: 0;
  }
  .no-arrows .e-spin-down, .no-arrows .e-spin-up {
    display: none !important;
  }
</style>
