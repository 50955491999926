<template>
  <v-container fluid
    class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>
        <v-toolbar flat>
           <v-spacer></v-spacer>
          <v-btn color="primary" @click='excelDownloadClick'>
            Export to Excel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary"
              @click.stop="addNewPlanningItem()">
            <v-icon class="mr-2">mdi-table-large-plus</v-icon>
            {{translate('New Existing funding Item')}}
          </v-btn>
        </v-toolbar>
        <ejs-grid :dataSource='items' v-if="dataLoaded"  :allowSorting="true" :allowFiltering="true"  :load='load' :cellSave='cellSave'
                  :editSettings='editSettings' :selectionSettings='selectionOptions' :allowResizing='true'
                  :pageSettings='pageSettings' :sortSettings='sortOptions' :searchSettings='searchOptions' :filterSettings="{type: 'Menu'}"
                  :allowPaging='true' :commandClick='commandClick' gridLines='Horizontal' :allowExcelExport='true'
                  :resizeStop="resizeStop" :excelHeaderQueryCellInfo='excelHeader' :actionComplete='customiseCell'>
          <e-columns>
            <e-column field='id' headerText='ID' :visible=false isPrimaryKey='true'>
            </e-column>
            <e-column field='tempId' headerText='tempID' :visible=false>
            </e-column>
            <e-column field='line' :headerText="translate('Line')" :width="columnWidths[5]">
            </e-column>
            <e-column field='currency_id' :headerText="translate('plannings_sales_currency')"  :filter="filterDropdown" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='currencyTypeParams' :validationRules='requiredValidationRule' width=70>
            </e-column>
            <e-column field='reference_rate_id' :headerText="translate('plannings_sales_reference_rate')"  :filter="filterDropdown" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='referenceRateTypeParams' width=110>
            </e-column>
            <e-column field='interest_first_year' :headerText="translate('Interest First Year')" :width="columnWidths[5]">
            </e-column>
            <e-column field='interest_other' :headerText="translate('Interest Other')" :width="columnWidths[5]">
            </e-column>
            <e-column textAlign='center' :allowSorting='false' :headerText="translate('table_actions')" :commands='deleteActionCommand' :allowEditing="false" width=60>
            </e-column>
            <e-column textAlign='center' :allowSorting='false' :headerText="translate('Amounts')" :columns='financingVolumeColumns'>
            </e-column>
          </e-columns>
        </ejs-grid>
    <v-btn color="accent"
      fab
      fixed
      right
      bottom
      @click.stop="savePlanning()">
      <v-icon dark>mdi-content-save</v-icon>
    </v-btn>
    <Snackbar :text="notificationText"
      :type="notificationType" />
    <v-overlay :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate
        size="64"></v-progress-circular>
    </v-overlay>
    <ConfirmDialog
      :dialog="deleteDialog"
      :description="deleteDialogDescription"
      @change="setDialogState"
      @confirmDelete="confirmDeleteAction"
    />
  </v-container>
</template>

<script>
import Vue from 'vue';
import {
  GridPlugin, Page, Sort, Edit, Resize, Search, Filter, ForeignKey, ExcelExport, CommandColumn,
} from '@syncfusion/ej2-vue-grids';
import { createElement } from '@syncfusion/ej2-base';
import { Query } from '@syncfusion/ej2-data';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';
import { mapGetters, mapState } from 'vuex';
import ConfirmDialog from '@/components/ConfirmDialog';
import Snackbar from '../components/Snackbar';
import Breadcrumbs from '../components/Breadcrumbs';
import { generatePlacementPeriodsCapex } from '../utilities/placementPeriod';
import { generateFundingPeriodsValues } from '../utilities/fundingPeriodsValues';
import { generateFundingPeriods } from '../utilities/fundingPeriods';
import { ejsValidatePlanningRows } from '../utilities/ejsValidatePlanningRows';

import api from '../api/lbt-api';

const {
  getExistingFundingForPlanning,
  createExistingFundingForPlanning,
  getPlanning,
  deleteExistingFundingForPlanning,
  updateExistingFundingForPlanning,
} = api;

Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);

let typeElem;
let typeObj;

export default {
  name: 'ExistingFunding',
  components: {
    Snackbar,
    Breadcrumbs,
    ConfirmDialog,
  },
  data() {
    return {
      dataLoaded: false,
      search: '',
      notificationText: '',
      notificationType: '',
      deleteDialog: false,
      deleteDialogDescription: '',
      itemForDeletion: {},
      loader: false,
      items: [],
      currencyValues: [],
      referenceRateValues: [],
      planningInfo: {},
      storagePrefix: 'existing_funding',
      storageSeparator: '.-.',
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'existing funding',
          disabled: true,
        },
      ],
      financingVolumes: [],
      requiredValidationRule: { required: true },
      columnWidths: [0, 0, 200, 280, 200, 130],
      editSettings: {
        allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch',
      },
      sortOptions: { columns: [{ field: 'tempId', direction: 'Ascending' }, { field: 'id', direction: 'Descending' }] },
      selectionOptions: { mode: 'Cell', type: 'Multiple', cellSelectionMode: 'Box' },
      searchOptions: { fields: ['note'], operator: 'contains', ignoreCase: true },
      dropdownValidationRule: { required: true },
      pageSettings: { pageSizes: true, pageSize: 20 },
      highlightCellAttributes: { class: 'highlight-cell' },
      highlightCellNoArrowsAttributes: { class: ['highlight-cell', 'no-arrows'] },
      deleteActionCommand: [{ buttonOption: { cssClass: 'v-icon notranslate v-icon--link mdi mdi-delete theme--light delete-action' } }],
      financingVolumeColumns: [],
      currencyTypeParams: this.initDropdownParams(),
      referenceRateTypeParams: this.initDropdownParams(),
      typeCascParams: {
        create: () => {
          typeElem = document.createElement('input');
          return typeElem;
        },
        read: () => typeObj.value,
        destroy: () => typeObj.destroy(),
        write: () => {
          typeObj = new DropDownList({
            dataSource: this.parentFinreps,
            fields: { value: 'id', text: 'title' },
            enabled: true,
            placeholder: 'Type',
          });
          typeObj.appendTo(typeElem);
        },
      },
      filterDropdown: {
        ui: {
          create: (args) => {
            const flValInput = createElement('input', { className: 'flm-input' });
            args.target.appendChild(flValInput);
            this.dropInstances[args.column.field] = new DropDownList({
              dataSource: this.dropInstances[args.column.field].dataSource,
              fields: this.dropInstances[args.column.field].fields,
              placeholder: 'Select a value',
              popupHeight: '240px',
            });
            this.dropInstances[args.column.field].appendTo(flValInput);
          },
          write: (args) => {
            this.dropInstances[args.column.field].text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, this.dropInstances[args.column.field].value);
          },
        },
      },
      dropInstances: {
        currency_id: {
          dataSource: [],
          dataAccessor: 'currencyValues',
          fields: { text: 'option', value: 'id' },
        },
      reference_rate_id: {
          dataSource: [],
          dataAccessor: 'referenceRateValues',
          fields: { text: 'option', value: 'id' },
        },
      },
    };
  },
  async created() {
    try {
      this.retrieveColumnWidths();
      await this.fetchExistingFundingItems();
      const planning = await getPlanning(this.$route.params.planningId);
      this.planningInfo = planning;
      this.placementPeriodValues = generatePlacementPeriodsCapex(planning.year, planning.month - 2);
      this.financingVolumes = generateFundingPeriods(planning.year, planning.month - 2);
      this.setDropdownDatasources();
      this.prepareFinancingVolumesGridHeaders();
      this.setFilterDropdowns();
      if (this.planningInfo.locked === 1) {
        this.editSettings.allowEditing = false;
        this.editSettings.allowAdding = false;
        this.editSettings.allowDeleting = false;
      }
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.dataLoaded = true;
      this.loader = false;
    }
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
    ...mapGetters('options', [
      'getOptionCategory',
    ]),
    ...mapState('plannings', [
      'activePlanning',
    ]),
  },
  watch: {
    search(searchText) {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      if (searchText !== null && searchText.length > 1) {
        grid.search(searchText);
      } else {
        grid.search();
      }
    },
  },
  provide: {
    grid: [Page, Sort, Edit, Resize, Search, Filter, ForeignKey, ExcelExport, CommandColumn],
  },
  methods: {
    load() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      grid.element.addEventListener('mousedown', (e) => {
        if (e.target.classList.contains('e-rowcell')) {
          const index = Number(e.target.getAttribute('Index'));
          const colindex = Number(e.target.getAttribute('aria-colindex'));
          const { field } = grid.getColumns()[colindex];
          grid.editModule.editCell(index, field);
        }
      });
    },
    retrieveColumnWidths() {
      for (let i = 0; i < this.columnWidths.length; i += 1) {
        const width = localStorage.getItem(this.storagePrefix + this.storageSeparator + i);
        if (width) {
          this.columnWidths[i] = width;
        }
      }
    },
    resizeStop(item) {
      let width = item.column.width.split('px');
      width = parseInt(width[0], 10);
      localStorage.setItem(this.storagePrefix + this.storageSeparator + item.column.index, width);
    },
    customiseCell(queryCellInfo) {
      const { cell } = queryCellInfo;
      if (cell && cell.textContent === 'NaN') {
        cell.classList.add('error');
      }
    },
    lookupFinancingVolumeHeader(idx) {
      return this.financingVolumes[Number(idx)];
    },
    commandClick(args) {
      this.deleteItem(args.rowData);
    },
    cellSave(args) {
      if (args.value !== '') {
        const { cell } = args;
        cell.classList.add('e-updatedtd');
        cell.classList.remove('error');
      }
    },
    fetchExistingFundingItemValues(key, value) {
      let fetchedValue = '';
      switch (key) {
        case 'currency_id':
          fetchedValue = this.getCurrencyValue(value);
          break;
        case 'reference_rate_id':
          fetchedValue = this.getReferenceRateValue(value);
          break;
        default:
          break;
      }
      return fetchedValue;
    },
    getCurrencyValue(value) {
      let fetchedValue = '';
      this.currencyValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getReferenceRateValue(value) {
      let fetchedValue = '';
      this.referenceRateValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    updateFinrepDropdownValues(totalRowIndex) {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const childrenFinreps = [];
      const [...addedRecords] = grid.getBatchChanges().addedRecords;
      const addedRecordsSize = addedRecords ? addedRecords.length : 0;
      const index = totalRowIndex - addedRecordsSize;
      this.otherFinreps.forEach((item) => {
        if (totalRowIndex >= addedRecordsSize) {
          if (item.parent_id === grid.dataSource[index].other_type_id) {
            childrenFinreps.push(item);
          }
        } else if (item.parent_id === addedRecords[totalRowIndex].other_type_id) {
          childrenFinreps.push(item);
        }
      });
      grid.columnModel[3].edit.params.dataSource = childrenFinreps;
    },
    excelDownloadClick() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const excelExportProperties = {
        // exportType: 'CurrentPage',
        fileName: `Other Input - Planning - ${this.activePlanning.year}-${this.activePlanning.month}.xlsx`,
      };
      grid.excelExport(excelExportProperties);
    },
    excelHeader(args) {
      const { field } = args.gridCell.column;
      if (field !== undefined && field.includes('.')) {
        const { cell } = args;
        if (field.startsWith('other_period')) {
          cell.value = this.financingVolumes[Number(cell.value)];
        }
      }
    },
    initDropdownParams() {
      return {
        params: {
          dataSource: null,
          fields: { text: 'option', value: 'id' },
          query: new Query(),
        },
      };
    },
    async fetchExistingFundingItems() {
      const other = await getExistingFundingForPlanning(this.$route.params.planningId);
      const items = other.exfund_entries;
      this.currencyValues = this.getOptionCategory('CURRENCY').options;
      this.referenceRateValues = this.getOptionCategory('REFERENCE_RATE').options;
      this.items = items;
    },
    setFilterDropdowns() {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.dropInstances) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.dropInstances.hasOwnProperty(key)) {
          this.dropInstances[key].dataSource = this[this.dropInstances[key].dataAccessor];
        }
      }
    },
    setDropdownDatasources() {
      this.currencyTypeParams.params.dataSource = this.getOptionCategory('CURRENCY').options;
      this.referenceRateTypeParams.params.dataSource = this.getOptionCategory('REFERENCE_RATE').options;
    },
    dropdownValueAccess(field, data) {
      return this.fetchExistingFundingItemValues(field, data[field]);
    },
    prepareFinancingVolumesGridHeaders() {
      const offset = 7;
      for (let i = 0; i < this.financingVolumes.length; i += 1) {
        const width = localStorage.getItem(this.storagePrefix + this.storageSeparator + (offset + i));
        this.financingVolumeColumns.push({
          field: `financing_volumes.${i}.value`,
          headerText: i,
          headerValueAccessor: this.lookupFinancingVolumeHeader,
          customAttributes: this.highlightCellNoArrowsAttributes,
          width: width != null ? width : 70,
          editType: 'numericedit',
          allowSorting: false,
          allowFiltering: false,
          textAlign: 'right',
          format: 'n2',
        });
      }
    },
    getOtherFinrepValue(key, value, field = 'title') {
      let fetchedValue = '';
      this.otherFinreps.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item[field];
        }
      });
      return fetchedValue;
    },
    addNewPlanningItem() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const [...addedRecords] = grid.getBatchChanges().addedRecords;
      const addedRecordsSize = addedRecords ? addedRecords.length : 0;
      grid.addRecord({
        tempId: Math.random(),
        id: this.items.length + addedRecordsSize + 1,
        currency_id: 0,
        refrence_rate_id: 0,
        interest_first_year: 0,
        interest_other: 0,
        line: '',
        financing_volumes: generateFundingPeriodsValues(this.planningInfo.year, this.planningInfo.month - 1),
      });
    },
    validate() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const [...addedRecords] = grid.getBatchChanges().addedRecords;
      const [...changedRecords] = grid.getBatchChanges().changedRecords;
      if (ejsValidatePlanningRows(grid, addedRecords)
        && ejsValidatePlanningRows(grid, changedRecords)) {
        return true;
      }
      return false;
    },
    async savePlanning() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const [...addedRecords] = grid.getBatchChanges().addedRecords;
      const [...changedRecords] = grid.getBatchChanges().changedRecords;
      if ((addedRecords === undefined && changedRecords === undefined)
      || (changedRecords.length === 0 && addedRecords.length === 0)) {
        return;
      }
      if (!this.validate()) {
        return;
      }

      // TODO: Send all items in bulk request
      this.loader = true;
      this.plid = this.$route.params.planningId;
      const existingFundingCreateRequests = [];
      const existingFundingUpdateRequests = [];
      try {
        if (addedRecords !== undefined) {
          for (let i = 0; i < addedRecords.length; i += 1) {
            existingFundingCreateRequests.push(createExistingFundingForPlanning(this.plid, addedRecords[i]));
          }
        }
        if (changedRecords !== undefined) {
          for (let j = 0; j < changedRecords.length; j += 1) {
            existingFundingUpdateRequests.push(updateExistingFundingForPlanning(this.plid, changedRecords[j], changedRecords[j].id));
          }
        }
        await Promise.all(existingFundingCreateRequests);
        await Promise.all(existingFundingUpdateRequests);
        this.notificationText = this.translate('notifications_planning_updated');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchExistingFundingItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    deleteItem(item) {
      this.itemForDeletion = item;
      this.deleteDialog = true;
      this.deleteDialogDescription = this.translate('dialog_delete_action_description');
    },
    async confirmDeleteAction() {
      if (this.itemForDeletion.tempId) {
        let index = -1;
        for (let i = 0; i < this.items.length; i += 1) {
          if (this.items[i].id === this.itemForDeletion.id) {
            index = i;
            break;
          }
        }

        if (index !== -1) {
          this.$delete(this.items, index);
          this.itemForDeletion = null;
        } else {
          const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
          const [...addedRecords] = grid.getBatchChanges().addedRecords;
          for (let i = 0; i < addedRecords.length; i += 1) {
            if (addedRecords[i].tempId === this.itemForDeletion.tempId) {
              grid.deleteRecord('id', addedRecords[i]);
              const rowIdx = grid.getRowIndexByPrimaryKey(addedRecords[i].id);
              const row = grid.getRowByIndex(rowIdx);
              grid.deleteRow(row);
              break;
            }
          }
        }
        this.deleteDialog = false;
        return;
      }
      this.loader = true;

      try {
        await deleteExistingFundingForPlanning(this.$route.params.planningId, this.itemForDeletion.id);
        this.deleteDialog = false;
        this.itemForDeletion = null;
        this.notificationText = this.translate('notifications_item_deleted_successfully');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchExistingFundingItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    setDialogState(value) {
      this.deleteDialog = value;
    },
  },
};
</script>

<style>
  @import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-icons/styles/material.css";

  .container {
    padding-bottom: 5rem !important;
  }

</style>
