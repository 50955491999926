export default {
  namespaced: true,
  state: {
    keys: {
      breadcrumbs_plannings: 'Plannings',
      breadcrumbs_plannings_capex: 'Capex',
      breadcrumbs_plannings_capex_ol: 'Capex OL',
      breadcrumbs_plannings_sales: 'Sales',
      breadcrumbs_plannings_balance_sheet: 'Balance Sheet',
      breadcrumbs_plannings_profit_loss: 'Profit/Loss',
      sidebar_options: 'Edit option',
      options_new_option_item: 'Add New Option Item',
      breadcrumbs_options: 'Options',
      breadcrumbs_options_list: 'List of options',
      breadcrumbs_other_finreps: 'List of other finreps',
      breadcrumbs_archive: 'List of Archive positions',
      breadcrumbs_plannings_comments: 'Report comments',
      options_search_items: 'Search items',
      sidebar_other_finrep: 'Other finreps',
      sidebar_other_finrep_all: 'All items',
      sidebar_archive: 'Archive',
      sidebar_other_finrep_types: 'Types',
      sidebar_finprep_konto_mapping: 'General Ledger mapping',
      sidebar_finprep_product_mapping: 'Product mapping',
      sidebar_exchange_rates: 'Exchange rates',
      sidebar_euribor: 'Euribor',
      sidebar_comments: 'Comments',
      fx_search_items: 'Exchange rate search items',
      breadcrumbs_fx: 'Exchange rates',
      options_items: 'Options',
      plannings_import: 'New Planning Data',
      planning_replace: 'Replace Planning Data',
      products_search_items: 'Product search items',
      products_new_mapping_item: 'Add new product mapping',
      product_mapping_items: 'Product mappings items',
      kontos_search_items: 'Konto search items',
      kontos_new_mapping_item: 'Add new Konto mapping',
      konto_mapping_items: 'Konto mappings items',
      no_items: 'No items found',
      option_id: 'ID',
      option_name: 'Option name',
      other_finrep_id: 'ID',
      other_finrep_title: 'Title',
      other_finrep_value: 'Value',
      other_finrep_parent: 'Other type',
      plannings_comments_title: 'Title',
      plannings_comments_comment: 'Comment',
      plannings_comments_page: 'Page',
      plannings_comments_sort: 'Order (in page)',
      plannings_comments_position: 'Position',
      button_save: 'Save',
      button_cancel: 'Cancel',
      button_close: 'Close',
      button_new_item: 'New item',
      dialog_confirm_action: 'Confirm action?',
      dialog_disagree: 'Disagree',
      dialog_agree: 'Agree',
      sidebar_plannings_summary_perex: 'PEREX',
      dialog_delete_action_description: 'Deleting this item will permanently remove all item data.',
      dialog_replace_action_description: 'Deleting this item will replace all sales and capex data.',
      form_field_required: 'Field required',
      form_field_maxlength: 'Max length is ',
      input_label_edit: 'Edit',
      plannings_create_new: 'New planning',
      plannings_lcy: 'LCY',
      plannings_fixed_assets_file: 'Fixed assets file',
      plannings_fixed_assets_support_file: 'Fixed assets support file',
      plannings_fixed_assets_planning_rates: 'Planning rates',
      plannings_planning_info: 'Planning info',
      plannings_leasing_or_rent: 'Leasing or rent',
      plannings_portfolio: 'Portfolio',
      plannings_new_production: 'New production',
      fee_calculation_ol: 'Fee accrual - OL',
      fee_calculation_fl: 'Fee accrual - FL',
      rent_calculation_ol: 'Rent accrual - OL',
      rent_calculation_fl: 'Rent accrual - FL',
      depo_calculation_ol: 'Subvention accrual - OL',
      depo_calculation_fl: 'Subvention accrual - FL',
      plannings_planning_date: 'Planning date',
      plannings_planning_author: 'Author',
      plannings_planning_created_success: 'Planning created',
      plannings_sales_items: 'Sales items',
      plannings_capex_items: 'Capex items',
      plannings_capex_depreciation: 'Depreciation',
      plannings_capex_book_value: 'Book value',
      plannings_capex_placement_period: 'Placement period',
      plannings_capex_asset_name: 'Asset name',
      plannings_capex_cost_center_name: 'Cost center name',
      plannings_capex_cost_center_code: 'Cost center code',
      plannings_capex_gl_account: 'Gl account',
      plannings_capex_finrep_bs_pl_id: 'Finrep position',
      plannings_capex_useful_life: 'Useful life',
      plannings_capex_depreciation_rate: 'Depreciation rate',
      plannings_capex_lcy: 'LCY',
      plannings_capex_eur: 'EUR',
      plannings_capex_no_items: 'No capex items in planning',
      plannings_new_capex_item: 'New capex item',
      plannings_new_sales_item: 'New sales item',
      plannings_sales_leasing_type_id: 'Product',
      plannings_sales_segment_id: 'Segment',
      plannings_sales_customer: 'Customer/CI',
      plannings_sales_product_group: 'Product Group',
      plannings_sales_product_name: 'Product Name',
      plannings_sales_channel: 'Channel',
      plannings_sales_currency: 'Currency',
      plannings_sales_maturity: 'Maturity',
      plannings_sales_payment_at_once: 'Payment at once',
      plannings_sales_repayment: 'Repayment',
      plannings_sales_type: 'Type',
      plannings_sales_interest_rate_type: 'Interest rate type',
      plannings_sales_reference_rate: 'Reference rate',
      plannings_sales_down_payment: 'Down payment(First rate)',
      plannings_sales_first_rent_accrual: 'First rent accrual',
      plannings_sales_guarantee: 'Guarantee(Deposit)',
      plannings_sales_residual_value: 'Residual value',
      plannings_sales_up_front_fee: 'Up-front fee',
      plannings_sales_up_front_fee_accrual: 'Up-front fee accrual',
      plannings_sales_intercalary_days: 'Intercalary days',
      plannings_sales_subvention: 'Subvention',
      plannings_sales_subvention_accrual_id: 'Subvention accrual',
      plannings_sales_ppmv: 'PPMV',
      plannings_sales_no_items: 'No sales items in planning',
      plannings_sales_financing_volumes: 'Financing volumes',
      plannings_sales_interest_rate: 'Interest rate',
      plannings_sales_total_investment_cost: 'Total investment cost(TIC)',
      plannings_sales_total_interest_rates: 'Total interest rates',
      plannings_sales_ponder_for_average: 'Ponder for average',
      sidebar_plannings_capex_ol: 'Capex OL',
      plannings_capex_total: 'Total',
      summary_risk: 'Summary risk',
      plannings_risk: 'Risk',
      plannings_funding: 'Funding',
      plannings_new_risk_item: 'New Risk Split',
      plannings_risk_type: 'Risk Type',
      plannings_risk_stage: 'Stage',
      plannings_no_plannings: 'No plannings created',
      sidebar_plannings: 'Plannings',
      sidebar_plannings_capex: 'Capex',
      sidebar_plannings_sales: 'Sales',
      sidebar_parameter_values: 'Parameter values',
      sidebar_plannings_bs: 'Balance sheet',
      sidebar_plannings_pl: 'Profit/Loss',
      sidebar_plannings_summary_pl: 'Summary P&L',
      sidebar_plannings_summary_bs: 'Summary BS',
      sidebar_plannings_summary_capex: 'Summary  CAPEX',
      sidebar_recalculate: 'Recalculate',
      breadcrumbs_plannings_summary_pl: 'Summary P&L',
      breadcrumbs_plannings_summary_bs: 'Summary BS',
      breadcrumbs_plannings_summary_capex: 'Summary CAPEX',
      plannings_capex_search_items: 'Search items by Asset name',
      plannings_sales_search_items: 'Search items by Product name',
      plannings_empty_search: 'No results',
      plannings_capex_ol_placement_period: 'Placement period',
      plannings_capex_ol_leasing_type: 'Leasing type',
      plannings_capex_ol_segment: 'Segment',
      plannings_capex_ol_product_group: 'Product group',
      plannings_capex_ol_product_name: 'Product name',
      plannings_capex_ol_channel: 'Channel',
      plannings_capex_ol_currency: 'Currency',
      plannings_capex_ol_maturity: 'Maturity',
      plannings_capex_ol_guarantee: 'Guarantee',
      plannings_capex_ol_residual_value: 'Residual value',
      plannings_capex_ol_ppmv: 'PPMV',
      plannings_capex_ol_capex: 'Capex',
      plannings_capex_ol_depreciation_period: 'Depreciation period',
      plannings_capex_ol_purchuase_value_depreciated: 'Purchuase value to be depreciated',
      plannings_capex_ol_depreciation_rate: 'Depreciation rate',
      plannings_capex_ol_rv_deposit: 'RV + Deposit',
      plannings_capex_ol_book_value: 'Book value',
      plannings_capex_ol_total: 'Total',
      plannings_capex_ol_items: 'Capex OL',
      plannings_capex_ol_depreciation_calc: 'Depreciation',
      plannings_capex_ol_book_value_calc: 'Book value',
      plannings_capex_ol_gain_loss: 'Gain/Loss from sale',
      plannings_interest_rate_floor: 'Interest rate floor',
      plannings_interest_down_payment: 'Down payment',
      planning_year: 'Year',
      planning_month: 'Month',
      planning_currency: 'Currency',
      planning_author: 'Author',
      planning_edit: 'Edit',
      planning_delete: 'Delete',
      sidebar_planning: 'Planning',
      planning_created_at: 'Created at',
      table_actions: 'Actions',
      notifications_item_deleted_successfully: 'Item deleted successfully',
      notifications_planning_updated: 'Planning updated',
      login: 'Login',
      login_password: 'Password',
      login_wrong_password: 'Wrong password',
      plannings_plbs_finrep: 'Finrep',
      plannings_plbs_summary_pos: 'Summary - Position mapping',
      plannings_plbs_fst_mapping: 'FST Mapping',
      plannings_plbs_code: 'Code',
      plannings_plbs_position: 'Position',
      plannings_loan: 'Plannings loan',
      existing_funding: 'Existing funding',
    },
  },
  getters: {
    translate: state => key => state.keys[key] || key,
  },
};
