<template>
  <v-container fluid>
    <Breadcrumbs
      :items="breadcrumbs"/>
    <v-toolbar flat>
      <v-col cols="12" sm="3"
             class="px-0">
        <v-text-field
          clearable
          v-model="search"
          append-icon="mdi-magnify"
          :label="translate('plannings_sales_search_items')"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click='excelDownloadClick'>
        Export to Excel
      </v-btn>
    </v-toolbar>

    <ejs-grid :dataSource='items' v-if="dataLoaded" :allowSorting="true" :load='load' :cellSave='cellSave'
              :editSettings='editSettings' :selectionSettings='selectionOptions' :pageSettings='pageSettings'
              :sortSettings='sortOptions' :searchSettings='searchOptions' :allowPaging='true' :allowFiltering='true'
              :commandClick='commandClick' gridLines='Horizontal' :queryCellInfo='customiseCell' :allowExcelExport='true'
              :excelHeaderQueryCellInfo='excelHeader' :allowFitering="true" :filterSettings="{type: 'Menu'}">
      <e-columns>
        <e-column field='id' headerText='ID' :visible=false isPrimaryKey='true'></e-column>
        <e-column field='tempId' headerText='tempID' :visible=false></e-column>
        <e-column field='planning_period' :headerText="translate('plannings_capex_ol_placement_period')" editType='numericedit' width=116></e-column>
        <e-column field='leasing_type_id' :headerText="translate('plannings_capex_ol_leasing_type')" :filter="getFilter()" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='leasingTypeParams' :validationRules='requiredValidationRule' width=90></e-column>
        <e-column field='segment_id' :headerText="translate('plannings_capex_ol_segment')" :filter="getFilter()" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='segmentTypeParams' :validationRules='requiredValidationRule' width=74></e-column>
        <e-column field='product_group_id' :headerText="translate('plannings_capex_ol_product_group')" :filter="getFilter()" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='productGroupTypeParams' :validationRules='requiredValidationRule' width="104"></e-column>
        <e-column field='product_name' :headerText="translate('plannings_capex_ol_product_name')" :validationRules='requiredValidationRule' width=170></e-column>
        <e-column field='channel_id' :headerText="translate('plannings_capex_ol_channel')" :valueAccessor='dropdownValueAccess' :filter="getFilter()" foreignKeyValue="name"  editType='dropdownedit' :edit='channelTypeParams' width=140></e-column>
        <e-column field='currency_id' :headerText="translate('plannings_capex_ol_currency')" :filter="getFilter()" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='currencyTypeParams' :validationRules='requiredValidationRule' width=70></e-column>
        <e-column field='maturity' :headerText="translate('plannings_capex_ol_maturity')" editType='numericedit' width=70></e-column>
        <e-column field='guarantee' :headerText="translate('plannings_capex_ol_guarantee')" editType='numericedit' format="p2" width=80></e-column>
        <e-column field='residual_value' :headerText="translate('plannings_capex_ol_residual_value')" format="p2" editType='numericedit' width=100></e-column>
        <e-column field='ppmv' :headerText="translate('plannings_capex_ol_ppmv')" editType='numericedit' format="p2" width=60></e-column>
        <e-column field='capex' :headerText="translate('plannings_capex_ol_capex')" editType='numericedit' width=60></e-column>
        <e-column field='depreciation_period' :headerText="translate('plannings_capex_ol_depreciation_period')" editType='numericedit' width=130></e-column>
        <e-column field='purchase_value_to_be_depreciated' :headerText="translate('plannings_capex_ol_purchuase_value_depreciated')" format="p2" editType='numericedit' width=210></e-column>
        <e-column field='depreciation_rate' :headerText="translate('plannings_capex_ol_depreciation_rate')" format="p2" editType='numericedit' width=120></e-column>
        <e-column field='rv_deposit' :headerText="translate('plannings_capex_ol_rv_deposit')" editType='numericedit' width=100></e-column>
        <e-column field='book_value' :headerText="translate('plannings_capex_ol_book_value')" editType='numericedit' width=80></e-column>
        <e-column field='total' :headerText="translate('plannings_capex_ol_total')" editType='numericedit' width=70></e-column>
        <e-column textAlign='center' :headerText="translate('plannings_capex_ol_depreciation_calc')" :allowSorting="true" :columns='depreciationColumns'></e-column>
        <e-column textAlign='center' :headerText="translate('plannings_capex_ol_book_value_calc')" :allowSorting="true" :columns='bookValueColumns'></e-column>
        <e-column textAlign='center' :headerText="translate('plannings_capex_ol_gain_loss')" :allowSorting="true" :columns='gainLossColumns'></e-column>
      </e-columns>
      <e-aggregates>
        <e-aggregate>
          <e-columns>
            <e-column type="Sum" field="rv_deposit" format="n2"></e-column>
            <e-column type="Sum" field="book_value" format="n2"></e-column>
            <e-column type="Sum" field="total" format="n2"></e-column>
            <e-column type="Sum" v-for="col in depreciationColumnsComputed" :field="col.field" format="n2" v-bind:key="col.field"></e-column>
            <e-column type="Sum" v-for="col in bookValueColumnsComputed" :field="col.field" format="n2" v-bind:key="col.field"></e-column>
            <e-column type="Sum" v-for="col in gainLossColumnsComputed" :field="col.field" format="n2" v-bind:key="col.field"></e-column>
          </e-columns>
        </e-aggregate>
      </e-aggregates>
    </ejs-grid>

    <v-overlay :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate
        size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import Vue from 'vue';
import {
  GridPlugin, Page, Sort, Edit, Resize, Filter, ForeignKey, Search, ExcelExport, CommandColumn, Aggregate,
} from '@syncfusion/ej2-vue-grids';
import { createElement } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { mapGetters, mapState } from 'vuex';
import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';
import { sumBy, toNumber } from 'lodash';
import { Query } from '@syncfusion/ej2-data';
import Breadcrumbs from '../components/Breadcrumbs';
import api from '../api/lbt-api';
import { generateCapexDepreciationPeriods } from '../utilities/capexDepreciationPeriods';

Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);

const {
  getCapexOLForPlanning,
  getPlanning,
} = api;

export default {
  name: 'CapexOLPlanningNew',
  components: {
    Breadcrumbs,
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
    ...mapGetters('options', [
      'getOptionCategory',
    ]),
    ...mapState('plannings', [
      'activePlanning',
    ]),
    depreciationColumnsComputed() {
      const data = [];
      for (let i = 0; i < this.depreciationColumns.length; i += 1) {
        if (this.depreciationColumns[i].showFooterSum) {
          data.push(this.depreciationColumns[i]);
        }
      }
      return data;
    },
    bookValueColumnsComputed() {
      const data = [];
      for (let i = 0; i < this.bookValueColumns.length; i += 1) {
        if (this.bookValueColumns[i].showFooterSum) {
          data.push(this.bookValueColumns[i]);
        }
      }
      return data;
    },
    gainLossColumnsComputed() {
      const data = [];
      for (let i = 0; i < this.gainLossColumns.length; i += 1) {
        if (this.gainLossColumns[i].showFooterSum) {
          data.push(this.gainLossColumns[i]);
        }
      }
      return data;
    },
  },
  data() {
    return {
      dataLoaded: true,
      loader: false,
      items: [],
      headers: [],
      excelData: [],
      leasingTypeValues: [],
      segmentValues: [],
      channelValues: [],
      currencyValues: [],
      productGroupValues: [],
      leasingTypeParams: this.initDropdownParams(),
      segmentTypeParams: this.initDropdownParams(),
      channelTypeParams: this.initDropdownParams(),
      currencyTypeParams: this.initDropdownParams(),
      productGroupTypeParams: this.initDropdownParams(),
      requiredValidationRule: { required: true },
      editSettings: {
        allowEditing: false, allowAdding: false, allowDeleting: false, mode: 'Batch',
      },
      selectionOptions: { mode: 'Cell', type: 'Multiple', cellSelectionMode: 'Box' },
      searchOptions: { fields: ['product_name'], operator: 'contains', ignoreCase: true },
      search: '',
      pagination: {
        page: 1,
        size: 200,
        total: 0,
        itemsPerPage: [25, 50, 100],
      },
      dropdownValidationRule: { required: false },
      pageSettings: { pageSizes: false },
      sortOptions: { columns: [{ field: 'tempId', direction: 'Ascending' }, { field: 'id', direction: 'Descending' }] },
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'breadcrumbs_plannings_capex_ol',
          disabled: true,
        },
      ],
      capexDepreciationPeriods: [],
      capexBookValuePeriods: [],
      gainLossPeriods: [],
      depreciationColumns: [],
      bookValueColumns: [],
      gainLossColumns: [],
      highlightCellAttributes: { class: 'highlight-cell' },
      dropInstances: {
        leasing_type_id: {
          dataSource: [],
          dataAccessor: 'leasingTypeValues',
          fields: { text: 'option', value: 'id' },
        },
        segment_id: {
          dataSource: [],
          dataAccessor: 'segmentValues',
          fields: { text: 'option', value: 'id' },
        },
        product_group_id: {
          dataSource: [],
          dataAccessor: 'productGroupValues',
          fields: { text: 'option', value: 'id' },
        },
        channel_id: {
          dataSource: [],
          dataAccessor: 'channelValues',
          fields: { text: 'option', value: 'id' },
        },
        currency_id: {
          dataSource: [],
          dataAccessor: 'currencyValues',
          fields: { text: 'option', value: 'id' },
        },
      },
    };
  },
  provide: {
    grid: [Page, Sort, Edit, Resize, Filter, ForeignKey, Search, ExcelExport, CommandColumn, Aggregate],
  },
  methods: {
    load() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];

      grid.element.addEventListener('mousedown', (e) => {
        if (e.target.classList.contains('e-rowcell')) {
          const index = Number(e.target.getAttribute('Index'));
          const colindex = Number(e.target.getAttribute('aria-colindex'));
          const { field } = grid.getColumns()[colindex];
          grid.editModule.editCell(index, field);
        }
      });
    },
    cellSave(args) {
      const colName = args.columnName.split('.');
      const { rowData } = args;
      let volume;
      switch (colName[0]) {
        case 'financing_volumes':
          if (colName[2] === 'fy') break;
          if (rowData.financing_volumes[colName[1]].fyEditable) break;
          volume = rowData.financing_volumes[colName[1]];
          volume.fy = sumBy(volume.periods, volumePeriod => toNumber(volumePeriod.value));
          rowData.financing_volumes[colName[1]].periods[colName[3]].value = args.value;
          break;
        default:
          break;
      }

      if (args.value !== '') {
        const { cell } = args;
        cell.classList.add('e-updatedtd');
        cell.classList.remove('error');
      }
    },
    customiseCell(queryCellInfo) {
      const { cell } = queryCellInfo;
      if (cell.textContent === 'NaN') {
        cell.classList.add('error');
      }
    },
    dropdownValueAccess(field, data) {
      return this.fetchCapexOlPlanningItemValues(field, data[field]);
    },
    fetchCapexOlPlanningItemValues(key, value) {
      let fetchedValue = '';
      switch (key) {
        case 'leasing_type_id':
          fetchedValue = this.getLeasingTypeValue(value);
          break;
        case 'segment_id':
          fetchedValue = this.getSegmentValue(value);
          break;
        case 'product_group_id':
          fetchedValue = this.getProductGroupValue(value);
          break;
        case 'product_name':
          fetchedValue = value;
          break;
        case 'channel_id':
          fetchedValue = this.getChannelValue(value);
          break;
        case 'currency_id':
          fetchedValue = this.getCurrencyValue(value);
          break;
        default:
          break;
      }
      return fetchedValue;
    },
    getLeasingTypeValue(value) {
      let fetchedValue = '';
      this.leasingTypeValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },

    getSegmentValue(value) {
      let fetchedValue = '';
      this.segmentValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getProductGroupValue(value) {
      let fetchedValue = '';
      this.productGroupValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getChannelValue(value) {
      let fetchedValue = '';
      this.channelValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getCurrencyValue(value) {
      let fetchedValue = '';
      this.currencyValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    async fetchCapexOlItems() {
      const capexOl = await getCapexOLForPlanning(this.$route.params.planningId, this.pagination);
      this.items = capexOl.capex_ol_entries;
      this.pagination.total = capexOl.pagination.total;

      this.leasingTypeValues = this.getOptionCategory('LEASING_TYPE').options;
      this.segmentValues = this.getOptionCategory('SEGMENT').options;
      this.productGroupValues = this.getOptionCategory('PRODUCT_GROUP').options;
      this.channelValues = this.getOptionCategory('CHANNEL').options;
      this.currencyValues = this.getOptionCategory('CURRENCY').options;
      this.setDropdownDatasources();
      this.setFilterDropdowns();
    },
    setFilterDropdowns() {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.dropInstances) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.dropInstances.hasOwnProperty(key)) {
          this.dropInstances[key].dataSource = this[this.dropInstances[key].dataAccessor];
        }
      }
    },
    getFilter() {
      return {
        ui: {
          create: (args) => {
            const flValInput = createElement('input', { className: 'flm-input' });
            args.target.appendChild(flValInput);
            this.dropInstances[args.column.field] = new DropDownList({
              dataSource: this.dropInstances[args.column.field].dataSource,
              fields: this.dropInstances[args.column.field].fields,
              placeholder: 'Select a value',
              popupHeight: '240px',
            });
            this.dropInstances[args.column.field].appendTo(flValInput);
          },
          write: (args) => {
            this.dropInstances[args.column.field].text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, this.dropInstances[args.column.field].value);
          },
        },
      };
    },
    setDropdownDatasources() {
      this.leasingTypeParams.params.dataSource = this.getOptionCategory('LEASING_TYPE').options;
      this.segmentTypeParams.params.dataSource = this.getOptionCategory('SEGMENT').options;
      this.productGroupTypeParams.params.dataSource = this.getOptionCategory('PRODUCT_GROUP').options;
      this.channelTypeParams.params.dataSource = this.getOptionCategory('CHANNEL').options;
      this.currencyTypeParams.params.dataSource = this.getOptionCategory('CURRENCY').options;
    },
    initDropdownParams() {
      return {
        params: {
          dataSource: null,
          fields: { text: 'option', value: 'id' },
          query: new Query(),
        },
      };
    },
    commandClick(args) {
      this.deleteItem(args.rowData);
    },
    excelDownloadClick() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const excelExportProperties = {
        // exportType: 'CurrentPage',
        fileName: `CapexOL - Planning - ${this.activePlanning.year}-${this.activePlanning.month}.xlsx`,
      };
      grid.excelExport(excelExportProperties);
    },
    excelHeader(args) {
      const { field } = args.gridCell.column;
      if (field !== undefined && field.includes('.')) {
        const { cell } = args;
        if (field.startsWith('financing') || field.startsWith('total_investment')) {
          cell.value = this.lookupFinancingVolumesPeriodHeader(cell.value);
        } else if (field.startsWith('interest') || field.startsWith('total_interest')) {
          cell.value = this.lookupInterestRateHeader(cell.value);
        } else if (field.startsWith('ponder')) {
          cell.value = this.lookupPonderForAveragesHeader(cell.value);
        }
      }
    },
    prepareDepreciationGridHeaders() {
      this.capexDepreciationPeriods.forEach((el, idx) => {
        this.depreciationColumns.push({
          field: `depreciation_calc.${idx}.value`,
          value: el,
          headerText: el,
          customAttributes: this.highlightCellAttributes,
          width: 70,
          showFooterSum: true,
          editType: 'numericedit',
          allowSorting: false,
          allowFiltering: false,
          textAlign: 'right',
          format: 'n2',
        });
      });
    },
    prepareBookValueGridHeaders() {
      this.capexBookValuePeriods.forEach((el, idx) => {
        this.bookValueColumns.push({
          field: `book_value_calc.${idx}.value`,
          value: el,
          headerText: el,
          width: 70,
          showFooterSum: true,
          editType: 'numericedit',
          allowSorting: false,
          allowFiltering: false,
          textAlign: 'right',
          format: 'n2',
        });
      });
    },
    prepareGainLossGridHeaders() {
      this.gainLossPeriods.forEach((el, idx) => {
        this.gainLossColumns.push({
          field: `gain_loss_calc.${idx}.value`,
          value: el,
          headerText: el,
          customAttributes: this.highlightCellAttributes,
          width: 70,
          showFooterSum: true,
          editType: 'numericedit',
          allowSorting: false,
          allowFiltering: false,
          textAlign: 'right',
          format: 'n2',
        });
      });
    },
  },
  async created() {
    this.loader = true;
    this.headers = [
      {
        text: this.translate('plannings_capex_ol_placement_period'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_leasing_type'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_segment'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_product_group'),
        align: 'left',
        sortable: false,
        width: 150,
      },
      {
        text: this.translate('plannings_capex_ol_product_name'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_channel'),
        align: 'left',
        sortable: false,
        width: 150,
      },
      {
        text: this.translate('plannings_capex_ol_currency'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_maturity'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_guarantee'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_residual_value'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_ppmv'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_capex'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_depreciation_period'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_purchuase_value_depreciated'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_depreciation_rate'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_rv_deposit'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_book_value'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_capex_ol_total'),
        align: 'left',
        sortable: false,
      },
    ];
    try {
      // const start = new Date().getTime();
      await this.fetchCapexOlItems();
      const planning = await getPlanning(this.$route.params.planningId);
      this.capexDepreciationPeriods = generateCapexDepreciationPeriods(planning.year, planning.month - 1);
      this.capexBookValuePeriods = generateCapexDepreciationPeriods(planning.year, planning.month - 1);
      this.gainLossPeriods = generateCapexDepreciationPeriods(planning.year, planning.month - 1);
      this.prepareDepreciationGridHeaders();
      this.prepareBookValueGridHeaders();
      this.prepareGainLossGridHeaders();
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
    this.capexDepreciationPeriods.forEach((el) => {
      this.headers.push({
        text: el,
        align: 'left',
        sortable: false,
        class: 'depreciation',
        value: el,
      });
    });
    this.capexBookValuePeriods.forEach((el) => {
      this.headers.push({
        text: el,
        align: 'left',
        sortable: false,
        value: el,
      });
    });
    this.gainLossPeriods.forEach((el) => {
      this.headers.push({
        text: el,
        align: 'left',
        sortable: false,
        class: 'gain-loss',
        value: el,
      });
    });
  },
};
</script>

<style lang="scss" scoped>
table {

  tr {
    $input-size: 10px !important;

    td {
      font-size: $input-size;
      height: 37px;

      &.depreciation, &.gain-loss {
        background: #eeeeee;
      }
    }
  }
}

.e-summarycell {
  padding: 0 !important;
}
</style>
