<template>
  <div>

      <div>
        <v-progress-linear
          v-model="progress"
          color="light-blue"
          height="25"
          reactive
        >
          <strong>{{ progress }} %</strong>
        </v-progress-linear>
      </div>
    <v-alert v-if="message" border="left" color="blue-grey" dark>
     <pre> {{ message }}</pre>
    </v-alert>

    <v-row no-gutters justify="center" align="center">
      <v-col cols="8">
        <v-file-input
          show-size
          label="New Planning File"
          v-model="newPlanningFile"
          @change="selectFile"
        ></v-file-input>
      </v-col>
            <v-col cols="4" class="pl-2">
        <v-btn color="success" dark small @click="upload">
          Upload
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" align="center">
         <v-col cols="8">
            <v-file-input
                prepend-icon="mdi-arrange-send-backward"
                :label="'Loans Planning File'"
                v-model="loansPlanningFile"
                show-size
                accept="application/*"
                @change="selectFile">
            </v-file-input>
            </v-col>

      <v-col cols="4" class="pl-2">
        <v-btn color="success" dark small @click="upload">
          Upload
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
     </v-row>
         <v-row no-gutters justify="center" align="center">
         <v-col cols="8">
            <v-file-input
                prepend-icon="mdi-arrange-send-backward"
                :label="'Fixed assets planning rates'"
                v-model="fixedAssetsPlanningRates"
                show-size
                accept="application/*"
                @change="selectFile">
            </v-file-input>
            </v-col>

      <v-col cols="4" class="pl-2">
        <v-btn color="success" dark small @click="upload">
          Upload
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
     </v-row>
         <v-row no-gutters justify="center" align="center">
         <v-col cols="8">
            <v-file-input
                prepend-icon="mdi-arrange-send-backward"
                :label="'Fixed assets'"
                v-model="fixedAssets"
                show-size
                accept="application/*"
                @change="selectFile">
            </v-file-input>
            </v-col>

      <v-col cols="4" class="pl-2">
        <v-btn color="success" dark small @click="upload">
          Upload
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
     </v-row>
         <v-row no-gutters justify="center" align="center">
         <v-col cols="8">
            <v-file-input
                prepend-icon="mdi-arrange-send-backward"
                :label="'Fixed Assets Support'"
                v-model="fixedAssetsSupport"
                show-size
                accept="application/*"
                @change="selectFile">
            </v-file-input>
            </v-col>

      <v-col cols="4" class="pl-2">
        <v-btn color="success" dark small @click="upload">
          Upload
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
     </v-row>
    <v-row no-gutters justify="center" align="center">
         <v-col cols="8">
            <v-file-input
                prepend-icon="mdi-arrange-send-backward"
                :label="'Archive Planning File'"
                v-model="archivePlanningFile"
                show-size
                accept="application/*"
                @change="selectFile">
            </v-file-input>
            </v-col>

      <v-col cols="4" class="pl-2">
        <v-btn color="success" dark small @click="upload">
          Upload
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
     </v-row>
  </div>
</template>

<script>
import axios from 'axios';
import { isAuthenticated } from '../utilities/isAuthenticated';


export default {
  name: 'upload-files',
  data() {
    return {
      progress: 0,
      message: '',
      fixedAssetsPlanningRates: [],
      fixedAssets: [],
      fixedAssetsSupport: [],
      newPlanningFile: [],
      loansPlanningFile: [],
      archivePlanningFile: [],
    };
  },

 methods: {
    selectFile(file) {
      this.progress = 0;
      this.message = file;
    },

    async upload() {
      if (!this.loansPlanningFile && !this.fixedAssetsPlanningRates && !this.fixedAssets && !this.fixedAssetsPlanningRates && !this.fixedAssetsSupport && !this.newPlanningFile) {
        this.message = 'Please select a file!';
        return;
      }
      this.message = '';


        const formData = new FormData();
        const baseUrl = localStorage.getItem('api_link');
        const apiToken = isAuthenticated() ? process.env.VUE_APP_API_TOKEN : 'neces_razbojnice';
        /*
        Add the form data we need to submit
        */
        formData.append('fa_rates_file', this.fixedAssetsPlanningRates);
        formData.append('fa_import_file', this.fixedAssets);
        formData.append('fa_support_file', this.fixedAssetsSupport);
        formData.append('fa_new_planning_file', this.newPlanningFile);
        formData.append('loans_planning_file', this.loansPlanningFile);
        formData.append('archive_planning_file', this.archivePlanningFile);
        /*
        Make the request to the POST /single-file URL
        */
        try {
        const response = await axios.post(`${baseUrl}/validate/`,
        formData,
        {
                headers: {
                'x-api-key': apiToken,
                'content-type': 'multipart/form-data',
                },
        onUploadProgress: function (progressEvent) {
        this.progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10);
        }.bind(this),
        });
        this.message = response.data;
        } catch (e) {
            this.message = e.response.data.message;
            throw new Error(e.response.data.message);
        }
    },
},
};
</script>
