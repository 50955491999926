export function generateFundingPeriods(startYear, startMonth) {
  const numOfMonths = 12;
  const depreciationPeriods = [];
  let monthAddition = 0;

  // Generate months for the first year
  while (startMonth + monthAddition <= numOfMonths) {
    const month = startMonth + monthAddition;
    depreciationPeriods.push(`${startYear}M${month}`);
    monthAddition += 1;
  }

  for (let yearIterator = 1; yearIterator <= 5; yearIterator += 1) {
    const year = startYear + yearIterator;
    for (let month = 1; month <= numOfMonths; month += 1) {
      if ([3, 6, 9, 12].includes(month)) { // For months 3, 6, 9, 12 generate quarters
        depreciationPeriods.push(`${year}Q${Math.ceil(month / 3)}`);
      } else {
        depreciationPeriods.push(`${year}M${month}`); // Otherwise, continue with monthly
      }
    }
  }

  return depreciationPeriods;
}
