<template>
  <tr :class="[invalidData ? 'invalid' : '', 'planning-row']">
    <td :class="{ 'has-error': errorBucket.leasing_type}">
      <v-select
        class="dropdown-value fixed-size medium"
        dense
        ref="leasing_type"
        :rules="[validators.required]"
        v-model="item.leasing_type_id"
        :hide-details="true"
        :items="leasingTypeValues"
        item-text="option"
        item-value="id"
      ></v-select>
    </td>
    <td :class="{ 'has-error': errorBucket.segment}">
      <v-select
        class="dropdown-value fixed-size"
        dense
        ref="segment"
        :rules="[validators.required]"
        v-model="item.segment_id"
        :hide-details="true"
        :items="segmentValues"
        item-text="option"
        item-value="id"
      ></v-select>
    </td>
    <td :class="{ 'has-error': errorBucket.amount}">
       <v-text-field v-model="item.amount"
            ref="amount"
            :rules="[validators.required]"
            single-line
            counter />
    </td>
    <td ref="delete">
      <v-icon small
        @click.stop="deleteItem()">
        mdi-delete
      </v-icon>
    </td>
  </tr>
</template>
<script>

import { mapGetters } from 'vuex';
import {
  each,
} from 'lodash';
import validators from '@/validators';

export default {
  name: 'IncreaseRow',
  props: {
    item: {
      type: Object,
    },
    rowIndex: Number,
  },
  data() {
    return {
      validators,
      invalidData: false,
      errorBucket: {},
      leasingTypeValues: [],
      segmentValues: [],
      clientTypeValues: [],

    };
  },
  computed: {
    ...mapGetters('options', [
      'getOptionCategory',
    ]),
  },
  created() {
    this.leasingTypeValues = this.getOptionCategory('LEASING_TYPE').options;
    this.segmentValues = this.getOptionCategory('SEGMENT').options;
    this.clientTypeValues = this.getOptionCategory('RISK_TYPED').options;
  },
  methods: {
    parseVolume(volume) {
      const volumeItem = volume;
      return volumeItem;
    },
    deleteItem() {
      this.$emit('delete', { item: this.item, rowIndex: this.rowIndex });
    },
    validate() {
      this.errorBucket = {};
      each(this.$refs, (val, key) => {
        if (val.errorBucket && val.errorBucket.length) this.errorBucket[key] = val.errorBucket;
      });

      if (Object.keys(this.errorBucket).length) {
        this.invalidData = true;
      } else {
        this.invalidData = false;
      }

      return this.errorBucket;
    },
  },
};
</script>

<style scoped lang="scss" >
.planning-row {
  $input-size: 10px !important;

  &.invalid > td:first-of-type{
    border-left: solid 4px #ff0000;
  }

  &.invalid > td {
    background: #fcecec;

    &.has-error {
      background: rgba(240, 52, 52, 1);
    }
  }

  .input-value {
    font-size: $input-size;
  }

  .dropdown-value {
    font-size: $input-size;

    &.fixed-size {
      width: 100px;

      &.medium {
        width: 150px;
      }

      &.large {
        width: 200px;
      }

      &.extra-large {
        width: 250px;
      }
    }
  }

  .financing-volumes, .tic-volumes, .ponder {
    background: #eeeeee;
  }
}
</style>
