<template>
  <v-container fluid
    class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>
    <v-data-table fixed-header
      dense
      class="elevation-1"
      :page.sync="pagination.page"
      :server-items-length="pagination.total || items.length"
      :headers="headers"
      :items="items"
      :footer-props="{
        'items-per-page-options': pagination.itemsPerPage
      }"
      :items-per-page="pagination.size">
      <template v-slot:top>
        <v-toolbar flat>
          <v-col cols="12" sm="3"
            class="px-0">
            <v-text-field
              clearable
              v-model="search"
              append-icon="mdi-magnify"
              :label="translate('options_search_items')"
              single-line
              hide-details
            ></v-text-field>
           </v-col>
          <v-spacer></v-spacer>
          <v-btn color="primary"
              @click.stop="addNewOptionsItem()">
            <v-icon class="mr-2">mdi-table-large-plus</v-icon>
            {{translate('options_new_option_item')}}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:header="props">
        <thead>
          <tr>
            <th :colspan="headers.length"
              class="text-center">
              {{translate('options_items')}}
            </th>
            </tr>
          <tr>
            <th v-for="(header, idx) in props.headers"
              :key="idx">
              {{header.text}}
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:body="props">
        <tbody>
          <OptionCategoryRow v-for="(item, idx) in props.items"
            v-show="!search || (item.option.indexOf(search) !== -1)"
            :ref="`row-${idx}`"
            :key="item.id || item.tempId"
            :item="item"
            :rowIndex="idx"
            @delete="deleteItem" />
          <tr v-if="!props.items.length"
            class="v-data-table__empty-wrapper">
            <td :colspan="headers.length">{{translate('plannings_capex_no_items')}}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-btn color="accent"
      fab
      fixed
      right
      bottom
      @click.stop="saveOption()">
      <v-icon dark>mdi-content-save</v-icon>
    </v-btn>
    <Snackbar :text="notificationText"
      :type="notificationType" />
    <v-overlay :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate
        size="64"></v-progress-circular>
    </v-overlay>
    <ConfirmDialog
      :dialog="deleteDialog"
      :description="deleteDialogDescription"
      @change="setDialogState"
      @confirmDelete="confirmDeleteAction"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import OptionCategoryRow from '@/components/OptionCategoryRow';
import ConfirmDialog from '@/components/ConfirmDialog';
import Snackbar from '../components/Snackbar';
import Breadcrumbs from '../components/Breadcrumbs';


import api from '../api/lbt-api';
import store from '../store/index';

const {
  getOptionsForCategory,
  createOptionsForCategory,
  // getOption,
  deleteOptionsForCategory,
  updateOptionsForCategory,
} = api;

export default {
  name: 'OptionCategory',
  components: {
    Snackbar,
    OptionCategoryRow,
    Breadcrumbs,
    ConfirmDialog,
  },
  data() {
    return {
      search: '',
      notificationText: '',
      notificationType: '',
      deleteDialog: false,
      deleteDialogDescription: '',
      itemForDeletion: {},
      category_id: {},
      loader: false,
      headers: [],
      items: [],
      originalItems: [],
      pagination: {
        page: 1,
        size: 25,
        total: 0,
        itemsPerPage: [25, 50, 100],
      },
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_options',
          route: '/options',
          disabled: false,
        },
        {
          translationKey: 'breadcrumbs_options',
          disabled: true,
        },
      ],

    };
  },
  async created() {
    this.loader = true;
    this.headers = [
      {
        text: 'Option name',
        align: 'left',
        sortable: false,
        value: 'option',
      },
      {
        text: 'Option value',
        align: 'left',
        sortable: false,
        value: 'value',
      },
      {
        text: this.translate('table_actions'),
        align: 'left',
        sortable: false,
        value: 'actions',
      },
    ];
    try {
      await this.fetchOptionCategoryItems();
      // const option = await getOption(this.$route.params.optionId);
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
  },
  methods: {
    async fetchOptionCategoryItems() {
      const optionVals = await getOptionsForCategory(this.$route.params.optionId, this.pagination);
      this.items = optionVals[0].options;
      this.category_id = optionVals[0].id;
      this.originalItems = JSON.parse(JSON.stringify(optionVals[0].options));
    },
    addNewOptionsItem() {
      this.items.unshift({
        tempId: Math.random(),
        option: '',
        value: '',
      });
    },
    async saveOption() {
      const rowNum = Object.keys(this.$refs).length;
      const optionCreateRequests = [];
      const optionUpdateRequests = [];
      let payloadHasInvalidRow = false;
      if (!rowNum) {
        return;
      }

      for (let iterator = 0; iterator < rowNum; iterator += 1) {
        if (this.$refs[`row-${iterator}`].length) {
          this.$refs[`row-${iterator}`][0].validate();
          if (this.$refs[`row-${iterator}`][0].invalidData) {
            payloadHasInvalidRow = true;
          }
        }
      }

      if (payloadHasInvalidRow) {
        return;
      }

      // TODO: Send all capex items in bulk request
      this.loader = true;
      try {
        for (let iterator = 0; iterator < rowNum; iterator += 1) {
          if (!this.items[iterator].id) {
            optionCreateRequests.push(createOptionsForCategory(this.category_id, this.items[iterator]));
          } else {
            for (let i = 0; i < this.originalItems.length; i += 1) {
              if (this.originalItems[i].id === this.items[iterator].id) {
                if (this.originalItems[i].option !== this.items[iterator].option
                  || this.originalItems[i].value !== this.items[iterator].value) {
                  optionUpdateRequests.push(updateOptionsForCategory(this.category_id, this.items[iterator]));
                }
                break;
              }
            }
          }
        }
        await Promise.all(optionCreateRequests);
        await Promise.all(optionUpdateRequests);
        this.notificationText = this.translate('notifications_planning_updated');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchOptionCategoryItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    deleteItem(item) {
      this.itemForDeletion = item;
      this.deleteDialog = true;
      this.deleteDialogDescription = this.translate('dialog_delete_action_description');
    },
    async confirmDeleteAction() {
      if (this.itemForDeletion.item.tempId) {
        this.$delete(this.items, this.itemForDeletion.rowIndex);
        this.deleteDialog = false;
        this.itemForDeletion = null;
        return;
      }
      this.loader = true;

      try {
        await deleteOptionsForCategory(this.category_id, this.itemForDeletion.item.id);
        this.deleteDialog = false;
        this.itemForDeletion = null;
        this.notificationText = this.translate('notifications_item_deleted_successfully');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchOptionCategoryItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    setDialogState(value) {
      this.deleteDialog = value;
    },
  },
  async beforeRouteEnter(from, to, next) {
    await store.dispatch('finrepBsPl/getOptions');
    next();
  },
};
</script>

<style lang="scss" scoped>

.container {
  padding-bottom: 5rem !important;
}

</style>
