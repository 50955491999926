<template>
  <v-app>
    <v-app-bar
      app
      color="secondary"
      clipped-left
    >
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <div class="d-flex align-center ml-3">
        <router-link
          to="/">
          <v-img
            :src="require('./assets/logo.png')"
            alt="Zame LBT"
            class="shrink mr-2"
            contain
            transition="scale-transition"
            width="120"
          />
        </router-link>
        <span v-show="showTitle" style="color: #3c4042;">{{ activeEntity }}</span>
      </div>
      <v-spacer />
      <v-btn v-if="!auth" class="mx-2" fab dark small color="primary">
        <v-icon dark>mdi-account</v-icon>
      </v-btn>
      <v-btn v-else @click="logout()" class="mx-2" fab dark small color="primary">
        <v-icon dark>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <Sidebar
      v-if="auth"
      @change="setDrawerState"
      :drawer="drawer"
    />
    <v-content>
      <transition>
        <router-view></router-view>
      </transition>
    </v-content>
    <Snackbar :text="notificationText"
      :type="notificationType" />
    <v-overlay :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate
        size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import Sidebar from './components/Sidebar';
import Snackbar from './components/Snackbar';

import { isAuthenticated } from './utilities/isAuthenticated';
import store from './store/index';


export default {
  name: 'App',
  components: {
    Sidebar,
    Snackbar,
  },
  async created() {
    try {
      const self = this;
      await store.dispatch('options/getOptions');
      self.interval = setInterval(() => {
        self.showTitle = !window.location.href.includes('login');

        if (self.showTitle) {
          clearInterval(self.interval);
          self.interval = null;
        }
      }, 120);
    } catch (message) {
      /*
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
       */
    } finally {
      this.loader = false;
    }
  },
  data() {
    return {
      drawer: true,
      notificationText: '',
      notificationType: '',
      loader: true,
      interval: null,
      showTitle: false,
    };
  },
  computed: {
    auth: isAuthenticated,
    activeEntity: () => {
      const entities = {
        bh: 'Sparkasse Leasing d.o.o. Sarajevo',
        hr: 'Erste & Steiermärkische S-Leasing d.o.o. Zagreb',
        cg: 'S-Leasing d.o.o. Podgorica',
        mk: 'Sparkasse Leasing d.o.o. Skopje',
        sr: 'Sparkasse d.o.o. Beograd',
        sl: 'Sparkasse Leasing S d.o.o. Ljubljana',
        le: 'Development environment',
        none: '',
      };

      return entities[localStorage.getItem('entity_code') || 'none'];
    },
  },
  watch: {
    dialogVisible() {
      this.$emit('change', this.dialogVisible);
    },
    dialog() {
      this.dialogVisible = this.dialog;
    },
  },
  methods: {
    setDrawerState(value) {
      this.drawer = value;
    },
    logout() {
      localStorage.removeItem('authenticatedAt');
      localStorage.removeItem('token');
      localStorage.removeItem('api_link');
      localStorage.removeItem('entity_code');
      this.$router.go();
    },
  },
};
</script>

<style>
.no-arrows .e-spin-down, .no-arrows .e-spin-up {
  display: none !important;
}
</style>
