import Vue from 'vue';
import VueRouter from 'vue-router';
import Plannings from '../views/Plannings';
import CapexPlanning from '../views/CapexPlanning';
import CapexPlanningNew from '../views/CapexPlanningNew';
import SalesPlanningNew from '../views/SalesPlanningNew';
import RiskPlanning from '../views/Risk';
import SummaryRISK from '../views/SummaryRISK';
import OtherInput from '../views/OtherInput';
import Charts from '../views/Charts';
import FundingInput from '../views/FundingInput';
import FundingInputNew from '../views/FundingInputNew';
import ExistingFunding from '../views/ExistingFunding';
import CapexOLPlanning from '../views/CapexOLPlanning';
import CapexOLPlanningNew from '../views/CapexOLPlanningNew';
import BalanceSheet from '../views/BalanceSheet';
import BalanceSheetNew from '../views/BalanceSheetNew';
import ProfitLoss from '../views/ProfitLoss';
import ProfitLossNew from '../views/ProfitLossNew';
import SummaryPL from '../views/SummaryPL';
import TaxInput from '../views/TaxInput';
import SummaryBS from '../views/SummaryBS';
import SummaryPlEco from '../views/SummaryPlEco';
import SummaryBsEco from '../views/SummaryBsEco';
import SummaryCAPEX from '../views/SummaryCAPEX';
import SummaryCAPEXold from '../views/SummaryCAPEXold';
import SummaryPEREX from '../views/SummaryPEREX';
import SummaryOPEX from '../views/SummaryOPEX';
import IncreaseAmount from '../views/IncreaseAmount';
import SummaryNewProduction from '../views/SummaryNewProduction';
import SummaryPortfolio from '../views/SummaryPortfolio';
import FxRates from '../views/ExchangeRates';
import Options from '../views/Options';
import Euribor from '../views/Euribor';
import OptionCategory from '../views/OptionCategory';
import ProductMapping from '../views/ProductMapping';
import KontoMapping from '../views/KontoMapping';
import OtherFinreps from '../views/OtherFinreps';
import ArchivePositions from '../views/ArchivePositions';
import PlanningComment from '../views/PlanningComment';
import Validator from '../views/Validator';
import Login from '../views/Login';
import UserManagement from '../views/User';
import UserActivityLogs from '../views/UserActivityLog';
import { isAuthenticated } from '../utilities/isAuthenticated';

import SummaryBsEcoFull from '../views/SummaryBsEcoFull';
import SummaryPlEcoFull from '../views/SummaryPlEcoFull';

Vue.use(VueRouter);

const nestedSidebarItems = [
  {
    translationKey: 'sidebar_plannings_capex',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/capex_new',
  },
  {
    translationKey: 'sidebar_plannings_sales',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/salesnew',
  },
  {
    translationKey: 'sidebar_plannings_capex_ol',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/capex_ol_new',
  },
  {
    translationKey: 'plannings_risk',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/risk',
  },
  {
    translationKey: 'summary_risk',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/summary_risk',
  },
  {
    translationKey: 'Increase amount',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/increase',
  },
  {
    translationKey: 'Other Input',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/other',
  },
  {
    translationKey: 'plannings_funding',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/funding_new',
  },
  {
    translationKey: 'existing_funding',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/existing_funding',
  },
  {
    translationKey: 'Tax',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/tax_input',
  },
  {
    translationKey: 'sidebar_plannings_bs',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/balance_sheet_new',
  },
  {
    translationKey: 'sidebar_plannings_pl',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/profit_loss_new',
  },
  {
    translationKey: 'sidebar_plannings_summary_pl',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/summary_pl',
  },
  {
    translationKey: 'sidebar_plannings_summary_bs',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/summary_bs',
  },
  {
    translationKey: 'sidebar_plannings_summary_capex',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/summary_capex',
  },
  {
    translationKey: 'sidebar_plannings_summary_perex',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/summary_perex',
  },
  {
    translationKey: 'OPEX',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/summary_opex',
  },
  {
    translationKey: 'plannings_new_production',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/summary_new_production',
  },
  {
    translationKey: 'plannings_portfolio',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/summary_portfolio',
  },
  {
    translationKey: 'BS ECO',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/summary_bs_eco_full',
  },
  {
    translationKey: 'PL ECO',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/summary_pl_eco_full',
  },
  {
    translationKey: 'Summary BS ECO',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/summary_bs_eco',
  },
  {
    translationKey: 'Summary PL ECO',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/summary_pl_eco',
  },
  {
    translationKey: 'Charts',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/charts',
  },
  {
    translationKey: 'sidebar_comments',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/comments',
  },
  {
    translationKey: 'sidebar_exchange_rates',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/fx_rates',
  },
  {
    translationKey: 'sidebar_euribor',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/euribor',
  },
  {
    translationKey: 'sidebar_archive',
    routeSupplantKeys: ['planningId'],
    route: '/plannings/{planningId}/archive_positions',
  },
];

const routes = [
  {
    path: '/plannings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // () => import(/* webpackChunkName: "about" */ ${component path})
    component: Plannings,
    name: 'plannings',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
  },
  {
    path: '/plannings/:planningId',
    component: CapexPlanning,
    name: 'planningCapex',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/capex_new',
    component: CapexPlanningNew,
    name: 'CapexPlanningNew',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/salesnew',
    component: SalesPlanningNew,
    name: 'planningSalesNew',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/capex_ol',
    component: CapexOLPlanning,
    name: 'capexOLPlanning',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/capex_ol_new',
    component: CapexOLPlanningNew,
    name: 'CapexOLPlanningNew',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/risk',
    component: RiskPlanning,
    name: 'riskPlanning',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/summary_risk',
    component: SummaryRISK,
    name: 'summaryRisk',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/increase',
    component: IncreaseAmount,
    name: 'IncreaseAmount',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/funding',
    component: FundingInput,
    name: 'fundingInput',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/funding_new',
    component: FundingInputNew,
    name: 'fundingInputNew',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/existing_funding',
    component: ExistingFunding,
    name: 'existingFunding',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/other',
    component: OtherInput,
    name: 'otherInput',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/tax_input',
    component: TaxInput,
    name: 'taxInput',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/balance_sheet',
    component: BalanceSheet,
    name: 'BalanceSheet',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/euribor',
    component: Euribor,
    name: 'Euribor',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/balance_sheet_new',
    component: BalanceSheetNew,
    name: 'BalanceSheetNew',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/profit_loss_new',
    component: ProfitLossNew,
    name: 'ProfitLossNew',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/profit_loss',
    component: ProfitLoss,
    name: 'ProfitLoss',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/summary_pl',
    component: SummaryPL,
    name: 'SummaryPL',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/summary_bs',
    component: SummaryBS,
    name: 'SummaryBS',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/summary_capex',
    component: SummaryCAPEX,
    name: 'SummaryCAPEX',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/summary_capex_old',
    component: SummaryCAPEXold,
    name: 'SummaryCAPEXold',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/summary_perex',
    component: SummaryPEREX,
    name: 'SummaryPEREX',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/summary_opex',
    component: SummaryOPEX,
    name: 'SummaryOPEX',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/summary_new_production',
    component: SummaryNewProduction,
    name: 'SummaryNewProduction',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/summary_portfolio',
    component: SummaryPortfolio,
    name: 'SummaryPortfolio',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/summary_pl_eco',
    component: SummaryPlEco,
    name: 'SummaryPlEco',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/summary_bs_eco',
    component: SummaryBsEco,
    name: 'SummaryBsEco',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/summary_bs_eco_full',
    component: SummaryBsEcoFull,
    name: 'SummaryBsEcoFull',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/summary_pl_eco_full',
    component: SummaryPlEcoFull,
    name: 'SummaryPlEcoFull',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/charts',
    component: Charts,
    name: 'Charts',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/fx_rates',
    component: FxRates,
    name: 'FxRates',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/comments',
    component: PlanningComment,
    name: 'PlanningComment',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  {
    path: '/plannings/:planningId/archive_positions',
    component: ArchivePositions,
    name: 'ArchivePosition',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
    meta: {
      sidebarNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: nestedSidebarItems,
      },
    },
  },
  { // TODO: Remove this when login screen is created
    path: '/',
    redirect: '/plannings',
  },
  {
    path: '/login',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (authenticated) next('/');
      else next();
    },
    component: Login,
  },
  {
    path: '/options',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // () => import(/* webpackChunkName: "about" */ ${component path})
    component: Options,
    name: 'options',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
  },
  {
    path: '/options/:optionId',
    component: OptionCategory,
    name: 'optionCategory',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
  },
  {
    path: '/finrep-product-mapping',
    component: ProductMapping,
    name: 'productMapping',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
  },
  {
    path: '/users',
    component: UserManagement,
    name: 'userManagement',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
  },
  {
    path: '/user_activity_logs',
    component: UserActivityLogs,
    name: 'userActivityLogs',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
  },
  {
    path: '/finrep-konto-mapping',
    component: KontoMapping,
    name: 'kontoMapping',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
  },
  {
    path: '/other-finrep',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // () => import(/* webpackChunkName: "about" */ ${component path})
    component: OtherFinreps,
    name: 'other_finrep',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
  },
  {
    path: '/other-type',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // () => import(/* webpackChunkName: "about" */ ${component path})
    component: OtherFinreps,
    name: 'other_type',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
  },
  {
    path: '/validator',
    component: Validator,
    name: 'validator',
    beforeEnter: (to, from, next) => {
      const authenticated = isAuthenticated();
      if (!authenticated) next('/login');
      else next();
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
