export function generateRiskPeriodsValues(startYear, startMonth) {
  const numOfMonths = 12;
  const numOfQuarters = 4;
  const periods = [];
  let monthAddition = 1;
  let nextYear = startYear + 1;
  // Current year calculated by month
  while (startMonth + monthAddition <= numOfMonths) {
    const month = startMonth + monthAddition;
    const depreciationPeriods = {
      period: `${startYear}M${month}`,
      value: 0,
    };
    monthAddition += 1;
    periods.push(depreciationPeriods);
  }
  for (let yearIterator = 1; yearIterator <= 1; yearIterator += 1) {
    // Next year calculated by quartals
    for (let iterator = 1; iterator <= numOfQuarters; iterator += 1) {
      const depreciationPeriods = {
        period: `${nextYear}Q${iterator}`,
        value: 0,
      };
      periods.push(depreciationPeriods);
    }
    nextYear += 1;
  }
  for (let yearIterator = 2; yearIterator <= 5; yearIterator += 1) {
    // Next year calculated by quartals
    const depreciationPeriods = {
      period: `${nextYear}`,
      value: 0,
    };
    nextYear += 1;
    periods.push(depreciationPeriods);
  }
  return periods;
}
