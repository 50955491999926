<template>
  <tr :class="[invalidData ? 'invalid' : '', 'planning-row']">
    <td :class="[errorBucket.name ? 'has-error' : '', 'input-value']">
      <v-edit-dialog
        large
        eager
        :return-value.sync="item.name">
        {{ item.name }}
        <template v-slot:input>
          <v-text-field v-model="item.name"
            ref="name"
            single-line
            counter />
        </template>
      </v-edit-dialog>
    </td>
    <td :class="[errorBucket.email ? 'has-error' : '', 'input-value']">
      <v-edit-dialog
        eager
        large
        :return-value.sync="item.email">
        {{ item.email }}
        <template v-slot:input>
          <v-text-field v-model="item.email"
            ref="email"
            single-line
            counter />
        </template>
      </v-edit-dialog>
    </td>
    <td :class="[errorBucket.password ? 'has-error' : '', 'input-value']">
      <v-edit-dialog
        eager
        large
        :return-value.sync="item.password"
        @save="savePassword">
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="item.password"
            :error-messages="errorBucket.password ? ['Password error'] : []"
            append-icon="mdi-eye"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <template v-slot:input>
          <v-text-field
            v-model="item.password"
            label="Edit Password"
            single-line
            counter
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            append-icon="mdi-eye"
            autofocus
          />
        </template>
      </v-edit-dialog>
    </td>
    <td :class="[errorBucket.role ? 'has-error' : '', 'input-value']">
      <v-edit-dialog
        eager
        large
        :return-value.sync="item.role">
        {{ item.role }}
        <template v-slot:input>
          <v-text-field v-model="item.role"
            ref="role"
            single-line
            counter />
        </template>
      </v-edit-dialog>
    </td>
    <td ref="delete">
      <v-icon small
              @click.stop="deleteItem()">
        mdi-delete
      </v-icon>
    </td>
  </tr>
</template>

<script>
import { each } from 'lodash';
import { mapGetters } from 'vuex';
import validators from '@/validators';

export default {
  name: 'UserRow',
  props: {
    item: {
      type: Object,
    },

    rowIndex: Number,
  },
  data() {
    return {
      validators,
      invalidData: false,
      errorBucket: {},
      OptionsOption: [],
    };
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
  },
  created() {
    // this.OptionCategory = this.getOptionCategory;
  },
  methods: {

    deleteItem() {
      this.$emit('delete', { item: this.item, rowIndex: this.rowIndex });
    },
    validate() {
      this.errorBucket = {};
      each(this.$refs, (val, key) => {
        if (val.errorBucket && val.errorBucket.length) this.errorBucket[key] = val.errorBucket;
      });

      if (Object.keys(this.errorBucket).length) {
        this.invalidData = true;
      } else {
        this.invalidData = false;
      }

      return this.errorBucket;
    },
  },
};
</script>

<style scoped lang="scss" >
.planning-row {
  $input-size: 10px !important;

  &.invalid > td:first-of-type{
    border-left: solid 4px #ff0000;
  }

  &.invalid > td {
    background: #fcecec;

    &.has-error {
      background: rgba(240, 52, 52, 1);
    }
  }

  .has-error {
    background: #fcecec;
  }

  .input-value {
    font-size: $input-size;
  }

  .dropdown-value {
    font-size: $input-size;

    &.fixed-size {
      width: 100px;

      &.medium {
        width: 150px;
      }

      &.large {
        width: 200px;
        }
        &.extra-large {
            width: 250px;
        }
    }
    }

.depreciation {
background: #eeeeee;
}
}
</style>
