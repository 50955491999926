export function generateBdgtPeriods(startYear) {
  const depreciationPeriods = [];
  let nextYear = startYear;

  for (let yearIterator = 1; yearIterator <= 6; yearIterator += 1) {
    // Next year calculated by quartals
    depreciationPeriods.push(`${nextYear}FY`);
    nextYear += 1;
  }
  return depreciationPeriods;
}
