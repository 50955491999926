<template>
  <v-container fluid
    class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>
    <v-data-table fixed-header
      dense
      class="elevation-1"
      :page.sync="pagination.page"
      :server-items-length="pagination.total || items.length"
      :headers="headers"
      :items="items"
      :footer-props="{
        'items-per-page-options': pagination.itemsPerPage
      }"
      :items-per-page="pagination.size">
      <template v-slot:top>
        <v-toolbar flat>
          <v-col cols="12" sm="3"
            class="px-0">
            <v-text-field
              clearable
              v-model="search"
              append-icon="mdi-magnify"
              :label="translate('plannings_capex_search_items')"
              single-line
              hide-details
            ></v-text-field>
           </v-col>
          <v-spacer></v-spacer>
          <v-btn color="primary"
              @click.stop="addNewPlanningItem()">
            <v-icon class="mr-2">mdi-table-large-plus</v-icon>
            {{translate('Increase planning')}}
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:body="props">
        <tbody>
          <IncreaseRow v-for="(item, idx) in props.items"
            v-show="!search || (item.asset_name.indexOf(search) !== -1)"
            :ref="`row-${idx}`"
            :key="item.id || item.tempId"
            :item="item"
            :rowIndex="idx"
            @delete="deleteItem" />
          <tr v-if="!props.items.length"
            class="v-data-table__empty-wrapper">
            <td :colspan="headers.length">{{translate('plannings_capex_no_items')}}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-btn color="accent"
      fab
      fixed
      right
      bottom
      @click.stop="savePlanning()">
      <v-icon dark>mdi-content-save</v-icon>
    </v-btn>
    <Snackbar :text="notificationText"
      :type="notificationType" />
    <v-overlay :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate
        size="64"></v-progress-circular>
    </v-overlay>
    <ConfirmDialog
      :dialog="deleteDialog"
      :description="deleteDialogDescription"
      @change="setDialogState"
      @confirmDelete="confirmDeleteAction"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import IncreaseRow from '@/components/IncreaseRow';
import ConfirmDialog from '@/components/ConfirmDialog';
import Snackbar from '../components/Snackbar';
import Breadcrumbs from '../components/Breadcrumbs';


import api from '../api/lbt-api';
import store from '../store/index';

const {
  getIncreaseForPlanning,
  createIncreaseForPlanning,
  getPlanning,
  deleteIncreaseForPlanning,
  updateIncreaseForPlanning,
} = api;

export default {
  name: 'IncreaseRisk',
  components: {
    Snackbar,
    IncreaseRow,
    Breadcrumbs,
    ConfirmDialog,
  },
  data() {
    return {
      search: '',
      notificationText: '',
      notificationType: '',
      deleteDialog: false,
      deleteDialogDescription: '',
      itemForDeletion: {},
      loader: false,
      headers: [],
      items: [],
      planningInfo: {},
      pagination: {
        page: 1,
        size: 25,
        total: 0,
        itemsPerPage: [25, 50, 100],
      },
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'Increase Amount',
          disabled: true,
        },
      ],
     };
  },
  async created() {
    this.headers = [
      {
        text: this.translate('plannings_sales_leasing_type_id'),
        align: 'left',
        sortable: false,
        value: 'leasing_type_id',
      },
      {
        text: this.translate('plannings_sales_segment_id'),
        align: 'left',
        sortable: false,
        value: 'segment_id',
      },
      {
        text: this.translate('plannings_amount'),
        align: 'left',
        sortable: false,
        value: 'amount',
      },
      {
        text: this.translate('table_actions'),
        align: 'left',
        sortable: false,
        value: 'actions',
      },
    ];
    try {
      await this.fetchPlanningIncreaseItems();
      const planning = await getPlanning(this.$route.params.planningId);
      this.planningInfo = planning;
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
  },
  methods: {
    async fetchPlanningIncreaseItems() {
      const inc = await getIncreaseForPlanning(this.$route.params.planningId);
      this.items = inc.increase_entries;
    },
    addNewPlanningItem() {
      this.items.unshift({
        tempId: Math.random(),
        leasing_type_id: 0,
        segment_id: 0,
        customer_id: 9,
        amount: 0,
      });
    },
    async savePlanning() {
      const rowNum = Object.keys(this.$refs).length;
      const increaseCreateRequests = [];
      const increaseUpdateRequests = [];
      let payloadHasInvalidRow = false;
      if (!rowNum) {
        return;
      }

      for (let iterator = 0; iterator < rowNum; iterator += 1) {
        if (this.$refs[`row-${iterator}`].length) {
          this.$refs[`row-${iterator}`][0].validate();
          if (this.$refs[`row-${iterator}`][0].invalidData) {
            payloadHasInvalidRow = true;
          }
        }
      }

      if (payloadHasInvalidRow) {
        return;
      }

      // TODO: Send all capex items in bulk request
      this.loader = true;
      try {
        for (let iterator = 0; iterator < rowNum; iterator += 1) {
          if (!this.items[iterator].id) {
            increaseCreateRequests.push(createIncreaseForPlanning(this.$route.params.planningId, this.items[iterator]));
          } else {
            increaseUpdateRequests.push(updateIncreaseForPlanning(this.$route.params.planningId, this.items[iterator], this.items[iterator].id));
          }
        }
        await Promise.all(increaseCreateRequests);
        await Promise.all(increaseUpdateRequests);
        this.notificationText = this.translate('notifications_planning_updated');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlanningIncreaseItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    deleteItem(item) {
      this.itemForDeletion = item;
      this.deleteDialog = true;
      this.deleteDialogDescription = this.translate('dialog_delete_action_description');
    },
    async confirmDeleteAction() {
      if (this.itemForDeletion.item.tempId) {
        this.$delete(this.items, this.itemForDeletion.rowIndex);
        this.deleteDialog = false;
        this.itemForDeletion = null;
        return;
      }
      this.loader = true;

      try {
        await deleteIncreaseForPlanning(this.$route.params.planningId, this.itemForDeletion.item.id);
        this.deleteDialog = false;
        this.itemForDeletion = null;
        this.notificationText = this.translate('notifications_item_deleted_successfully');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlanningIncreaseItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    setDialogState(value) {
      this.deleteDialog = value;
    },
  },
  async beforeRouteEnter(from, to, next) {
    await store.dispatch('finrepBsPl/getOptions');
    next();
  },
};
</script>

<style lang="scss" scoped>

.container {
  padding-bottom: 5rem !important;
}

</style>
