<template>
  <v-container fluid
               class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>

    <v-toolbar flat>
      <v-col cols="12" sm="3"
             class="px-0">
        <v-text-field
          clearable
          v-model="search"
          append-icon="mdi-magnify"
          :label="translate('plannings_sales_search_items')"
          single-line
          hide-details

        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <!--
      <v-btn color="primary" @click='excelDownloadClick'>
        Export to Excel
      </v-btn>
      -->
      <v-spacer></v-spacer>
      <v-btn v-if="editMode" color="primary"
             @click.stop="showAddDialog()">
        <v-icon class="mr-2">mdi-table-large-plus</v-icon>
        {{translate('new comment')}}
      </v-btn>
    </v-toolbar>


    <ejs-grid
      :dataSource='items'
      :selectionSettings='selectionOptions'
      :allowPaging='false'
      :allowEditing="true"
      :withHeader='true'
      gridLines='Both'
      :load='load'
      :commandClick="handleItemAction"
      :editSettings="editSettings"
      :allowTextWrap='true'
      :cellEdit = 'actionBegin'
    >

      <e-columns>
        <e-column field='id' :headerText="translate('other_finrep_id')" textAlign='center'  width=20 :visible=true isPrimaryKey='true'>
        </e-column>
        <e-column field='tempId' headerText='tempID' :visible=false>
        </e-column>
        <e-column field='title' :headerText="translate('plannings_comments_title')" width=80>
        </e-column>
        <e-column field='comment' :headerText="translate('plannings_comments_comment')" width=220>
        </e-column>
        <e-column field='position' :headerText="translate('plannings_comments_position')" :valueAccessor='positionValueAccess'
                  editType='dropdownedit' :edit='positionTypeParams' :validationRules='{required: true}' width=60>
        </e-column>
        <e-column field='page' :headerText="translate('plannings_comments_page')" width=50 :valueAccessor="pageValueAccessor">
        </e-column>
        <e-column field='sort' textAlign='center' :headerText="translate('plannings_comments_sort')" width=50>
        </e-column>
        <e-column textAlign='center' v-if="editMode" :allowSorting='false' :headerText="translate('table_actions')" :commands='actionCommands'
                  :allowEditing="false" width=30>
        </e-column>
      </e-columns>
    </ejs-grid>

    <Snackbar :text="notificationText"
              :type="notificationType" />
    <v-overlay :value="loader"
               :z-index="1000">
      <v-progress-circular indeterminate
                           size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialogAddEdit" fullscreen hide-overlay color="primary"
              transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click.native="dialogAddEdit = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Planning comments</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.native="saveOption()">
            <v-icon style="padding-right: 10px;">mdi-content-save</v-icon>
            Save
          </v-btn>
        </v-toolbar>

        <v-container fluid grid-list-sm>
          <v-form ref="form">
            <v-layout row wrap class="asset-instance-layout">
              <v-flex xs12 sm6>
                <v-text-field
                  v-model="itemForEdit.title"
                  :name="'title'"
                  :label="'Title.. *'"
                  :prepend-icon="'mdi-package-variant-closed'">
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                <v-select
                  class="dropdown-value"
                  :name="'position'"
                  :label="'Position.. *'"
                  prepend-icon="mdi-bookmark-multiple-outline"
                  v-model="itemForEdit.position"
                  :items="[{text: 'Bottom', value: 'bottom'}, {text: 'Right', value: 'right'}]"
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6>
                <v-select
                  v-model="itemForEdit.page"
                  :items="pageOptions"
                  :label="'Page.. *'"
                  prepend-icon="mdi-page-last"
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field
                  v-model="itemForEdit.sort"
                  :name="'sort'"
                  :label="'Order.. *'"
                  :prepend-icon="'mdi-compare-vertical'">
                </v-text-field>
              </v-flex>
              <v-flex sm12>
                <v-textarea
                  style="padding-top: 24px;"
                  v-model="itemForEdit.comment"
                  :name="'comment'"
                  outlined
                  :label="'Comment.. *'"
                  :prepend-icon="'mdi-comment-edit-outline'">
                </v-textarea>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>

    <ConfirmDialog
      :dialog="deleteDialog"
      :description="deleteDialogDescription"
      @change="setDialogState"
      @confirmDelete="confirmDeleteAction"
    />
  </v-container>
</template>

<script>
  import Vue from 'vue';
  import {
    GridPlugin, Page, Sort, Edit, Resize, Search, CommandColumn,
  } from '@syncfusion/ej2-vue-grids';
  import { mapGetters } from 'vuex';
  import { Query } from '@syncfusion/ej2-data';
  import Snackbar from '../components/Snackbar';
  import Breadcrumbs from '../components/Breadcrumbs';
  import ConfirmDialog from '../components/ConfirmDialog';

  import api from '../api/lbt-api';

  const {
    getPlanningsComments,
    updatePlanningsComments,
    createPlanningsComments,
    deletePlanningsComments,
    getPlanning,
  } = api;

  Vue.use(GridPlugin);

  export default {
    name: 'OtherFinreps',
    components: {
      Snackbar,
      Breadcrumbs,
      ConfirmDialog,
    },
    data() {
      return {
        search: '',
        notificationText: '',
        notificationType: '',
        dialogAddEdit: false,
        deleteDialog: false,
        editMode: true,
        deleteDialogDescription: '',
        itemForEdit: {
          position: 'bottom',
        },
        itemForDeletion: {},
        category_id: {},
        loader: false,
        headers: [],
        items: [],
        positionTypeParams: this.initDropdownParams(),
        actionCommands: [
          { buttonOption: { cssClass: 'v-icon notranslate v-icon--link mdi mdi-pencil theme--light delete-action' } },
          { buttonOption: { cssClass: 'v-icon notranslate v-icon--link mdi mdi-delete theme--light delete-action' } },
        ],
        selectionOptions: { mode: 'Cell', type: 'Multiple', cellSelectionMode: 'Box' },
        pagination: {
          page: 1,
          size: 100,
          total: 0,
          itemsPerPage: [100, 500, 1000],
        },
        editSettings: {
          allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch',
        },
        breadcrumbs: [
          {
            translationKey: 'breadcrumbs_plannings',
            route: '/plannings',
            disabled: false,
          },
          {
            translationKey: 'breadcrumbs_plannings_comments',
            disabled: true,
          },
        ],
        pageOptions: [
          { text: '1', value: '1' },
          { text: '2', value: '2' },
          { text: '3', value: '3' },
          { text: '4', value: '20' }, // New page 4 is actually page 20 in the system
          { text: '5', value: '4' },
          { text: '6', value: '5' },
          { text: '7', value: '6' },
          { text: '8', value: '7' },
          { text: '9', value: '8' },
          { text: '10', value: '9' },
          { text: '11', value: '10' },
          { text: '12', value: '11' },
          { text: '13', value: '12' },
          { text: '14', value: '22' },
          { text: '15', value: '13' },
          { text: '16', value: '14' },
          { text: '17', value: '16' },
          { text: '18', value: '17' },
          { text: '19', value: '18' },
          { text: '20', value: '19' },
          { text: '21', value: '21' },
        ],
      };
    },
    provide: {
      grid: [Page, Sort, Edit, Resize, Search, CommandColumn],
    },
    async created() {
      this.loader = true;
      try {
        await this.fetchPlanningsComments();
        document.body.addEventListener('keyup', (e) => {
          if (e.key === 'Escape') {
            this.dialogAddEdit = false;
          }
        });
      const planning = await getPlanning(this.$route.params.planningId);
      if (planning.locked === 1) {
        this.editSettings.allowEditing = false;
        this.editSettings.allowAdding = false;
        this.editSettings.allowDeleting = false;
        this.editMode = false;
      }
        // const option = await getOption(this.$route.params.optionId);
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    computed: {
      ...mapGetters('translations', [
        'translate',
      ]),
    },
    methods: {
      async fetchPlanningsComments() {
        const items = await getPlanningsComments(this.$route.params.planningId);
        this.items = items;
      },
      actionBegin(args) {
        const argsCopy = args;
        if (!args.rowData.tempId) {
          argsCopy.cancel = true;
        }
      },
      pageValueAccessor(field, data) {
          const pageOption = this.pageOptions.find(option => option.value.toString() === data.page.toString());
          return pageOption ? pageOption.text : data.page;
      },
      excelDownloadClick() {
        const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
        const excelExportProperties = {
          // exportType: 'CurrentPage',
          fileName: 'Comments.xlsx',
        };
        grid.excelExport(excelExportProperties);
      },
      showAddDialog() {
        this.itemForEdit = {
          position: 'bottom',
        };
        this.dialogAddEdit = true;
      },
      load() {
        const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
        grid.element.addEventListener('mousedown', (e) => {
          if (e.target.classList.contains('e-rowcell')) {
            const index = Number(e.target.getAttribute('Index'));
            const colindex = Number(e.target.getAttribute('aria-colindex'));
            const { field } = grid.getColumns()[colindex];
            grid.editModule.editCell(index, field);
          }
        });
      },
      positionValueAccess(field, data) {
        if (data.position === 'right') {
          return 'Right';
        }
        if (data.position === 'bottom') {
          return 'Bottom';
        }

        return 'Default';
      },
      initDropdownParams() {
        return {
          params: {
            dataSource: [{ text: 'Bottom', value: 'bottom' }, { text: 'Right', value: 'right' }],
            fields: { text: 'text', value: 'value' },
            query: new Query(),
          },
        };
      },
      addNewPlanningComment() {
        const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
        const [...addedRecords] = grid.getBatchChanges().addedRecords;
        const addedRecordsSize = addedRecords ? addedRecords.length : 0;
        grid.addRecord({
          tempId: Math.random(),
          id: this.items.length + addedRecordsSize + 1,
          title: '',
          value: '',
          position: 'bottom',
          page: '',
          sort: '',
          parent_id: null,
        });
      },
      async saveOption() {
        const addedRecords = [];
        const changedRecords = [];

        if (!this.itemForEdit.id) {
          this.itemForEdit.id = 0;
          addedRecords.push(this.itemForEdit);
        } else {
          changedRecords.push(this.itemForEdit);
        }

        this.loader = true;
        const createRequests = [];
        const updateRequests = [];
        try {
          if (addedRecords !== undefined) {
            for (let i = 0; i < addedRecords.length; i += 1) {
              createRequests.push(createPlanningsComments(this.$route.params.planningId, addedRecords[i]));
            }
          }
          if (changedRecords !== undefined) {
            for (let j = 0; j < changedRecords.length; j += 1) {
              updateRequests.push(updatePlanningsComments(changedRecords[j].id, changedRecords[j]));
            }
          }
          await Promise.all(createRequests);
          await Promise.all(updateRequests);
          this.notificationText = this.translate('notifications_planning_updated');
          this.notificationType = 'success';
          setTimeout(() => { this.notificationText = null; }, 100);
          await this.fetchPlanningsComments();
        } catch (message) {
          this.notificationText = message;
          this.notificationType = 'error';
          setTimeout(() => { this.notificationText = null; }, 100);
        } finally {
          this.loader = false;
          this.dialogAddEdit = false;
        }
      },
      handleItemAction(args) {
        if (args.commandColumn.buttonOption.cssClass.includes('mdi-pencil')) {
          this.editItem(args);
        } else {
          this.deleteItem(args);
        }
      },
      editItem(args) {
        this.itemForEdit = args.rowData;
        this.dialogAddEdit = true;
      },
      deleteItem(args) {
        this.itemForDeletion = args.rowData;
        this.deleteDialog = true;
        this.deleteDialogDescription = this.translate('dialog_delete_action_description');
      },
      async confirmDeleteAction() {
        if (this.itemForDeletion.tempId) {
          let index = -1;
          for (let i = 0; i < this.items.length; i += 1) {
            if (this.items[i].id === this.itemForDeletion.id) {
              index = i;
              break;
            }
          }

          if (index !== -1) {
            this.$delete(this.items, index);
            this.itemForDeletion = null;
          } else {
            const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
            const [...addedRecords] = grid.getBatchChanges().addedRecords;
            for (let i = 0; i < addedRecords.length; i += 1) {
              if (addedRecords[i].tempId === this.itemForDeletion.tempId) {
                grid.deleteRecord('id', addedRecords[i]);
                const rowIdx = grid.getRowIndexByPrimaryKey(addedRecords[i].id);
                const row = grid.getRowByIndex(rowIdx);
                grid.deleteRow(row);
                break;
              }
            }
          }
          this.deleteDialog = false;
          return;
        }

        this.loader = true;

        try {
          await deletePlanningsComments(this.itemForDeletion.id);
          this.deleteDialog = false;
          this.itemForDeletion = null;
          this.notificationText = this.translate('notifications_item_deleted_successfully');
          this.notificationType = 'success';
          setTimeout(() => { this.notificationText = null; }, 100);
          await this.fetchPlanningsComments();
        } catch (message) {
          this.notificationText = message;
          this.notificationType = 'error';
          setTimeout(() => { this.notificationText = null; }, 100);
        } finally {
          this.loader = false;
        }
      },
      setDialogState(value) {
        this.deleteDialog = value;
      },
    },
  };
</script>

<style lang="scss" scoped>

  .container {
    padding-bottom: 5rem !important;
  }

</style>
