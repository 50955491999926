<template>
  <v-container fluid class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>
    <v-toolbar flat>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click='excelDownloadClick'>
        Export to Excel
      </v-btn>
    </v-toolbar>
    <v-spacer></v-spacer>
    <ejs-treegrid ref="grid" :dataSource='items' v-if='items' childMapping='sub' :enableCollapseAll='true'
                  :allowResizing='true' :frozenRows='rows' :selectionSettings='selectionOptions' :frozenColumns='columns' :treeColumnIndex='0'
                  :resizeStop="resizeStop" :allowExcelExport='true' :enablePersistence="true"
                  :excelHeaderQueryCellInfo='excelHeader' :allowFiltering="true" :filterSettings="{type: 'Menu'}">
      <e-columns>
        <e-column field='finrep' :headerText="'Finrep'" :width="columnWidths[0]"></e-column>
        <e-column field='summary_pos' :headerText="translate('plannings_plbs_summary_pos')" :width="columnWidths[1]"></e-column>
        <e-column field='position' headerText='Position' :width="columnWidths[2]"></e-column>
        <e-column textAlign='center' :columns='valuesColumns'>
        </e-column>
      </e-columns>
    </ejs-treegrid>

    <br>

    <ejs-grid ref="gridEco" :allowResizing="true" :enablePersistence="true"  :dataSource='itemsEco' v-if='dataLoaded' :allowExcelExport='true'
              :excelHeaderQueryCellInfo='excelHeader'>
      <e-columns>
        <e-column field='title' :headerText="'Title'" width=220>
        </e-column>
        <e-column field='row' :headerText="'Row'" width=220>
        </e-column>
        <e-column textAlign='center' :headerText="translate('Periods')" :columns='valuesColumns'>
        </e-column>
      </e-columns>
    </ejs-grid>

    <Snackbar :text="notificationText"
              :type="notificationType" />
    <v-overlay :value="loader"
               :z-index="1000">
      <v-progress-circular indeterminate
                           size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
  import Vue from 'vue';
  import { mapGetters, mapState } from 'vuex';
  import {
    TreeGridPlugin, ExcelExport as TreeExcelExport, Freeze, Filter, Resize as TreeResize,
  } from '@syncfusion/ej2-vue-treegrid';
  import {
    GridPlugin, ExcelExport, Resize,
  } from '@syncfusion/ej2-vue-grids';
  import Breadcrumbs from '@/components/Breadcrumbs';
  import Snackbar from '@/components/Snackbar';
  import api from '../api/lbt-api';

  Vue.use(GridPlugin);
  Vue.use(TreeGridPlugin);

  const {
    getSummaryPLECOFullForPlanning,
  } = api;

  export default {
    name: 'SummaryPlEcoFull',
    components: {
      Breadcrumbs,
      Snackbar,
    },
    data() {
      return {
        notificationText: '',
        loader: false,
        notificationType: null,
        headers: [],
        items: [],
        itemsEco: [],
        dataLoaded: true,
        expanded: [],
        rows: 0,
        columns: 3,
        storagePrefix: 'pl_eco_full',
        storageSeparator: '.-.',
        columnWidths: [120, 200, 230],
        selectionOptions: { mode: 'Cell', type: 'Multiple', cellSelectionMode: 'Box' },
        breadcrumbs: [
          {
            translationKey: 'breadcrumbs_plannings',
            route: '/plannings',
            disabled: false,
          },
          {
            translationKey: 'pl eco',
            disabled: true,
          },
        ],
        valuesColumns: [],
        highlightCellAttributes: { class: 'highlight-cell' },
      };
    },
    computed: {
      ...mapGetters('translations', [
        'translate',
      ]),
      ...mapState('plannings', [
        'activePlanning',
      ]),
    },
    async created() {
      this.loader = true;
      this.retrieveColumnWidths();
      try {
        const planning = await getSummaryPLECOFullForPlanning(this.$route.params.planningId);
        this.headers = planning.periods;
        this.items = planning.rows;
        this.itemsEco = planning.eco_table;
        this.prepareValuesGridHeaders();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    methods: {
      retrieveColumnWidths() {
        for (let i = 0; i < this.columnWidths.length; i += 1) {
          const width = localStorage.getItem(this.storagePrefix + this.storageSeparator + i);
          if (width) {
            this.columnWidths[i] = width;
          }
        }
      },
      prepareValuesGridHeaders() {
        const offset = 4;
        const item = this.items[0];
        for (let i = 0; i < item.values.length; i += 1) {
          const width = localStorage.getItem(this.storagePrefix + this.storageSeparator + (offset + i));
          this.valuesColumns.push({
            field: `values.${i}`,
            headerText: i,
            headerValueAccessor: this.lookupValuesHeader,
            customAttributes: this.highlightCellAttributes,
            width: width != null ? width : 70,
            textAlign: 'right',
            format: 'n2',
            allowFiltering: false,
          });
        }
      },
      lookupValuesHeader(idx) {
        return this.headers[Number(idx)];
      },
      onDropdownChange(selected) {
        const { value } = selected;
        switch (value) {
          case 'Product Type':
            // TODO API call and refresh
            break;
          case 'Segment':
            // TODO API call and refresh
            break;
          case 'Leasing Type':
            // TODO API call and refresh
            break;
          default:
            break;
        }
      },
      excelDownloadClick() {
        const appendExcelExportProperties = {
          multipleExport: { type: 'AppendToSheet', blankRows: 5 },
          fileName: `PL Eco - Planning - ${this.activePlanning.year}-${this.activePlanning.month}.xlsx`,
        };

        const firstGridExport = this.$refs.grid.excelExport(appendExcelExportProperties, true);
        firstGridExport.then((fData) => {
          this.$refs.gridEco.excelExport(appendExcelExportProperties, false, fData);
        });
      },
      excelHeader(args) {
        const { cell } = args;
        if (cell.index > 2) {
          cell.value = this.headers[cell.value];
        }
      },
      resizeStop(item) {
        let width = item.column.width.split('px');
        width = parseInt(width[0], 10);
        localStorage.setItem(this.storagePrefix + this.storageSeparator + item.column.index, width);
      },
    },
    provide: {
      grid: [ExcelExport, Resize],
      treegrid: [TreeExcelExport, Freeze, TreeResize, Filter],
    },
  };
</script>

<style>
  @import '../../node_modules/@syncfusion/ej2-vue-treegrid/styles/material.css';
  .e-grid .e-altrow {
    background-color: #fafafa;
  }
  .e-grid .e-headercell.highlight-cell, .highlight-cell {
    background: #eeeeee;
  }
  .e-headercell {
    padding: 15px 0 !important;
  }
  .e-headercell .e-headercelldiv {
    text-align: center;
  }
  .e-treerowcell{
    padding: 0 !important;
  }
  .e-rowcell {
    padding: 3px !important;
  }
</style>
