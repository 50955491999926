import find from 'lodash/find';
import api from '../../api/lbt-api';

const {
  getPlanning,
  getPlannings,
} = api;

export default {
  namespaced: true,
  state: {
    plannings: [],
    activePlanning: {},
  },
  mutations: {
    setPlannings(state, plannings) {
      state.plannings = plannings;
    },
    setActivePlanning(state, activePlanning) {
      state.activePlanning = activePlanning;
    },
  },
  actions: {
    async getPlannings({ commit }, pagination) {
      const result = await getPlannings(pagination);
      commit('setPlannings', result.plannings);

      return result;
    },
    async getPlanning({ commit }, id) {
      const planning = await getPlanning(id);
      commit('setActivePlanning', planning);

      return planning;
    },
  },
  getters: {
    getPlanningWithId: state => (id) => {
      const result = find(state.plannings, element => element.id === id);
      return result;
    },
  },
};
