/* eslint-disable no-param-reassign */

import axios from 'axios';
import { mapState, mapGetters } from 'vuex';
import { isAuthenticated } from '../utilities/isAuthenticated';

const baseUrl = localStorage.getItem('api_link');
const apiToken = isAuthenticated() ? process.env.VUE_APP_API_TOKEN : 'neces_razbojnice';

export default {
  computed: {
    ...mapState('loading', [
      'progress',
    ]),
    ...mapGetters('loading', [
      'getProgress',
    ]),
  },
  async createNewPlanning(
    planningInfo,
    username,
    date,
    lcyOption,
    salesFloorId,
    fundingFloorId,
    salesDownPaymentId,
    feeCalculationOl,
    feeCalculationFl,
    rentCalculationOl,
    rentCalculationFl,
    depoCalculationOl,
    depoCalculationFl,
    fixedAssets,
    fixedAssetsSupport,
    fixedAssetsPlanningRates,
    newPlanningFile,
    loansPlanningFile,
    riskPlanningFile,
    vendorPlanningFile,
    leasingOrRent,
    otherFundingInput,
  ) {
    const body = new FormData();
    const year = date.split('-')[0];
    const month = date.split('-')[1];
    body.set('planning_info', planningInfo);
    body.set('username', username);
    body.set('year', year);
    body.set('month', month);
    body.set('lcy_option_id', lcyOption);
    body.set('sales_floor_id', salesFloorId);
    body.set('funding_floor_id', fundingFloorId);
    body.set('sales_down_payment_id', salesDownPaymentId);
    body.set('fee_calculation_ol_id', feeCalculationOl);
    body.set('fee_calculation_fl_id', feeCalculationFl);
    body.set('rent_calculation_ol_id', rentCalculationOl);
    body.set('rent_calculation_fl_id', rentCalculationFl);
    body.set('depo_calculation_ol_id', depoCalculationOl);
    body.set('depo_calculation_fl_id', depoCalculationFl);
    body.set('leasing_or_rent', leasingOrRent);
    body.set('other_funding_input', otherFundingInput);
    body.append('fa_import_file', fixedAssets);
    body.append('fa_support_file', fixedAssetsSupport);
    body.append('fa_rates_file', fixedAssetsPlanningRates);
    body.append('fa_new_planning_file', newPlanningFile);
    body.append('loans_planning_file', loansPlanningFile);
    body.append('risk_planning_file', riskPlanningFile);
    body.append('vendor_planning_file', vendorPlanningFile);

    try {
      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/plannings/`,
        data: body,
        headers: {
          'x-api-key': apiToken,
          'content-type': 'multipart/form-data',
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async replacePlanning(
    planningId,
    replacePlanningFile,
    archivePlanningFile,
    avgPlanningFile,
    replaceRiskData,
    replaceExhangeRates,
  ) {
    const body = new FormData();
    body.set('planning_id', planningId);
    body.append('replace_planning_file', replacePlanningFile);
    body.append('archive_planning_file', archivePlanningFile);
    body.append('avg_planning_file', avgPlanningFile);
    body.append('replace_fx_rates', replaceExhangeRates);
    body.append('replace_risk_data', replaceRiskData);
    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/plannings/${planningId}`,
        data: body,
        headers: {
          'x-api-key': apiToken,
          'content-type': 'multipart/form-data',
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getPlannings({ page, size }) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/?page=${page}&size=${size}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async calcPlanning(user, planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/calc/${user}/${planningId}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async fundingCopyPlanning(planningId, planningId2) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/importfunding/${planningId2}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async validatePlanning(
    fixedAssets,
    fixedAssetsSupport,
    fixedAssetsPlanningRates,
    newPlanningFile,
    loansPlanningFile,
    vendorPlanningFile,
  ) {
    const body = new FormData();
    body.append('fa_import_file', fixedAssets);
    body.append('fa_support_file', fixedAssetsSupport);
    body.append('fa_rates_file', fixedAssetsPlanningRates);
    body.append('fa_new_planning_file', newPlanningFile);
    body.append('loans_planning_file', loansPlanningFile);
    body.append('vendor_planning_file', vendorPlanningFile);
    try {
      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/validate/`,
        data: body,
        headers: {
          'x-api-key': apiToken,
          'content-type': 'multipart/form-data',
        },
        onUploadProgress: function (progressEvent) {
          this.progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10);
          this.progress = 10;
        }.bind(this),
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getFiles() {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/validate`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async salesRecalculate(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/recalculate`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async loansRecalculate(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/recalculateloans`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },

  async deletePlanning(planningId) {
    try {
      const result = await axios({
        method: 'DELETE',
        url: `${baseUrl}/plannings/${planningId}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async lockPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/lock`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async duplicatePlanning(planningId, copyNaziv) {
    const body = new FormData();
    body.append('copy_naziv', copyNaziv);
    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/plannings/${planningId}/duplicate`,
        data: body,
        headers: {
          'x-api-key': apiToken,
          'content-type': 'multipart/form-data',
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async mergePlanning(planningId1, planningId2) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId1}/duplicate/${planningId2}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async archivePlanning(planningId, planningArchive, planningIdArchive, columnFromArchive) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/archive/${planningArchive}/${planningIdArchive}/${columnFromArchive}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },

  async getCapexForPlanning(planningId, { page, size }) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/capex?page=${page}&size=${size}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },

  async getRiskForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/risk`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getExistingFundingForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/ex_funding`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getFx(planningId, currency = null, { page, size }) {
    try {
      const result = await axios({
        method: 'GET',
        url: currency ? `${baseUrl}/plannings/${planningId}/fx?page=${page}&size=${size}&currency=${currency}` : `${baseUrl}/plannings/${planningId}/fx?page=${page}&size=${size}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },

  async getCapexOLForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/capex_ol?page=1&size=10000`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getOptionsForCategory(optionId, { page, size }) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/options/?category_name=${optionId}&page=${page}&size=${size}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getOtherFinreps() {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/other_finrep/`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getArchivePositions(planningId, tip) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/archive_positions?type=${tip}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },

  async updateArchivePosition(planningId, id, optionData) {
    const normalizedOptionData = optionData;
    normalizedOptionData.mapppos = optionData.mapppos ? String(optionData.mapppos) : null;
    normalizedOptionData.finrep = String(optionData.finrep);
    normalizedOptionData.bilpos = optionData.bilpos ? String(optionData.bilpos) : null;
    normalizedOptionData.yearminustwo = Number(optionData.yearminustwo);
    normalizedOptionData.yearminusone = Number(optionData.yearminusone);
    normalizedOptionData.actual = Number(optionData.actual);
    normalizedOptionData.bdgt = Number(optionData.bdgt);
    normalizedOptionData.type = String(optionData.type);
    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/plannings/${planningId}/archive_positions/${id}`,
        data: normalizedOptionData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async createArchivePosition(planningId, optionData) {
    const normalizedOptionData = optionData;
    normalizedOptionData.mapppos = optionData.mapppos ? String(optionData.mapppos) : null;
    normalizedOptionData.finrep = String(optionData.finrep);
    normalizedOptionData.bilpos = optionData.bilpos ? String(optionData.bilpos) : null;
    normalizedOptionData.yearminustwo = Number(optionData.yearminustwo);
    normalizedOptionData.yearminusone = Number(optionData.yearminusone);
    normalizedOptionData.actual = Number(optionData.actual);
    normalizedOptionData.bdgt = Number(optionData.bdgt);
    normalizedOptionData.type = String(optionData.type);
    try {
      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/plannings/${planningId}/archive_positions`,
        data: normalizedOptionData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async deleteArchivePosition(planningId, id) {
    try {
      const result = await axios({
        method: 'DELETE',
        url: `${baseUrl}/plannings/${planningId}/archive_positions/${id}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async createOtherFinrep(optionData) {
    const normalizedOptionData = optionData;

    normalizedOptionData.title = String(optionData.title);
    normalizedOptionData.value = String(optionData.value);
    normalizedOptionData.parent_id = Number(optionData.parent_id);

    try {
      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/other_finrep/`,
        data: normalizedOptionData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async updateOtherFinrep(id, optionData) {
    const normalizedOptionData = optionData;

    normalizedOptionData.title = String(optionData.title);
    normalizedOptionData.value = String(optionData.value);
    normalizedOptionData.parent_id = optionData.parent_id ? Number(optionData.parent_id) : null;

    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/other_finrep/${id}`,
        data: normalizedOptionData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async deleteOtherFinrep(id) {
    try {
      const result = await axios({
        method: 'DELETE',
        url: `${baseUrl}/other_finrep/${id}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getPlanningsComments(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings_comments/${planningId}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async createPlanningsComments(planningId, optionData) {
    const normalizedOptionData = optionData;

    normalizedOptionData.title = String(optionData.title);
    normalizedOptionData.comment = String(optionData.comment);
    normalizedOptionData.page = Number(optionData.page);
    normalizedOptionData.sort = Number(optionData.sort);
    normalizedOptionData.planning_id = Number(planningId);

    try {
      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/plannings_comments/`,
        data: normalizedOptionData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async updatePlanningsComments(id, optionData) {
    const normalizedOptionData = optionData;

    normalizedOptionData.title = String(optionData.title);
    normalizedOptionData.comment = String(optionData.comment);
    normalizedOptionData.page = Number(optionData.page);
    normalizedOptionData.sort = Number(optionData.sort);

    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/plannings_comments/${id}`,
        data: normalizedOptionData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async deletePlanningsComments(id) {
    try {
      const result = await axios({
        method: 'DELETE',
        url: `${baseUrl}/plannings_comments/${id}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getProductMapping({ page, size }) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/finrep_bs_pl/?page=${page}&size=${size}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getKontoMapping({ page, size }) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/fa_finrep_mapping/?page=${page}&size=${size}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async updateCapexOlForPlanning(planningId, capexOlData) {
    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/plannings/${planningId}/capex`,
        data: capexOlData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async updateFx(planningId, fxData, fxId) {
    const normalizedFxData = fxData;
    normalizedFxData.value = parseFloat(fxData.value);

    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/plannings/${planningId}/fx/${fxId}`,
        data: normalizedFxData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async updateFxModifier(planningId, modifier) {
    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/plannings/${planningId}/fx_modifier/${modifier}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async updateFxArchive(planningId, modifier, period) {
    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/plannings/${planningId}/fx_archive/${modifier}/${period}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getEuribor(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/euribor`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async createCapexForPlanning(planningId, capexData) {
    const normalizedCapexData = capexData;

    normalizedCapexData.finrep_bs_pl_id = Number(capexData.finrep_bs_pl_id);
    normalizedCapexData.useful_life = Number(capexData.useful_life);
    normalizedCapexData.capex_lcy = Number(capexData.capex_lcy);
    normalizedCapexData.capex_eur = Number(capexData.capex_eur);

    try {
      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/plannings/${planningId}/capex`,
        data: normalizedCapexData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async updateCapexForPlanning(planningId, capexData, capexId) {
    const normalizedCapexData = capexData;

    normalizedCapexData.finrep_bs_pl_id = Number(capexData.finrep_bs_pl_id);
    normalizedCapexData.useful_life = Number(capexData.useful_life);
    normalizedCapexData.capex_lcy = Number(capexData.capex_lcy);
    normalizedCapexData.capex_eur = Number(capexData.capex_eur);

    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/plannings/${planningId}/capex/${capexId}`,
        data: normalizedCapexData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async createRiskForPlanning(planningId, capexData) {
    const normalizedCapexData = capexData;

    normalizedCapexData.leasing_type_id = Number(capexData.leasing_type_id);
    normalizedCapexData.segment_id = Number(capexData.segment_id);
    normalizedCapexData.risk_type_id = Number(capexData.risk_type_id);
    normalizedCapexData.stage_id = Number(capexData.stage_id);
    normalizedCapexData.risk_period.forEach((period) => {
      period.value = Number(period.value);
    });
    try {
      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/plannings/${planningId}/risk`,
        data: normalizedCapexData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async createExistingFundingForPlanning(planningId, capexData) {
    const normalizedCapexData = capexData;
    if (normalizedCapexData.line === 0 || normalizedCapexData.line === null) {
      normalizedCapexData.line = '';
    }
    normalizedCapexData.currency_id = Number(capexData.currency_id);
    normalizedCapexData.reference_rate_id = Number(capexData.reference_rate_id);
    normalizedCapexData.financing_volumes.forEach((period) => {
      period.value = Number(period.value);
    });
    try {
      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/plannings/${planningId}/ex_funding`,
        data: normalizedCapexData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async createIncreaseForPlanning(planningId, capexData) {
    const normalizedCapexData = capexData;

    normalizedCapexData.leasing_type_id = Number(capexData.leasing_type_id);
    normalizedCapexData.segment_id = Number(capexData.segment_id);

    normalizedCapexData.amount = Number(capexData.amount);

    try {
      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/plannings/${planningId}/increase_amount`,
        data: normalizedCapexData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async updateRiskForPlanning(planningId, riskData, riskId) {
    const normalizedCapexData = riskData;

    normalizedCapexData.leasing_type_id = Number(riskData.leasing_type_id);
    normalizedCapexData.segment_id = Number(riskData.segment_id);
    normalizedCapexData.risk_type_id = Number(riskData.risk_type_id);
    normalizedCapexData.stage_id = Number(riskData.stage_id);
    normalizedCapexData.risk_period.forEach((period) => {
      period.value = Number(period.value);
    });
    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/plannings/${planningId}/risk/${riskId}`,
        data: normalizedCapexData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async updateExistingFundingForPlanning(planningId, riskData, riskId) {
    const normalizedCapexData = riskData;

    if (normalizedCapexData.line === 0 || normalizedCapexData.line === null) {
      normalizedCapexData.line = '';
    }
    normalizedCapexData.currency_id = Number(riskData.currency_id);
    normalizedCapexData.reference_rate_id = Number(riskData.reference_rate_id);
    normalizedCapexData.financing_volumes.forEach((period) => {
      period.value = Number(period.value);
    });
    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/plannings/${planningId}/ex_funding/${riskId}`,
        data: normalizedCapexData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async updateIncreaseForPlanning(planningId, riskData, riskId) {
    const normalizedCapexData = riskData;

    normalizedCapexData.leasing_type_id = Number(riskData.leasing_type_id);
    normalizedCapexData.segment_id = Number(riskData.segment_id);
    normalizedCapexData.amount = Number(riskData.amount);

    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/plannings/${planningId}/increase_amount/${riskId}`,
        data: normalizedCapexData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async createOptionsForCategory(categoryId, optionData) {
    const normalizedOptionData = optionData;
    // normalizedOptionData.category_id = Number(optionData.category_id);
    normalizedOptionData.option = String(optionData.option);
    normalizedOptionData.value = String(optionData.value);

    try {
      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/options/${categoryId}`,
        data: normalizedOptionData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async createProductMapping(itemData) {
    const normalizedProductData = itemData;
    normalizedProductData.name = String(itemData.name);
    normalizedProductData.finrep_bs_code = Number(itemData.finrep_bs_code);
    normalizedProductData.finrep_pl_code = Number(itemData.finrep_pl_code);
    normalizedProductData.summary_bs_code = Number(itemData.summary_bs_code);
    normalizedProductData.summary_pl_code = Number(itemData.summary_pl_code);
    try {
      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/finrep_bs_pl/`,
        data: normalizedProductData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async createKontoMapping(itemData) {
    const normalizedKontData = itemData;
    normalizedKontData.account = String(itemData.account);
    normalizedKontData.finrep_pl_code = Number(itemData.finrep_pl_code);
    try {
      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/fa_finrep_mapping/`,
        data: normalizedKontData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },

  async updateOptionsForCategory(categoryId, optionData) {
    const normalizedOptionData = optionData;
    normalizedOptionData.id = Number(optionData.id);
    normalizedOptionData.category_id = Number(categoryId);
    normalizedOptionData.option = String(optionData.option);
    normalizedOptionData.value = String(optionData.value);

    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/options/${optionData.id}`,
        data: normalizedOptionData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async updateProductMapping(itemData, pId) {
    const normalizedProductData = itemData;
    normalizedProductData.name = String(itemData.name);
    normalizedProductData.finrep_bs_code = Number(itemData.finrep_bs_code);
    normalizedProductData.finrep_pl_code = Number(itemData.finrep_pl_code);
    normalizedProductData.summary_bs_code = Number(itemData.summary_bs_code);
    normalizedProductData.summary_pl_code = Number(itemData.summary_pl_code);
    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/finrep_bs_pl/${pId}`,
        data: normalizedProductData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async deleteKontoMapping(kontoId) {
    try {
      const result = await axios({
        method: 'DELETE',
        url: `${baseUrl}/fa_finrep_mapping/${kontoId}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async updateKontoMapping(itemData, kontoId) {
    const normalizedKontData = itemData;
    normalizedKontData.account = String(itemData.account);
    normalizedKontData.finrep_pl_code = Number(itemData.finrep_pl_code);
    delete normalizedKontData.id;
    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/fa_finrep_mapping/${kontoId}`,
        data: normalizedKontData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },


  async deleteCapexForPlanning(planningId, capexId) {
    try {
      const result = await axios({
        method: 'DELETE',
        url: `${baseUrl}/plannings/${planningId}/capex/${capexId}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async deleteIncreaseForPlanning(planningId, capexId) {
    try {
      const result = await axios({
        method: 'DELETE',
        url: `${baseUrl}/plannings/${planningId}/increase_amount/${capexId}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async deleteRiskForPlanning(planningId, riskId) {
    try {
      const result = await axios({
        method: 'DELETE',
        url: `${baseUrl}/plannings/${planningId}/risk/${riskId}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async deleteOtherForPlanning(planningId, riskId) {
    try {
      const result = await axios({
        method: 'DELETE',
        url: `${baseUrl}/plannings/${planningId}/other/${riskId}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async deleteExistingFundingForPlanning(planningId, riskId) {
    try {
      const result = await axios({
        method: 'DELETE',
        url: `${baseUrl}/plannings/${planningId}/ex_funding/${riskId}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async deleteOptionsForCategory(categoryId, optionId) {
    try {
      const result = await axios({
        method: 'DELETE',
        url: `${baseUrl}/options/${categoryId}/option/${optionId}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async deleteSalesForPlanning(planningId, capexId) {
    try {
      const result = await axios({
        method: 'DELETE',
        url: `${baseUrl}/plannings/${planningId}/sales/${capexId}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async deleteFundingForPlanning(planningId, capexId) {
    try {
      const result = await axios({
        method: 'DELETE',
        url: `${baseUrl}/plannings/${planningId}/funding/${capexId}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async createSalesForPlanning(planningId, salesData) {
    const normalizedSalesData = salesData;
    normalizedSalesData.dep_period = Number(salesData.dep_period);
    normalizedSalesData.maturity = Number(salesData.maturity);
    normalizedSalesData.intercalary_days = Number(salesData.intercalary_days);
    normalizedSalesData.subvention = Number(salesData.subvention);
    if (normalizedSalesData.subvention_accrual_id === 0 || normalizedSalesData.subvention_accrual_id === null) {
      delete normalizedSalesData.subvention_accrual_id;
    }
    if (normalizedSalesData.payment_at_once_id === 0 || normalizedSalesData.payment_at_once_id === null) {
      delete normalizedSalesData.payment_at_once_id;
    }
    if (normalizedSalesData.reference_rate_id === 0 || normalizedSalesData.reference_rate_id === null) {
      delete normalizedSalesData.reference_rate_id;
    }
    if (normalizedSalesData.up_front_fee_accrual_id === 0 || normalizedSalesData.up_front_fee_accrual_id === null) {
      delete normalizedSalesData.up_front_fee_accrual_id;
    }


    normalizedSalesData.financing_volumes.forEach((year) => {
      year.fy = Number(year.fy);
      year.periods.forEach((period) => {
        period.value = Number(period.value);
      });
    });

    normalizedSalesData.interest_rates.forEach((period) => {
      period.value = Number(period.value);
    });

    try {
      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/plannings/${planningId}/sales`,
        data: normalizedSalesData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async updateSalesForPlanning(planningId, salesData, salesId) {
    const normalizedSalesData = salesData;

    normalizedSalesData.maturity = Number(salesData.maturity);
    normalizedSalesData.dep_period = Number(salesData.dep_period);
    normalizedSalesData.intercalary_days = Number(salesData.intercalary_days);
    normalizedSalesData.subvention = Number(salesData.subvention);
    if (normalizedSalesData.subvention_accrual_id === 0 || normalizedSalesData.subvention_accrual_id === null) {
      delete normalizedSalesData.subvention_accrual_id;
    }
    if (normalizedSalesData.payment_at_once_id === 0 || normalizedSalesData.payment_at_once_id === null) {
      delete normalizedSalesData.payment_at_once_id;
    }
    if (normalizedSalesData.reference_rate_id === 0 || normalizedSalesData.reference_rate_id === null) {
      delete normalizedSalesData.reference_rate_id;
    }
    if (normalizedSalesData.up_front_fee_accrual_id === 0 || normalizedSalesData.up_front_fee_accrual_id === '' || normalizedSalesData.up_front_fee_accrual_id === null) {
      delete normalizedSalesData.up_front_fee_accrual_id;
    }

    normalizedSalesData.financing_volumes.forEach((year) => {
      year.fy = Number(year.fy);
      year.periods.forEach((period) => {
        period.value = Number(period.value);
      });
    });

    normalizedSalesData.interest_rates.forEach((period) => {
      period.value = Number(period.value);
    });

    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/plannings/${planningId}/sales/${salesId}`,
        data: normalizedSalesData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },

  async getSalesForPlanning(planningId, { page, size }) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/sales?page=${page}&size=${size}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },

  async createFundingForPlanning(planningId, fundingData) {
    const normalizedSalesData = fundingData;
    normalizedSalesData.maturity = Number(fundingData.maturity);
    if (normalizedSalesData.reference_rate_id === 0 || normalizedSalesData.reference_rate_id === null) {
      delete normalizedSalesData.reference_rate_id;
    }
    if (normalizedSalesData.fee_accrual_id === 0 || normalizedSalesData.fee_accrual_id === null) {
      delete normalizedSalesData.fee_accrual_id;
    }

    normalizedSalesData.financing_volumes.forEach((year) => {
      year.fy = Number(year.fy);
      year.periods.forEach((period) => {
        period.value = Number(period.value);
      });
    });

    normalizedSalesData.interest_rates.forEach((period) => {
      period.value = Number(period.value);
    });

    try {
      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/plannings/${planningId}/funding`,
        data: normalizedSalesData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async updateFundingForPlanning(planningId, fundingData, fundingId) {
    const normalizedSalesData = fundingData;

    normalizedSalesData.maturity = Number(fundingData.maturity);
    if (normalizedSalesData.reference_rate_id === 0 || normalizedSalesData.reference_rate_id === null) {
      delete normalizedSalesData.reference_rate_id;
    }
    if (normalizedSalesData.fee_accrual_id === 0 || normalizedSalesData.fee_accrual_id === '' || normalizedSalesData.fee_accrual_id === null) {
      delete normalizedSalesData.fee_accrual_id;
    }

    normalizedSalesData.financing_volumes.forEach((year) => {
      year.fy = Number(year.fy);
      year.periods.forEach((period) => {
        period.value = Number(period.value);
      });
    });

    normalizedSalesData.interest_rates.forEach((period) => {
      period.value = Number(period.value);
    });

    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/plannings/${planningId}/funding/${fundingId}`,
        data: normalizedSalesData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },

  async getFundingForPlanning(planningId, { page, size }) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/funding?page=${page}&size=${size}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },


  async createOtherForPlanning(planningId, otherData) {
    const normalizedSalesData = otherData;
    if (normalizedSalesData.finrep_code_id === 0 || normalizedSalesData.finrep_code_id === null) {
      delete normalizedSalesData.finrep_code_id;
    }
    if (normalizedSalesData.other_type_id === 0 || normalizedSalesData.other_type_id === null) {
      delete normalizedSalesData.other_type_id;
    }


    normalizedSalesData.other_period.forEach((period) => {
      period.value = Number(period.value);
    });


    try {
      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/plannings/${planningId}/other`,
        data: normalizedSalesData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async updateOtherForPlanning(planningId, fundingData, fundingId) {
    const normalizedSalesData = fundingData;


    if (normalizedSalesData.finrep_code_id === 0 || normalizedSalesData.finrep_code_id === null) {
      delete normalizedSalesData.finrep_code_id;
    }
    if (normalizedSalesData.other_type_id === 0 || normalizedSalesData.other_type_id === '' || normalizedSalesData.other_type_id === null) {
      delete normalizedSalesData.other_type_id;
    }

    normalizedSalesData.other_period.forEach((period) => {
      period.value = Number(period.value);
    });


    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/plannings/${planningId}/other/${fundingId}`,
        data: normalizedSalesData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },

  async getOtherForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/other`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },


  async getProfitLossForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/pl`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getSummaryPLForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/summary_pl`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getSummaryBSForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/summary_bs`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getSummaryCAPEXForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/summary_capex`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getSummaryPEREXForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/summary_perex`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getIncreaseForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/increase_amount`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getSummaryOPEXForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/summary_opex`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getSummaryRISKForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/summary_risk`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getSummaryRISKECOForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/summary_risk_eco`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getSummaryBSECOForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/bs_eco`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getSummaryPLECOForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/pl_eco`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getSummaryBSECOFullForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/bs_eco_full`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getSummaryPLECOFullForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/pl_eco_full`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getSummaryTAXforPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/tax`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getSummaryPortfolioForPlanning(planningId, type, currency) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/summary_portfolio?type=${type}&currency=${currency}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getSummaryNewProductionForPlanning(planningId, type, currency) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/summary_new_production?type=${type}&currency=${currency}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getBalanceSheetForPlanning(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/bs`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },


  async getOptions() {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/options/`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },

  async getFinrepBsPl() {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/finrep_bs_pl/`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getChart(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/summary_chart`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getLoanFunding(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/calc`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getFundingDiff(planningId) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/plannings/${planningId}/reports/fund_diff`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getUserActivityLogs({ page, perPage }) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/users/user_activity_logs?page=${page}&perPage=${perPage}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async getUsers({ page, size }) {
    try {
      const result = await axios({
        method: 'GET',
        url: `${baseUrl}/users/?page=${page}&size=${size}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async createUser(userData) {
    try {
      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/users/`,
        data: userData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async updateUser(userData, userId) {
    try {
      const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/users/${userId}`,
        data: userData,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  async deleteUser(userId) {
    try {
      const result = await axios({
        method: 'DELETE',
        url: `${baseUrl}/users/${userId}`,
        headers: {
          'x-api-key': apiToken,
        },
      });
      return result.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
};
