<template>
  <v-container fluid class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>
    <v-toolbar flat>
        <v-spacer></v-spacer>
      <v-btn color="primary" @click='excelDownloadClick'>
        Export to Excel
      </v-btn>
    </v-toolbar>
    <v-spacer></v-spacer>
    <ejs-treegrid :dataSource='items' v-if='items' childMapping='sub' :allowResizing='true'
                  :resizeStop="resizeStop" :enableCollapseAll='true' :selectionSettings='selectionOptions'
                  :treeColumnIndex='0' :height='600'  :frozenRows='rows' :frozenColumns='columns'
                  :allowExcelExport='true' :excelHeaderQueryCellInfo='excelHeader'
                  :allowFiltering='true' :filterSettings="{type: 'Menu'}">
        <e-columns>
            <e-column field='finrep' headerText='Finrep' :width='columnWidths[0]'></e-column>
            <e-column field='summary_pos' headerText='Summary - Pos. mapping' :width='columnWidths[1]'></e-column>
            <e-column field='fst_mapping' headerText='FST' :width='columnWidths[2]'></e-column>
            <e-column field='code' headerText='Code' :width='columnWidths[3]'></e-column>
            <e-column field='position' headerText='Position' :width='columnWidths[4]'></e-column>
            <e-column textAlign='center' :columns='valuesColumns'>
            </e-column>
        </e-columns>
    </ejs-treegrid>
    <Snackbar :text="notificationText"
      :type="notificationType" />
    <v-overlay :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate
        size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import {
  TreeGridPlugin, ExcelExport, Freeze, Resize, Filter,
} from '@syncfusion/ej2-vue-treegrid';
import Breadcrumbs from '@/components/Breadcrumbs';
import Snackbar from '@/components/Snackbar';
import api from '../api/lbt-api';

Vue.use(TreeGridPlugin);

const {
  getProfitLossForPlanning,
} = api;

export default {
  name: 'SummaryPortfolio',
  components: {
    Breadcrumbs,
    Snackbar,
  },
  data() {
    return {
      notificationText: '',
      loader: false,
      notificationType: null,
      headers: [],
      items: [],
      storagePrefix: 'pl_new',
      storageSeparator: '.-.',
      columnWidths: [90, 130, 25, 32, 200],
      rows: 0,
      columns: 2,
      selectionOptions: { mode: 'Cell', type: 'Multiple', cellSelectionMode: 'Box' },
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'breadcrumbs_plannings_summary_pl',
          disabled: true,
        },
      ],
      valuesColumns: [],
      highlightCellAttributes: { class: 'highlight-cell' },
    };
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
    ...mapState('plannings', [
      'activePlanning',
    ]),
  },
  async created() {
    this.loader = true;
    try {
      this.retrieveColumnWidths();
      const planning = await getProfitLossForPlanning(this.$route.params.planningId);
      this.headers = planning.periods;
      this.items = planning.rows;
      this.prepareValuesGridHeaders();
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
  },
  methods: {
    prepareValuesGridHeaders() {
      const offset = 6;
      const item = this.items[0];
      for (let i = 0; i < item.values.length; i += 1) {
        const width = localStorage.getItem(this.storagePrefix + this.storageSeparator + (offset + i));
        this.valuesColumns.push({
          field: `values.${i}`,
          headerText: i,
          headerValueAccessor: this.lookupValuesHeader,
          customAttributes: this.highlightCellAttributes,
          width: width != null ? width : 70,
          allowFiltering: false,
          textAlign: 'right',
          format: 'n2',
        });
      }
    },
    retrieveColumnWidths() {
      for (let i = 0; i < this.columnWidths.length; i += 1) {
        const width = localStorage.getItem(this.storagePrefix + this.storageSeparator + i);
        if (width) {
          this.columnWidths[i] = width;
        }
      }
    },
    resizeStop(item) {
      let width = item.column.width.split('px');
      width = parseInt(width[0], 10);
      localStorage.setItem(this.storagePrefix + this.storageSeparator + item.column.index, width);
    },
    lookupValuesHeader(idx) {
      return this.headers[Number(idx)];
    },
    excelDownloadClick() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const excelExportProperties = {
        exportType: 'CurrentPage',
        fileName: `Profit loss - Planning - ${this.activePlanning.year}-${this.activePlanning.month}.xlsx`,
      };
      grid.excelExport(excelExportProperties);
    },
    excelHeader(args) {
      const { cell } = args;
      if (cell.index > 2) {
        cell.value = this.headers[cell.value];
      }
    },
  },
  provide: {
    treegrid: [ExcelExport, Freeze, Resize, Filter],
  },
};
</script>

<style>
  @import '../../node_modules/@syncfusion/ej2-vue-treegrid/styles/material.css';

  .e-grid .e-headercell.highlight-cell, .highlight-cell {
    background: #eeeeee;
  }

  .e-headercell {
    padding: 15px 0 !important;
  }
  .e-headercell .e-headercelldiv {
    text-align: center;
  }
  .e-treerowcell{
    padding: 0 !important;
  }
  .e-rowcell {
    padding: 3px !important;
  }
</style>
