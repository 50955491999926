import Vue from 'vue';

Vue.filter('toPercentageExtended', (value, type = 'number') => {
  if (!value && type === 'number') return 0;
  if (!value && type === 'string') return '0 %';
  const newValue = (value * 100).toFixed(4);
  if (type === 'number') {
    return newValue;
  }
  return `${newValue} %`;
});
