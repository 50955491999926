<template>
  <tr :class="[invalidData ? 'invalid' : '', 'planning-row']">
  <td :class="[errorBucket.line ? 'has-error' : '', 'input-value']">
      <v-edit-dialog
        large
        eager
        color="accent"
        :return-value.sync="item.line">
        {{ item.line }}
        <template v-slot:input>
          <v-text-field v-model="item.line"
            ref="line"
            :rules="[validators.required]"
            single-line
            counter />
        </template>
      </v-edit-dialog>
    </td>
    <td :class="{ 'has-error': errorBucket.currency}">
      <v-select
        class="dropdown-value"
        dense
        ref="currency"
        :rules="[validators.required]"
        v-model="item.currency_id"
        :hide-details="true"
        :items="currencyValues"
        item-text="option"
        item-value="id"
      ></v-select>
    </td>
    <td :class="[errorBucket.maturity ?'has-error' : '', 'input-value']">
      <v-edit-dialog
        large
        eager
        color="accent"
        :return-value.sync="item.maturity">
        {{ item.maturity }}
        <template v-slot:input>
          <v-text-field v-model="item.maturity"
            type="number"
            ref="maturity"
            :rules="[validators.required]"
            single-line
            counter />
        </template>
      </v-edit-dialog>
    </td>
    <td :class="{ 'has-error': errorBucket.repayment_type}">
      <v-select
        class="dropdown-value fixed-size"
        dense
        ref="repayment_type"
        :rules="[validators.required]"
        v-model="item.repayment_id"
        :hide-details="true"
        :items="repaymentValues"
        item-text="option"
        item-value="id"
      ></v-select>
    </td>
    <td :class="{ 'has-error': errorBucket.intereset_rate_type}">
      <v-select
        class="dropdown-value"
        dense
        ref="intereset_rate_type"
        :rules="[validators.required]"
        v-model="item.interest_rate_type_id"
        :hide-details="true"
        :items="interestRateTypeValue"
        item-text="option"
        item-value="id"
      ></v-select>
    </td>
    <td :class="{ 'has-error': errorBucket.reference_rate}">
      <v-select
        class="dropdown-value fixed-size medium"
        dense
        clearable
        ref="reference_rate"
        v-model="item.reference_rate_id"
        :hide-details="true"
        :items="referenceRateValues"
        item-text="option"
        item-value="id"
      ></v-select>
    </td>
    <td :class="[errorBucket.fee ?'has-error' : '', 'input-value']">
      <v-edit-dialog
        large
        eager
        color="accent">
        {{ item.fee  | toPercentage('string') }}
        <template v-slot:input>
          <v-text-field
            :value="item.fee | toPercentage"
            @change="onValueChange($event, 'fee')"
            type="number"
            ref="fee"
            single-line
            counter />
        </template>
      </v-edit-dialog>
    </td>
    <td :class="{ 'has-error': errorBucket.fee_accrual}">
      <v-select
        class="dropdown-value fixed-size medium"
        :disabled="!item.fee"
        dense
        clearable
        ref="fee_accrual"
        :rules="[validators.conditionallyRequired(!item.fee_accrual_id && (item.fee  > 0))]"
        v-model="item.fee_accrual_id"
        :hide-details="true"
        :items="accrualValues"
        item-text="option"
        item-value="id"
      ></v-select>
    </td>
    <td :class="{ 'has-error': errorBucket.placement_period}">
      <v-select
        class="dropdown-value fixed-size"
        dense
        ref="placement_period"
        :rules="[validators.required]"
        v-model="item.placement_period"
        :hide-details="true"
        :items="placementPeriodValues"
      ></v-select>
    </td>
    <td ref="delete">
      <v-icon small
        @click.stop="deleteItem()">
        mdi-delete
      </v-icon>
    </td>
    <template v-for="(period, idx) in flattenedVolumePeriods">
      <td :key="`financing-volumes-${item.id}-${idx}`"
        class="input-value financing-volumes">
        <v-edit-dialog large
                        :key="`dialog-${item.id}-${idx}`"
                        color="accent">
          <span v-if="period.showInPercentage">{{ period.value | toPercentage('string') }}</span>
          <span v-else>{{ period.value }}</span>
        <template v-slot:input>
          <v-text-field
            :value="financingVolumes[period.volumeIndex].periods[period.periodIndex].value | toPercentage()"
            @change="onVolumePeriodChange($event, period)"
            single-line
            type="number"
            v-if="period.showInPercentage"
            counter />
          <v-text-field
            :value="financingVolumes[period.volumeIndex].periods[period.periodIndex].value"
            @change="onVolumePeriodChange($event, period)"
            v-else
            single-line
            type="number"
            counter />
        </template>
      </v-edit-dialog>
      </td>
      <td :key="`fy-${period.volumeIndex}`"
        class="input-value financing-volumes"
          v-if="period.isLast">
        <template v-if="!financingVolumes[period.volumeIndex].fyEditable">
          {{ financingVolumes[period.volumeIndex].fy }}
        </template>
        <v-edit-dialog large
                        v-else
                        color="accent">
          {{ financingVolumes[period.volumeIndex].fy }}
        <template v-slot:input>
          <v-text-field
            v-model="financingVolumes[period.volumeIndex].fy"
            type="number"
            single-line
            counter />
        </template>
      </v-edit-dialog>
      </td>
    </template>
    <td :class="[errorBucket[`interest_rate_${idx}`] ? 'has-error' : '', 'input-value']"
      :key="`interest_rate-${idx}`"
      v-for="(interest, idx) in item.interest_rates">
      <v-edit-dialog
        large
        eager
        color="accent">
        {{ interest.value | toPercentage('string') }}
        <template v-slot:input>
          <v-text-field
            :value="interest.value | toPercentage"
            @change="onInterestRateChange($event, idx)"
            type="number"
            :ref="`interest_rate_${idx}`"
            :rules="[validators.required]"
            single-line
            counter />
        </template>
      </v-edit-dialog>
    </td>


  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  each,
  flatMap,
  map,
  sumBy,
  toNumber,
} from 'lodash';
import validators from '@/validators';

export default {
  name: 'FundingInputRow',
  props: {
    item: {
      type: Object,
    },
    rowIndex: Number,
    placementPeriodValues: Array,
  },
  data() {
    return {
      validators,
      invalidData: false,
      errorBucket: {},
      currencyValues: [],
      paymentAtOnceValues: [],
      typeValues: [],
      repaymentValues: [],
      interestRateTypeValue: [],
      referenceRateValues: [],
      accrualValues: [],
      flattenedFinancingVolumes: [],
      firstFinancingVolume: {},
      financingVolumes: [],
      ticVolumes: [],
    };
  },
  computed: {
    ...mapGetters('options', [
      'getOptionCategory',
    ]),
    flattenedVolumePeriods() {
      return flatMap(this.financingVolumes, volume => volume.periods);
    },
  },
  created() {
    this.currencyValues = this.getOptionCategory('CURRENCY').options;
    this.repaymentValues = this.getOptionCategory('REPAYMENT_TYPE').options;
    this.interestRateTypeValue = this.getOptionCategory('INTEREST_RATE_TYPE').options;
    this.referenceRateValues = this.getOptionCategory('REFERENCE_RATE').options;
    this.accrualValues = this.getOptionCategory('ACCRUAL_METHOD').options;

    if (!this.item.financing_volumes) return;

    this.financingVolumes = map(this.item.financing_volumes, this.parseVolume);
  },
  methods: {
    parseVolume(volume, volumeIndex) {
      const volumeItem = volume;
      volumeItem.fyEditable = volumeIndex > 1;
      volumeItem.periods = map(volumeItem.periods, (itemPeriod, i) => {
        const period = itemPeriod;
        period.volumeIndex = volumeIndex;
        period.periodIndex = i;
        period.isLast = volumeItem.periods.length - 1 === i;
        period.showInPercentage = volumeIndex > 1;

        return period;
      });

      return volumeItem;
    },
    onValueChange(value, key) {
      this.item[key] = value / 100;
    },
    deleteItem() {
      this.$emit('delete', { item: this.item, rowIndex: this.rowIndex });
    },
    onVolumePeriodChange(value, period) {
      if (period.showInPercentage) {
        this.financingVolumes[period.volumeIndex].periods[period.periodIndex].value = (value / 100).toFixed(4);
      } else {
        this.financingVolumes[period.volumeIndex].periods[period.periodIndex].value = value;
      }
      if (this.financingVolumes[period.volumeIndex].fyEditable) return;
      const volume = this.financingVolumes[period.volumeIndex];
      volume.fy = sumBy(volume.periods, volumePeriod => toNumber(volumePeriod.value));
      this.item.financing_volumes[period.volumeIndex].periods[period.periodIndex].value = value;
    },
    onInterestRateChange(value, index) {
      this.item.interest_rates[index].value = (value / 100).toFixed(4);
    },
    validate() {
      this.errorBucket = {};
      each(this.$refs, (val, key) => {
        if (val.errorBucket && val.errorBucket.length) this.errorBucket[key] = val.errorBucket;
      });

      if (Object.keys(this.errorBucket).length) {
        this.invalidData = true;
      } else {
        this.invalidData = false;
      }

      return this.errorBucket;
    },
  },
};
</script>

<style scoped lang="scss" >
.planning-row {
  $input-size: 10px !important;

  &.invalid > td:first-of-type{
    border-left: solid 4px #ff0000;
  }

  &.invalid > td {
    background: #fcecec;

    &.has-error {
      background: rgba(240, 52, 52, 1);
    }
  }

  .input-value {
    font-size: $input-size;
  }

  .dropdown-value {
    font-size: $input-size;

    &.fixed-size {
      width: 100px;

      &.medium {
        width: 150px;
      }

      &.large {
        width: 200px;
      }

      &.extra-large {
        width: 250px;
      }
    }
  }

  .financing-volumes, .tic-volumes, .ponder {
    background: #eeeeee;
  }
}
</style>
