<template>
  <v-container fluid class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>
    <v-toolbar flat>
      <v-btn color="primary" @click='excelDownloadClick'>
        Export to Excel
      </v-btn>
    </v-toolbar>
    <v-spacer></v-spacer>
    <ejs-treegrid :dataSource='items' v-if='items'  :allowResizing='true'  :enableCollapseAll='false' childMapping='sub'
                  :pageSettings='pageSettings' :allowPaging='true' :allowFiltering='true' :filterSettings="{type: 'Menu'}"
                  :allowTextWrap="true" :treeColumnIndex='0' :allowExcelExport='true' :excelHeaderQueryCellInfo='excelHeader'>
        <e-columns>
            <e-column field='summary_pos' headerText='Summary - Position Mapping' width='240'></e-column>
            <e-column field='position' headerText='Position' width='200'></e-column>
            <e-column textAlign='center' :columns='valuesColumns'>
            </e-column>
        </e-columns>
    </ejs-treegrid>
    <Snackbar :text="notificationText"
      :type="notificationType" />
    <v-overlay :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate
        size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import {
  TreeGridPlugin, ExcelExport, Page, Resize, Filter,
} from '@syncfusion/ej2-vue-treegrid';
import Breadcrumbs from '@/components/Breadcrumbs';
import Snackbar from '@/components/Snackbar';
import api from '../api/lbt-api';

Vue.use(TreeGridPlugin);

const {
  getSummaryPLForPlanning,
} = api;

export default {
  name: 'SummaryPortfolio',
  components: {
    Breadcrumbs,
    Snackbar,
  },
  data() {
    return {
      notificationText: '',
      loader: false,
      notificationType: null,
      headers: [],
      items: [],
      expanded: [],
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'breadcrumbs_plannings_summary_pl',
          disabled: true,
        },
      ],
      valuesColumns: [],
      highlightCellAttributes: { class: 'highlight-cell' },
      pageSettings: { pageSizes: true, pageSize: 50 },
    };
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
    ...mapState('plannings', [
      'activePlanning',
    ]),
  },
  async created() {
    this.loader = true;
    try {
      const planning = await getSummaryPLForPlanning(this.$route.params.planningId);
      this.headers = planning.periods;
      const items = planning.rows;
      /*
      for (let i = items.length - 1; i > 0; i -= 1) {
        if (items[i].summary_pos && (items[i].summary_pos === ' CIR' || items[i].summary_pos === ' CIR adjusted')) {
          items[i].summary_pos += ' (%)';
          for (let j = 0; j < items[i].values.length; j += 1) {
            items[i].values[j] = parseFloat(items[i].values[j] * 100).toFixed(2);
          }
        }
      }
       */
      this.items = items;
      this.prepareValuesGridHeaders();
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
  },
  methods: {
     toggleExpandedItem(item) {
      // eslint-disable-next-line
      item.isExpanded = !item.isExpanded;
      this.expanded.push(item);
    },
    prepareValuesGridHeaders() {
      const item = this.items[0];
      for (let i = 0; i < item.values.length; i += 1) {
        this.valuesColumns.push({
          field: `values.${i}`,
          headerText: i,
          headerValueAccessor: this.lookupValuesHeader,
          customAttributes: this.highlightCellAttributes,
          width: 60,
          format: 'n2',
          textAlign: 'right',
          allowFiltering: false,
        });
      }
    },
    lookupValuesHeader(idx) {
      return this.headers[Number(idx)];
    },
    excelDownloadClick() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const excelExportProperties = {
        exportType: 'CurrentPage',
        fileName: `Summary PL - Planning - ${this.activePlanning.year}-${this.activePlanning.month}.xlsx`,
      };
      grid.excelExport(excelExportProperties);
    },
    excelHeader(args) {
      const { cell } = args;
      if (cell.index > 2) {
        cell.value = this.headers[cell.value];
      }
    },
  },
  provide: {
    treegrid: [ExcelExport, Page, Resize, Filter],
  },
};
</script>

<style>
  @import '../../node_modules/@syncfusion/ej2-vue-treegrid/styles/material.css';

  .e-grid .e-headercell.highlight-cell, .highlight-cell {
    background: #eeeeee;
  }
  .e-headercelldiv {
    text-align: center !important;
  }
</style>
