export function generateFundingPeriodsValues(startYear, startMonth) {
  const numOfMonths = 12;
  const periods = [];
  let monthAddition = 0;

  // Generate months for the first year
  while (startMonth + monthAddition <= numOfMonths) {
    const month = startMonth + monthAddition;
    periods.push({
      period: `${startYear}M${month}`,
      value: 0,
    });
    monthAddition += 1;
  }

  // Generate periods for subsequent years
  for (let yearIterator = 1; yearIterator <= 5; yearIterator += 1) {
    const year = startYear + yearIterator;

    // Switch to generating months or quarters
    for (let month = 1; month <= numOfMonths; month += 1) {
      if ([3, 6, 9, 12].includes(month)) {
        periods.push({
          period: `${year}Q${Math.ceil(month / 3)}`,
          value: 0,
        });
      } else {
        periods.push({
          period: `${year}M${month}`,
          value: 0,
        });
      }
    }
  }

  return periods;
}
