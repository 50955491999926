<template>
  <v-list-group
    :prepend-icon="formattedDropdown.prependIcon"
    :value="nestedItemsVisible"
  >
    <template v-slot:activator>
      <v-list-item-title>
        {{translate(formattedDropdown.translationKey)}}
      </v-list-item-title>
    </template>
    <v-list-item>
      <v-list-item-title>
      {{translate('sidebar_planning')}}: {{planningYear}}/{{planningMonth}}
      </v-list-item-title>
    </v-list-item>
    <router-link
      v-for="(item, idx) in formattedDropdown.items"
      :key="idx"
      exact
      tag="v-list-item"
      active-class="router-link-active v-list-item--active"
      :to="item.bindedRoute">
      <v-list-item-content>
        <v-list-item-title>{{translate(item.translationKey)}}</v-list-item-title>
      </v-list-item-content>
    </router-link>
    <v-list-item
      :key="'item.generate00'"
      @click="generateReport()"
      link
    >
      <v-list-item-content>
        <v-list-item-title>Generate report</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      :key="'item.generate01'"
      @click="generateExcel()"
      link
    >
      <v-list-item-content>
        <v-list-item-title>Generate excel</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-group>
</template>

<script>
import each from 'lodash/each';
import { mapGetters, mapActions } from 'vuex';
import { supplant } from '../utilities/supplant';
import store from '../store';

export default {
  name: 'SidebarNestedItems',
  props: {
    dropdown: Object,
    supplantKeys: Object,
  },
  async created() {
    this.formatRouteBindings();
    const planning = await store.dispatch('plannings/getPlanning', this.$route.params.planningId);
    this.planningYear = planning.year;
    this.planningMonth = planning.month;
  },
  data() {
    return {
      nestedItemsVisible: true,
      formattedDropdown: this.dropdown,
      planningYear: '',
      planningMonth: '',
    };
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
    ...mapActions('plannings', [
      'getPlanning',
    ]),
  },
  methods: {
    formatRouteBindings() {
      this.formattedDropdown.items = this.dropdown.items.map((item) => {
        if (item.routeSupplantKeys) {
          each(item.routeSupplantKeys, (key) => {
            // eslint-disable-next-line no-param-reassign
            item.bindedRoute = supplant(item.route, {
              [key]: this.supplantKeys[key],
            });
          });
        }
        return item;
      });
    },
    generateReport() {
        const win = window.open(`${localStorage.getItem('api_link')}/pdf_reports/presentation/${this.$route.params.planningId}`, '_blank');
        win.focus();
    },
    generateExcel() {
    // Generate a random number to use as a parameter
        const randomParam = Math.floor(Math.random() * 1000000);
        // Append the random parameter to the URL
        const url = `${localStorage.getItem('api_link')}/excel_reports/excel/${this.$route.params.planningId}?random=${randomParam}`;
        // Open the new URL in a new window and focus on it
        const win = window.open(url, '_blank');
        win.focus();
      },
    },
  watch: {
    deep: true,
    supplantKeys() {
      this.formatRouteBindings();
    },
  },
};
</script>
