<template>
  <v-container fluid
               class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>

    <v-toolbar flat>
      <v-col cols="12" sm="3"
             class="px-0">
        <!--
        <v-text-field
          clearable
          v-model="search"
          append-icon="mdi-magnify"
          :label="translate('plannings_sales_search_items')"
          single-line
          hide-details
        ></v-text-field>
        -->
      </v-col>
      <v-spacer></v-spacer>

      <v-btn color="primary" @click='excelDownloadClick'>
        Export to Excel
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn color="primary"
             @click.stop="addNewOtherFinrepItem">
        <v-icon class="mr-2">mdi-table-large-plus</v-icon>
        {{translate('new other finrep item')}}
      </v-btn>
    </v-toolbar>


    <ejs-grid
      :dataSource='items'
      v-if="items.length > 0 && forceShow"
      :selectionSettings='selectionOptions'
      :allowPaging='false'
      :allowEditing="true"
      :allowSorting="true"
      :allowFiltering="true"
      :withHeader='true'
      gridLines='Both'
      :load='load'
      :commandClick="deleteItem"
      :editSettings="editSettings"
      :allowTextWrap='true'
      :allowExcelExport='true'

    >

      <e-columns>
        <e-column field='id' :headerText="translate('other_finrep_id')" textAlign='center'  width=50 :visible=true isPrimaryKey='true'>
        </e-column>
        <e-column field='tempId' headerText='tempID' :visible=false>
        </e-column>
        <e-column field='title' :headerText="translate('other_finrep_title')">
        </e-column>
        <e-column field='value' v-if="!isOtherType" :headerText="translate('other_finrep_value')">
        </e-column>
        <e-column field='parent_id' v-if="!isOtherType" :headerText="translate('other_finrep_parent')" :valueAccessor='dropdownValueAccess' editType='dropdownedit'
                  :edit='parentTypeParams' width=280>
        </e-column>
        <e-column textAlign='center' :allowFiltering="false" :allowSorting='false' :headerText="translate('table_actions')" :commands='deleteActionCommand' :allowEditing="false" width=60>
        </e-column>
      </e-columns>
    </ejs-grid>

    <v-btn color="accent"
           fab
           fixed
           right
           bottom
           @click.stop="saveOption()">
      <v-icon dark>mdi-content-save</v-icon>
    </v-btn>
    <Snackbar :text="notificationText"
              :type="notificationType" />
    <v-overlay :value="loader"
               :z-index="1000">
      <v-progress-circular indeterminate
                           size="64"></v-progress-circular>
    </v-overlay>

    <ConfirmDialog
      :dialog="deleteDialog"
      :description="deleteDialogDescription"
      @change="setDialogState"
      @confirmDelete="confirmDeleteAction"
    />
  </v-container>
</template>

<script>
import Vue from 'vue';
import {
  GridPlugin, Page, Sort, Edit, Resize, Filter, Search, CommandColumn, ExcelExport,
} from '@syncfusion/ej2-vue-grids';
import { mapGetters } from 'vuex';
import { Query } from '@syncfusion/ej2-data';
import Snackbar from '../components/Snackbar';
import Breadcrumbs from '../components/Breadcrumbs';
import ConfirmDialog from '../components/ConfirmDialog';

import api from '../api/lbt-api';
import store from '../store/index';

const {
  getOtherFinreps,
  updateOtherFinrep,
  createOtherFinrep,
  deleteOtherFinrep,
} = api;

Vue.use(GridPlugin);

export default {
  name: 'OtherFinreps',
  components: {
    Snackbar,
    Breadcrumbs,
    ConfirmDialog,
  },
  data() {
    return {
      search: '',
      notificationText: '',
      notificationType: '',
      deleteDialog: false,
      deleteDialogDescription: '',
      itemForDeletion: {},
      category_id: {},
      loader: false,
      headers: [],
      items: [],
      originalItems: [],
      isOtherType: false,
      forceShow: true,
      parentTypeParams: this.initDropdownParams(),
      deleteActionCommand: [{ buttonOption: { cssClass: 'v-icon notranslate v-icon--link mdi mdi-delete theme--light delete-action' } }],
      selectionOptions: { mode: 'Cell', type: 'Multiple', cellSelectionMode: 'Box' },
      pagination: {
        page: 1,
        size: 25,
        total: 0,
        itemsPerPage: [25, 50, 100],
      },
      editSettings: {
        allowEditing: true, allowAdding: true, allowSorting: true, allowDeleting: true, mode: 'Batch',
      },
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_options',
          route: '/options',
          disabled: false,
        },
        {
          translationKey: 'breadcrumbs_other_finreps',
          disabled: true,
        },
      ],
    };
  },
  provide: {
    grid: [Page, Sort, Edit, Resize, Filter, Search, ExcelExport, CommandColumn],
  },
  async created() {
    this.loader = true;
    try {
      await this.fetchOtherFinrepItems();
      // const option = await getOption(this.$route.params.optionId);
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
  },
  watch: {
    $route() {
      this.forceShow = false;
      this.isOtherType = this.$route.matched[0].name === 'other_type';
      this.fetchOtherFinrepItems();

      const self = this;
      setTimeout(() => {
        self.forceShow = true;
      }, 300);
    },
  },
  methods: {
    async fetchOtherFinrepItems() {
      this.isOtherType = this.$route.matched[0].name === 'other_type';
      const items = await getOtherFinreps(this.pagination);
      this.originalItems = items;
      const tempItems = [];
      for (let i = 0; i < items.length; i += 1) {
        if (!items[i].parent_id || items[i].parent_id <= 0 || items[i].parent_id === '') {
          if (this.isOtherType) {
            tempItems.push(items[i]);
          }
        } else if (!this.isOtherType) {
          tempItems.push(items[i]);
        }
      }
      this.items = tempItems;
      if (!this.isOtherType) this.setDropdownDatasources();
    },
    setDropdownDatasources() {
      const parents = [];

      this.originalItems.forEach((item) => {
        if (!item.parent_id || item.parent_id === 0 || item.parent_id === '') {
          parents.push(item);
        }
      });

      this.parentTypeParams.params.dataSource = parents;
    },
    excelDownloadClick() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const excelExportProperties = {
        // exportType: 'CurrentPage',
        fileName: 'Other finreps.xlsx',
      };
      grid.excelExport(excelExportProperties);
    },
    load() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      grid.element.addEventListener('mousedown', (e) => {
        if (e.target.classList.contains('e-rowcell')) {
          const index = Number(e.target.getAttribute('Index'));
          const colindex = Number(e.target.getAttribute('aria-colindex'));
          const { field } = grid.getColumns()[colindex];
          grid.editModule.editCell(index, field);
        }
      });
    },
    addNewOtherFinrepItem() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const [...addedRecords] = grid.getBatchChanges().addedRecords;
      const addedRecordsSize = addedRecords ? addedRecords.length : 0;
      grid.addRecord({
        tempId: Math.random(),
        id: this.items.length + addedRecordsSize + 1,
        title: '',
        value: '',
        parent_id: null,
      });
    },
    dropdownValueAccess(field, data) {
      return this.getOtherFinrepValue(field, data[field]);
    },
    getOtherFinrepValue(key, value) {
      let fetchedValue = '';
      this.originalItems.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.title;
        }
      });
      return fetchedValue;
    },
    initDropdownParams() {
      return {
        params: {
          dataSource: null,
          allowFiltering: true,
          fields: { text: 'title', value: 'id' },
          query: new Query(),
        },
      };
    },
    async saveOption() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const [...addedRecords] = grid.getBatchChanges().addedRecords;
      const [...changedRecords] = grid.getBatchChanges().changedRecords;
      if (addedRecords === undefined && changedRecords === undefined) {
        return;
      }

      this.loader = true;
      const oFinrepCreateRequests = [];
      const oFinrepUpdateRequests = [];
      try {
        if (addedRecords !== undefined) {
          for (let i = 0; i < addedRecords.length; i += 1) {
            oFinrepCreateRequests.push(createOtherFinrep(addedRecords[i]));
          }
        }
        if (changedRecords !== undefined) {
          for (let j = 0; j < changedRecords.length; j += 1) {
            oFinrepUpdateRequests.push(updateOtherFinrep(changedRecords[j].id, changedRecords[j]));
          }
        }
        await Promise.all(oFinrepCreateRequests);
        await Promise.all(oFinrepUpdateRequests);
        this.notificationText = this.translate('notifications_planning_updated');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchOtherFinrepItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    deleteItem(args) {
      this.itemForDeletion = args.rowData;
      this.deleteDialog = true;
      this.deleteDialogDescription = this.translate('dialog_delete_action_description');
    },
    async confirmDeleteAction() {
      if (this.itemForDeletion.tempId) {
        let index = -1;
        for (let i = 0; i < this.items.length; i += 1) {
          if (this.items[i].id === this.itemForDeletion.id) {
            index = i;
            break;
          }
        }

        if (index !== -1) {
          this.$delete(this.items, index);
          this.itemForDeletion = null;
        } else {
          const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
          const [...addedRecords] = grid.getBatchChanges().addedRecords;
          for (let i = 0; i < addedRecords.length; i += 1) {
            if (addedRecords[i].tempId === this.itemForDeletion.tempId) {
              grid.deleteRecord('id', addedRecords[i]);
              const rowIdx = grid.getRowIndexByPrimaryKey(addedRecords[i].id);
              const row = grid.getRowByIndex(rowIdx);
              grid.deleteRow(row);
              break;
            }
          }
        }
        this.deleteDialog = false;
        return;
      }

      this.loader = true;

      try {
        await deleteOtherFinrep(this.itemForDeletion.id);
        this.deleteDialog = false;
        this.itemForDeletion = null;
        this.notificationText = this.translate('notifications_item_deleted_successfully');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchOtherFinrepItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    setDialogState(value) {
      this.deleteDialog = value;
    },
  },
  async beforeRouteEnter(from, to, next) {
    await store.dispatch('finrepBsPl/getOptions');
    next();
  },
};
</script>

<style lang="scss" scoped>

.container {
  padding-bottom: 5rem !important;
}

</style>
