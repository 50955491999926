import find from 'lodash/find';
import api from '../../api/lbt-api';

const {
  getOptions,
} = api;

export default {
  namespaced: true,
  state: {
    fetched: false,
    options: [],
  },
  mutations: {
    setOptionsFetchedFlag(state) {
      state.fetched = true;
    },
    setOptions(state, options) {
      state.options = options;
    },
  },
  actions: {
    async getOptions({ commit, state }) {
      if (!state.fetched) {
        const result = await getOptions();
        commit('setOptions', result);
        commit('setOptionsFetchedFlag');
      }
      return state.options;
    },
  },
  getters: {
    getOptionCategory: state => (category) => {
      const result = find(state.options, element => element.category === category);
      return result;
    },
  },
};
