<template>
  <tr :class="[invalidData ? 'invalid' : '', 'planning-row']">
    <td :class="{ 'has-error': errorBucket.leasing_type}">
      <v-select
        class="dropdown-value fixed-size medium"
        dense
        ref="leasing_type"
        :rules="[validators.required]"
        v-model="item.leasing_type_id"
        :hide-details="true"
        :items="leasingTypeValues"
        item-text="option"
        item-value="id"
      ></v-select>
    </td>
    <td :class="{ 'has-error': errorBucket.segment}">
      <v-select
        class="dropdown-value fixed-size"
        dense
        ref="segment"
        :rules="[validators.required]"
        v-model="item.segment_id"
        :hide-details="true"
        :items="segmentValues"
        item-text="option"
        item-value="id"
      ></v-select>
    </td>
    <td :class="{ 'has-error': errorBucket.client_type}">
      <v-select
        class="dropdown-value fixed-size medium"
        dense
        ref="client_type"
        :rules="[validators.required]"
        v-model="item.risk_type_id"
        :hide-details="true"
        :items="clientTypeValues"
        item-text="option"
        item-value="id"
      ></v-select>
    </td>
    <td :class="{ 'has-error': errorBucket.product_group}">
      <v-select
        class="dropdown-value fixed-size medium"
        dense
        ref="product_group"
        :rules="[validators.required]"
        v-model="item.stage_id"
        :hide-details="true"
        :items="productGroupValues"
        item-text="option"
        item-value="id"
      ></v-select>
    </td>

    <td ref="delete">
      <v-icon small
        @click.stop="deleteItem()">
        mdi-delete
      </v-icon>
    </td>
  <template v-if="item.risk_period && item.risk_period.length">
      <td v-for="(period, idx) in item.risk_period"
        class="depreciation input-value"
        :key="`risk_rate-${idx}`">
         <v-edit-dialog
        large
        eager
        color="accent">
        {{ period.value | toPercentageExtended('string') }}
        <template v-slot:input>
          <v-text-field
            :value="period.value | toPercentageExtended"
            @change="onInterestRateChange($event, idx)"
            type="number"
            :ref="`risk_rate_${idx}`"
            :rules="[validators.required]"
            single-line
            counter />
        </template>
      </v-edit-dialog>
      </td>
    </template>
    <template v-else>
      <td v-for="(period, idx) in riskPeriods"
        class="depreciation input-value"
        :key="`risk_rate-${idx}`">
        0
      </td>
    </template>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  each,
  map,
  sumBy,
  toNumber,
} from 'lodash';
import validators from '@/validators';

export default {
  name: 'RiskRow',
  props: {
    item: {
      type: Object,
    },
    rowIndex: Number,
  },
  data() {
    return {
      validators,
      invalidData: false,
      errorBucket: {},
      leasingTypeValues: [],
      segmentValues: [],
      clientTypeValues: [],
      productGroupValues: [],
      financingVolumes: [],
    };
  },
  computed: {
    ...mapGetters('options', [
      'getOptionCategory',
    ]),


  },
  created() {
    this.leasingTypeValues = this.getOptionCategory('LEASING_TYPE').options;
    this.segmentValues = this.getOptionCategory('SEGMENT').options;
    this.clientTypeValues = this.getOptionCategory('RISK_TYPED').options;
    this.productGroupValues = this.getOptionCategory('STAGE').options;
    if (!this.item.risk_period) return;

    this.financingVolumes = map(this.item.risk_period, this.parseVolume);
  },
  methods: {
    parseVolume(volume) {
      const volumeItem = volume;
      return volumeItem;
    },
    onValueChange(value, key) {
      this.item[key] = value / 100;
    },
    deleteItem() {
      this.$emit('delete', { item: this.item, rowIndex: this.rowIndex });
    },
    onVolumePeriodChange(value, period) {
      if (period.showInPercentage) {
        this.financingVolumes[period.volumeIndex].periods[period.periodIndex].value = (value / 100).toFixed(6);
      } else {
        this.financingVolumes[period.volumeIndex].periods[period.periodIndex].value = value;
      }
      if (this.financingVolumes[period.volumeIndex].fyEditable) return;
      const volume = this.financingVolumes[period.volumeIndex];
      volume.fy = sumBy(volume.periods, volumePeriod => toNumber(volumePeriod.value));
      this.item.financing_volumes[period.volumeIndex].periods[period.periodIndex].value = value;
    },
    onInterestRateChange(value, index) {
      this.item.risk_period[index].value = (value / 100).toFixed(6);
    },
    validate() {
      this.errorBucket = {};
      each(this.$refs, (val, key) => {
        if (val.errorBucket && val.errorBucket.length) this.errorBucket[key] = val.errorBucket;
      });

      if (Object.keys(this.errorBucket).length) {
        this.invalidData = true;
      } else {
        this.invalidData = false;
      }

      return this.errorBucket;
    },
  },
};
</script>

<style scoped lang="scss" >
.planning-row {
  $input-size: 10px !important;

  &.invalid > td:first-of-type{
    border-left: solid 4px #ff0000;
  }

  &.invalid > td {
    background: #fcecec;

    &.has-error {
      background: rgba(240, 52, 52, 1);
    }
  }

  .input-value {
    font-size: $input-size;
  }

  .dropdown-value {
    font-size: $input-size;

    &.fixed-size {
      width: 100px;

      &.medium {
        width: 150px;
      }

      &.large {
        width: 200px;
      }

      &.extra-large {
        width: 250px;
      }
    }
  }

  .financing-volumes, .tic-volumes, .ponder {
    background: #eeeeee;
  }
}
</style>
