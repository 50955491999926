<template>
  <v-container fluid class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>
    <v-toolbar flat>
      <v-col cols="12" sm="3"
             class="px-0">
        <v-text-field
          clearable
          v-model="search"
          append-icon="mdi-magnify"
          :label="translate('plannings_sales_search_items')"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click='excelDownloadClick'>
        Export to Excel
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary"
             @click.stop="addNewPlanningItem()">
        <v-icon class="mr-2">mdi-table-large-plus</v-icon>
        {{translate('plannings_new_sales_item')}}
      </v-btn>
    </v-toolbar>
    <ejs-grid :dataSource='items' v-if="dataLoaded" :allowSorting="true" :load='load' :height='600' :cellSave='cellSave'
              :editSettings='editSettings' :selectionSettings='selectionOptions' :pageSettings='pageSettings'
              :sortSettings='sortOptions' :searchSettings='searchOptions' :allowPaging='true'
              :commandClick='commandClick' gridLines='Horizontal' :queryCellInfo='customiseCell'
              :excelQueryCellInfo="excelExportPreprocessing" :allowExcelExport='true' :allowResizing='true'
              :excelHeaderQueryCellInfo='excelHeader' :allowFiltering='true' :filterSettings="{type: 'Menu'}">
      <e-columns>
        <e-column field='id' headerText='ID' :visible=false isPrimaryKey='true'>
        </e-column>
        <e-column field='tempId' headerText='tempID' :visible=false>
        </e-column>
        <e-column field='leasing_type_id' :headerText="translate('plannings_sales_leasing_type_id')" :filter="filterDropdown" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='leasingTypeParams' :validationRules='requiredValidationRule' width=70>
        </e-column>
        <e-column field='segment_id' :headerText="translate('plannings_sales_segment_id')"  :filter="filterDropdown" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='segmentTypeParams' :validationRules='requiredValidationRule' width=70>
        </e-column>
        <e-column field='customer_id' :headerText="translate('plannings_sales_customer')"  :filter="filterDropdown" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='customerTypeParams' width=100>
        </e-column>
        <e-column field='product_group_id' :headerText="translate('plannings_sales_product_group')"  :filter="filterDropdown" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='productGroupTypeParams' :validationRules='requiredValidationRule' width=110>
        </e-column>
        <e-column field='product_name' :headerText="translate('plannings_sales_product_name')" :validationRules='requiredValidationRule' width=150>
        </e-column>
        <e-column field='channel_id' :headerText="translate('plannings_sales_channel')"  :filter="filterDropdown" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='channelTypeParams' width=120>
        </e-column>
        <e-column field='currency_id' :headerText="translate('plannings_sales_currency')"  :filter="filterDropdown" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='currencyTypeParams' :validationRules='requiredValidationRule' width=70>
        </e-column>
        <e-column field='maturity' :headerText="translate('plannings_sales_maturity')" editType='numericedit' width=70>
        </e-column>
        <e-column field='dep_period' :headerText="translate('plannings_capex_ol_depreciation_period')" editType='numericedit' width=130>
        </e-column>
        <e-column field='payment_at_once_id' :headerText="translate('plannings_sales_payment_at_once')" :filter="filterDropdown" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='paymentAtOnceTypeParams' placeholder='__' width=110>
        </e-column>
        <e-column field='type_id' :headerText="translate('plannings_sales_type')" :valueAccessor='dropdownValueAccess' :filter="filterDropdown" foreignKeyValue="name" editType='dropdownedit' :edit='typeTypeParams' :validationRules='requiredValidationRule' width=50>
        </e-column>
        <e-column field='repayment_id' :headerText="translate('plannings_sales_repayment')" :valueAccessor='dropdownValueAccess'  :filter="filterDropdown" foreignKeyValue="name" editType='dropdownedit' :edit='repaymentTypeParams' :validationRules='requiredValidationRule' width=80>
        </e-column>
        <e-column field='interest_rate_type_id' :headerText="translate('plannings_sales_interest_rate_type')"  :filter="filterDropdown" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='interestRateTypeParams' :validationRules='requiredValidationRule' width=110>
        </e-column>
        <e-column field='reference_rate_id' :headerText="translate('plannings_sales_reference_rate')"  :filter="filterDropdown" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='referenceRateTypeParams' width=110>
        </e-column>
        <e-column field='down_payment' :valueAccessor="percentageFieldAccessorValue" :headerText="translate('plannings_sales_down_payment')" editType='numericedit' :edit='percentageTypeParams' width=155>
        </e-column>
        <e-column field='first_rent_accrual_id' :headerText="translate('plannings_sales_first_rent_accrual')"  :filter="filterDropdown" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='firstRentAccrualTypeParams' width=120>
        </e-column>
        <e-column field='guarantee' :valueAccessor="percentageFieldAccessorValue" :headerText="translate('plannings_sales_guarantee')" editType='numericedit' :edit='percentageTypeParams' width=125>
        </e-column>
        <e-column field='residual_value' :valueAccessor="percentageFieldAccessorValue" :headerText="translate('plannings_sales_residual_value')" editType='numericedit' :edit='percentageTypeParams' width=110>
        </e-column>
        <e-column field='dep_value' :valueAccessor="percentageFieldAccessorValue" :headerText="translate('Value to be depreciated %')" editType='numericedit' :edit='percentageTypeParams' width=158>
        </e-column>
        <e-column field='up_front_fee' :valueAccessor="percentageFieldAccessorValue" :headerText="translate('plannings_sales_up_front_fee')" editType='numericedit' :edit='percentageTypeParams' width=105>
        </e-column>
        <e-column field='up_front_fee_accrual_id' :headerText="translate('plannings_sales_up_front_fee_accrual')"  :filter="filterDropdown" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='upFrontFeeAccrualTypeParams' width=130>
        </e-column>
        <e-column field='intercalary_days' :headerText="translate('plannings_sales_intercalary_days')" editType='numericedit' width=110>
        </e-column>
        <e-column field='subvention' :headerText="translate('plannings_sales_subvention')" editType='numericedit' width=80>
        </e-column>
        <e-column field='subvention_accrual_id' :headerText="translate('plannings_sales_subvention_accrual_id')"  :filter="filterDropdown" foreignKeyValue="name" :valueAccessor='dropdownValueAccess' editType='dropdownedit' :edit='subventionAccrualTypeParams' width=120>
        </e-column>
        <e-column field='ppmv' :valueAccessor="percentageFieldAccessorValue" :headerText="translate('plannings_sales_ppmv')" editType='numericedit' :edit='percentageTypeParams' width=66>
        </e-column>
        <e-column textAlign='center' :headerText="translate('table_actions')" :allowSorting="true" :commands='deleteActionCommand' :allowEditing="false" width=60>
        </e-column>
        <e-column textAlign='center' :headerText="translate('plannings_sales_financing_volumes')" :allowSorting="true" :columns='financingVolumeColumns'>
        </e-column>
        <e-column textAlign='center' :headerText="translate('plannings_sales_interest_rate')" :allowSorting="true" :columns='interestRatesColumns'>
        </e-column>
        <e-column textAlign='center' :headerText="translate('plannings_sales_total_investment_cost')" :allowSorting="true" :columns='ticPeriodsColumns'>
        </e-column>
        <e-column textAlign='center' :headerText="translate('plannings_sales_total_interest_rates')" :allowSorting="true" :columns='totalInterestRatesColumns'>
        </e-column>
        <e-column textAlign='center' :headerText="translate('plannings_sales_ponder_for_average')" :allowSorting="true" :columns='ponderForAveragesColumns'>
        </e-column>
      </e-columns>
      <e-aggregates>
        <e-aggregate>
          <e-columns>
            <e-column type="Sum" v-for="col in financingVolumeColumnsComputed" :field="col.field" format="n2" v-bind:key="col.field" textAlign="left"></e-column>
            <e-column type="Sum" v-for="col in ticPeriodsColumnsComputed" :field="col.field" format="n2" v-bind:key="col.field" textAlign="left"></e-column>
            <e-column type="Sum" v-for="col in ponderForAveragesColumnsComputed" :field="col.field" format="n2" v-bind:key="col.field" textAlign="left"></e-column>
          </e-columns>
        </e-aggregate>
      </e-aggregates>
    </ejs-grid>
    <v-btn color="accent"
           fab
           fixed
           right
           bottom
           @click.stop="savePlanning()">
      <v-icon dark>mdi-content-save</v-icon>
    </v-btn>
    <v-overlay :value="loader"
               :z-index="1000">
      <v-progress-circular indeterminate
                           size="64"></v-progress-circular>
    </v-overlay>
    <ConfirmDialog
      :dialog="deleteDialog"
      :description="deleteDialogDescription"
      @change="setDialogState"
      @confirmDelete="confirmDeleteAction"
    />
  </v-container>
</template>

<script>
import Vue from 'vue';
import {
  GridPlugin, Page, Sort, Edit, Resize, Filter, ForeignKey, Search, ExcelExport, CommandColumn, Aggregate,
} from '@syncfusion/ej2-vue-grids';
import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { mapGetters, mapState } from 'vuex';
import {
  map,
  flatMap,
  sumBy,
  toNumber,
} from 'lodash';
import { createElement } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import Breadcrumbs from '../components/Breadcrumbs';
import ConfirmDialog from '../components/ConfirmDialog';
import api from '../api/lbt-api';
import store from '../store/index';
import { generateFinancingVolumesPeriods } from '../utilities/salesFinancingVolumes';
import { generateInterestRatePeriods } from '../utilities/interestRatePeriods';
import { parseVolume } from '../utilities/parseVolume';
import { validatePlanningRows } from '../utilities/validatePlanningRows';

// TODO: Refactor destructing
const {
  createSalesForPlanning,
  getSalesForPlanning,
  updateSalesForPlanning,
  deleteSalesForPlanning,
  getPlanning,
  salesRecalculate,
} = api;

Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);

export default {
  name: 'SalesPlanningNew',
  components: {
    Breadcrumbs,
    ConfirmDialog,
  },
  async created() {
    this.loader = true;
    try {
      await this.fetchPlanningSalesItems();
      const planning = await getPlanning(this.$route.params.planningId);
      this.planningInfo = planning;
      this.financingVolumesPeriods = generateFinancingVolumesPeriods(planning.year, planning.month);
      this.interestRatePeriods = generateInterestRatePeriods(planning.year, planning.month);
      this.totalInterestRatePeriods = generateInterestRatePeriods(planning.year, planning.month);
      this.ponderForAveragePeriods = generateInterestRatePeriods(planning.year, planning.month);
      if (this.planningInfo.locked === 1) {
        this.editSettings.allowEditing = false;
        this.editSettings.allowAdding = false;
        this.editSettings.allowDeleting = false;
      }
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.dataLoaded = true;
      this.loader = false;
    }
    this.financingVolumesPeriods.forEach((el) => {
      el.periods.forEach((data) => {
        this.financingVolumeHeaders.push(`${el.year}${data.period}`);
      });
      this.financingVolumeHeaders.push('FY');
    });
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
    ...mapGetters('options', [
      'getOptionCategory',
    ]),
    ...mapState('plannings', [
      'activePlanning',
    ]),
    flattenedVolumePeriods() {
      return flatMap(this.financingVolumes, volume => volume.periods);
    },
    flattenedTicPeriods() {
      return flatMap(map(this.item.total_investment_costs), volume => volume.periods);
    },
    financingVolumeColumnsComputed() {
      const data = [];
      for (let i = 0; i < this.financingVolumeColumns.length; i += 1) {
        if (this.financingVolumeColumns[i].showFooterSum) {
          data.push(this.financingVolumeColumns[i]);
        }
      }
      return data;
    },
    ticPeriodsColumnsComputed() {
      const data = [];
      for (let i = 0; i < this.ticPeriodsColumns.length; i += 1) {
        if (this.ticPeriodsColumns[i].showFooterSum) {
          data.push(this.ticPeriodsColumns[i]);
        }
      }
      return data;
    },
    ponderForAveragesColumnsComputed() {
      const data = [];
      for (let i = 0; i < this.ponderForAveragesColumns.length; i += 1) {
        if (this.ponderForAveragesColumns[i].showFooterSum) {
          data.push(this.ponderForAveragesColumns[i]);
        }
      }
      return data;
    },
  },
  watch: {
    search(searchText) {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      if (searchText !== null && searchText.length > 1) {
        grid.search(searchText);
      } else {
        grid.search();
      }
    },
  },
  data() {
    return {
      dataLoaded: false,
      search: '',
      notificationText: '',
      notificationType: '',
      loader: false,
      itemForDeletion: null,
      deleteDialog: false,
      deleteDialogDescription: '',
      financingVolumesPeriods: [],
      interestRatePeriods: [],
      ponderForAveragePeriods: [],
      totalInterestRatePeriods: [],
      headers: [],
      items: null,
      plid: 0,
      pagination: {
        page: 1,
        size: 1000,
        total: 0,
        itemsPerPage: [100, 200, 300],
      },
      planningInfo: {},
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'breadcrumbs_plannings_sales',
          disabled: true,
        },
      ],
      json_fields: {
      },
      json_data: [
        {
          leasing_type_id: 255,
          product_group_id: 23,
        },
      ],
      excelData: [],
      leasingTypeValues: [],
      segmentValues: [],
      clientTypeValues: [],
      productGroupValues: [],
      channelValues: [],
      currencyValues: [],
      paymentAtOnceValues: [],
      typeValues: [],
      repaymentValues: [],
      interestRateTypeValue: [],
      referenceRateValues: [],
      accrualValues: [],
      financingVolumesValues: {},
      interestRateValues: {},
      ticVolumes: {},
      totalInterestRatesValues: {},
      ponderForAverageValues: {},
      editSettings: {
        allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch',
      },
      sortOptions: { columns: [{ field: 'tempId', direction: 'Ascending' }, { field: 'id', direction: 'Descending' }] },
      selectionOptions: { mode: 'Cell', type: 'Multiple', cellSelectionMode: 'Box' },
      searchOptions: { fields: ['product_name'], operator: 'contains', ignoreCase: true },
      requiredValidationRule: { required: true },
      pageSettings: { pageSizes: true, pageSize: 100 },
      leasingTypeParams: this.initDropdownParams(),
      segmentTypeParams: this.initDropdownParams(),
      customerTypeParams: this.initDropdownParams(),
      productGroupTypeParams: this.initDropdownParams(),
      channelTypeParams: this.initDropdownParams(),
      currencyTypeParams: this.initDropdownParams(),
      paymentAtOnceTypeParams: this.initDropdownParams(),
      typeTypeParams: this.initDropdownParams(),
      repaymentTypeParams: this.initDropdownParams(),
      interestRateTypeParams: this.initDropdownParams(),
      referenceRateTypeParams: this.initDropdownParams(),
      firstRentAccrualTypeParams: this.initDropdownParams(),
      upFrontFeeAccrualTypeParams: this.initDropdownParams(),
      subventionAccrualTypeParams: this.initDropdownParams(),
      financingVolumeHeaders: [],
      percentageTypeParams: {
        params: {
          min: 0,
          max: 100,
          step: 0.01,
        },
      },
      highlightCellAttributes: { class: 'highlight-cell' },
      financingVolumeColumns: [],
      interestRatesColumns: [],
      ticPeriodsColumns: [],
      totalInterestRatesColumns: [],
      ponderForAveragesColumns: [],
      deleteActionCommand: [{ buttonOption: { cssClass: 'v-icon notranslate v-icon--link mdi mdi-delete theme--light delete-action' } }],
      filterDropdown: {
        ui: {
          create: (args) => {
            const flValInput = createElement('input', { className: 'flm-input' });
            args.target.appendChild(flValInput);
            this.dropInstances[args.column.field] = new DropDownList({
              dataSource: this.dropInstances[args.column.field].dataSource,
              fields: this.dropInstances[args.column.field].fields,
              placeholder: 'Select a value',
              popupHeight: '240px',
            });
            this.dropInstances[args.column.field].appendTo(flValInput);
          },
          write: (args) => {
            this.dropInstances[args.column.field].text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, this.dropInstances[args.column.field].value);
          },
        },
      },
      dropInstances: {
        leasing_type_id: {
          dataSource: [],
          dataAccessor: 'leasingTypeValues',
          fields: { text: 'option', value: 'id' },
        },
        segment_id: {
          dataSource: [],
          dataAccessor: 'segmentValues',
          fields: { text: 'option', value: 'id' },
        },
        customer_id: {
          dataSource: [],
          dataAccessor: 'clientTypeValues',
          fields: { text: 'option', value: 'id' },
        },
        product_group_id: {
          dataSource: [],
          dataAccessor: 'productGroupValues',
          fields: { text: 'option', value: 'id' },
        },
        channel_id: {
          dataSource: [],
          dataAccessor: 'channelValues',
          fields: { text: 'option', value: 'id' },
        },
        currency_id: {
          dataSource: [],
          dataAccessor: 'currencyValues',
          fields: { text: 'option', value: 'id' },
        },
        payment_at_once_id: {
          dataSource: [],
          dataAccessor: 'paymentAtOnceValues',
          fields: { text: 'option', value: 'id' },
        },
        type_id: {
          dataSource: [],
          dataAccessor: 'typeValues',
          fields: { text: 'option', value: 'id' },
        },
        repayment_id: {
          dataSource: [],
          dataAccessor: 'repaymentValues',
          fields: { text: 'option', value: 'id' },
        },
        interest_rate_type_id: {
          dataSource: [],
          dataAccessor: 'interestRateTypeValue',
          fields: { text: 'option', value: 'id' },
        },
        reference_rate_id: {
          dataSource: [],
          dataAccessor: 'referenceRateValues',
          fields: { text: 'option', value: 'id' },
        },
        first_rent_accrual_id: {
          dataSource: [],
          dataAccessor: 'accrualValues',
          fields: { text: 'option', value: 'id' },
        },
        up_front_fee_accrual_id: {
          dataSource: [],
          dataAccessor: 'accrualValues',
          fields: { text: 'option', value: 'id' },
        },
        subvention_accrual_id: {
          dataSource: [],
          dataAccessor: 'accrualValues',
          fields: { text: 'option', value: 'id' },
        },
      },
    };
  },
  provide: {
    grid: [Page, Sort, Edit, Resize, Search, Filter, ForeignKey, ExcelExport, CommandColumn, Aggregate],
  },
  methods: {
    load() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      grid.element.addEventListener('mousedown', (e) => {
        if (e.target.classList.contains('e-rowcell')) {
          const index = Number(e.target.getAttribute('Index'));
          const colindex = Number(e.target.getAttribute('aria-colindex'));
          const { field } = grid.getColumns()[colindex];
          grid.editModule.editCell(index, field);
        }
      });
    },
    financingVolumeValueAccessor(column, item) {
      const colName = column.split('.');
      return `${item.financing_volumes[colName[1]].periods[colName[3]].value}%`;
    },
    interestRateValueAccessor(column, item) {
      const colName = column.split('.');
      return `${item.interest_rates[colName[1]].value}%`;
    },
    totalInterestRateValueAccessor(column, item) {
      const colName = column.split('.');
      return `${item.total_interest_rates[colName[1]].value}%`;
    },
    lookupFinancingVolumesPeriodHeader(idx) {
      return this.financingVolumeHeaders[Number(idx)];
    },
    lookupInterestRateHeader(idx) {
      return this.interestRatePeriods[Number(idx)].period;
    },
    lookupPonderForAveragesHeader(idx) {
      return this.ponderForAveragePeriods[Number(idx)].period;
    },
    initDropdownParams() {
      return {
        params: {
          dataSource: null,
          fields: { text: 'option', value: 'id' },
          query: new Query(),
        },
      };
    },
    async fetchPlanningSalesItems() {
      const sales = await getSalesForPlanning(this.$route.params.planningId, this.pagination);
      this.setDropdownDatasources();
      this.items = sales.sales_entries;
      for (let i = 0; i < this.items.length; i += 1) {
        for (let j = 2; j < this.items[i].financing_volumes.length; j += 1) {
          for (let k = 0; k < this.items[i].financing_volumes[j].periods.length; k += 1) {
            this.items[i].financing_volumes[j].periods[k].value = (this.items[i].financing_volumes[j].periods[k].value * 100).toFixed(2);
          }
        }
        for (let j = 0; j < this.items[i].interest_rates.length; j += 1) {
          this.items[i].interest_rates[j].value = (this.items[i].interest_rates[j].value * 100).toFixed(2);
        }
        for (let j = 0; j < this.items[i].total_interest_rates.length; j += 1) {
          this.items[i].total_interest_rates[j].value = (this.items[i].total_interest_rates[j].value * 100).toFixed(2);
        }
        this.items[i].down_payment = (this.items[i].down_payment * 100).toFixed(2);
        this.items[i].guarantee = (this.items[i].guarantee * 100).toFixed(2);
        this.items[i].residual_value = (this.items[i].residual_value * 100).toFixed(2);
        this.items[i].dep_value = (this.items[i].dep_value * 100).toFixed(2);
        this.items[i].up_front_fee = (this.items[i].up_front_fee * 100).toFixed(2);
        this.items[i].ppmv = (this.items[i].ppmv * 100).toFixed(2);
      }
      this.pagination.total = sales.pagination.total;
      this.leasingTypeValues = this.getOptionCategory('LEASING_TYPE').options;
      this.segmentValues = this.getOptionCategory('SEGMENT').options;
      this.clientTypeValues = this.getOptionCategory('CLIENT_TYPE').options;
      this.productGroupValues = this.getOptionCategory('PRODUCT_GROUP').options;
      this.channelValues = this.getOptionCategory('CHANNEL').options;
      this.currencyValues = this.getOptionCategory('CURRENCY').options;
      this.paymentAtOnceValues = this.getOptionCategory('PAYMENT_AT_ONCE').options;
      this.typeValues = this.getOptionCategory('TYPE').options;
      this.repaymentValues = this.getOptionCategory('REPAYMENT_TYPE').options;
      this.interestRateTypeValue = this.getOptionCategory('INTEREST_RATE_TYPE').options;
      this.referenceRateValues = this.getOptionCategory('REFERENCE_RATE').options;
      this.accrualValues = this.getOptionCategory('ACCRUAL_METHOD').options;
      // this.setExcelColumnTitles();
      // this.setExcelFieldValues();
      this.prepareFinancingVolumesGridHeaders();
      this.prepareInterestRatesGridHeaders();
      this.prepareTicPeriodsGridHeaders();
      this.prepareTotalInterestRatesGridHeaders();
      this.preparePonderForAveragesGridHeaders();
      this.setFilterDropdowns();
    },
    setFilterDropdowns() {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.dropInstances) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.dropInstances.hasOwnProperty(key)) {
          this.dropInstances[key].dataSource = this[this.dropInstances[key].dataAccessor];
        }
      }
    },
    setDropdownDatasources() {
      this.leasingTypeParams.params.dataSource = this.getOptionCategory('LEASING_TYPE').options;
      this.segmentTypeParams.params.dataSource = this.getOptionCategory('SEGMENT').options;
      this.customerTypeParams.params.dataSource = this.getOptionCategory('CLIENT_TYPE').options;
      this.productGroupTypeParams.params.dataSource = this.getOptionCategory('PRODUCT_GROUP').options;
      this.channelTypeParams.params.dataSource = this.getOptionCategory('CHANNEL').options;
      this.currencyTypeParams.params.dataSource = this.getOptionCategory('CURRENCY').options;
      this.paymentAtOnceTypeParams.params.dataSource = this.getOptionCategory('PAYMENT_AT_ONCE').options;
      this.typeTypeParams.params.dataSource = this.getOptionCategory('TYPE').options;
      this.repaymentTypeParams.params.dataSource = this.getOptionCategory('REPAYMENT_TYPE').options;
      this.interestRateTypeParams.params.dataSource = this.getOptionCategory('INTEREST_RATE_TYPE').options;
      this.referenceRateTypeParams.params.dataSource = this.getOptionCategory('REFERENCE_RATE').options;
      this.firstRentAccrualTypeParams.params.dataSource = this.getOptionCategory('ACCRUAL_METHOD').options;
      this.upFrontFeeAccrualTypeParams.params.dataSource = this.getOptionCategory('ACCRUAL_METHOD').options;
      this.subventionAccrualTypeParams.params.dataSource = this.getOptionCategory('ACCRUAL_METHOD').options;
    },
    prepareFinancingVolumesGridHeaders() {
      this.financingVolumeColumns = [];
      const data = flatMap(map(this.items[0].financing_volumes, parseVolume), volume => volume.periods);
      let frOffset = 0;
      data.forEach((period, idx) => {
        if (period.showInPercentage === true) {
          this.financingVolumeColumns.push({
            field: `financing_volumes.${period.volumeIndex}.periods.${period.periodIndex}.value`,
            headerText: idx + frOffset,
            headerValueAccessor: this.lookupFinancingVolumesPeriodHeader,
            customAttributes: this.highlightCellAttributes,
            width: 70,
            editType: 'numericedit',
            textAlign: 'right',
            showFooterSum: false,
            valueAccessor: this.financingVolumeValueAccessor,
            edit: this.percentageTypeParams,
            allowSorting: false,
            allowFiltering: false,
          });
        } else {
          this.financingVolumeColumns.push({
            type: 'number',
            field: `financing_volumes.${period.volumeIndex}.periods.${period.periodIndex}.value`,
            headerText: idx + frOffset,
            headerValueAccessor: this.lookupFinancingVolumesPeriodHeader,
            customAttributes: this.highlightCellAttributes,
            width: 70,
            format: 'n2',
            textAlign: 'right',
            showFooterSum: true,
            editType: 'numericedit',
            allowSorting: false,
            allowFiltering: false,
          });
        }
        if (period.isLast) {
          frOffset += 1;
          this.financingVolumeColumns.push({
            field: `financing_volumes.${period.volumeIndex}.fy`,
            headerText: idx + frOffset,
            headerValueAccessor: this.lookupFinancingVolumesPeriodHeader,
            customAttributes: this.highlightCellAttributes,
            width: 70,
            type: 'number',
            format: 'n2',
            textAlign: 'right',
            showFooterSum: true,
            editType: 'numericedit',
            allowSorting: false,
            allowFiltering: false,
          });
        }
      });
    },
    prepareInterestRatesGridHeaders() {
      this.interestRatesColumns = [];
      const item = this.items[0];
      for (let j = 0; j < item.interest_rates.length; j += 1) {
        this.interestRatesColumns.push({
          field: `interest_rates.${j}.value`,
          headerText: j,
          headerValueAccessor: this.lookupInterestRateHeader,
          valueAccessor: this.interestRateValueAccessor,
          width: 70,
          editType: 'numericedit',
          textAlign: 'right',
          edit: this.percentageTypeParams,
          allowSorting: false,
          allowFiltering: false,
        });
      }
    },
    prepareTicPeriodsGridHeaders() {
      this.ticPeriodsColumns = [];
      const data = flatMap(map(this.items[0].total_investment_costs, parseVolume), volume => volume.periods);
      let frOffset = 0;
      data.forEach((period, idx) => {
        this.ticPeriodsColumns.push({
          type: 'number',
          field: `total_investment_costs.${period.volumeIndex}.periods.${period.periodIndex}.value`,
          headerText: idx + frOffset,
          headerValueAccessor: this.lookupFinancingVolumesPeriodHeader,
          customAttributes: this.highlightCellAttributes,
          width: 70,
          format: 'n2',
          textAlign: 'right',
          showFooterSum: true,
          allowEditing: false,
          allowSorting: false,
          allowFiltering: false,
        });
        if (period.isLast) {
          frOffset += 1;
          this.ticPeriodsColumns.push({
            type: 'number',
            field: `total_investment_costs.${period.volumeIndex}.fy`,
            headerText: idx + frOffset,
            headerValueAccessor: this.lookupFinancingVolumesPeriodHeader,
            customAttributes: this.highlightCellAttributes,
            width: 70,
            format: 'n2',
            textAlign: 'right',
            showFooterSum: true,
            editType: 'numericedit',
            allowEditing: true,
            allowSorting: false,
            allowFiltering: false,
          });
        }
      });
    },
    prepareTotalInterestRatesGridHeaders() {
      this.totalInterestRatesColumns = [];
      const item = this.items[0];
      for (let j = 0; j < item.total_interest_rates.length; j += 1) {
        this.totalInterestRatesColumns.push({
          field: `total_interest_rates.${j}.value`,
          headerText: j,
          headerValueAccessor: this.lookupInterestRateHeader,
          valueAccessor: this.totalInterestRateValueAccessor,
          width: 70,
          textAlign: 'right',
          allowEditing: item.total_interest_rates[j].fyEditable,
          allowSorting: false,
          allowFiltering: false,
        });
      }
    },
    preparePonderForAveragesGridHeaders() {
      this.ponderForAveragesColumns = [];
      const item = this.items[0];
      for (let j = 0; j < item.ponder_for_averages.length; j += 1) {
        this.ponderForAveragesColumns.push({
          type: 'number',
          field: `ponder_for_averages.${j}.value`,
          headerText: j,
          headerValueAccessor: this.lookupPonderForAveragesHeader,
          customAttributes: this.highlightCellAttributes,
          width: 70,
          format: 'n2',
          textAlign: 'right',
          showFooterSum: true,
          allowEditing: item.ponder_for_averages[j].fyEditable,
          allowSorting: false,
          allowFiltering: false,
        });
      }
    },
    dropdownValueAccess(field, data) {
      return this.fetchPlaningsSalesItemValues(field, data[field]);
    },
    financialValueAccess(field, data) {
      const path = field.split('.');
      const period = data[path[0]][path[1]][path[2]][path[3]];
      if (period.showInPercentage === true) {
        return `${this.$options.filters.toPercentage(period[path[4]])} %`;
      }
      return period[path[4]];
    },
    excelExportPreprocessing(item) {
      const tempItem = item;
      if (tempItem.value.toString().slice(-1) === '%') {
        const val = tempItem.value.toString();
        tempItem.value = parseFloat(val.substr(0, val.length - 1)) / 100;
      }
      return tempItem;
    },
    excelDownloadClick() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const excelExportProperties = {
        // exportType: 'CurrentPage',
        fileName: `Sales - Planning - ${this.activePlanning.year}-${this.activePlanning.month}.xlsx`,
      };
      grid.excelExport(excelExportProperties);
    },
    excelHeader(args) {
      const col = args.gridCell.column;
      if (col.field !== undefined && col.field.includes('.')) {
        const { cell } = args;
        if (col.field.startsWith('financing')) {
          cell.value = this.lookupFinancingVolumesPeriodHeader(cell.value);

          const parts = col.field.split('.');
          if (parseFloat(parts[1]) >= 2 && parts[2] !== 'fy') {
            col.format = 'p2';
          }
        } else if (col.field.startsWith('total_investment')) {
          cell.value = this.lookupFinancingVolumesPeriodHeader(cell.value);
        } else if (col.field.startsWith('interest') || col.field.startsWith('total_interest')) {
          cell.value = this.lookupInterestRateHeader(cell.value);
          col.format = 'p2';
        } else if (col.field.startsWith('ponder')) {
          cell.value = this.lookupPonderForAveragesHeader(cell.value);
        }
      }
      if (col.field === 'down_payment' || col.field === 'dep_value' || col.field === 'guarantee' || col.field === 'residual_value' || col.field === 'up_front_fee' || col.field === 'ppmv') {
        col.format = 'p2';
      }
    },
    customiseCell(queryCellInfo) {
      const { cell } = queryCellInfo;
      if (cell.textContent === 'NaN') {
        cell.classList.add('error');
      }
    },
    commandClick(args) {
      this.deleteItem(args.rowData);
    },
    cellSave(args) {
      let volume;
      const colName = args.columnName.split('.');
      const { rowData } = args;
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const parentRow = args.cell.closest('tr');
      let fy = ''; // default message - if needed
      switch (colName[0]) {
        case 'financing_volumes':
          if (colName[2] === 'fy') break;

          volume = rowData.financing_volumes[colName[1]];
          for (let i = 0; i < grid.dataSource.length; i += 1) {
            if (grid.dataSource[i].id === rowData.id) {
              fy = sumBy(volume.periods, volumePeriod => toNumber(volumePeriod.value));
              if (colName[1] < 2) {
                grid.dataSource[i].financing_volumes[colName[1]].periods[colName[3]].value = args.value;
                grid.dataSource[i].financing_volumes[colName[1]].fy = fy;
                break;
              }
            }
          }


          for (let i = args.cell.cellIndex; i < parentRow.children.length; i += 1) {
            const colNameIn = grid.columnModel[i].field.split('.');
            if (colNameIn[0] === 'financing_volumes' && colNameIn[2] === 'fy') {
              if (colName[1] < 2) {
                parentRow.children[i].innerHTML = fy;
                break;
              }
            }
          }
          break;
        default:
          break;
      }

      if (args.value !== '') {
        const { cell } = args;
        cell.classList.add('e-updatedtd');
        cell.classList.remove('error');
      }
    },
    setExcelColumnTitles() {
      const columnTitles = this.headersForExcel;
      columnTitles.forEach((title) => {
        this.json_fields[title.text] = title.value;
      });
    },
    setExcelFieldValues() {
      const final = [];
      let finalObject = {};
      const itemsForExcel = this.items;
      const excelRows = Object.keys(itemsForExcel).length;
      for (let i = 0; i < excelRows; i += 1) {
        const singleObj = {};
        let withFinancVolumes = {};
        let withIRVolumes = {};
        let withTICVolumes = {};
        let withTIRVolumes = {};
        let withPFAVolumes = {};
        const rowKeys = Object.keys(itemsForExcel[i]);
        const rowValues = Object.values(itemsForExcel[i]);
        for (let j = 0; j < rowKeys.length; j += 1) {
          singleObj[rowKeys[j]] = this.fetchPlaningsSalesItemValues(rowKeys[j], rowValues[j]);
        }
        withFinancVolumes = this.financingVolumesValues;
        withIRVolumes = this.interestRateValues;
        withTICVolumes = this.ticVolumes;
        withTIRVolumes = this.totalInterestRatesValues;
        withPFAVolumes = this.ponderForAverageValues;
        finalObject = {
          ...singleObj,
          ...withFinancVolumes,
          ...withIRVolumes,
          ...withTICVolumes,
          ...withTIRVolumes,
          ...withPFAVolumes,
        };
        final.push(finalObject);
      }
      this.json_data = final;
    },
    fetchPlaningsSalesItemValues(key, value) {
      let fetchedValue = '';
      switch (key) {
        case 'leasing_type_id':
          fetchedValue = this.getLeasingTypeValue(value);
          break;
        case 'segment_id':
          fetchedValue = this.getSegmentValue(value);
          break;
        case 'customer_id':
          fetchedValue = this.getCustomerValue(value);
          break;
        case 'product_group_id':
          fetchedValue = this.getProductGroupValue(value);
          break;
        case 'product_name':
          fetchedValue = value;
          break;
        case 'channel_id':
          fetchedValue = this.getChannelValue(value);
          break;
        case 'currency_id':
          fetchedValue = this.getCurrencyValue(value);
          break;
        case 'maturity':
          fetchedValue = value;
          break;
        case 'dep_period':
          fetchedValue = value;
          break;
        case 'payment_at_once_id':
          fetchedValue = this.getPaymetAtOnceValue(value);
          break;
        case 'type_id':
          fetchedValue = this.getTypeValue(value);
          break;
        case 'repayment_id':
          fetchedValue = this.getRepaymentValue(value);
          break;
        case 'interest_rate_type_id':
          fetchedValue = this.getInterestTypeValue(value);
          break;
        case 'reference_rate_id':
          fetchedValue = this.getReferenceRateValue(value);
          break;
        case 'down_payment':
          fetchedValue = `${value * 100} %`;
          break;
        case 'first_rent_accrual_id': case 'up_front_fee_accrual_id': case 'subvention_accrual_id':
          fetchedValue = this.getRirstRentalAccuralValue(value);
          break;
        case 'dep_value':
          fetchedValue = `${value * 100} %`;
          break;
        case 'guarantee':
          fetchedValue = `${value * 100} %`;
          break;
        case 'residual_value':
          fetchedValue = `${value * 100} %`;
          break;
        case 'up_front_fee':
          fetchedValue = `${value * 100} %`;
          break;
        case 'intercalary_days':
          fetchedValue = value;
          break;
        case 'subvention':
          fetchedValue = value;
          break;
        case 'ppmv':
          fetchedValue = `${value * 100} %`;
          break;
        case 'financing_volumes':
          this.getFinancingVolumesValue(value);
          break;
        case 'interest_rates':
          this.getInterestRatesValue(value);
          break;
        case 'total_investment_costs':
          this.getTotalInvestmentCostValue(value);
          break;
        case 'total_interest_rates':
          this.getTotalInterestRatesValue(value);
          break;
        case 'ponder_for_averages':
          this.getPonderForAveragesValue(value);
          break;
        default:
          break;
      }
      return fetchedValue;
    },
    percentageFieldAccessorValue(column, item) {
      return item[column] !== 'NaN' ? `${item[column]}%` : '';
    },
    getPonderForAveragesValue(value) {
      this.ponderForAverageValues = {};
      const singleObj = {};
      value.forEach((item) => {
        const jsonTitle = item.period;
        this.json_fields[`PFA-${jsonTitle}`] = `PFA-${jsonTitle}`;
        singleObj[`PFA-${jsonTitle}`] = item.value;
      });
      this.ponderForAverageValues = singleObj;
    },
    getTotalInterestRatesValue(value) {
      this.totalInterestRatesValues = {};
      const singleObj = {};
      value.forEach((item) => {
        const jsonTitle = item.period;
        this.json_fields[`TIR-${jsonTitle}`] = `TIR-${jsonTitle}`;
        singleObj[`TIR-${jsonTitle}`] = (item.value).toFixed(3);
      });
      this.totalInterestRatesValues = singleObj;
    },
    getTotalInvestmentCostValue(value) {
      this.ticVolumes = {};
      const singleObj = {};
      value.forEach((item) => {
        item.periods.forEach((innerItem) => {
          const jsonTitle = item.year + innerItem.period;
          this.json_fields[`TIC-${jsonTitle}`] = `TIC-${jsonTitle}`;
          singleObj[`TIC-${jsonTitle}`] = innerItem.value;
        });
      });
      this.ticVolumes = singleObj;
    },
    getInterestRatesValue(value) {
      this.interestRateValues = {};
      const singleObj = {};
      value.forEach((item) => {
        const jsonTitle = item.period;
        this.json_fields[`IR-${jsonTitle}`] = `IR-${jsonTitle}`;
        singleObj[`IR-${jsonTitle}`] = `${(item.value * 100).toFixed(2)} %`;
      });
      this.interestRateValues = singleObj;
    },
    getFinancingVolumesValue(value) {
      this.financingVolumesValues = {};
      const singleObj = {};
      let fyValue = '';
      value.forEach((item) => {
        fyValue = item.fy;
        item.periods.forEach((innerItem) => {
          const jsonTitle = item.year + innerItem.period;
          this.json_fields[`FV-${jsonTitle}`] = `FV-${jsonTitle}`;
          singleObj[`FV-${jsonTitle}`] = innerItem.value;
        });
        singleObj[`FV-${item.year}-FY`] = fyValue;
        this.json_fields[`FV-${item.year}-FY`] = `FV-${item.year}-FY`;
      });
      this.financingVolumesValues = singleObj;
    },
    getLeasingTypeValue(value) {
      let fetchedValue = '';
      this.leasingTypeValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getSegmentValue(value) {
      let fetchedValue = '';
      this.segmentValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getCustomerValue(value) {
      let fetchedValue = '';
      this.clientTypeValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getProductGroupValue(value) {
      let fetchedValue = '';
      this.productGroupValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getChannelValue(value) {
      let fetchedValue = '';
      this.channelValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getCurrencyValue(value) {
      let fetchedValue = '';
      this.currencyValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getPaymetAtOnceValue(value) {
      let fetchedValue = '';
      this.paymentAtOnceValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getTypeValue(value) {
      let fetchedValue = '';
      this.typeValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getRepaymentValue(value) {
      let fetchedValue = '';
      this.repaymentValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getInterestTypeValue(value) {
      let fetchedValue = '';
      this.interestRateTypeValue.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getReferenceRateValue(value) {
      let fetchedValue = '';
      this.referenceRateValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getRirstRentalAccuralValue(value) {
      let fetchedValue = '';
      this.accrualValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    updatePagination(page) {
      this.pagination.page = page;
      this.fetchPlanningSalesItems(this.pagination);
    },
    addNewPlanningItem() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const [...addedRecords] = grid.getBatchChanges().addedRecords;
      const addedRecordsSize = addedRecords ? addedRecords.length : 0;
      grid.addRecord({
        tempId: Math.random(),
        id: this.items.length + addedRecordsSize + 1,
        leasing_type_id: 0,
        segment_id: 0,
        customer_id: 9,
        product_group_id: 0,
        product_name: '',
        channel_id: 7,
        currency_id: 0,
        maturity: 48,
        dep_period: 60,
        payment_at_once_id: 0,
        type_id: 0,
        repayment_id: 0,
        interest_rate_type_id: 0,
        reference_rate_id: 0,
        down_payment: 0,
        first_rent_accrual_id: 0,
        guarantee: 0,
        residual_value: 0,
        dep_value: 0,
        up_front_fee: 0,
        up_front_fee_accrual_id: 0,
        intercalary_days: 0,
        subvention: 0,
        subvention_accrual_id: 0,
        ppmv: 0,
        financing_volumes: generateFinancingVolumesPeriods(this.planningInfo.year, this.planningInfo.month),
        interest_rates: generateInterestRatePeriods(this.planningInfo.year, this.planningInfo.month),
        total_investment_costs: generateFinancingVolumesPeriods(this.planningInfo.year, this.planningInfo.month),
        total_interest_rates: generateInterestRatePeriods(this.planningInfo.year, this.planningInfo.month),
        ponder_for_averages: generateInterestRatePeriods(this.planningInfo.year, this.planningInfo.month),
      });
    },
    validate() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const [...addedRecords] = grid.getBatchChanges().addedRecords;
      const [...changedRecords] = grid.getBatchChanges().changedRecords;
      if (validatePlanningRows(grid, addedRecords)
        && validatePlanningRows(grid, changedRecords)) {
        return true;
      }
      return false;
    },
    divideBy100(records) {
      const data = records;
      for (let i = 0; i < data.length; i += 1) {
        for (let j = 0; j < data[i].financing_volumes.length; j += 1) {
          let fyAgain = 0; // Jbg nešto zeza, moram ovako.
          for (let k = 0; k < data[i].financing_volumes[j].periods.length; k += 1) {
            if (j < 2) {
              fyAgain += data[i].financing_volumes[j].periods[k].value;
            } else {
              data[i].financing_volumes[j].periods[k].value /= 100;
            }
          }
          if (j < 2) {
            data[i].financing_volumes[j].fy = fyAgain;
          }
        }
        for (let j = 0; j < this.items[i].interest_rates.length; j += 1) {
          data[i].interest_rates[j].value /= 100;
        }
        for (let j = 0; j < this.items[i].total_interest_rates.length; j += 1) {
          data[i].total_interest_rates[j].value /= 100;
        }
        data[i].down_payment /= 100;
        data[i].guarantee /= 100;
        data[i].residual_value /= 100;
        data[i].dep_value /= 100;
        data[i].up_front_fee /= 100;
        data[i].ppmv /= 100;
      }
      return data;
    },
    async savePlanning() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      let [...addedRecords] = grid.getBatchChanges().addedRecords;
      let [...changedRecords] = grid.getBatchChanges().changedRecords;
      if (addedRecords === undefined && changedRecords === undefined) {
        return;
      }
      if (addedRecords) addedRecords = this.divideBy100(addedRecords);
      if (changedRecords) changedRecords = this.divideBy100(changedRecords);


      const rows = grid.getRows();
      if (!rows || rows.length === 0) {
        return;
      }

      if (!this.validate()) {
        return;
      }

      this.loader = true;
      this.plid = this.$route.params.planningId;
      const salesCreateRequests = [];
      const salesUpdateRequests = [];
      try {
        if (addedRecords !== undefined) {
          for (let i = 0; i < addedRecords.length; i += 1) {
            salesCreateRequests.push(createSalesForPlanning(this.plid, addedRecords[i]));
          }
        }
        if (changedRecords !== undefined) {
          for (let j = 0; j < changedRecords.length; j += 1) {
            salesUpdateRequests.push(updateSalesForPlanning(this.plid, changedRecords[j], changedRecords[j].id));
          }
        }
        await Promise.all(salesCreateRequests);
        await Promise.all(salesUpdateRequests);
        salesRecalculate(this.plid);
        this.notificationText = this.translate('notifications_planning_updated');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlanningSalesItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    deleteItem(item) {
      this.itemForDeletion = item;
      this.deleteDialog = true;
      this.deleteDialogDescription = this.translate('dialog_delete_action_description');
    },
    async confirmDeleteAction() {
      if (this.itemForDeletion.tempId) {
        let index = -1;
        for (let i = 0; i < this.items.length; i += 1) {
          if (this.items[i].id === this.itemForDeletion.id) {
            index = i;
            break;
          }
        }

        if (index !== -1) {
          this.$delete(this.items, index);
          this.itemForDeletion = null;
        } else {
          const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
          const [...addedRecords] = grid.getBatchChanges().addedRecords;
          for (let i = 0; i < addedRecords.length; i += 1) {
            if (addedRecords[i].tempId === this.itemForDeletion.tempId) {
              grid.deleteRecord('id', addedRecords[i]);
              const rowIdx = grid.getRowIndexByPrimaryKey(addedRecords[i].id);
              const row = grid.getRowByIndex(rowIdx);
              grid.deleteRow(row);
              break;
            }
          }
        }
        this.deleteDialog = false;
        return;
      }
      this.loader = true;
      try {
        await deleteSalesForPlanning(this.$route.params.planningId, this.itemForDeletion.id);
        this.deleteDialog = false;
        this.itemForDeletion = null;
        this.notificationText = this.translate('notifications_item_deleted_successfully');
        this.notificationType = 'success';
        await this.fetchPlanningSalesItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
      } finally {
        this.loader = false;
      }
    },
    setDialogState(value) {
      this.deleteDialog = value;
    },
  },
  async beforeRouteEnter(from, to, next) {
    await store.dispatch('options/getOptions');
    next();
  },
};
</script>

<style>
@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-icons/styles/material.css";

.container {
  padding-bottom: 5rem !important;
}

.e-grid.e-default .e-gridheader th.e-firstcell {
  border-left: none;
}

.e-dlg-container.e-dlg-center-center,
.e-dlg-container.e-dlg-center-center {
  display: none;
}

.e-grid .e-headercell.highlight-cell, .highlight-cell {
  background: #eeeeee;
}

.v-icon.v-icon.delete-action {
  font-size: 16px;
}

.e-summarycell {
  padding: 0 !important;
}
</style>
