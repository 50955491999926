<template>
  <v-container fluid class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>
    <v-toolbar flat>
      <v-btn color="primary" @click='excelDownloadClick'>
        Export to Excel
      </v-btn>
    </v-toolbar>
    <v-spacer></v-spacer>
    <ejs-treegrid :dataSource='items' v-if='items' childMapping='sub'  :allowResizing='true' :enableCollapseAll='false'
                  :pageSettings='pageSettings' :allowPaging='true'
                  :allowTextWrap="true" :treeColumnIndex='0' :allowExcelExport='true' :excelHeaderQueryCellInfo='excelHeader'
                  :allowFiltering='true' :filterSettings="{type: 'Menu'}">
        <e-columns>
            <e-column field='summary_pos' headerText='Summary - Position Mapping' width='240'></e-column>
            <e-column field='position' headerText='Position' width='190'></e-column>
            <e-column textAlign='center' :columns='valuesColumns'>
            </e-column>
        </e-columns>
    </ejs-treegrid>
    <br>
    <ejs-treegrid :dataSource='resultItems' childMapping='sub'
                  :treeColumnIndex='0' :height='auto'>
      <e-columns>
        <e-column field='description' headerText='Description' width='300'></e-column>
        <e-column textAlign='center' :columns='columnItems'></e-column>
      </e-columns>
    </ejs-treegrid>
    <Snackbar :text="notificationText"
      :type="notificationType" />
    <v-overlay :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate
        size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import {
  TreeGridPlugin, ExcelExport, Page, Resize, Filter,
} from '@syncfusion/ej2-vue-treegrid';
import Breadcrumbs from '@/components/Breadcrumbs';
import Snackbar from '@/components/Snackbar';
import api from '../api/lbt-api';

Vue.use(TreeGridPlugin);

const {
  getSummaryBSForPlanning,
} = api;

export default {
  name: 'SummaryPortfolio',
  components: {
    Breadcrumbs,
    Snackbar,
  },
  data() {
    return {
      notificationText: '',
      loader: false,
      notificationType: null,
      headers: [],
      items: [],
      expanded: [],
      resultItems: [],
      columnItems: [],
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'summary B&S',
          disabled: true,
        },
      ],
      valuesColumns: [],
      highlightCellAttributes: { class: 'highlight-cell' },
      pageSettings: { pageSizes: true, pageSize: 100 },
    };
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
    ...mapState('plannings', [
      'activePlanning',
    ]),
  },
  async created() {
    this.loader = true;
    try {
      const planning = await getSummaryBSForPlanning(this.$route.params.planningId);
      this.headers = planning.periods;
      this.items = planning.rows;
      this.prepareValuesGridHeaders();
      this.updateResultItems();
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
  },
  methods: {
    prepareValuesGridHeaders() {
      const item = this.items[0];
      for (let i = 0; i < item.values.length; i += 1) {
        this.valuesColumns.push({
          field: `values.${i}`,
          headerText: i,
          headerValueAccessor: this.lookupValuesHeader,
          customAttributes: this.highlightCellAttributes,
          width: 60,
          format: 'n2',
          textAlign: 'right',
          allowFiltering: false,
        });
      }
    },
    lookupValuesHeader(idx) {
      return this.headers[Number(idx)];
    },
    excelDownloadClick() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const excelExportProperties = {
        exportType: 'CurrentPage',
        fileName: `Summary BS - Planning - ${this.activePlanning.year}-${this.activePlanning.month}.xlsx`,
      };
      grid.excelExport(excelExportProperties);
    },
    excelHeader(args) {
      const { cell } = args;
      if (cell.index > 2) {
        cell.value = this.headers[cell.value];
      }
    },
    updateResultItems() {
      const row10 = this.items.length > 5 ? this.items[5].values : [];
      const row5 = this.items.length > 10 ? this.items[10].values : [];
      const differences = row10.map((value, index) => value - (row5[index] || 0));
      if (row10.length && row5.length) {
        // Prepare a single row with each index's difference
        this.resultItems = [{
          description: 'Aseets=Liabilities+Equity',
          ...differences.reduce((acc, diff, index) => ({ ...acc, [`diff${index}`]: diff }), {}),
        }];
      }
      this.columnItems = differences.map((_, index) => ({
        field: `diff${index}`,
        headerText: this.headers[Number(index)],
        width: 100,
        textAlign: 'Right',
        format: 'N2',
      }));
    },
  },
  provide: {
    treegrid: [ExcelExport, Page, Resize, Filter],
  },
};
</script>

<style>
  @import '../../node_modules/@syncfusion/ej2-vue-treegrid/styles/material.css';

  .e-grid .e-headercell.highlight-cell, .highlight-cell {
    background: #eeeeee;
  }
  .e-headercelldiv {
    text-align: center !important;
  }
</style>
