import api from '../../api/lbt-api';

const {
  getFinrepBsPl,
} = api;

export default {
  namespaced: true,
  state: {
    fetched: false,
    options: [],
  },
  mutations: {
    setOptionsFetchedFlag(state) {
      state.fetched = true;
    },
    setOptions(state, options) {
      state.options = options;
    },
  },
  actions: {
    async getOptions({ commit, state }) {
      if (!state.fetched) {
        const result = await getFinrepBsPl();
        commit('setOptions', result);
        commit('setOptionsFetchedFlag');
      }
      return state.options;
    },
  },
  getters: {
    getAllOptions: state => state.options,
  },
};
