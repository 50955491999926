<template>
  <v-container fluid class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>
    <v-data-table fixed-header
      class="elevation-1"
      dense
      :page.sync="pagination.page"
      :server-items-length="pagination.total || items.length"
      :headers="headers"
      :items="items"
      :footer-props="{
        'items-per-page-options': pagination.itemsPerPage
      }"
      :items-per-page="pagination.size"
      @update:page="updatePagination">
      <template v-slot:top>
        <v-toolbar flat>
           <v-col cols="12" sm="3"
            class="px-0">
            <v-text-field
              clearable
              v-model="search"
              append-icon="mdi-magnify"
              :label="translate('plannings_sales_search_items')"
              single-line
              hide-details
            ></v-text-field>
           </v-col>
          <v-spacer></v-spacer>
          <v-btn color="primary">
            <download-excel
            :data   = "json_data"
            :fields = "json_fields"
            name = "ZAME-Export.xls">
              Export to Excel
          </download-excel>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary"
              @click.stop="addNewFundingItem()">
            <v-icon class="mr-2">mdi-table-large-plus</v-icon>
            {{translate('new funding item')}}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:header="props">
        <thead>
          <tr>
            <th :colspan="headers.length
                          - numOfFinancingVolumeHeaders
                          - financingVolumesPeriods.length
                          - interestRatePeriods.length"
              class="text-center">
              {{translate('Funding items')}}
            </th>
            <th :colspan="numOfFinancingVolumeHeaders + financingVolumesPeriods.length"
              class="text-center financing-volumes">
              {{translate('plannings_sales_financing_volumes')}}
            </th>
            <th :colspan="interestRatePeriods.length"
              class="text-center interest-rate">
              {{translate('plannings_sales_interest_rate')}}
            </th>
          </tr>
          <tr>
            <th v-for="(header, idx) in props.headers"
              :key="idx">
              {{header.text}}
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:body="props">
        <tbody>
          <FundingInputRow v-for="(item, idx) in props.items"
            v-show="!search || (item.product_name.indexOf(search) !== -1)"
            :ref="`row-${idx}`"
            :key="item.id || item.tempId"
            :item="item"
            :placementPeriodValues="placementPeriodValues"
            :rowIndex="idx"
            @delete="deleteItem" />
          <tr v-if="!props.items.length"
            class="v-data-table__empty-wrapper">
            <td :colspan="headers.length">{{translate('No items')}}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-btn color="accent"
      fab
      fixed
      right
      bottom
      @click.stop="savePlanning()">
      <v-icon dark>mdi-content-save</v-icon>
    </v-btn>
    <Snackbar :text="notificationText"
      :type="notificationType" />
    <v-overlay :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate
        size="64"></v-progress-circular>
    </v-overlay>
    <ConfirmDialog
      :dialog="deleteDialog"
      :description="deleteDialogDescription"
      @change="setDialogState"
      @confirmDelete="confirmDeleteAction"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import Snackbar from '../components/Snackbar';
import Breadcrumbs from '../components/Breadcrumbs';
import FundingInputRow from '../components/FundingInputRow';
import api from '../api/lbt-api';
import store from '../store/index';
import ConfirmDialog from '../components/ConfirmDialog';
import { generateFinancingVolumesPeriods } from '../utilities/fundingFinancingVolumes';
import { generateInterestRatePeriods } from '../utilities/interestRatePeriods';
import { generatePlacementPeriodsCapex } from '../utilities/placementPeriod';

// TODO: Refactor destructing
const {
  createFundingForPlanning,
  getFundingForPlanning,
  updateFundingForPlanning,
  deleteFundingForPlanning,
  getPlanning,
  getLoanFunding,
} = api;

export default {
  name: 'FundingInput',
  components: {
    Snackbar,
    Breadcrumbs,
    FundingInputRow,
    ConfirmDialog,
  },
  async created() {
    this.headers = [
      {
        text: this.translate('Line'),
        align: 'left',
        sortable: false,
        value: 'line',
      },
      {
        text: this.translate('plannings_sales_currency'),
        align: 'left',
        sortable: false,
        value: 'currency_id',
      },
      {
        text: this.translate('plannings_sales_maturity'),
        align: 'left',
        sortable: false,
        value: 'maturity',
      },
      {
        text: this.translate('plannings_sales_repayment'),
        align: 'left',
        sortable: false,
        value: 'repayment_id',
      },
      {
        text: this.translate('plannings_sales_interest_rate_type'),
        align: 'left',
        sortable: false,
        value: 'interest_rate_type_id',
      },
      {
        text: this.translate('plannings_sales_reference_rate'),
        align: 'left',
        sortable: false,
        value: 'reference_rate_id',
      },
      {
        text: this.translate('Approval fee'),
        align: 'left',
        sortable: false,
        value: 'fee',
      },
      {
        text: this.translate('Approval fee accrual'),
        align: 'left',
        sortable: false,
        value: 'fee_accrual_id',
      },
      {
        text: this.translate('Repayment starts'),
        align: 'left',
        sortable: false,
        value: 'placement_period',
      },
      {
        text: this.translate('table_actions'),
        align: 'left',
        sortable: false,
        value: 'actions',
      },
    ];
    this.loader = true;
    try {
      await this.fetchPlanningFundingItems();
      const planning = await getPlanning(this.$route.params.planningId);
      this.planningInfo = planning;
      this.financingVolumesPeriods = generateFinancingVolumesPeriods(planning.year, planning.month, this.periodVals);
      this.interestRatePeriods = generateInterestRatePeriods(planning.year, planning.month);
      this.placementPeriodValues = generatePlacementPeriodsCapex(planning.year, planning.month);
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
    this.financingVolumesPeriods.forEach((el) => {
      el.periods.forEach((data) => {
        this.numOfFinancingVolumeHeaders += 1;
        this.headers.push({
          text: `${el.year}${data.period}`,
          align: 'left',
          class: 'financing-volumes',
          sortable: false,
          value: el,
        });
      });
      this.headers.push({
        text: 'FY',
        align: 'left',
        class: 'financing-volumes',
        sortable: false,
      });
    });
    this.interestRatePeriods.forEach((el) => {
      this.headers.push({
        text: el.period,
        align: 'left',
        class: 'interest-rate',
        sortable: false,
      });
    });
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
    ...mapGetters('options', [
      'getOptionCategory',
    ]),
  },
  data() {
    return {
      search: '',
      notificationText: '',
      notificationType: '',
      loader: false,
      itemForDeletion: null,
      deleteDialog: false,
      deleteDialogDescription: '',
      financingVolumesPeriods: [],
      interestRatePeriods: [],
      numOfFinancingVolumeHeaders: 0,
      headers: [],
      items: [],
      plid: 0,
      pagination: {
        page: 1,
        size: 20,
        total: 0,
        itemsPerPage: [20, 50, 100],
      },
      planningInfo: {},
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'plannings_funding',
          disabled: true,
        },
      ],
      json_fields: {
      },
      json_data: [
        {
          leasing_type_id: 255,
          product_group_id: 23,
        },
      ],
      excelData: [],
      leasingTypeValues: [],
      segmentValues: [],
      clientTypeValues: [],
      productGroupValues: [],
      channelValues: [],
      currencyValues: [],
      paymentAtOnceValues: [],
      typeValues: [],
      repaymentValues: [],
      interestRateTypeValue: [],
      referenceRateValues: [],
      accrualValues: [],
      financingVolumesValues: {},
      interestRateValues: {},
      placementPeriodValues: [],
      periodVals: [],
    };
  },
  methods: {
    async fetchPlanningFundingItems() {
      const funding = await getFundingForPlanning(this.$route.params.planningId, this.pagination);
      this.periodVals = await getLoanFunding(this.$route.params.planningId);
      this.items = funding.funding_entries;
      this.pagination.total = funding.pagination.total;
      this.currencyValues = this.getOptionCategory('CURRENCY').options;
      this.repaymentValues = this.getOptionCategory('REPAYMENT_TYPE').options;
      this.interestRateTypeValue = this.getOptionCategory('INTEREST_RATE_TYPE').options;
      this.referenceRateValues = this.getOptionCategory('REFERENCE_RATE').options;
      this.accrualValues = this.getOptionCategory('ACCRUAL_METHOD').options;
      this.setExcelColumnTitles();
      this.setExcelFieldValues();
    },
    setExcelColumnTitles() {
      const columnTitles = this.headers;
      columnTitles.forEach((title) => {
        this.json_fields[title.text] = title.value;
      });
    },
    setExcelFieldValues() {
      const final = [];
      let finalObject = {};
      const itemsForExcel = this.items;
      const excelRows = Object.keys(itemsForExcel).length;
      for (let i = 0; i < excelRows; i += 1) {
        const singleObj = {};
        let withFinancVolumes = {};
        let withIRVolumes = {};
        let withTICVolumes = {};
        let withTIRVolumes = {};
        let withPFAVolumes = {};
        const rowKeys = Object.keys(itemsForExcel[i]);
        const rowValues = Object.values(itemsForExcel[i]);
        for (let j = 0; j < rowKeys.length; j += 1) {
          singleObj[rowKeys[j]] = this.fetchPlaningsSalesItemValues(rowKeys[j], rowValues[j]);
        }
        withFinancVolumes = this.financingVolumesValues;
        withIRVolumes = this.interestRateValues;
        withTICVolumes = this.ticVolumes;
        withTIRVolumes = this.totalInterestRatesValues;
        withPFAVolumes = this.ponderForAverageValues;
        finalObject = {
          ...singleObj,
          ...withFinancVolumes,
          ...withIRVolumes,
          ...withTICVolumes,
          ...withTIRVolumes,
          ...withPFAVolumes,
        };
        final.push(finalObject);
      }
      this.json_data = final;
    },
    fetchPlaningsSalesItemValues(key, value) {
      let fetchedValue = '';
      switch (key) {
        case 'leasing_type_id':
          fetchedValue = this.getLeasingTypeValue(value);
          break;
        case 'segment_id':
          fetchedValue = this.getSegmentValue(value);
          break;
        case 'customer_id':
          fetchedValue = this.getCustomerValue(value);
          break;
        case 'product_group_id':
          fetchedValue = this.getProductGroupValue(value);
          break;
        case 'product_name':
          fetchedValue = value;
          break;
        case 'channel_id':
          fetchedValue = this.getChannelValue(value);
          break;
        case 'currency_id':
          fetchedValue = this.getCurrencyValue(value);
          break;
        case 'maturity':
          fetchedValue = value;
          break;
        case 'dep_period':
          fetchedValue = value;
          break;
        case 'payment_at_once_id':
          fetchedValue = this.getPaymetAtOnceValue(value);
          break;
        case 'type_id':
          fetchedValue = this.getTypeValue(value);
          break;
        case 'repayment_id':
          fetchedValue = this.getRepaymentValue(value);
          break;
        case 'interest_rate_type_id':
          fetchedValue = this.getInterestTypeValue(value);
          break;
        case 'reference_rate_id':
          fetchedValue = this.getReferenceRateValue(value);
          break;
        case 'down_payment':
          fetchedValue = `${value * 100} %`;
          break;
        case 'first_rent_accrual_id':
          fetchedValue = this.getRirstRentalAccuralValue(value);
          break;
        case 'up_front_fee_accrual_id':
          fetchedValue = this.getRirstRentalAccuralValue(value);
          break;
        case 'dep_value':
          fetchedValue = `${value * 100} %`;
          break;
        case 'guarantee':
          fetchedValue = `${value * 100} %`;
          break;
        case 'residual_value':
          fetchedValue = `${value * 100} %`;
          break;
        case 'up_front_fee':
          fetchedValue = `${value * 100} %`;
          break;
        case 'intercalary_days':
          fetchedValue = value;
          break;
        case 'subvention':
          fetchedValue = value;
          break;
        case 'subvention_accrual_id':
          fetchedValue = value;
          break;
        case 'ppmv':
          fetchedValue = `${value * 100} %`;
          break;
        case 'financing_volumes':
          this.getFinancingVolumesValue(value);
          break;
        case 'interest_rates':
          this.getInterestRatesValue(value);
          break;
        case 'total_investment_costs':
          this.getTotalInvestmentCostValue(value);
          break;
        case 'total_interest_rates':
          this.getTotalInterestRatesValue(value);
          break;
        case 'ponder_for_averages':
          this.getPonderForAveragesValue(value);
          break;
        default:
          break;
      }
      return fetchedValue;
    },
    getPonderForAveragesValue(value) {
      this.ponderForAverageValues = {};
      const singleObj = {};
      value.forEach((item) => {
        const jsonTitle = item.period;
        this.json_fields[`PFA-${jsonTitle}`] = `PFA-${jsonTitle}`;
        singleObj[`PFA-${jsonTitle}`] = item.value;
      });
      this.ponderForAverageValues = singleObj;
    },
    getTotalInterestRatesValue(value) {
      this.totalInterestRatesValues = {};
      const singleObj = {};
      value.forEach((item) => {
        const jsonTitle = item.period;
        this.json_fields[`TIR-${jsonTitle}`] = `TIR-${jsonTitle}`;
        singleObj[`TIR-${jsonTitle}`] = (item.value).toFixed(3);
      });
      this.totalInterestRatesValues = singleObj;
    },
    getTotalInvestmentCostValue(value) {
      this.ticVolumes = {};
      const singleObj = {};
      value.forEach((item) => {
        item.periods.forEach((innerItem) => {
          const jsonTitle = item.year + innerItem.period;
          this.json_fields[`TIC-${jsonTitle}`] = `TIC-${jsonTitle}`;
          singleObj[`TIC-${jsonTitle}`] = innerItem.value;
        });
      });
      this.ticVolumes = singleObj;
    },
    getInterestRatesValue(value) {
      this.interestRateValues = {};
      const singleObj = {};
      value.forEach((item) => {
        const jsonTitle = item.period;
        this.json_fields[`IR-${jsonTitle}`] = `IR-${jsonTitle}`;
        singleObj[`IR-${jsonTitle}`] = `${(item.value * 100).toFixed(2)} %`;
      });
      this.interestRateValues = singleObj;
    },
    getFinancingVolumesValue(value) {
      this.financingVolumesValues = {};
      const singleObj = {};
      let fyValue = '';
      value.forEach((item) => {
        fyValue = item.fy;
        item.periods.forEach((innerItem) => {
          const jsonTitle = item.year + innerItem.period;
          this.json_fields[`FV-${jsonTitle}`] = `FV-${jsonTitle}`;
          singleObj[`FV-${jsonTitle}`] = innerItem.value;
        });
        singleObj[`FV-${item.year}-FY`] = fyValue;
        this.json_fields[`FV-${item.year}-FY`] = `FV-${item.year}-FY`;
      });
      this.financingVolumesValues = singleObj;
    },
    getLeasingTypeValue(value) {
      let fetchedValue = '';
      this.leasingTypeValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getSegmentValue(value) {
      let fetchedValue = '';
      this.segmentValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getCustomerValue(value) {
      let fetchedValue = '';
      this.clientTypeValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getProductGroupValue(value) {
      let fetchedValue = '';
      this.productGroupValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getChannelValue(value) {
      let fetchedValue = '';
      this.channelValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getCurrencyValue(value) {
      let fetchedValue = '';
      this.currencyValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getPaymetAtOnceValue(value) {
      let fetchedValue = '';
      this.paymentAtOnceValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getTypeValue(value) {
      let fetchedValue = '';
      this.typeValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getRepaymentValue(value) {
      let fetchedValue = '';
      this.repaymentValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getInterestTypeValue(value) {
      let fetchedValue = '';
      this.interestRateTypeValue.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getReferenceRateValue(value) {
      let fetchedValue = '';
      this.referenceRateValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    getRirstRentalAccuralValue(value) {
      let fetchedValue = '';
      this.accrualValues.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item.option;
        }
      });
      return fetchedValue;
    },
    updatePagination(page) {
      this.pagination.page = page;
      this.fetchPlanningFundingItems(this.pagination);
    },
    addNewFundingItem() {
      this.items.unshift({
        tempId: Math.random(),
        id: this.items.length + 2,
        line: '',
        currency_id: 0,
        maturity: 48,
        repayment_id: 0,
        interest_rate_type_id: 0,
        reference_rate_id: 0,
        fee: 0,
        fee_accrual_id: 0,
        placement_period: 0,
        financing_volumes: generateFinancingVolumesPeriods(
          this.planningInfo.year,
          this.planningInfo.month,
          this.periodVals,
        ),
        interest_rates: generateInterestRatePeriods(this.planningInfo.year, this.planningInfo.month),
      });
    },
    async savePlanning() {
      const rowNum = Object.keys(this.$refs).length;
      let payloadHasInvalidRow = false;
      const fundingCreateRequests = [];
      const fundingUpdateRequests = [];
      if (!rowNum) {
        return;
      }

      for (let iterator = 0; iterator < rowNum; iterator += 1) {
        if (this.$refs[`row-${iterator}`].length) {
          this.$refs[`row-${iterator}`][0].validate();
          if (this.$refs[`row-${iterator}`][0].invalidData) {
            payloadHasInvalidRow = true;
          }
        }
      }

      if (payloadHasInvalidRow) {
        return;
      }

      // TODO: Send all sales items
      this.loader = true;
      this.plid = this.$route.params.planningId;
      try {
        for (let iterator = 0; iterator < rowNum; iterator += 1) {
          if (this.items[iterator].tempId) {
            fundingCreateRequests.push(createFundingForPlanning(this.plid, this.items[iterator]));
          } else {
            fundingUpdateRequests.push(updateFundingForPlanning(this.plid, this.items[iterator], this.items[iterator].id));
          }
        }
        await Promise.all(fundingCreateRequests);
        await Promise.all(fundingUpdateRequests);
        this.notificationText = this.translate('notifications_planning_updated');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);

        await this.fetchPlanningFundingItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    deleteItem(item) {
      this.itemForDeletion = item;
      this.deleteDialog = true;
      this.deleteDialogDescription = this.translate('dialog_delete_action_description');
    },
    async confirmDeleteAction() {
      if (this.itemForDeletion.item.tempId) {
        this.$delete(this.items, this.itemForDeletion.rowIndex);
        this.deleteDialog = false;
        this.itemForDeletion = null;
        return;
      }
      this.loader = true;

      try {
        await deleteFundingForPlanning(this.$route.params.planningId, this.itemForDeletion.item.id);
        this.deleteDialog = false;
        this.itemForDeletion = null;
        this.notificationText = this.translate('notifications_item_deleted_successfully');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlanningFundingItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    setDialogState(value) {
      this.deleteDialog = value;
    },
  },
  async beforeRouteEnter(from, to, next) {
    await store.dispatch('options/getOptions');
    next();
  },
};
</script>


<style lang="scss" scoped>
.container {
  padding-bottom: 5rem !important;
}

</style>
