export default {
  namespaced: true,
  state: {
    progress: {
      stanje: 0,
    },
  },
  getters: {
    getProgress: state => state.progress.stanje,
  },
  mutations: {
    setProgress(state, stanje) {
      state.progres = { ...state.progres, stanje };
    },
  },
};
