<template>
  <v-container fluid class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>
    <v-data-table fixed-header
                  dense
                  class="elevation-1"
                  show-expand
                  item-key="code"
                  :expanded="expanded"
                  :page.sync="pagination.page"
                  :single-expand="false"
                  :server-items-length="pagination.total || items.length"
                  :headers="headers"
                  :items="items"
                  :footer-props="{
        'items-per-page-options': pagination.itemsPerPage
      }"
                  :items-per-page="pagination.size">
      <template v-slot:body="props">
        <template v-for="(item, itemIdx) in props.items">
          <tbody :key="`row-${item.code}-${itemIdx}`">
          <tr :item="item">
            <td>
              <v-icon v-show="item.sub.length > 0 && !item.isExpanded"
                      @click="toggleExpandedItem(item)"
              >
                mdi-chevron-down
              </v-icon>
              <v-icon v-show="item.sub.length > 0 && item.isExpanded"
                      @click="toggleExpandedItem(item)"
              >
                mdi-chevron-up
              </v-icon>
            </td>

            <td>
              {{item.summary_pos}}
            </td>

            <td>
              {{item.position}}
            </td>
            <td v-for="(value, valueIdx) in item.values"
                :key="`item-${item.code}-${valueIdx}`"
            >
              {{value}}
            </td>
          </tr>
          </tbody>
          <template>
            <SummarySubItemRow
              class="expanded-row"
              :key="`expanded-${itemIdx}`"
              v-show="item.isExpanded"
              :items="item.sub" />
          </template>
        </template>
      </template>
    </v-data-table>
    <Snackbar :text="notificationText"
              :type="notificationType" />
    <v-overlay :value="loader"
               :z-index="1000">
      <v-progress-circular indeterminate
                           size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';

import Breadcrumbs from '@/components/Breadcrumbs';
import Snackbar from '@/components/Snackbar';
import SummarySubItemRow from '@/components/SummarySubItemRow';
import api from '../api/lbt-api';

const {
  getSummaryCAPEXForPlanning,
} = api;

export default {
  name: 'SummaryCAPEX',
  components: {
    Breadcrumbs,
    Snackbar,
    SummarySubItemRow,
  },
  data() {
    return {
      notificationText: '',
      loader: false,
      notificationType: null,
      headers: [],
      items: [],
      expanded: [],
      pagination: {
        page: 1,
        size: 25,
        total: 0,
        itemsPerPage: [25, 50, 100],
      },
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'breadcrumbs_plannings_summary_capex',
          disabled: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
  },
  async created() {
    this.headers = [

      {
        text: this.translate('plannings_plbs_summary_pos'),
        align: 'left',
        sortable: false,
        colspan: 10,
      },

      {
        text: this.translate('plannings_plbs_position'),
        align: 'left',
        sortable: false,
        width: 350,
      },
    ];
    this.loader = true;
    try {
      const planning = await getSummaryCAPEXForPlanning(this.$route.params.planningId);
      // eslint-disable-next-line arrow-body-style
      const headers = planning.periods.map((el) => {
        return {
          text: el,
          align: 'left',
          sortable: false,
        };
      });
      this.headers = this.headers.concat(headers);
      this.items = planning.rows;
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
  },
  methods: {
    toggleExpandedItem(item) {
      // eslint-disable-next-line
      item.isExpanded = !item.isExpanded;
      this.expanded.push(item);
    },
  },
};
</script>

<style lang="scss">
table {
  border-collapse: collapse;

  tr {
    $input-size: 10px !important;

    td {
      font-size: $input-size;
      height: 37px !important;
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.12);;
    }

    &.subitem-level-1 {
      td {
        background-color: #F4F4F4;
        height: 37px;
      }
    }

    &.subitem-level-2 {
      td {
        background-color: #FAD874;
        height: 37px;
      }
    }

    &.subitem-level-3 {
      td {
        background-color: #F8EBC5;
        height: 37px;
      }
    }

    &.subitem-level-4 {
      td {
        background-color: #FCF7E9;
        height: 37px;
      }
    }
  }
}
</style>
