import { concat, each, map } from 'lodash';

const getRowCellElements = (item, createElement) => {
  const cells = [
    createElement('td', ''),
    createElement('td', item.finrep),
    createElement('td', item.summary_pos),
    createElement('td', item.fst_mapping),
    createElement('td', item.code),
    createElement('td', item.position),
  ];

  const valuesCells = map(item.values, (val, i) => {
    const cell = createElement('td', {
      key: `item-${item.code}-${i}`,
      class: 'text-xs-right',
    }, val);

    return cell;
  });

  return concat(cells, valuesCells);
};

const getRowElements = (rows, level, createElement) => {
  const rowElements = [];

  const getElements = (items, itemsLevel) => {
    each(items, (item) => {
      const row = createElement('tr', {
        class: `subitem-level-${itemsLevel}`,
      }, getRowCellElements(item, createElement));

      rowElements.push(row);

      if (item.sub.length) getElements(item.sub, itemsLevel + 1);
    });
  };

  getElements(rows, level);

  return rowElements;
};

export default {
  name: 'PLBSSubItemRow',
  props: {
    items: {
      type: Array,
    },
  },
  render(createElement) {
    return createElement('tbody', getRowElements(this.items, 1, createElement));
  },
};
