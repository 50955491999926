<template>
  <v-container fluid
    class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>
        <v-toolbar flat>
          <v-col cols="12" sm="3"
            class="px-0">
            <v-text-field
              clearable
              v-model="search"
              append-icon="mdi-magnify"
              :label="translate('plannings_capex_search_items')"
              single-line
              hide-details
            ></v-text-field>
           </v-col>
           <v-spacer></v-spacer>
          <v-btn color="primary" @click='excelDownloadClick'>
            Export to Excel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary"
              @click.stop="addNewPlanningItem()">
            <v-icon class="mr-2">mdi-table-large-plus</v-icon>
            {{translate('New Other Item')}}
          </v-btn>
        </v-toolbar>
        <ejs-grid :dataSource='items' v-if="dataLoaded"  :allowSorting="true" :allowFiltering="true"  :load='load' :cellSave='cellSave'
                  :editSettings='editSettings' :selectionSettings='selectionOptions' :allowResizing='true'
                  :pageSettings='pageSettings' :sortSettings='sortOptions' :searchSettings='searchOptions' :filterSettings="{type: 'Menu'}"
                  :allowPaging='true' :commandClick='commandClick' gridLines='Horizontal' :allowExcelExport='true'
                  :resizeStop="resizeStop" :excelHeaderQueryCellInfo='excelHeader' :actionComplete='customiseCell'>
          <e-columns>
            <e-column field='id' headerText='ID' :visible=false isPrimaryKey='true'>
            </e-column>
            <e-column field='tempId' headerText='tempID' :visible=false>
            </e-column>
            <e-column field='other_type_id' :headerText="translate('Type')" :filter="filterDropdown" foreignKeyValue="name"
                      :valueAccessor='dropdownValueAccess' editType='dropdownedit'
                      :validationRules='dropdownValidationRule' :edit='typeCascParams' :width="columnWidths[2]">
            </e-column>
            <e-column field='finrep_code_id' :headerText="translate('Finrep code')" :filter="filterDropdown" foreignKeyValue="name"
                      :valueAccessor='dropdownValueAccess' editType='dropdownedit'
                      :edit='finrepCascParams' :validationRules='dropdownValidationRule' :width="columnWidths[3]">
            </e-column>
            <e-column field='finrep_code_id_v' :headerText="translate('Value')" :filter="filterDropdown" foreignKeyValue="name"
                      :valueAccessor='dropdownValueOtherTypeAccess' editType='dropdownedit'
                      :allowEditing="false" :edit='typeCascParams' :width="columnWidths[4]">
            </e-column>
            <e-column field='finrep_code_id_id' :headerText="translate('ID_other')" :filter="filterDropdown" foreignKeyValue="name"
                      :valueAccessor='dropdownValueOtherTypeAccessID' editType='dropdownedit'
                      :allowEditing="false" :edit='typeCascParams' :width="columnWidths[5]">
            </e-column>
            <e-column field='note' :headerText="translate('Note')" :width="columnWidths[6]">
            </e-column>
            <e-column textAlign='center' :allowSorting='false' :headerText="translate('table_actions')" :commands='deleteActionCommand' :allowEditing="false" width=60>
            </e-column>
            <e-column textAlign='center' :allowSorting='false' :headerText="translate('Other Type')" :columns='otherPeriodColumns'>
            </e-column>
          </e-columns>
        </ejs-grid>
    <v-btn color="accent"
      fab
      fixed
      right
      bottom
      @click.stop="savePlanning()">
      <v-icon dark>mdi-content-save</v-icon>
    </v-btn>
    <Snackbar :text="notificationText"
      :type="notificationType" />
    <v-overlay :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate
        size="64"></v-progress-circular>
    </v-overlay>
    <ConfirmDialog
      :dialog="deleteDialog"
      :description="deleteDialogDescription"
      @change="setDialogState"
      @confirmDelete="confirmDeleteAction"
    />
  </v-container>
</template>

<script>
import Vue from 'vue';
import {
  GridPlugin, Page, Sort, Edit, Resize, Search, Filter, ForeignKey, ExcelExport, CommandColumn,
} from '@syncfusion/ej2-vue-grids';
import { createElement } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { mapGetters, mapState } from 'vuex';
import ConfirmDialog from '@/components/ConfirmDialog';
import Snackbar from '../components/Snackbar';
import Breadcrumbs from '../components/Breadcrumbs';
import { generatePlacementPeriodsCapex } from '../utilities/placementPeriod';
import { generateRiskPeriodsValues } from '../utilities/riskPeriodsValues';
import { generateRiskPeriods } from '../utilities/riskPeriods';
import { ejsValidatePlanningRows } from '../utilities/ejsValidatePlanningRows';

import api from '../api/lbt-api';

const {
  getOtherForPlanning,
  createOtherForPlanning,
  getOtherFinreps,
  getPlanning,
  deleteOtherForPlanning,
  updateOtherForPlanning,
} = api;

Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);

let typeElem;
let finrepElem;
let typeObj;
let finrepObj;

export default {
  name: 'OtherInput',
  components: {
    Snackbar,
    Breadcrumbs,
    ConfirmDialog,
  },
  data() {
    return {
      dataLoaded: false,
      search: '',
      notificationText: '',
      notificationType: '',
      deleteDialog: false,
      parentFinreps: [],
      childrenFinreps: [],
      deleteDialogDescription: '',
      itemForDeletion: {},
      loader: false,
      items: [],
      otherFinreps: [],
      planningInfo: {},
      storagePrefix: 'other_input',
      storageSeparator: '.-.',
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'other input',
          disabled: true,
        },
      ],
      placementPeriodValues: [],
      otherPeriods: [],
      columnWidths: [0, 0, 200, 280, 200, 130],
      editSettings: {
        allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch',
      },
      sortOptions: { columns: [{ field: 'tempId', direction: 'Ascending' }, { field: 'id', direction: 'Descending' }] },
      selectionOptions: { mode: 'Cell', type: 'Multiple', cellSelectionMode: 'Box' },
      searchOptions: { fields: ['note'], operator: 'contains', ignoreCase: true },
      dropdownValidationRule: { required: true },
      pageSettings: { pageSizes: true, pageSize: 20 },
      highlightCellAttributes: { class: 'highlight-cell' },
      highlightCellNoArrowsAttributes: { class: ['highlight-cell', 'no-arrows'] },
      deleteActionCommand: [{ buttonOption: { cssClass: 'v-icon notranslate v-icon--link mdi mdi-delete theme--light delete-action' } }],
      finrepCodeTypeParams: this.initDropdownParams(),
      otherTypeParams: this.initDropdownParams(),
      otherPeriodColumns: [],
      typeCascParams: {
        create: () => {
          typeElem = document.createElement('input');
          return typeElem;
        },
        read: () => typeObj.value,
        destroy: () => typeObj.destroy(),
        write: () => {
          typeObj = new DropDownList({
            dataSource: this.parentFinreps,
            fields: { value: 'id', text: 'title' },
            enabled: true,
            placeholder: 'Type',
          });
          typeObj.appendTo(typeElem);
        },
      },
      finrepCascParams: {
        create: () => {
          finrepElem = document.createElement('input');
          return finrepElem;
        },
        read: () => finrepObj.value,
        destroy: () => finrepObj.destroy(),
        write: (arg) => {
          typeObj = {
            value: arg.rowData.other_type_id,
          };
          const children = [];
          for (let i = 0; i < this.childrenFinreps.length; i += 1) {
            if (typeObj.value === this.childrenFinreps[i].parent_id) {
              children.push(this.childrenFinreps[i]);
            }
          }
          if (children.length === 0) {
            finrepObj.enabled = false;
          }
          finrepObj = new DropDownList({
            dataSource: children,
            fields: { value: 'id', text: 'title' },
            placeholder: 'Finrep',
          });
          finrepObj.appendTo(finrepElem);
        },
      },
      filterDropdown: {
        ui: {
          create: (args) => {
            const flValInput = createElement('input', { className: 'flm-input' });
            args.target.appendChild(flValInput);
            this.dropInstances[args.column.field] = new DropDownList({
              dataSource: this.dropInstances[args.column.field].dataSource,
              fields: this.dropInstances[args.column.field].fields,
              placeholder: 'Select a value',
              popupHeight: '240px',
            });
            this.dropInstances[args.column.field].appendTo(flValInput);
          },
          write: (args) => {
            this.dropInstances[args.column.field].text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, this.dropInstances[args.column.field].value);
          },
        },
      },
      dropInstances: {
        other_type_id: {
          dataSource: [],
          dataAccessor: 'parentFinreps',
          fields: { text: 'title', value: 'id' },
        },
        finrep_code_id: {
          dataSource: [],
          dataAccessor: 'childrenFinreps',
          fields: { text: 'title', value: 'id' },
        },
        finrep_code_id_v: {
          dataSource: [],
          dataAccessor: 'childrenFinreps',
          fields: { text: 'value', value: 'id' },
        },
        finrep_code_id_id: {
          dataSource: [],
          dataAccessor: 'childrenFinreps',
          fields: { text: 'id', value: 'id' },
        },
      },
    };
  },
  async created() {
    try {
      this.retrieveColumnWidths();
      await this.fetchPlanningOtherItems();
      const planning = await getPlanning(this.$route.params.planningId);
      this.otherFinreps = await getOtherFinreps();
      this.planningInfo = planning;
      this.placementPeriodValues = generatePlacementPeriodsCapex(planning.year, planning.month - 1);
      this.otherPeriods = generateRiskPeriods(planning.year, planning.month - 1);
      this.setDropdownDatasources();
      this.prepareOtherPeriodsGridHeaders();
      this.setFilterDropdowns();
      if (this.planningInfo.locked === 1) {
        this.editSettings.allowEditing = false;
        this.editSettings.allowAdding = false;
        this.editSettings.allowDeleting = false;
      }
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.dataLoaded = true;
      this.loader = false;
    }
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
    ...mapGetters('options', [
      'getOptionCategory',
    ]),
    ...mapState('plannings', [
      'activePlanning',
    ]),
  },
  watch: {
    search(searchText) {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      if (searchText !== null && searchText.length > 1) {
        grid.search(searchText);
      } else {
        grid.search();
      }
    },
  },
  provide: {
    grid: [Page, Sort, Edit, Resize, Search, Filter, ForeignKey, ExcelExport, CommandColumn],
  },
  methods: {
    load() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      grid.element.addEventListener('mousedown', (e) => {
        if (e.target.classList.contains('e-rowcell')) {
          const index = Number(e.target.getAttribute('Index'));
          const colindex = Number(e.target.getAttribute('aria-colindex'));
          const { field } = grid.getColumns()[colindex];
          grid.editModule.editCell(index, field);
        }
      });
    },
    retrieveColumnWidths() {
      for (let i = 0; i < this.columnWidths.length; i += 1) {
        const width = localStorage.getItem(this.storagePrefix + this.storageSeparator + i);
        if (width) {
          this.columnWidths[i] = width;
        }
      }
    },
    resizeStop(item) {
      let width = item.column.width.split('px');
      width = parseInt(width[0], 10);
      localStorage.setItem(this.storagePrefix + this.storageSeparator + item.column.index, width);
    },
    customiseCell(queryCellInfo) {
      const { cell } = queryCellInfo;
      if (cell && cell.textContent === 'NaN') {
        cell.classList.add('error');
      }
    },
    lookupOtherPeriodHeader(idx) {
      return this.otherPeriods[Number(idx)];
    },
    initDropdownParams() {
      return {
        params: {
          dataSource: null,
          allowFiltering: true,
          fields: { text: 'title', value: 'id' },
          query: new Query(),
        },
      };
    },
    dropdownValueAccess(field, data) {
      return this.getOtherFinrepValue(field, data[field], 'title');
    },
    dropdownValueOtherTypeAccess(field, data) {
      return this.getOtherFinrepValue(field, data[field], 'value');
    },
    dropdownValueOtherTypeAccessID(field, data) {
      return this.getOtherFinrepValue(field, data[field], 'id');
    },
    commandClick(args) {
      this.deleteItem(args.rowData);
    },
    cellSave(args) {
      const colName = args.columnName.split('.');
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const parentRow = args.cell.closest('tr');
      switch (colName[0]) {
        case 'finrep_code_id':
          for (let i = args.cell.cellIndex; i < parentRow.children.length; i += 1) {
            const colNameIn = grid.columnModel[i].field.split('.');
            if (colNameIn[0] === 'note') {
              parentRow.children[i - 1].innerHTML = this.dropdownValueOtherTypeAccessID('finrep_code_id', { finrep_code_id: args.value });
              parentRow.children[i - 2].innerHTML = this.dropdownValueOtherTypeAccess('finrep_code_id', { finrep_code_id: args.value });
              break;
            }
          }

          switch (this.getOtherFinrepValue('finrep_code_id', args.value)) {
            case 'Headcount': case 'FTE': case 'Free contracts of work*':
              this.notificationText = 'Please fill Perex data from second planning year';
              this.notificationType = 'info';
              setTimeout(() => { this.notificationText = null; }, 100);
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      if (args.value !== '') {
        const { cell } = args;
        cell.classList.add('e-updatedtd');
        cell.classList.remove('error');
      }
    },
    updateFinrepDropdownValues(totalRowIndex) {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const childrenFinreps = [];
      const [...addedRecords] = grid.getBatchChanges().addedRecords;
      const addedRecordsSize = addedRecords ? addedRecords.length : 0;
      const index = totalRowIndex - addedRecordsSize;
      this.otherFinreps.forEach((item) => {
        if (totalRowIndex >= addedRecordsSize) {
          if (item.parent_id === grid.dataSource[index].other_type_id) {
            childrenFinreps.push(item);
          }
        } else if (item.parent_id === addedRecords[totalRowIndex].other_type_id) {
          childrenFinreps.push(item);
        }
      });
      grid.columnModel[3].edit.params.dataSource = childrenFinreps;
    },
    excelDownloadClick() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const excelExportProperties = {
        // exportType: 'CurrentPage',
        fileName: `Other Input - Planning - ${this.activePlanning.year}-${this.activePlanning.month}.xlsx`,
      };
      grid.excelExport(excelExportProperties);
    },
    excelHeader(args) {
      const { field } = args.gridCell.column;
      if (field !== undefined && field.includes('.')) {
        const { cell } = args;
        if (field.startsWith('other_period')) {
          cell.value = this.otherPeriods[Number(cell.value)];
        }
      }
    },
    async fetchPlanningOtherItems() {
      const other = await getOtherForPlanning(this.$route.params.planningId);
      const items = other.other_entries;
      for (let i = 0; i < items.length; i += 1) {
        items[i].finrep_code_id_v = items[i].finrep_code_id;
        items[i].finrep_code_id_id = items[i].finrep_code_id;
      }
      this.items = items;
    },
    setFilterDropdowns() {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.dropInstances) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.dropInstances.hasOwnProperty(key)) {
          this.dropInstances[key].dataSource = this[this.dropInstances[key].dataAccessor];
        }
      }
    },
    setDropdownDatasources() {
      const parentFinreps = [];
      const childrenFinreps = [];

      this.otherFinreps.forEach((item) => {
        if (!item.parent_id || item.parent_id === 0 || item.parent_id === '') {
          parentFinreps.push(item);
        } else {
          childrenFinreps.push(item);
        }
      });

      this.parentFinreps = parentFinreps;
      this.childrenFinreps = childrenFinreps;
    },
    prepareOtherPeriodsGridHeaders() {
      const offset = 7;
      for (let i = 0; i < this.otherPeriods.length; i += 1) {
        const width = localStorage.getItem(this.storagePrefix + this.storageSeparator + (offset + i));
        this.otherPeriodColumns.push({
          field: `other_period.${i}.value`,
          headerText: i,
          headerValueAccessor: this.lookupOtherPeriodHeader,
          customAttributes: this.highlightCellNoArrowsAttributes,
          width: width != null ? width : 70,
          editType: 'numericedit',
          allowSorting: false,
          allowFiltering: false,
          textAlign: 'right',
          format: 'n2',
        });
      }
    },
    getOtherFinrepValue(key, value, field = 'title') {
      let fetchedValue = '';
      this.otherFinreps.forEach((item) => {
        if (item.id === value) {
          fetchedValue = item[field];
        }
      });
      return fetchedValue;
    },
    addNewPlanningItem() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const [...addedRecords] = grid.getBatchChanges().addedRecords;
      const addedRecordsSize = addedRecords ? addedRecords.length : 0;
      grid.addRecord({
        tempId: Math.random(),
        id: this.items.length + addedRecordsSize + 1,
        other_type_id: 0,
        finrep_code_id: 0,
        note: '',
        other_period: generateRiskPeriodsValues(this.planningInfo.year, this.planningInfo.month - 1),
      });
    },
    validate() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const [...addedRecords] = grid.getBatchChanges().addedRecords;
      const [...changedRecords] = grid.getBatchChanges().changedRecords;
      if (ejsValidatePlanningRows(grid, addedRecords)
        && ejsValidatePlanningRows(grid, changedRecords)) {
        return true;
      }
      return false;
    },
    async savePlanning() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const [...addedRecords] = grid.getBatchChanges().addedRecords;
      const [...changedRecords] = grid.getBatchChanges().changedRecords;
      if ((addedRecords === undefined && changedRecords === undefined)
      || (changedRecords.length === 0 && addedRecords.length === 0)) {
        return;
      }

      if (!this.validate()) {
        return;
      }

      // TODO: Send all items in bulk request
      this.loader = true;
      this.plid = this.$route.params.planningId;
      const otherCreateRequests = [];
      const otherUpdateRequests = [];
      try {
        if (addedRecords !== undefined) {
          for (let i = 0; i < addedRecords.length; i += 1) {
            otherCreateRequests.push(createOtherForPlanning(this.plid, addedRecords[i]));
          }
        }
        if (changedRecords !== undefined) {
          for (let j = 0; j < changedRecords.length; j += 1) {
            otherUpdateRequests.push(updateOtherForPlanning(this.plid, changedRecords[j], changedRecords[j].id));
          }
        }

        await Promise.all(otherCreateRequests);
        await Promise.all(otherUpdateRequests);
        this.notificationText = this.translate('notifications_planning_updated');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlanningOtherItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    deleteItem(item) {
      this.itemForDeletion = item;
      this.deleteDialog = true;
      this.deleteDialogDescription = this.translate('dialog_delete_action_description');
    },
    async confirmDeleteAction() {
      if (this.itemForDeletion.tempId) {
        let index = -1;
        for (let i = 0; i < this.items.length; i += 1) {
          if (this.items[i].id === this.itemForDeletion.id) {
            index = i;
            break;
          }
        }

        if (index !== -1) {
          this.$delete(this.items, index);
          this.itemForDeletion = null;
        } else {
          const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
          const [...addedRecords] = grid.getBatchChanges().addedRecords;
          for (let i = 0; i < addedRecords.length; i += 1) {
            if (addedRecords[i].tempId === this.itemForDeletion.tempId) {
              grid.deleteRecord('id', addedRecords[i]);
              const rowIdx = grid.getRowIndexByPrimaryKey(addedRecords[i].id);
              const row = grid.getRowByIndex(rowIdx);
              grid.deleteRow(row);
              break;
            }
          }
        }
        this.deleteDialog = false;
        return;
      }
      this.loader = true;

      try {
        await deleteOtherForPlanning(this.$route.params.planningId, this.itemForDeletion.id);
        this.deleteDialog = false;
        this.itemForDeletion = null;
        this.notificationText = this.translate('notifications_item_deleted_successfully');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlanningOtherItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    setDialogState(value) {
      this.deleteDialog = value;
    },
  },
};
</script>

<style>
  @import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
  @import "../../node_modules/@syncfusion/ej2-icons/styles/material.css";

  .container {
    padding-bottom: 5rem !important;
  }

</style>
