<template>
  <v-container fluid
    class="container-main">
    <v-data-table
      fixed-header
      class="elevation-1"
      :page.sync="pagination.page"
      :server-items-length="pagination.total"
      :headers="headers"
      :items="plannings"
      :no-data-text="translate('plannings_no_plannings')"
      :footer-props="{
        'items-per-page-options': pagination.itemsPerPage
      }"
      :items-per-page="pagination.size"
    >

    <template v-slot:item.username="{ item }">
      {{item.username || 'Unknown User'}}
    </template>
    <template v-slot:item.created_at="{ item }">
      {{item.created_at | date}}
    </template>

    <template v-slot:item.actions="{ item }">

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon
                 @click="editItem(item.id)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-if="item.locked == 0" v-on="on" icon
                 @click="replaceItem(item.id)">
            <v-icon>mdi-file-refresh-outline</v-icon>
          </v-btn>
        </template>
        <span>Replace planning data</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon
                 @click="duplicateItem(item.id)">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>Duplicate</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-if="item.locked == 0" v-on="on" icon
                 @click="recalculateItem(item.id)">
            <v-icon>mdi-calculator-variant</v-icon>
          </v-btn>
        </template>
        <span>Recalculate</span>
      </v-tooltip>
            <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-if="item.locked == 0" v-on="on" icon
                 @click="archiveItem(item.id)">
            <v-icon>mdi-archive</v-icon>
          </v-btn>
        </template>
        <span>Select archive data</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-if="item.locked == 0" v-on="on" icon
                 @click="lockItem(item.id)">
            <v-icon>mdi-lock</v-icon>
          </v-btn>
        </template>
        <span>Lock</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-if="item.locked == 0" v-on="on" icon
                 @click="deleteItem(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="1268">
      <v-card>
          <v-card-title class="pa-0"
            color="primary"
            primary-title>
            <v-toolbar
              color="secondary"
            >
              <v-toolbar-title>
                {{translate('plannings_create_new')}}
              </v-toolbar-title>
            </v-toolbar>
          </v-card-title>

          <v-card-text
            class="mt-1">
            <v-form ref="createPlanning">
            <v-container>
              <v-row>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-text-field
                    v-model="planningInfo"
                    :rules="[validators.required]"
                    :label="translate('plannings_planning_info')"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-select
                    prepend-icon="mdi-human"
                    v-model="username"
                    :rules="[validators.required]"
                    :items="entityOptions"
                    :item-text="'option'"
                    :item-value="'value'"
                    :label="translate('plannings_planning_author')"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-menu
                    v-model="datepicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="date"
                        :label="translate('plannings_planning_date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      landscape
                      type="month"
                      v-model="date"
                      @input="datepicker = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-select
                    prepend-icon="mdi-cash"
                    v-model="lcyOption"
                    :rules="[validators.required]"
                    :items="currencyOptions"
                    :item-text="'option'"
                    :item-value="'id'"
                    :label="translate('plannings_lcy')"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-select
                    prepend-icon="mdi-puzzle-plus-outline"
                    :rules="[validators.required]"
                    v-model="sales_floor_id"
                    :items="salesFloorOptions"
                    :item-text="'option'"
                    :item-value="'id'"
                    :label="translate('plannings_interest_rate_floor')"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-select
                    prepend-icon="mdi-compare-horizontal"
                    :rules="[validators.required]"
                    v-model="funding_floor_id"
                    :items="fundingFloorOptions"
                    :item-text="'option'"
                    :item-value="'id'"
                    :label="translate('Funding IR floor')"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-select
                    prepend-icon="mdi-compare-vertical"
                    :rules="[validators.required]"
                    v-model="sales_down_payment_id"
                    :items="salesDownPaymentOptions"
                    :item-text="'option'"
                    :item-value="'id'"
                    :label="translate('plannings_interest_down_payment')"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-select
                    prepend-icon="mdi-camera-metering-spot"
                    v-model="other_funding_input"
                    :items="otherFundingInputOptions"
                    :item-text="'option'"
                    :item-value="'value'"
                    :label="'Other funding input'"
                  ></v-select>
                </v-col>
                <v-col
                cols="12" xs="12" sm="6" md="3"
              >
                <v-select
                  prepend-icon="mdi-arrange-bring-forward"
                  :rules="[validators.required]"
                  v-model="fee_calculation_ol"
                  :items="calculationPaymentOptions"
                  :item-text="'option'"
                  :item-value="'id'"
                  :label="translate('fee_calculation_ol')"
                ></v-select>
              </v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-select
                    prepend-icon="mdi-folder-account-outline"
                    :rules="[validators.required]"
                    v-model="rent_calculation_ol"
                    :items="calculationPaymentOptions"
                    :item-text="'option'"
                    :item-value="'id'"
                    :label="translate('rent_calculation_ol')"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-select
                    prepend-icon="mdi-file-cabinet"
                    :rules="[validators.required]"
                    v-model="depo_calculation_ol"
                    :items="calculationPaymentOptions"
                    :item-text="'option'"
                    :item-value="'id'"
                    :label="translate('depo_calculation_ol')"
                  ></v-select>
                </v-col>
                <v-col md="3" class="hidden-sm-and-down"></v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-select
                    prepend-icon="mdi-folder-plus-outline"
                    :rules="[validators.required]"
                    v-model="fee_calculation_fl"
                    :items="calculationPaymentOptions"
                    :item-text="'option'"
                    :item-value="'id'"
                    :label="translate('fee_calculation_fl')"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-select
                    prepend-icon="mdi-arrange-send-to-back"
                    :rules="[validators.required]"
                    v-model="rent_calculation_fl"
                    :items="calculationPaymentOptions"
                    :item-text="'option'"
                    :item-value="'id'"
                    :label="translate('rent_calculation_fl')"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-select
                    prepend-icon="mdi-cog-counterclockwise"
                    :rules="[validators.required]"
                    v-model="depo_calculation_fl"
                    :items="calculationPaymentOptions"
                    :item-text="'option'"
                    :item-value="'id'"
                    :label="translate('depo_calculation_fl')"
                  ></v-select>
                </v-col>
                <v-col md="3" class="hidden-sm-and-down"></v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-file-input
                    prepend-icon="mdi-folder-search-outline"
                    :label="translate('plannings_fixed_assets_file')"
                    v-model="fixedAssets"
                    show-size
                    required
                    accept="application/*"
                    :rules="[validators.required]">
                  </v-file-input>
                </v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-file-input
                    prepend-icon="mdi-vector-square"
                    :label="translate('plannings_fixed_assets_support_file')"
                    v-model="fixedAssetsSupport"
                    show-size
                    required
                    accept="application/*"
                    :rules="[validators.required]">
                  </v-file-input>
                </v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-file-input
                    prepend-icon="mdi-arrange-send-backward"
                    :label="translate('plannings_fixed_assets_planning_rates')"
                    v-model="fixedAssetsPlanningRates"
                    show-size
                    required
                    accept="application/*"
                    :rules="[validators.required]">
                  </v-file-input>
                </v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-file-input
                    prepend-icon="mdi-shield-account-outline"
                    :label="translate('plannings_import')"
                    v-model="newPlanningFile"
                    show-size
                    required
                    accept="application/*">
                  </v-file-input>
                </v-col>

                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-file-input
                    prepend-icon="mdi-credit-card-check-outline"
                    :label="translate('Plannins loan')"
                    v-model="loansPlanningFile"
                    show-size
                    required
                    accept="application/*"
                    :rules="[validators.required]">
                  </v-file-input>
                </v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-file-input
                    prepend-icon="mdi-credit-card-multiple-outline"
                    :label="translate('Risk and Funding')"
                    v-model="riskPlanningFile"
                    show-size
                    required
                    accept="application/*">
                  </v-file-input>
                </v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-file-input
                    prepend-icon="mdi-auto-fix"
                    :label="translate('Vendor planning file')"
                    v-model="vendorPlanningFile"
                    show-size
                    accept="application/*">
                  </v-file-input>
                </v-col>
                <v-col
                  cols="12" xs="12" sm="6" md="3"
                >
                  <v-select
                    prepend-icon="mdi-wallet-membership"
                    v-model="leasing_or_rent"
                    :items="leasingOrRentOptions"
                    :item-text="'option'"
                    :item-value="'value'"
                    :label="translate('plannings_leasing_or_rent')"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              @click.stop="reset();">
              {{translate('button_cancel')}}
            </v-btn>
            <v-btn
              large
              color="accent"
              @click.stop="submit();">
              {{translate('button_save')}}
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="replacedialog"
      persistent
      scrollable
      max-width="768">
      <v-card>
        <v-form ref="replacePlanning">
          <v-toolbar
            color="secondary"
            >
            <v-toolbar-title>
              {{translate('planning_replace')}}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text
            class="mt-3">
            <v-container>

                  <v-row>
                    <v-col
                      cols="3"
                      md="4"
                    >
                      <v-file-input
                        :label="translate('plannings_import')"
                        v-model="replacePlanningFile"
                        show-size
                        accept="application/*">
                      </v-file-input>
                    </v-col>
                  <v-col
                      cols="3"
                      md="4"
                    >
                      <v-file-input
                        :label="translate('Archive data')"
                        v-model="archivePlanningFile"
                        show-size
                        accept="application/*">
                      </v-file-input>
                    </v-col>
                    <v-col
                      cols="3"
                      md="4"
                    >
                      <v-file-input
                        :label="translate('AVG contracts')"
                        v-model="avgPlanningFile"
                        show-size
                        accept="application/*">
                      </v-file-input>
                    </v-col>
                  </v-row>
                <v-row>
                    <v-col
                      cols="3"
                      md="4"
                    >
                      <v-file-input
                        :label="translate('Risk data')"
                        v-model="replaceRiskData"
                        show-size
                        accept="application/*">
                      </v-file-input>
                    </v-col>
                  <v-col
                      cols="3"
                      md="4"
                    >
                      <v-file-input
                        :label="translate('Exchange rates')"
                        v-model="replaceExhangeRates"
                        show-size
                        accept="application/*">
                      </v-file-input>
                    </v-col>
                    <v-col
                      cols="3"
                      md="4"
                    >
                    </v-col>
                  </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              @click.stop="resetReplace();">
              {{translate('button_cancel')}}
            </v-btn>
            <v-btn
              large
              color="accent"
              @click.stop="confirmReplaceAction();">
              {{translate('button_save')}}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

     <v-dialog
      v-model="rmerge"
      persistent
      scrollable
      max-width="768">
      <v-card>
        <v-form ref="mergePlanning">
          <v-toolbar
            color="secondary"
            >
            <v-toolbar-title>
              Consolidate
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text
            class="mt-3">
            <v-container>
                  <v-row>
                    <v-col
                      cols="3"
                      md="4"
                    >
                    <v-select
                      prepend-icon="mdi-file-settings-variant"
                      :rules="[validators.required]"
                      v-model="prvi"
                      :items="plannings"
                      :item-text="'planning_info'"
                      :item-value="'id'"
                      :label="translate('ID first to consolidate')"
                    ></v-select>
                    </v-col>
                    <v-col
                            cols="3"
                            md="4"
                          >
                      <v-select
                        prepend-icon="mdi-file-settings-variant"
                        :rules="[validators.required]"
                        v-model="drugi"
                        :items="plannings"
                        :item-text="'planning_info'"
                        :item-value="'id'"
                        :label="translate('ID second to consolidate')"
                      ></v-select>
                    </v-col>
                  </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              @click.stop="resetMerge();">
              {{translate('button_cancel')}}
            </v-btn>
            <v-btn
              large
              color="accent"
              @click.stop="confirmMergeAction();">
              {{translate('button_save')}}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  <v-dialog
      v-model="copyDialog"
      persistent
      scrollable
      max-width="768">
      <v-card>
        <v-form ref="copyPlanning">
          <v-toolbar
            color="secondary"
            >
            <v-toolbar-title>
              Copy
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text
            class="mt-3">
            <v-container>
                  <v-row>
                    <v-col
                      cols="3"
                      md="4"
                    >
                  <v-text-field
                    v-model="copyNaziv"
                    :rules="[validators.required]"
                    :label="translate('plannings_planning_info')"
                  ></v-text-field>
                    </v-col>
                  </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              @click.stop="resetCopy();">
              {{translate('button_cancel')}}
            </v-btn>
            <v-btn
              large
              color="accent"
              @click.stop="confirmCopyAction();">
              {{translate('button_save')}}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  <v-dialog
      v-model="archiveDialog"
      persistent
      scrollable
      max-width="768">
      <v-card>
        <v-form ref="archivePlanning">
          <v-toolbar
            color="secondary"
            >
            <v-toolbar-title>
              Select archive data
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text
            class="mt-3">
            <v-container>
                  <v-row>
                    <v-col
                      cols="3"
                      md="4"
                    >
                    <v-select
                      prepend-icon="mdi-file-settings-variant"
                      v-model="archiveGodine"
                      :items="archiveYearsMinnus"
                      :item-text="'name'"
                      :item-value="'value'"
                      :label="'Archive year in current planning'"
                    ></v-select>
                    </v-col>
                    <v-col
                            cols="3"
                            md="4"
                          >
                      <v-select
                        prepend-icon="mdi-file-settings-variant"
                        v-model="archivePlaniranje"
                        :items="plannings"
                        :item-text="'planning_info'"
                        :item-value="'id'"
                        :label="('Planning to use archive from')"
                      ></v-select>
                    </v-col>
                    <v-col
                            cols="3"
                            md="4"
                          >
                      <v-select
                        prepend-icon="mdi-file-settings-variant"
                        v-model="archivePlaniranjeGodina"
                       :items="archiveYears"
                       :item-text="'name'"
                       :item-value="'value'"
                        :label="'Column from selected planning'"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="3"
                      md="4"
                    >
                    <v-select
                      prepend-icon="mdi-file-settings-variant"
                      v-model="archiveGodine1"
                      :items="archiveYearsMinnus"
                      :item-text="'name'"
                      :item-value="'value'"
                      :label="'Archive year in current planning'"
                    ></v-select>
                    </v-col>
                    <v-col
                            cols="3"
                            md="4"
                          >
                      <v-select
                        prepend-icon="mdi-file-settings-variant"
                        v-model="archivePlaniranje1"
                        :items="plannings"
                        :item-text="'planning_info'"
                        :item-value="'id'"
                        :label="('Planning to use archive from')"
                      ></v-select>
                    </v-col>
                    <v-col
                            cols="3"
                            md="4"
                          >
                      <v-select
                        prepend-icon="mdi-file-settings-variant"
                        v-model="archivePlaniranjeGodina1"
                       :items="archiveYears"
                       :item-text="'name'"
                       :item-value="'value'"
                        :label="'Column from selected planning'"
                      ></v-select>
                    </v-col>
                  </v-row>
                                    <v-row>
                    <v-col
                      cols="3"
                      md="4"
                    >
                    <v-select
                      prepend-icon="mdi-file-settings-variant"
                      v-model="archiveGodine2"
                      :items="archiveYearsMinnus"
                      :item-text="'name'"
                      :item-value="'value'"
                      :label="'Archive year in current planning'"
                    ></v-select>
                    </v-col>
                    <v-col
                            cols="3"
                            md="4"
                          >
                      <v-select
                        prepend-icon="mdi-file-settings-variant"
                        v-model="archivePlaniranje2"
                        :items="plannings"
                        :item-text="'planning_info'"
                        :item-value="'id'"
                        :label="('Planning to use archive from')"
                      ></v-select>
                    </v-col>
                    <v-col
                            cols="3"
                            md="4"
                          >
                      <v-select
                        prepend-icon="mdi-file-settings-variant"
                        v-model="archivePlaniranjeGodina2"
                       :items="archiveYears"
                       :item-text="'name'"
                       :item-value="'value'"
                        :label="'Column from selected planning'"
                      ></v-select>
                    </v-col>
                  </v-row>
                                    <v-row>
                    <v-col
                      cols="3"
                      md="4"
                    >
                    <v-select
                      prepend-icon="mdi-file-settings-variant"
                      v-model="archiveGodine3"
                      :items="presetBdgt"
                      :item-text="'name'"
                      :item-value="'value'"
                      :label="'Budget'"
                    ></v-select>
                    </v-col>
                    <v-col
                            cols="3"
                            md="4"
                          >
                      <v-select
                        prepend-icon="mdi-file-settings-variant"
                        v-model="archivePlaniranje3"
                        v-on:change="changeRoute()"
                        :items="plannings"
                        :item-text="'planning_info'"
                        :item-value="'id'"
                        :label="('Planning to use archive from')"
                      ></v-select>
                    </v-col>
                    <v-col
                            cols="3"
                            md="4"
                          >
                      <v-select
                        prepend-icon="mdi-file-settings-variant"
                        v-model="archivePlaniranjeGodina3"
                       :items="bdgtYears"
                       :item-text="'name'"
                       :item-value="'value'"
                        :label="'Select year from planning to use as budget'"
                      ></v-select>
                    </v-col>
                  </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              @click.stop="resetArchive();">
              {{translate('button_cancel')}}
            </v-btn>
            <v-btn
              large
              color="accent"
              @click.stop="confirmArchiveAction();">
              {{translate('button_save')}}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-btn
      color="accent"
      fab
      fixed
      right
      bottom
      @click.stop="dialog = true"
      >
      <v-icon dark>mdi-plus</v-icon>
    </v-btn>
    <v-btn
      color="accent"
      fab
      fixed
      middle
      bottom
      @click.stop="rmerge = true"
      >
      <v-icon dark>mdi-merge</v-icon>
    </v-btn>
    <Snackbar
      :text="notificationText"
      :type="notificationType"/>
    <v-overlay
      :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <ConfirmDialog
      :dialog="deleteDialog"
      :description="deleteDialogDescription"
      @change="setDialogState"
      @confirmDelete="confirmDeleteAction"
    />

    <v-dialog
      v-model="lockDialog"
      persistent
      max-width="290"
    >

      <v-card>
        <v-card-title class="text-h5">
          Lock planninng?
        </v-card-title>
        <v-card-text>This action is undable. I change you want to activate it again you will need to ask vendor</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="lockDialog = false"
          >
            Disagree
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmLockAction(); lockDialog = false"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import validators from '@/validators';
import ConfirmDialog from '@/components/ConfirmDialog';
import Snackbar from '../components/Snackbar';
import api from '../api/lbt-api';
import store from '../store/index';
import { generateBdgtPeriods } from '../utilities/bdgtPeriods';

const {
  createNewPlanning,
  replacePlanning,
  deletePlanning,
  lockPlanning,
  duplicatePlanning,
  mergePlanning,
  salesRecalculate,
  archivePlanning,
  getPlanning,
  calcPlanning,
} = api;

export default {
  name: 'Plannings',
  components: {
    Snackbar,
    ConfirmDialog,
  },
  data() {
    return {
      validators,
      loader: false,
      dialog: false,
      replacedialog: false,
      archiveDialog: false,
      rmerge: false,
      copyDialog: false,
      datepicker: false,
      date: new Date().toISOString().substr(0, 7),
      currencyOptions: [],
      plann: [],
      entityOptions: [],
      salesFloorOptions: [],
      fundingFloorOptions: [],
      salesDownPaymentOptions: [],
      calculationPaymentOptions: [],
      leasingOrRentOptions: [],
      otherFundingInputOptions: [{ option: 'Deposits from customers (2030320000)', value: '2030320000' }, { option: 'Deposits from banks (2030310000)', value: '2030310000' }],
      lcyOption: '',
      sales_floor_id: 0,
      prvi: 0,
      drugi: 0,
      funding_floor_id: 0,
      sales_down_payment_id: 0,
      fee_calculation_ol: 0,
      fee_calculation_fl: 0,
      rent_calculation_ol: 0,
      rent_calculation_fl: 0,
      depo_calculation_ol: 0,
      depo_calculation_fl: 0,
      fixedAssets: [],
      fixedAssetsSupport: [],
      fixedAssetsPlanningRates: [],
      newPlanningFile: [],
      replacePlanningFile: [],
      archivePlanningFile: [],
      avgPlanningFile: [],
      replaceExhangeRates: [],
      replaceRiskData: [],
      loansPlanningFile: [],
      riskPlanningFile: [],
      vendorPlanningFile: [],
      archivePlaniranje: [],
      archivePlaniranjeGodina: [],
      archiveGodine: [],
      archivePlaniranje1: [],
      archivePlaniranjeGodina1: [],
      archiveGodine1: [],
      archivePlaniranje2: [],
      archivePlaniranjeGodina2: [],
      archiveGodine2: [],
      archivePlaniranje3: [],
      archivePlaniranjeGodina3: [],
      archiveGodine3: [],
      leasing_or_rent: 'none',
      other_funding_input: '2030320000',
      notificationText: '',
      notificationType: '',
      headers: [],
      plannings: [],
      copyNaziv: '',
      pagination: {
        page: 1,
        size: 100,
        total: 0,
        itemsPerPage: [25, 50, 100],
      },
      deleteDialog: false,
      deleteDialogDescription: '',
      lockDialog: false,
      lockDialogDescription: '',
      duplicateDialogDescription: '',
      replaceDialogDescription: '',
      itemForDeletion: null,
      itemForLock: null,
      user: null,
      planningId: null,
      itemForReplacement: null,
      planningInfo: '',
      username: '',
      bdgtYears: '',
      archiveYears: [
      {
        name: 'year - 1',
        value: 1,
      },
      {
        name: 'year - 2',
        value: 2,
      },
      {
        name: 'bdgt',
        value: 3,
      },
      {
        name: 'actual',
        value: 4,
      },
      ],
      archiveYearsMinnus: [
      {
        name: 'year - 1',
        value: 1,
      },
      {
        name: 'year - 2',
        value: 2,
      },
      {
        name: 'actual',
        value: 4,
      },

      ],
      presetBdgt: [
      {
        name: 'bdgt',
        value: 3,
      },
      ],
    };
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
    ...mapGetters('options', [
      'getOptionCategory',
    ]),
  },
  methods: {
    async submit() {
      if (!this.$refs.createPlanning.validate()) {
        return;
      }
      this.loader = true;
      try {
       const rezultat = await createNewPlanning(
          this.planningInfo,
          this.username,
          this.date,
          this.lcyOption,
          this.sales_floor_id,
          this.funding_floor_id,
          this.sales_down_payment_id,
          this.fee_calculation_ol,
          this.fee_calculation_fl,
          this.rent_calculation_ol,
          this.rent_calculation_fl,
          this.depo_calculation_ol,
          this.depo_calculation_fl,
          this.fixedAssets,
          this.fixedAssetsSupport,
          this.fixedAssetsPlanningRates,
          this.newPlanningFile,
          this.loansPlanningFile,
          this.riskPlanningFile,
          this.vendorPlanningFile,
          this.leasing_or_rent,
          this.other_funding_input,
        );
        this.notificationText = this.translate('plannings_planning_created_success');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 1000);
        await this.fetchPlannings();
        this.reset();
        this.notificationText = 'Planning data succesfully uploadaed. Data calculation started. You will recive notification on mail when calculation is finished';
        setTimeout(() => { this.notificationText = null; }, 100);
        this.user = rezultat.planningUser;
        this.planningId = rezultat.planningId;
        await calcPlanning(this.user, this.planningId);
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    reset() {
      this.$refs.createPlanning.resetValidation();
      this.dialog = false;
      this.date = new Date().toISOString().substr(0, 7);
      this.fixedAssets = [];
      this.fixedAssetsSupport = [];
      this.fixedAssetsPlanningRates = [];
      this.planningInfo = '';
      this.username = '';
    },
    checkLocked() {

    },
    resetReplace() {
      this.$refs.replacePlanning.resetValidation();
      this.replacedialog = false;
      this.replacePlanningFile = null;
      this.archivePlanningFile = null;
      this.avgPlanningFile = null;
      this.replaceExhangeRates = null;
      this.replaceRiskData = null;
    },
    resetMerge() {
      this.rmerge = false;
      this.prvi = 0;
      this.drugi = 0;
    },
    resetCopy() {
      this.copyDialog = false;
      this.copyNaziv = '';
    },
    async changeRoute() {
      const planningBdgt = await getPlanning(this.archivePlaniranje3);
      this.bdgtYears = generateBdgtPeriods(planningBdgt.year);
    },
    editItem(id) {
      this.$router.push({ path: `/plannings/${id}/capex_new` });
    },
    async recalculateItem(id) {
      this.notificationText = 'Recalculate has started! This can take a while..';
      this.notificationType = 'success';
      setTimeout(() => { this.notificationText = null; }, 100);
      await salesRecalculate(id);
      this.notificationText = 'Recalculate has finished successfully!';
      this.notificationType = 'success';
      setTimeout(() => { this.notificationText = null; }, 100);
    },
    deleteItem(id) {
      this.itemForDeletion = id;
      this.deleteDialog = true;
      this.deleteDialogDescription = this.translate('dialog_delete_action_description');
    },
    lockItem(id) {
      this.itemForLock = id;
      this.lockDialog = true;
      this.lockDialogDescription = this.translate('Lock');
    },

    duplicateItem(id) {
      this.itemForDeletion = id;
      this.copyDialog = true;
      this.duplicateDialogDescription = this.translate('Copy item');
    },
    resetArchive() {
      this.archiveGodine1 = false;
      this.archiveGodine2 = [];
      this.archiveGodine = [];
      this.archivePlaniranje = [];
      this.archivePlaniranjeGodina = [];
      this.archiveGodine3 = [];
      this.archivePlaniranje1 = [];
      this.archivePlaniranjeGodina1 = [];
      this.archivePlaniranje2 = [];
      this.archivePlaniranjeGodina2 = [];
      this.archivePlaniranje3 = [];
      this.archivePlaniranjeGodina3 = [];
      this.archiveDialog = false;
      this.prvi = 0;
      this.drugi = 0;
    },
    async confirmCopyAction() {
      if (!this.itemForDeletion) {
        this.copyDialog = false;
        this.itemForDeletion = null;
        return;
      }
      this.loader = true;

      try {
        await duplicatePlanning(this.itemForDeletion, this.copyNaziv);
        this.copyDialog = false;
        this.notificationText = this.translate('copied');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlannings();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
        this.copyDialog = false;
      }
    },
    async confirmArchiveAction() {
      if (!this.itemForReplacement) {
        this.replacedialog = false;
        this.itemForReplacement = null;
        return;
      }
      this.loader = true;

      try {
        /* await archivePlanning(this.itemForReplacement, this.archiveGodine, this.archivePlaniranje, this.archivePlaniranjeGodina); */
        if (Number.isInteger(this.archiveGodine)) {
          await archivePlanning(this.itemForReplacement, this.archiveGodine, this.archivePlaniranje, this.archivePlaniranjeGodina);
        }
        if (Number.isInteger(this.archiveGodine1)) {
          await archivePlanning(this.itemForReplacement, this.archiveGodine1, this.archivePlaniranje1, this.archivePlaniranjeGodina1);
        }
        if (Number.isInteger(this.archiveGodine2)) {
          await archivePlanning(this.itemForReplacement, this.archiveGodine2, this.archivePlaniranje2, this.archivePlaniranjeGodina2);
        }
        if (Number.isInteger(this.archiveGodine3)) {
          await archivePlanning(this.itemForReplacement, this.archiveGodine3, this.archivePlaniranje3, this.archivePlaniranjeGodina3);
        }
        this.notificationText = this.translate('copied');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlannings();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    replaceItem(id) {
      this.itemForReplacement = id;
      this.replacedialog = true;
      this.replaceDialogDescription = false;
    },
    archiveItem(id) {
      this.itemForReplacement = id;
      this.archiveDialog = true;
    },
    async confirmDeleteAction() {
      if (!this.itemForDeletion) {
        this.deleteDialog = false;
        this.itemForDeletion = null;
        return;
      }
      this.loader = true;

      try {
        await deletePlanning(this.itemForDeletion);
        this.deleteDialog = false;
        this.notificationText = this.translate('notifications_item_deleted_successfully');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlannings();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    async confirmLockAction() {
      if (!this.itemForLock) {
        this.lockDialog = false;
        this.itemForLock = null;
        return;
      }
      this.loader = true;

      try {
        await lockPlanning(this.itemForLock);
        this.LockDialog = false;
        this.notificationText = this.translate('Item locked');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlannings();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
        this.LockDialog = false;
      }
    },
    async confirmReplaceAction() {
      if (!this.itemForReplacement) {
        this.replacedialog = false;
        this.itemForReplacement = null;
        return;
      }
      this.loader = true;

      try {
        await replacePlanning(this.itemForReplacement, this.replacePlanningFile, this.archivePlanningFile, this.avgPlanningFile, this.replaceRiskData, this.replaceExhangeRates);
        this.notificationText = this.translate('notifications_planning_updated');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlannings();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
        this.replacedialog = false;
        this.resetReplace();
      }
    },
    async confirmMergeAction() {
      this.loader = true;

      try {
        await mergePlanning(this.prvi, this.drugi);
        this.notificationText = this.translate('notifications_planning_updated');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlannings();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
        this.rmerge = false;
      }
    },
    async fetchPlannings() {
      const plannings = await store.dispatch('plannings/getPlannings', this.pagination);
      this.plannings = plannings.plannings;
      this.pagination.total = plannings.pagination.total;
    },
    setDialogState(value) {
      this.deleteDialog = value;
    },
    setDialogStateLocked(value) {
      this.lockDialog = value;
    },
  },
  async created() {
    this.headers = [
      {
        text: this.translate('planning_year'),
        align: 'left',
        sortable: false,
        value: 'year',
      },
      {
        text: this.translate('planning_month'),
        align: 'left',
        sortable: false,
        value: 'month',
      },
      {
        text: this.translate('planning_currency'),
        align: 'center',
        sortable: false,
        value: 'lcy_option.option',
      },
      {
        text: this.translate('planning_author'),
        align: 'center',
        sortable: false,
        value: 'username',
      },
      {
        text: this.translate('plannings_planning_info'),
        align: 'center',
        sortable: false,
        value: 'planning_info',
      },
            {
        text: this.translate('ID'),
        align: 'center',
        sortable: false,
        value: 'id',
      },
      {
        text: this.translate('planning_created_at'),
        align: 'center',
        sortable: false,
        value: 'created_at',
      },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        value: 'actions',
      }];
    this.loader = true;
    try {
      await this.fetchPlannings();
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
    this.bdgtYears = generateBdgtPeriods(2020);
    this.currencyOptions = this.getOptionCategory('CURRENCY').options;
    this.currencyOptions = this.getOptionCategory('CURRENCY').options;
    this.entityOptions = this.getOptionCategory('LEGAL_ENTITY').options;
    this.salesFloorOptions = this.getOptionCategory('YES_NO_OPTION_FLOOR').options;
    this.fundingFloorOptions = this.getOptionCategory('YES_NO_OPTION_FLOOR').options;
    this.salesDownPaymentOptions = this.getOptionCategory('DOWN_PAYMENT_FINREP_POSITION').options;
    this.calculationPaymentOptions = this.getOptionCategory('CALC_TYPE').options;
    this.leasingOrRentOptions = this.getOptionCategory('LEASING_OR_RENT').options;
  },
  async beforeRouteEnter(from, to, next) {
    await store.dispatch('options/getOptions');
    next();
  },
};
</script>

<style lang="scss" scoped>
.container-main{
  padding-bottom: 5rem !important;
}
</style>
