export function generateRiskPeriods(startYear, startMonth) {
  const numOfMonths = 12;
  const numOfQuarters = 4;
  const depreciationPeriods = [];
  let monthAddition = 1;
  let nextYear = startYear + 1;

  // Current year calculated by month
  while (startMonth + monthAddition <= numOfMonths) {
    const month = startMonth + monthAddition;
    depreciationPeriods.push(`${startYear}M${month}`);
    monthAddition += 1;
  }

  for (let yearIterator = 1; yearIterator <= 1; yearIterator += 1) {
    // Next year calculated by quartals
    for (let iterator = 1; iterator <= numOfQuarters; iterator += 1) {
      depreciationPeriods.push(`${nextYear}Q${iterator}`);
    }
    nextYear += 1;
  }
  for (let yearIterator = 2; yearIterator <= 5; yearIterator += 1) {
    // Next year calculated by quartals
    depreciationPeriods.push(`FY${nextYear}`);
    nextYear += 1;
  }
  return depreciationPeriods;
}
