<template>
  <v-container fluid class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>
     <v-data-table fixed-header
      dense
      class="elevation-1"
      show-expand
      item-key="code"
      :expanded="expanded"

      :single-expand="false"

      :headers="headers"
      :items="items"
      :footer-props="{
        'items-per-page-options': pagination.itemsPerPage
      }"
      :items-per-page="pagination.size">
      <template v-slot:body="props">
        <template v-for="(item, itemIdx) in props.items">
          <tbody :key="`row-${item.code}-${itemIdx}`">
            <tr :item="item"   @click="markRow(item)" :class="{'primary': item.code===selectedId}">
            <td>
              <v-icon v-show="item.sub.length > 0 && !item.isExpanded"
                @click="toggleExpandedItem(item)"
                >
                mdi-chevron-down
              </v-icon>
              <v-icon v-show="item.sub.length > 0 && item.isExpanded"
                @click="toggleExpandedItem(item)"
                >
                mdi-chevron-up
              </v-icon>
            </td>
            <td>
              {{item.finrep}}
            </td>
            <td>
              {{item.summary_pos}}
            </td>
            <td>
              {{item.fst_mapping}}
            </td>
            <td>
              {{item.code}}
            </td>
            <td>
              {{item.position}}
            </td>
            <td v-for="(value, valueIdx) in item.values"
              :key="`item-${item.code}-${valueIdx}`"
            >
              {{value}}
            </td>
          </tr>
          </tbody>
          <template>
            <PLBSSubItemRow
              class="expanded-row"
              :key="`expanded-${itemIdx}`"
              v-show="item.isExpanded"
              :items="item.sub" />
          </template>
        </template>
      </template>
    </v-data-table>
    <Snackbar :text="notificationText"
      :type="notificationType" />
    <v-overlay :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate
        size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';

import Breadcrumbs from '@/components/Breadcrumbs';
import Snackbar from '@/components/Snackbar';
import PLBSSubItemRow from '@/components/PLBSSubItemRow';
import api from '../api/lbt-api';

const {
  getBalanceSheetForPlanning,
} = api;

export default {
  name: 'BalanceSheet',
  components: {
    Breadcrumbs,
    Snackbar,
    PLBSSubItemRow,
  },
  data() {
    return {
      headers: [],
      items: [],
      notificationText: '',
      loader: false,
      selectedId: -1,
      notificationType: null,
      expanded: [],
      pagination: {
        page: 1,
        size: 18,
        total: 0,
        itemsPerPage: [18, 50, 100],
      },
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'breadcrumbs_plannings_balance_sheet',
          disabled: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
  },
  async created() {
    this.headers = [
      {
        text: this.translate('plannings_plbs_finrep'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_plbs_summary_pos'),
        align: 'left',
        sortable: false,
        colspan: 10,
      },
      {
        text: this.translate('plannings_plbs_fst_mapping'),
        align: 'left',
        sortable: false,
      },
      {
        text: this.translate('plannings_plbs_code'),
        align: 'left',
        sortable: false,
        width: 20,
      },
      {
        text: this.translate('plannings_plbs_position'),
        align: 'left',
        sortable: false,
        width: 200,
      },
    ];
    this.loader = true;
    try {
      const planning = await getBalanceSheetForPlanning(this.$route.params.planningId);
      // eslint-disable-next-line arrow-body-style
      const headers = planning.periods.map((el) => {
        return {
          text: el,
          align: 'left',
          sortable: false,
        };
      });
      this.headers = this.headers.concat(headers);
      this.items = planning.rows;
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
  },
  methods: {
    toggleExpandedItem(item) {
      // eslint-disable-next-line
      item.isExpanded = !item.isExpanded;
      this.expanded.push(item);
    },
    markRow(item) {
      this.selectedId = item.code;
      this.$set(item, 'selected', true);
    },
  },
};
</script>

<style lang="scss">
table {
  border-collapse: collapse;

  tr {
    $input-size: 10px !important;

    td {
      font-size: $input-size;
      height: 37px !important;
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.12);;
    }

    &.subitem-level-1 {
      td {
        background-color: #F4F4F4;
        height: 37px;
      }
    }

    &.subitem-level-2 {
      td {
        background-color: #FAD874;
        height: 37px;
      }
    }

    &.subitem-level-3 {
      td {
        background-color: #F8EBC5;
        height: 37px;
      }
    }

    &.subitem-level-4 {
      td {
        background-color: #FCF7E9;
        height: 37px;
      }
    }
  }
}
</style>
