<template>
  <tr :class="[invalidData ? 'invalid' : '', 'planning-row']">
    <td :class="{ 'has-error': errorBucket.placement_period}">
      <v-select
        class="dropdown-value fixed-size"
        dense
        ref="placement_period"
        :rules="[validators.required]"
        v-model="item.placement_period"
        :hide-details="true"
        :items="placementPeriodValues"
      ></v-select>
    </td>
    <td :class="[errorBucket.asset_name ? 'has-error' : '', 'input-value']">
      <v-edit-dialog
        large
        eager
        color="accent"
        :return-value.sync="item.asset_name">
        {{ item.asset_name }}
        <template v-slot:input>
          <v-text-field v-model="item.asset_name"
            ref="asset_name"
            :rules="[validators.required]"
            single-line
            counter />
        </template>
      </v-edit-dialog>
    </td>
    <td :class="[errorBucket.cost_center_name ? 'has-error' : '', 'input-value']">
      <v-edit-dialog
        large
        eager
        :return-value.sync="item.cost_center_name">
        {{ item.cost_center_name }}
        <template v-slot:input>
          <v-text-field v-model="item.cost_center_name"
            ref="cost_center_name"
            single-line
            counter />
        </template>
      </v-edit-dialog>
    </td>
    <td :class="[errorBucket.cost_center_code ?'has-error' : '', 'input-value']">
      <v-edit-dialog
        eager
        large
        :return-value.sync="item.cost_center_code">
        {{ item.cost_center_code }}
        <template v-slot:input>
          <v-text-field v-model="item.cost_center_code"
            ref="cost_center_code"
            single-line
            counter />
        </template>
      </v-edit-dialog>
    </td>
    <td :class="[errorBucket.gl_account ? 'has-error' : '', 'input-value']">
      <v-edit-dialog
        large
        eager
        :return-value.sync="item.gl_account">
        {{ item.gl_account }}
        <template v-slot:input>
          <v-text-field v-model="item.gl_account"
            ref="gl_account"
            single-line
            counter />
        </template>
      </v-edit-dialog>
    </td>
    <td :class="{ 'has-error': errorBucket.finrep_bs_pl}">
      <v-select
        class="dropdown-value"
        dense
        ref="finrep_bs_pl"
        :rules="[validators.required]"
        v-model="item.finrep_bs_pl_id"
        :hide-details="true"
        :items="finrepBsPlOptions"
        item-text="name"
        item-value="id"
      ></v-select>
    </td>
    <td :class="[errorBucket.useful_life ? 'has-error' : '', 'input-value']">
      <v-edit-dialog
        large
        eager
        :return-value.sync="item.useful_life">
        {{ item.useful_life }}
        <template v-slot:input>
          <v-text-field v-model="item.useful_life"
            ref="useful_life"
            :rules="[validators.required]"
            single-line
            counter />
        </template>
      </v-edit-dialog>
    </td>
    <td :class="[errorBucket.depreciation_rate ? 'has-error' : '', 'input-value']">
      <v-edit-dialog
        large
        eager>
        {{ item.depreciation_rate | toPercentage('string') }}
        <template v-slot:input>
          <v-text-field
            type="number"
            :value="item.depreciation_rate | toPercentage"
            ref="depreciation_rate"
            @change="onDepreciationChange($event, 'depreciation_rate')"
            :rules="[validators.required]"
            single-line
            counter />
        </template>
      </v-edit-dialog>
    </td>
    <td :class="[errorBucket.capex_lcy ? 'has-error' : '', 'input-value']">
      <v-edit-dialog
        eager
        large
        :return-value.sync="item.capex_lcy">
        {{ item.capex_lcy }}
        <template v-slot:input>
          <v-text-field v-model="item.capex_lcy"
            ref="capex_lcy"
            single-line
            counter />
        </template>
      </v-edit-dialog>
    </td>
    <td :class="[errorBucket.capex_eur ? 'has-error' : '', 'input-value']">
      <v-edit-dialog
        eager
        large
        :return-value.sync="item.capex_eur">
        {{ item.capex_eur }}
        <template v-slot:input>`
          <v-text-field v-model="item.capex_eur"
            ref="capex_eur"
            single-line
            counter />
        </template>
      </v-edit-dialog>
    </td>
    <td :class="['input-value']">
      {{item.capex_total}}
    </td>
    <td ref="delete">
      <v-icon small
              @click.stop="deleteItem()">
        mdi-delete
      </v-icon>
    </td>
    <template v-if="item.depreciation && item.depreciation.length">
      <td v-for="(period, idx) in item.depreciation"
        class="depreciation input-value"
        :key="idx + 1 + Math.random()">
        {{period.value}}
      </td>
    </template>
    <template v-else>
      <td v-for="(period, idx) in capexDepreciationPeriods"
        class="depreciation input-value"
        :key="idx + 2 + Math.random()">
        0
      </td>
    </template>
    <template v-if="item.book_value && item.book_value.length">
      <td v-for="(period, idx) in item.book_value"
        class="input-value"
        :key="idx + 3 + Math.random()">
        {{period.value}}
      </td>
    </template>
    <template v-else>
      <td v-for="(period, idx) in capexBookValuePeriods"
        class="input-value"
        :key="idx + 4 + Math.random()">
        0
      </td>
    </template>
  </tr>
</template>

<script>
import { each } from 'lodash';
import { mapGetters } from 'vuex';
import validators from '@/validators';

export default {
  name: 'PlanningCapexRow',
  props: {
    item: {
      type: Object,
    },
    rowIndex: Number,
    placementPeriodValues: Array,
    capexDepreciationPeriods: Array,
    capexBookValuePeriods: Array,
  },
  data() {
    return {
      validators,
      invalidData: false,
      errorBucket: {},
      finrepBsPlOptions: [],
    };
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
    ...mapGetters('finrepBsPl', [
      'getAllOptions',
    ]),
  },
  created() {
    this.finrepBsPlOptions = this.getAllOptions;
  },
  methods: {
    onDepreciationChange(value, key) {
      this.item[key] = value / 100;
    },
    deleteItem() {
      this.$emit('delete', { item: this.item, rowIndex: this.rowIndex });
    },
    validate() {
      this.errorBucket = {};
      each(this.$refs, (val, key) => {
        if (val.errorBucket && val.errorBucket.length) this.errorBucket[key] = val.errorBucket;
      });

      if (Object.keys(this.errorBucket).length) {
        this.invalidData = true;
      } else {
        this.invalidData = false;
      }

      return this.errorBucket;
    },
  },
};
</script>

<style scoped lang="scss" >
.planning-row {
  $input-size: 10px !important;

  &.invalid > td:first-of-type{
    border-left: solid 4px #ff0000;
  }

  &.invalid > td {
    background: #fcecec;

    &.has-error {
      background: rgba(240, 52, 52, 1);
    }
  }

  .has-error {
    background: #fcecec;
  }

  .input-value {
    font-size: $input-size;
  }

  .dropdown-value {
    font-size: $input-size;

    &.fixed-size {
      width: 100px;

      &.medium {
        width: 150px;
      }

      &.large {
        width: 200px;
      }

      &.extra-large {
        width: 250px;
      }
    }
  }

  .depreciation {
    background: #eeeeee;
  }
}
</style>
