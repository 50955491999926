<template>
  <v-container fluid class="container">
    <Breadcrumbs :items="breadcrumbs" />
    <v-data-table
      fixed-header
      dense
      class="elevation-1"
      :page.sync="pagination.page"
      :server-items-length="pagination.total || items.length"
      :headers="headers"
      :items="items"
      :footer-props="{
        'items-per-page-options': pagination.itemsPerPage
      }"
      :items-per-page="pagination.size"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-col cols="12" sm="3" class="px-0">
            <v-text-field
              clearable
              v-model="search"
              append-icon="mdi-magnify"
              :label="translate('users_search_items')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.stop="addNewUser()">
            <v-icon class="mr-2">mdi-account-plus</v-icon>
            {{ translate("users_new_user") }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:header="props">
        <thead>
          <tr>
            <th :colspan="headers.length" class="text-center">
              {{ translate("user_management") }}
            </th>
          </tr>
          <tr>
            <th v-for="(header, idx) in props.headers" :key="idx">
              {{ header.text }}
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:body="props">
        <tbody>
          <UserRow
            v-for="(item, idx) in props.items"
            v-show="!search || (item.username.indexOf(search) !== -1)"
            :ref="`row-${idx}`"
            :key="item.id || item.tempId"
            :item="item"
            :rowIndex="idx"
            @delete="deleteUser"
          />
          <tr v-if="!props.items.length" class="v-data-table__empty-wrapper">
            <td :colspan="headers.length">{{ translate("no_items") }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-btn
      color="accent"
      fab
      fixed
      right
      bottom
      @click.stop="saveUsers()"
    >
      <v-icon dark>mdi-content-save</v-icon>
    </v-btn>
    <Snackbar :text="notificationText" :type="notificationType" />
    <v-overlay :value="loader" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <ConfirmDialog
      :dialog="deleteDialog"
      :description="deleteDialogDescription"
      @change="setDialogState"
      @confirmDelete="confirmDeleteAction"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import UserRow from '@/components/UserRow';
import ConfirmDialog from '@/components/ConfirmDialog';
import Snackbar from '../components/Snackbar';
import Breadcrumbs from '../components/Breadcrumbs';
import api from '../api/lbt-api';

const {
    getUsers,
    createUser,
    deleteUser,
    updateUser,
 } = api;
 export default {
name: 'UserManagement',
components: {
Snackbar,
UserRow,
Breadcrumbs,
ConfirmDialog,
},
data() {
return {
search: '',
notificationText: '',
notificationType: '',
deleteDialog: false,
deleteDialogDescription: '',
itemForDeletion: {},
loader: false,
headers: [],
items: [],
pagination: {
page: 1,
size: 25,
total: 0,
itemsPerPage: [25, 50, 100],
},
breadcrumbs: [
{
translationKey: 'sidebar_user_management',
route: '/user-management',
disabled: false,
},
{
translationKey: 'sidebar_user_management',
disabled: true,
},
],
};
},
async created() {
this.loader = true;
this.headers = [
{
text: 'Username',
align: 'left',
sortable: false,
value: 'username',
},
{
text: 'Email',
align: 'left',
sortable: false,
value: 'email',
},
{
text: 'Password',
align: 'left',
sortable: false,
value: 'password',
},
{
text: 'Role',
align: 'left',
sortable: false,
value: 'role',
},
{
text: this.translate('table_actions'),
align: 'left',
sortable: false,
value: 'actions',
},
];
try {
await this.fetchUserItems();
} catch (message) {
this.notificationText = message;
this.notificationType = 'error';
setTimeout(() => {
this.notificationText = null;
}, 100);
} finally {
this.loader = false;
}
},
computed: {
...mapGetters('translations', ['translate']),
},
methods: {
async fetchUserItems() {
const userVals = await getUsers(this.pagination);
this.items = userVals;
this.pagination.total = userVals.total;
},
addNewUser() {
this.items.unshift({
tempId: Math.random(),
username: '',
email: '',
role: '',
password: '',
});
},
async saveUsers() {
const rowNum = Object.keys(this.$refs).length;
const userCreateRequests = [];
const userUpdateRequests = [];
let payloadHasInvalidRow = false;
if (!rowNum) {
return;
}
for (let iterator = 0; iterator < rowNum; iterator += 1) {
    if (this.$refs[`row-${iterator}`].length) {
      this.$refs[`row-${iterator}`][0].validate();
      if (this.$refs[`row-${iterator}`][0].invalidData) {
        payloadHasInvalidRow = true;
      }
    }
  }

  if (payloadHasInvalidRow) {
    return;
  }

  this.loader = true;
  try {
    for (let iterator = 0; iterator < rowNum; iterator += 1) {
      if (!this.items[iterator].id) {
        userCreateRequests.push(createUser(this.items[iterator]));
      } else {
        userUpdateRequests.push(updateUser(this.items[iterator], this.items[iterator].id));
      }
    }
    await Promise.all(userCreateRequests);
    await Promise.all(userUpdateRequests);
    this.notificationText = this.translate('notifications_users_updated');
    this.notificationType = 'success';
    setTimeout(() => {
      this.notificationText = null;
    }, 100);
    await this.fetchUserItems();
  } catch (message) {
    this.notificationText = message;
     this.notificationType = 'error';
    setTimeout(() => { this.notificationText = null; }, 100);
  } finally {
    this.loader = false;
  }
},
deleteUser(item) {
  this.itemForDeletion = item;
  this.deleteDialog = true;
  this.deleteDialogDescription = this.translate('dialog_delete_action_description');
},
async confirmDeleteAction() {
  if (this.itemForDeletion.item.tempId) {
    this.$delete(this.items, this.itemForDeletion.rowIndex);
    this.deleteDialog = false;
    this.itemForDeletion = null;
    return;
  }
  this.loader = true;

  try {
    await deleteUser(this.itemForDeletion.item.id);
    this.deleteDialog = false;
    this.itemForDeletion = null;
    this.notificationText = this.translate('user deleted successfully');
    this.notificationType = 'success';
    setTimeout(() => { this.notificationText = null; }, 100);
    await this.fetchUserItems();
  } catch (message) {
    this.notificationText = message;
    this.notificationType = 'error';
    setTimeout(() => { this.notificationText = null; }, 100);
  } finally {
    this.loader = false;
  }
},
setDialogState(value) {
  this.deleteDialog = value;
},
},
};
</script>

<style lang='scss' scoped>
.container {
  padding-bottom: 5rem !important;
}
</style>
