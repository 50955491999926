<template>
  <v-container fluid class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>
    <v-toolbar flat>
      <ejs-dropdownlist id='dropdownlist_type' ref='dropdown_type' :dataSource='typeDropdownDataSource' :change='onTypeDropdownChange'
                        v-model="typeDropdown" placeholder='Report Type' width='200' class="pr-10"></ejs-dropdownlist>
      <ejs-dropdownlist id='dropdownlist_currency' ref='dropdown_currency' :dataSource='currencyDropdownDataSource' :change='onCurrencyDropdownChange'
                        v-model="currencyDropdown" placeholder='Currency' width='200' ></ejs-dropdownlist>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click='excelDownloadClick'>
        Export to Excel
      </v-btn>
    </v-toolbar>
    <v-spacer></v-spacer>
    <ejs-treegrid :dataSource='items' v-if='items' childMapping='sub' :treeColumnIndex='0' :height='600'
                  :allowTextWrap="true"
                  :allowExcelExport='true'
                  :excelHeaderQueryCellInfo='excelHeader'>
        <e-columns>
            <e-column field='summary_pos' headerText='Summary - Position Mapping' width='230'></e-column>
            <e-column field='position' headerText='Position' width='170'></e-column>
            <e-column textAlign='center' :columns='valuesColumns'>
            </e-column>
        </e-columns>
    </ejs-treegrid>
    <Snackbar :text="notificationText"
      :type="notificationType" />
    <v-overlay :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate
        size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import {
  TreeGridPlugin, ExcelExport,
} from '@syncfusion/ej2-vue-treegrid';
import Breadcrumbs from '@/components/Breadcrumbs';
import Snackbar from '@/components/Snackbar';
import api from '../api/lbt-api';

Vue.use(TreeGridPlugin);

const {
  getSummaryNewProductionForPlanning,
} = api;

export default {
  name: 'SummaryNewProduction',
  components: {
    Breadcrumbs,
    Snackbar,
  },
  data() {
    return {
      notificationText: '',
      loader: false,
      notificationType: null,
      headers: [],
      items: [],
      expanded: [],
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'plannings_new_production',
          disabled: true,
        },
      ],
      valuesColumns: [],
      highlightCellAttributes: { class: 'highlight-cell' },
      typeDropdown: 'Product Type',
      typeDropdownValue: 'product_type',
      typeDropdownDataSource: ['Product Type', 'Segment', 'Leasing Type'],
      currencyDropdown: 'LCY',
      currencyDropdownValue: 'lcy',
      currencyDropdownDataSource: ['LCY', 'EUR'],
    };
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
    ...mapState('plannings', [
      'activePlanning',
    ]),
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loader = true;
      try {
        const planning = await getSummaryNewProductionForPlanning(this.$route.params.planningId, this.typeDropdownValue, this.currencyDropdownValue);
        this.headers = planning.periods;
        this.items = planning.rows;
        this.prepareValuesGridHeaders();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    toggleExpandedItem(item) {
      // eslint-disable-next-line
      item.isExpanded = !item.isExpanded;
      this.expanded.push(item);
    },
    prepareValuesGridHeaders() {
      this.valuesColumns = [];
      const item = this.items[0];
      for (let i = 0; i < item.values.length; i += 1) {
        this.valuesColumns.push({
          field: `values.${i}`,
          headerText: i,
          headerValueAccessor: this.lookupValuesHeader,
          customAttributes: this.highlightCellAttributes,
          format: 'n2',
          width: 55,
          textAlign: 'left',
        });
      }
    },
    lookupValuesHeader(idx) {
      return this.headers[Number(idx)];
    },
    onTypeDropdownChange(selected) {
      const { value } = selected;
      switch (value) {
        case 'Product Type':
          this.typeDropdownValue = 'product_type';
          break;
        case 'Segment':
          this.typeDropdownValue = 'segment';
          break;
        case 'Leasing Type':
          this.typeDropdownValue = 'leasing_type';
          break;
        default:
          break;
      }

      setTimeout(this.fetchData, 100);
    },
    onCurrencyDropdownChange(selected) {
      const { value } = selected;
      switch (value) {
        case 'LCY':
          this.currencyDropdownValue = 'lcy';
          break;
        case 'EUR':
          this.currencyDropdownValue = 'eur';
          break;
        default:
          break;
      }

      setTimeout(this.fetchData, 100);
    },
    excelDownloadClick() {
      const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0];
      const excelExportProperties = {
        exportType: 'CurrentPage',
        fileName: `Summary New Production - Planning - ${this.activePlanning.year}-${this.activePlanning.month}.xlsx`,
      };
      grid.excelExport(excelExportProperties);
    },
    excelHeader(args) {
      const { cell } = args;
      if (cell.index > 2) {
        cell.value = this.headers[cell.value];
      }
    },
  },
  provide: {
    treegrid: [ExcelExport],
  },
};
</script>

<style>
  @import '../../node_modules/@syncfusion/ej2-vue-treegrid/styles/material.css';

  .e-grid .e-headercell.highlight-cell, .highlight-cell {
    background: #eeeeee;
  }
  .e-valid-input {
    margin-right: 10px;
  }
  .e-headercelldiv {
    text-align: center !important;
  }
</style>
