import Vue from 'vue';
import JsonExcel from 'vue-json-excel';
import App from './App';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './styles/main.scss';
import './filters/toPercentage';
import './filters/toPercentageExtended';
import './filters/date';

Vue.component('downloadExcel', JsonExcel);
Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
