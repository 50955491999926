export function ejsSingleClickToEditCell(grid) {
  grid.element.addEventListener('mousedown', (e) => {
    if (e.target.classList.contains('e-rowcell')) {
      const index = Number(e.target.getAttribute('Index'));
      const colindex = Number(e.target.getAttribute('aria-colindex'));
      const { field } = grid.getColumns()[colindex];
      grid.editModule.editCell(index, field);
    }
  });
}
