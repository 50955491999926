<template>
  <v-container fluid
    class="container">
    <Breadcrumbs
      :items="breadcrumbs"/>
    <v-data-table fixed-header
      dense
      class="elevation-1"
      :page.sync="pagination.page"
      :server-items-length="pagination.total || items.length"
      :headers="headers"
      :items="items"
      :footer-props="{
        'items-per-page-options': pagination.itemsPerPage
      }"
      :items-per-page="pagination.size">
      <template v-slot:top>
        <v-toolbar flat>
          <v-col cols="12" sm="3"
            class="px-0">
            <v-text-field
              clearable
              v-model="search"
              append-icon="mdi-magnify"
              :label="translate('plannings_capex_search_items')"
              single-line
              hide-details
            ></v-text-field>
           </v-col>
          <v-spacer></v-spacer>
          <v-btn color="primary"
              @click.stop="addNewPlanningItem()">
            <v-icon class="mr-2">mdi-table-large-plus</v-icon>
            {{translate('plannings_new_capex_item')}}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:header="props">
        <thead>
          <tr>
            <th :colspan="headers.length - capexDepreciationPeriods.length - capexBookValuePeriods.length"
              class="text-center">
              {{translate('plannings_capex_items')}}
            </th>
            <th :colspan="capexDepreciationPeriods.length"
              class="text-center depreciation">
              {{translate('plannings_capex_depreciation')}}
            </th>
            <th :colspan="capexBookValuePeriods.length"
              class="text-center">
              {{translate('plannings_capex_book_value')}}
            </th>
          </tr>
          <tr>
            <th v-for="(header, idx) in props.headers"
              :key="idx + '_'">
              {{header.text}}
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:body="props">
        <tbody>
          <PlanningCapexRow v-for="(item, idx) in props.items"
            v-show="!search || (item.asset_name.indexOf(search) !== -1)"
            :ref="`row-${idx}`"
            :key="item.id || item.tempId"
            :item="item"
            :rowIndex="idx"
            :placementPeriodValues="placementPeriodValues"
            :capexDepreciationPeriods="capexDepreciationPeriods"
            :capexBookValuePeriods="capexBookValuePeriods"
            @delete="deleteItem" />
          <!--
          <tr>
            <td v-for="col in headers" v-bind:key="col.value + '-x'"><b> { sumColumn(col) }</b></td>
          </tr>
          -->
          <tr v-if="!props.items || !props.items.length"
            class="v-data-table__empty-wrapper">
            <td :colspan="headers.length">{{translate('plannings_capex_no_items')}}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-btn color="accent"
      fab
      fixed
      right
      bottom
      @click.stop="savePlanning()">
      <v-icon dark>mdi-content-save</v-icon>
    </v-btn>
    <Snackbar :text="notificationText"
      :type="notificationType" />
    <v-overlay :value="loader"
      :z-index="1000">
      <v-progress-circular indeterminate
        size="64"></v-progress-circular>
    </v-overlay>
    <ConfirmDialog
      :dialog="deleteDialog"
      :description="deleteDialogDescription"
      @change="setDialogState"
      @confirmDelete="confirmDeleteAction"
    />
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import PlanningCapexRow from '@/components/PlanningCapexRow';
import ConfirmDialog from '@/components/ConfirmDialog';
import Snackbar from '../components/Snackbar';
import Breadcrumbs from '../components/Breadcrumbs';
import { generatePlacementPeriodsCapex } from '../utilities/placementPeriod';
import { generateCapexDepreciationPeriods } from '../utilities/capexDepreciationPeriods';

import api from '../api/lbt-api';
import store from '../store/index';

const {
  getCapexForPlanning,
  createCapexForPlanning,
  getPlanning,
  deleteCapexForPlanning,
  updateCapexForPlanning,
} = api;

export default {
  name: 'CapexPlanning',
  components: {
    Snackbar,
    PlanningCapexRow,
    Breadcrumbs,
    ConfirmDialog,
  },
  data() {
    return {
      search: '',
      notificationText: '',
      notificationType: '',
      deleteDialog: false,
      deleteDialogDescription: '',
      itemForDeletion: {},
      loader: false,
      headers: [],
      items: [],
      pagination: {
        page: 1,
        size: 100,
        total: 0,
        itemsPerPage: [100, 200, 500],
      },
      breadcrumbs: [
        {
          translationKey: 'breadcrumbs_plannings',
          route: '/plannings',
          disabled: false,
        },
        {
          translationKey: 'breadcrumbs_plannings_capex',
          disabled: true,
        },
      ],
      placementPeriodValues: [],
      capexDepreciationPeriods: [],
      capexBookValuePeriods: [],
    };
  },
  async created() {
    this.loader = true;
    this.headers = [
      {
        text: this.translate('plannings_capex_placement_period'),
        align: 'left',
        sortable: false,
        value: 'placement_period',
      },
      {
        text: this.translate('plannings_capex_asset_name'),
        align: 'left',
        sortable: false,
        value: 'asset_name',
      },
      {
        text: this.translate('plannings_capex_cost_center_name'),
        align: 'left',
        sortable: false,
        value: 'cost_center_name',
      },
      {
        text: this.translate('plannings_capex_cost_center_code'),
        align: 'left',
        sortable: false,
        value: 'cost_center_code',
      },
      {
        text: this.translate('plannings_capex_gl_account'),
        align: 'left',
        sortable: false,
        value: 'gl_account',
      },
      {
        text: this.translate('plannings_capex_finrep_bs_pl_id'),
        align: 'left',
        sortable: false,
        value: 'finrep_bs_pl_id',
      },
      {
        text: this.translate('plannings_capex_useful_life'),
        align: 'left',
        sortable: false,
        value: 'useful_life',
      },
      {
        text: this.translate('plannings_capex_depreciation_rate'),
        align: 'left',
        sortable: false,
        value: 'depreciation_rate',
      },
      {
        text: this.translate('plannings_capex_lcy'),
        align: 'left',
        sortable: false,
        value: 'capex_lcy',
      },
      {
        text: this.translate('plannings_capex_eur'),
        align: 'left',
        sortable: false,
        value: 'capex_eur',
      },
      {
        text: this.translate('plannings_capex_total'),
        align: 'left',
        sortable: false,
        value: 'capex_total',
      },
      {
        text: this.translate('table_actions'),
        align: 'left',
        sortable: false,
        value: 'actions',
      },
    ];
    try {
      await this.fetchPlanningCapexItems();
      const planning = await getPlanning(this.$route.params.planningId);
      this.placementPeriodValues = generatePlacementPeriodsCapex(planning.year, planning.month);
      this.capexDepreciationPeriods = generateCapexDepreciationPeriods(planning.year, planning.month - 1);
      this.capexBookValuePeriods = generateCapexDepreciationPeriods(planning.year, planning.month - 1);
    } catch (message) {
      this.notificationText = message;
      this.notificationType = 'error';
      setTimeout(() => { this.notificationText = null; }, 100);
    } finally {
      this.loader = false;
    }
    this.capexDepreciationPeriods.forEach((el) => {
      this.headers.push({
        text: el,
        align: 'left',
        sortable: false,
        class: 'depreciation',
        value: el,
      });
    });
    this.capexBookValuePeriods.forEach((el) => {
      this.headers.push({
        text: el,
        align: 'left',
        sortable: false,
        class: 'book_value',
        value: el,
      });
    });
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
  },
  methods: {
    async fetchPlanningCapexItems() {
      const capex = await getCapexForPlanning(this.$route.params.planningId, this.pagination);
      this.items = capex.capex_entries;
      this.pagination.total = capex.pagination.total;
    },
    addNewPlanningItem() {
      this.items.unshift({
        tempId: Math.random(),
        placement_period: 0,
        asset_name: '',
        cost_center_name: '',
        cost_center_code: '',
        gl_account: null,
        finrep_bs_pl_id: 0,
        useful_life: 0,
        depreciation_rate: 0,
        capex_lcy: 0,
        capex_eur: 0,
        capex_total: 0,
      });
    },
    async savePlanning() {
      const rowNum = Object.keys(this.$refs).length;
      const capexCreateRequests = [];
      const capexUpdateRequests = [];
      let payloadHasInvalidRow = false;
      if (!rowNum) {
        return;
      }

      for (let iterator = 0; iterator < rowNum; iterator += 1) {
        if (this.$refs[`row-${iterator}`].length) {
          this.$refs[`row-${iterator}`][0].validate();
          if (this.$refs[`row-${iterator}`][0].invalidData) {
            payloadHasInvalidRow = true;
          }
        }
      }

      if (payloadHasInvalidRow) {
        return;
      }

      // TODO: Send all capex items in bulk request
      this.loader = true;
      try {
        for (let iterator = 0; iterator < rowNum; iterator += 1) {
          if (!this.items[iterator].id) {
            capexCreateRequests.push(createCapexForPlanning(this.$route.params.planningId, this.items[iterator]));
          } else {
            capexUpdateRequests.push(updateCapexForPlanning(this.$route.params.planningId, this.items[iterator], this.items[iterator].id));
          }
        }
        await Promise.all(capexCreateRequests);
        await Promise.all(capexUpdateRequests);
        this.notificationText = this.translate('notifications_planning_updated');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlanningCapexItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    sumColumn(item) {
      let sum = 0;

      if (item.class) {
        for (let i = 0; i < this.items.length; i += 1) {
          for (let j = 0; j < this.items[i][item.class].length; j += 1) {
            if (this.items[i][item.class][j].period === item.value) {
              sum += this.items[i][item.class][j].value;
              break;
            }
          }
        }
        return sum.toFixed(2);
      }

      if (item.value === 'capex_lcy' || item.value === 'capex_eur' || item.value === 'capex_total') {
        for (let i = 0; i < this.items.length; i += 1) {
          sum += parseInt(this.items[i][item.value], 8);
        }
        return sum.toFixed(2);
      }

      return '';
    },
    deleteItem(item) {
      this.itemForDeletion = item;
      this.deleteDialog = true;
      this.deleteDialogDescription = this.translate('dialog_delete_action_description');
    },
    async confirmDeleteAction() {
      if (this.itemForDeletion.item.tempId) {
        this.$delete(this.items, this.itemForDeletion.rowIndex);
        this.deleteDialog = false;
        this.itemForDeletion = null;
        return;
      }
      this.loader = true;

      try {
        await deleteCapexForPlanning(this.$route.params.planningId, this.itemForDeletion.item.id);
        this.deleteDialog = false;
        this.itemForDeletion = null;
        this.notificationText = this.translate('notifications_item_deleted_successfully');
        this.notificationType = 'success';
        setTimeout(() => { this.notificationText = null; }, 100);
        await this.fetchPlanningCapexItems();
      } catch (message) {
        this.notificationText = message;
        this.notificationType = 'error';
        setTimeout(() => { this.notificationText = null; }, 100);
      } finally {
        this.loader = false;
      }
    },
    setDialogState(value) {
      this.deleteDialog = value;
    },
  },
  async beforeRouteEnter(from, to, next) {
    await store.dispatch('finrepBsPl/getOptions');
    next();
  },
};
</script>

<style lang="scss" scoped>

.container {
  padding-bottom: 5rem !important;
}

</style>
