<template>
  <v-snackbar
    v-model="visible"
    :color="color"
    :top="true"
    :right="true"
    :left="false"
    :bottom="false"
    :timeout="type === 'error' ? 30000 : 3000"
    :multi-line="true">
    {{ notification }}
    <v-btn
      text
      @click="visible = false">
      {{this.translate('button_close')}}
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';

const STATUS_COLOR_MAP = {
  success: 'success',
  info: 'info',
  error: 'error',
  general: 'darken-2',
  warning: 'secondary',
};

export default {
  name: 'Snackbar',
  props: [
    'type',
    'text',
  ],
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
  },
  data() {
    return {
      visible: false,
      notification: '',
      color: STATUS_COLOR_MAP.general,
    };
  },
  watch: {
    text() {
      if (this.text) {
        this.color = STATUS_COLOR_MAP[this.type] || STATUS_COLOR_MAP.general;
        this.notification = this.text;
        this.visible = true;
      }
    },
  },
};
</script>
