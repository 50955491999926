export function generateFinancingVolumesPeriods(startYear, startMonth) {
  const numOfMonths = 12;
  const numOfQuarters = 4;
  const periods = [];
  let monthAddition = 1;
  let yearAddition = 1;
  let month = startMonth;

  if (startMonth <= numOfMonths) {
    const financingVolume = {
      fy: 0,
      periods: [],
      year: startYear,
    };
    do {
      financingVolume.periods.push({
        period: `M${month}`,
        value: 0,
      });
      month = startMonth + monthAddition;
      monthAddition += 1;
    } while (month <= numOfMonths);
    periods.push(financingVolume);
  }

  while (startYear + yearAddition <= startYear + 5) {
    const nextYear = startYear + yearAddition;
    const financingVolume = {
      fy: 0,
      periods: [],
      year: nextYear,
    };
    for (let iterator = 1; iterator <= numOfQuarters; iterator += 1) {
      financingVolume.periods.push({
        period: `Q${iterator}`,
        value: 0,
      });
    }
    periods.push(financingVolume);
    yearAddition += 1;
  }
  return periods;
}
