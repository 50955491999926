<template>
  <v-navigation-drawer
    v-model="drawable"
    app
    clipped
    >
    <v-list
      nav
      dense
      >
      <SidebarNestedItems
        v-if="$route.meta.sidebarNestedItems && $route.meta.sidebarNestedItems.active"
        :supplant-keys="supplantKeys"
        :dropdown="$route.meta.sidebarNestedItems"
      />
      <router-link
        exact
        v-if="!$route.meta.sidebarNestedItems || !$route.meta.sidebarNestedItems.active"
        tag="v-list-item"
        active-class="router-link-active v-list-item--active"
        to="/plannings">
        <v-list-item-icon>
          <v-icon>mdi-gavel</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{translate('sidebar_plannings')}}
          </v-list-item-title>
        </v-list-item-content>
      </router-link>
      <router-link
        exact
        tag="v-list-item"
        active-class="router-link-active v-list-item--active"
        to="/validator">
        <v-list-item-icon>
          <v-icon>mdi-forward</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{translate('Validator')}}
          </v-list-item-title>
        </v-list-item-content>
      </router-link>
      <router-link
        exact
        tag="v-list-item"
        active-class="router-link-active v-list-item--active"
        to="/options">
        <v-list-item-icon>
          <v-icon>mdi-forward</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{translate('sidebar_parameter_values')}}
          </v-list-item-title>
        </v-list-item-content>
      </router-link>
      <router-link
        exact
        tag="v-list-item"
        active-class="router-link-active v-list-item--active"
        to="/finrep-product-mapping">
        <v-list-item-icon>
          <v-icon>mdi-table</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{translate('sidebar_finprep_product_mapping')}}
          </v-list-item-title>
        </v-list-item-content>
      </router-link>
      <router-link
        exact
        tag="v-list-item"
        active-class="router-link-active v-list-item--active"
        to="/users">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{translate('Users')}}
          </v-list-item-title>
        </v-list-item-content>
      </router-link>
      <router-link
        exact
        tag="v-list-item"
        active-class="router-link-active v-list-item--active"
        to="/user_activity_logs">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{'User activity logs'}}
          </v-list-item-title>
        </v-list-item-content>
      </router-link>
      <router-link
        exact
        tag="v-list-item"
        active-class="router-link-active v-list-item--active"
        to="/finrep-konto-mapping">
        <v-list-item-icon>
          <v-icon>mdi-package-variant-closed</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{translate('sidebar_finprep_konto_mapping')}}
          </v-list-item-title>
        </v-list-item-content>
      </router-link>
      <v-list-group
        prepend-icon="mdi-page-next-outline"
        :value="false"
      >
        <template v-slot:activator>
          <v-list-item-title>
            {{translate('sidebar_other_finrep')}}
          </v-list-item-title>
        </template>

        <router-link
          exact
          tag="v-list-item"
          active-class="router-link-active v-list-item--active"
          to="/other-finrep">
          <v-list-item-content>
            <v-list-item-title>
              {{translate('sidebar_other_finrep_all')}}
            </v-list-item-title>
          </v-list-item-content>
        </router-link>

        <router-link
          exact
          tag="v-list-item"
          active-class="router-link-active v-list-item--active"
          to="/other-type">
          <v-list-item-content>
            <v-list-item-title>
              {{translate('sidebar_other_finrep_types')}}
            </v-list-item-title>
          </v-list-item-content>
        </router-link>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import SidebarNestedItems from './SidebarNestedItems';

export default {
  name: 'Sidebar',
  props: ['drawer'],
  components: {
    SidebarNestedItems,
  },
  created() {
    this.supplantKeys.planningId = this.$route.params.planningId;
  },
  data() {
    return {
      drawable: this.drawer,
      otherFinrepNestedItems: {
        active: true,
        translationKey: 'sidebar_plannings',
        prependIcon: 'mdi-gavel',
        items: [
          {
            translationKey: 'sidebar_plannings_capex',
            routeSupplantKeys: ['planningId'],
            route: '/plannings/{planningId}',
          },
          {
            translationKey: 'sidebar_plannings_sales',
            routeSupplantKeys: ['planningId'],
            route: '/plannings/{planningId}/salesnew',
          },
        ],
      },
      supplantKeys: {},
    };
  },
  computed: {
    ...mapGetters('translations', [
      'translate',
    ]),
  },
  watch: {
    drawer() {
      this.drawable = this.drawer;
    },
    drawable() {
      this.$emit('change', this.drawable);
    },
    $route() {
      this.supplantKeys.planningId = this.$route.params.planningId;
    },
  },
};
</script>
